import { PropsWithChildren } from "react";
import { Skeleton } from "src/main/components";

interface SkeletonLoaderProps extends PropsWithChildren {}

const SkeletonLoader = (props: SkeletonLoaderProps) => {
  return (
    <>
      <div className="mt-8 flex w-full flex-col px-3">
        <Skeleton
          component={Skeleton.Box}
          count={2}
          className="mt-3 !h-40 w-full"
        />
        <Skeleton
          component={Skeleton.Box}
          className="mt-3 !h-12 w-full"
        />
      </div>
    </>
  );
};

export default SkeletonLoader;
