import { clsx } from "clsx";
import { PropsWithClassName } from "src/main/components";
import "./AnimatedGiftBox.css";

interface AnimatedGiftBoxProps extends PropsWithClassName {}

const AnimatedGiftBox = ({ className }: AnimatedGiftBoxProps) => {
  return (
    <div className={clsx("relative h-20 w-20", className)}>
      <img
        src="/images/icon/glass-giftbox-bg.png"
        alt="giftbox"
        className={clsx("gift-box__background absolute top-0 left-0 h-20 w-20", className)}
      />
      <img
        src="/images/icon/glass-giftbox-fg.png"
        alt="giftbox"
        className={clsx("gift-box__foreground absolute top-0 left-0 h-20 w-20", className)}
      />
    </div>
  );
};

export default AnimatedGiftBox;
