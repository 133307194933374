import React from "react";
import { useTranslation } from "react-i18next";
import { FaArrowRight } from "react-icons/fa6";
import CoinAmount from "./CoinAmount";
import "./GiftIcon.css";
import StrokeText from "./StrokeText";

const GiftBody: React.FC<GiftBodyProps> = ({ onClick, onClickContinue, redeemAnimation, amount }) => {
  const { t } = useTranslation();
  return (
    <>
      <div className="mx-auto w-full text-center text-white">
        <div className={`p-2 text-center text-lg font-bold sm:text-3xl`}>{t("Today Bonus for You")}!</div>
        {!amount && <div className="text-center text-xs sm:text-base">{t("Tap anywhere to reveal a daily gift")}!</div>}
      </div>
      {!!amount && (
        <div className="absolute top-2/3 left-1/2 z-[51] w-full -translate-x-1/2  -translate-y-1/2 transform text-center">
          <div className="relative flex flex-col items-center justify-center">
            <div className="absolute -top-[84px] h-[264px] w-[300px] bg-[radial-gradient(45.35%_45.35%_at_50%_50%,#FFF_0%,rgba(255,246,167,0.50)_59.4%,rgba(255,230,0,0.00)_100%)]"></div>
            <CoinAmount text={amount ? amount : t("already redeemed")} />

            <StrokeText text={t("points collected")} />

            <button
              type="button"
              onClick={onClickContinue}
              className="btn-orange mt-3 flex w-2/3 max-w-[300px] items-center justify-center capitalize md:text-2xl lg:text-base"
            >
              {t("continue")}

              <FaArrowRight className="mx-1 inline-block h-[18px]" />
            </button>
          </div>
        </div>
      )}
      {/*{redeemAnimation ? <FinishScreen onClick={onClickContinue} /> : <ReadyScreen onClick={onClick} />}*/}
    </>
  );
};

export default GiftBody;

GiftBody.defaultProps = {};

interface GiftBodyProps {
  onClick: (evt: React.MouseEvent<HTMLImageElement>) => void;
  onClickContinue: (evt: React.MouseEvent) => void;
  redeemAnimation: boolean;
  amount: undefined | string;
}
