import React from "react";
import { useLocation } from "react-router-dom";
import { ProfileView } from "src/main/views/component";
import OrderHistoryContent from "./components/OrderHistoryContent";

const OrderHistory: React.FC = () => {
  const { hash } = useLocation();
  const showPointInfo = hash === "#point";

  return (
    <ProfileView title={showPointInfo ? "points history" : "order history"}>
      <OrderHistoryContent />
    </ProfileView>
  );
};

export default OrderHistory;
