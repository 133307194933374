import { clsx } from "clsx";
import { throttle } from "lodash";
import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "src/main/components/_inputs/_core";
import { useTypedSelector } from "src/main/hooks";
import { RewardBoxStatus } from "src/main/views/RewardsPage/types";
import useActions from "../../../../hooks/useActions";

interface ClaimButtonProps {
  onClick: () => void;
  status?: RewardBoxStatus;
  loading?: boolean;
}

export default function ClaimButton({ onClick, status, loading }: ClaimButtonProps) {
  const { t } = useTranslation();
  const actions = useActions();
  const state = useTypedSelector((state) => state);
  const openPlayNow = useCallback(() => {
    // toggle play now modal
    const launchState = !state.layout.playnow.launch;
    actions.launchPlaynow({ launch: launchState });
  }, [state, actions]);

  const handleOnClick = () => {
    if (status === RewardBoxStatus.LOCKED || status === RewardBoxStatus.GET_MORE_POINTS) {
      // open play now
      openPlayNow();
      return;
    }
    onClick();
  };

  const buttonText = useMemo(() => {
    // convert to switch
    switch (status) {
      case RewardBoxStatus.CLAIMABLE:
        return t("claim");
      case RewardBoxStatus.CLAIMED:
        return t("received");
      case RewardBoxStatus.LOCKED:
      case RewardBoxStatus.GET_MORE_POINTS:
        return t("Get More Points");
      default:
        return "";
    }
  }, [t, status]);

  const throttleOnClick = useMemo(
    () =>
      throttle(handleOnClick, 2000, {
        leading: true,
        trailing: false,
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [status],
  );

  const buttonVariant = useMemo(() => {
    switch (status) {
      case RewardBoxStatus.CLAIMED:
        return "secondary";
      case RewardBoxStatus.LOCKED:
      default:
        return "green";
    }
  }, [status]);

  return (
    <div className="w-full">
      <Button
        className={clsx(
          "w-full animate-fade-in cursor-pointer items-center justify-center rounded-md border border-[rgba(255,255,255,0.24)] py-1 px-2 text-xs font-bold uppercase leading-[22px] text-white transition-colors duration-1000 [animation-duration:1s]",
          {
            "pointer-events-none": loading || status === RewardBoxStatus.LOCKED || status === RewardBoxStatus.CLAIMED,
            "max-[360px]:text-xxs":
              status && [RewardBoxStatus.LOCKED, RewardBoxStatus.GET_MORE_POINTS].includes(status),
            "hover:!bg-[radial-gradient(circle_at_50%_14.81%,#96e24e_0%,#48890A_100%)]":
              status && [RewardBoxStatus.CLAIMABLE].includes(status),
          },
        )}
        size="sm"
        variant={buttonVariant}
        loading={loading}
        onClick={throttleOnClick}
      >
        {buttonText}
      </Button>
    </div>
  );
}
