import { BuilderProp } from "src/main/api/site";
import { MissionModel, QueryResult, QueryResultEntries } from "src/main/types";
import { require200Status } from "src/main/utils";

export interface ListMissionResponse extends QueryResultEntries<MissionModel[]> {}

export const listMission = (builder: BuilderProp) => {
  return builder.query<ListMissionResponse, void>({
    providesTags: ["mission"],
    query: () => ({
      url: `/member/mission/list`,
      validateStatus: require200Status,
    }),
    transformResponse: (response: QueryResult<ListMissionResponse>) => {
      return response.result;
    },
  });
};

export interface UpdateMemberMissionInfoRequest {
  pwaDownloaded: boolean;
}

export const updateMemberMissionInfo = (builder: BuilderProp) => {
  return builder.mutation<void, UpdateMemberMissionInfoRequest>({
    invalidatesTags: ["mission"],
    query: (req) => ({
      url: `/member/mission/info`,
      method: "POST",
      validateStatus: require200Status,
      body: req,
    }),
    transformResponse: (response: QueryResult<void>) => {
      return response.result;
    },
  });
};

export interface ClaimMissionRewardRequest {
  id: string;
}

export const claimMissionReward = (builder: BuilderProp) => {
  return builder.mutation<void, string>({
    invalidatesTags: ["mission"],
    query: (id) => ({
      url: `/member/mission/${id}/claim`,
      method: "POST",
      validateStatus: require200Status,
    }),
    transformResponse: (response: QueryResult<void>) => {
      return response.result;
    },
  });
};
