import React, { useCallback, useMemo, useRef } from "react";
import { useSelector } from "react-redux";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import { Banner, LoadingContainer, Skeleton } from "src/main/components";
import { PromotionBannerModel } from "src/main/models";
import { RootState } from "src/main/store";
import { UserState } from "src/main/store/user/slices";
import { ResourceType } from "src/main/types";
import { getResourceType } from "src/main/utils";

const SLIDER_SETTINGS = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 5000,
  arrows: false,
};

const PromotionBannerSection: React.FC = () => {
  const sliderRef = useRef<Slider | null>(null);
  const videoRef = useRef<HTMLVideoElement | null>(null);

  const { promoBanner } = useSelector<RootState, UserState>((state) => state.user);

  const handlerSlideChange = (current: number, next: number) => {
    const bannerArray = promoBanner;
    if (!bannerArray?.length) return;
    const nextSlideResourceType = getResourceType(bannerArray?.[next]?.image?.url ?? "");
    if (nextSlideResourceType === ResourceType.Video && videoRef.current) {
      videoRef.current.play();
    }
  };

  const isFetchingBanner = useMemo(() => {
    return promoBanner === null;
  }, [promoBanner]);

  const handleVideoEnded = () => {
    // if (sliderRef.current) {
    //     sliderRef?.current?.slickNext();
    //     sliderRef?.current?.slickPlay();
    // }
    // if (videoRef.current) {
    //     videoRef.current.currentTime = 0.02;
    //     videoRef.current.pause();
    // }
  };
  const handleVideoStartPlay = useCallback(() => {
    //set pause to slider
    if (sliderRef.current) {
      sliderRef?.current?.slickPause();
    }
    if (videoRef.current) {
      videoRef.current.currentTime = 0;
      videoRef.current.play();
    }
  }, []);

  const handleTimeUpdate = () => {
    const duration = videoRef.current?.duration;
    if (videoRef.current?.currentTime && duration && videoRef.current?.currentTime > duration - 0.1) {
      videoRef.current.pause();
      sliderRef?.current?.slickNext();
      sliderRef?.current?.slickPlay();
    }
  };

  return (
    <LoadingContainer
      loader={
        <Skeleton
          component={Skeleton.Box}
          componentClassName="!rounded-none"
          className="aspect-[16/5] w-full"
        />
      }
      loading={isFetchingBanner}
    >
      {!!promoBanner?.length && (
        <div className="relative h-auto">
          <Slider
            ref={sliderRef}
            beforeChange={handlerSlideChange}
            {...SLIDER_SETTINGS}
          >
            {promoBanner?.map((item: PromotionBannerModel, index) => (
              <Banner
                data={item}
                key={index}
                ignoreActionOnTypes={[ResourceType.Video]}
                wrapperClassName={{
                  image: "w-full items-center image-slide",
                  video: "w-full items-center video-slide",
                }}
                imgProps={{
                  className: "block w-full object-fill h-full",
                }}
                videoProps={{
                  className: "w-full h-full object-cover",
                  autoPlay: true,
                  loop: true,
                  muted: true,
                  onTimeUpdate: handleTimeUpdate,
                  onPlay: handleVideoStartPlay,
                  onEnded: handleVideoEnded,
                  controls: true,
                  ref: videoRef,
                }}
              />
            ))}
          </Slider>
        </div>
      )}
    </LoadingContainer>
  );
};

export default PromotionBannerSection;
