export enum RewardBoxStatus {
  CLAIMED = "CLAIMED", // already claimed
  CLAIMABLE = "CLAIMABLE", // can claim
  LOCKED = "LOCKED", // waiting for next period
  GET_MORE_POINTS = "GET_MORE_POINTS", // claimPoints is 0
}

export interface RewardBoxProps {
  claimPoints: number;
  remainingSeconds: number;
}

export interface RewardInfoData {
  remainingSeconds: number;
  periodInSeconds: number;
  claimPoints?: number;
}
