import { clsx } from "clsx";
import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { IcoLine, IcoMessenger } from "src/assets";
import { SOCIAL_MEDIA_TYPE } from "src/main/contants";
import { DeployCountry } from "src/main/types";
import { getDeployEnv } from "src/main/utils";

interface SupportButtonProps {
  className?: string;
  textRenderer?: (deployCountry: DeployCountry, platform: string) => string;
}

const ContactSupportButton: React.FC<SupportButtonProps> = ({ className, textRenderer }: SupportButtonProps) => {
  const { t } = useTranslation();
  const currentEnv = getDeployEnv();

  const { contactPlatform, contactUrl } = useMemo(() => {
    let platform = SOCIAL_MEDIA_TYPE.LINE;
    let url = "";

    // Get primary contact platform
    const contactPlatform = currentEnv.contactPlatforms.find((platform) => platform.primary);
    if (!!contactPlatform) {
      platform = contactPlatform.type;
      url = contactPlatform.url;
    }

    return {
      contactPlatform: platform,
      contactUrl: url,
    };
  }, [currentEnv.contactPlatforms]);

  const handleOnClick = useCallback(() => {
    window.open(contactUrl, "_blank");
  }, [contactUrl]);

  const contactIcon = useMemo(() => {
    switch (currentEnv.deployCountry) {
      case DeployCountry.PH:
        return <IcoMessenger className="my-1.5 mr-2 h-6 w-6" />;
      case DeployCountry.TH:
        return <IcoLine />;
      default:
        return <IcoLine />;
    }
  }, [currentEnv.deployCountry]);

  const contactButtonClassName = useMemo(() => {
    switch (currentEnv.deployCountry) {
      case DeployCountry.PH:
        return "bg-gradient-to-r from-[rgba(12,55,177,1)] to-[rgba(88,123,221,1)]";
      case DeployCountry.TH:
        return "bg-gradient-to-r from-[rgba(55,177,12,1)] to-[rgba(123,221,89,1)]";
      default:
        return "bg-gradient-to-r from-[rgba(55,177,12,1)] to-[rgba(123,221,89,1)]";
    }
  }, [currentEnv.deployCountry]);

  return (
    <button
      type="button"
      className={clsx(
        "inline-flex items-center rounded-lg px-4 py-2 text-center text-sm font-bold text-white text-shadow-md",
        contactButtonClassName,
        className,
      )}
      onClick={handleOnClick}
    >
      <div className="flex w-full items-center justify-center">
        {contactIcon}
        {!!textRenderer
          ? textRenderer(currentEnv.deployCountry ?? DeployCountry.TH, contactPlatform)
          : t("Contact Support")}
      </div>
    </button>
  );
};

export default ContactSupportButton;
