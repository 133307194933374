import { clsx } from "clsx";
import React from "react";
import { Skeleton } from "src/main/components";

interface SkeletonLoaderProps extends React.HTMLAttributes<HTMLDivElement> {}

function SkeletonLoader(props: SkeletonLoaderProps) {
  return (
    <>
      {/*DESKTOP VIEW*/}
      <div
        {...props}
        className={clsx("hidden grid-cols-9 gap-x-4 lg:grid", props.className)}
      >
        <div className="col-span-5 space-y-4">
          <Skeleton
            component={Skeleton.Box}
            className="!h-8"
          />
          <Skeleton
            component={Skeleton.Box}
            className="!h-40"
            count={2}
          />
        </div>
        <div className="col-span-4 space-y-4">
          <Skeleton
            component={Skeleton.Box}
            className="!h-40"
            count={2}
          />
          <Skeleton
            component={Skeleton.Box}
            className="!h-8"
          />
        </div>
      </div>

      {/*MOBILE VIEW*/}
      <div
        {...props}
        className={clsx("flex flex-col items-center justify-start px-4 lg:hidden", props.className)}
      >
        <Skeleton
          component={Skeleton.Box}
          className="my-8 !h-8 !w-1/2"
        />
        <Skeleton
          component={Skeleton.Box}
          className="!h-52"
        />
        <Skeleton
          component={Skeleton.Box}
          className="mt-4 !h-24"
          count={3}
        />
      </div>
    </>
  );
}

export default SkeletonLoader;
