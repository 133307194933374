import moment from "moment";
import { AuthToken } from "../types";

const EncodePathURL = (url: string): string => {
  return url.replace(/[!'()*]/g, function (c) {
    return "%" + c.charCodeAt(0).toString(16);
  });
};

export default EncodePathURL;

export const tokenToUri = (token: AuthToken) => {
  try {
    const parsedResult = {
      tokenType: token.tokenType,
      accessToken: token.accessToken,
      expiresAt: token.expiresAt.valueOf(),
      refreshToken: token.refreshToken,
      refreshExpiresAt: token.refreshExpiresAt?.valueOf(),
    };

    const json = JSON.stringify(parsedResult);
    const key = encodeURIComponent(json);
    return key;
  } catch (error) {
    return null;
  }
};

export const uriToToken = (uri: string): AuthToken | null => {
  try {
    const decodedJson = decodeURIComponent(uri);
    const parsedResult = JSON.parse(decodedJson);
    parsedResult.expiresAt = parsedResult.expiresAt ? moment(parsedResult.expiresAt) : undefined;
    parsedResult.refreshExpiresAt = parsedResult.refreshExpiresAt ? moment(parsedResult.refreshExpiresAt) : undefined;

    return parsedResult as AuthToken;
  } catch (error) {
    return null;
  }
};

export function isValidURL(str: string) {
  try {
    new URL(str);
    return true;
  } catch (error) {
    return false;
  }
}
