import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { matchPath, useLocation } from "react-router-dom";
import { siteApi } from "src/main/api";
import { CloseButton } from "src/main/components";
import { PWA_PROMPT_REMINDER } from "src/main/contants";
import { RootState } from "src/main/store";
import { AuthState } from "src/main/store/auth/slices";
import { getStorageItem, logger } from "src/main/utils";

let deferredPrompt: any;

const SKIPPED_ROUTES = ["/vipstatus/*", "/maintenance"];

const AddToHomeScreen = () => {
  const [reminderExpired, setReminderExpired] = useState(true);
  const [installable, setInstallable] = useState(false);
  const { t } = useTranslation();
  const location = useLocation();
  const { self, oauth } = useSelector<RootState, AuthState>((state) => state.auth);
  const [markAppInstalled] = siteApi.useUpdateMemberMissionInfoMutation();

  const skippedRoute = useMemo((): boolean => {
    return SKIPPED_ROUTES.some((route) => matchPath({ path: route }, location.pathname));
  }, [location.pathname]);

  const getCurrentTimestap = () => {
    const currentDate = new Date();
    return currentDate.getTime();
  };

  const closeHandler = () => {
    const currentTimestap = getCurrentTimestap();
    const extendTimeStamp = currentTimestap + PWA_PROMPT_REMINDER;
    localStorage.setItem("_a2hs_reminder", extendTimeStamp + "");
    setInstallable(false);
  };

  const checkReminder = () => {
    const storedA2hsReminder = getStorageItem("_a2hs_reminder", [] as any, JSON.parse);
    if (storedA2hsReminder.length === 0) {
      return true;
    }
    const currentTimestap = getCurrentTimestap();
    return currentTimestap > storedA2hsReminder;
  };

  const markAsInstalled = useCallback(() => {
    if (!!self && !!oauth && !!self.member?.id) {
      markAppInstalled({
        pwaDownloaded: true,
      });
    }
  }, [markAppInstalled, oauth, self]);

  useEffect(() => {
    // check user already installed PWA
    if (!!self && !!oauth && !!self.member?.id) {
      const displayMode = sessionStorage.getItem("display-mode");
      if (displayMode === "standalone") {
        markAsInstalled();
      }
    }
  }, [self, oauth, markAsInstalled]);

  useEffect(() => {
    const reminder = checkReminder();
    if (!reminder) {
      setReminderExpired(false);
      return;
    }
    window.addEventListener("beforeinstallprompt", (e) => {
      e.preventDefault();
      deferredPrompt = e;
      setInstallable(true);
    });

    window.addEventListener("appinstalled", () => {
      // Log install to analytics
      logger._console.log("INSTALL: Success");
      markAsInstalled();
    });
    // eslint-disable-next-line
  }, []);

  const handleInstallClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    // Hide the app provided install promotion
    setInstallable(false);
    // Show the install prompt
    deferredPrompt.prompt();
    // Wait for the user to respond to the prompt
    deferredPrompt.userChoice.then((choiceResult: any) => {
      if (choiceResult.outcome === "accepted") {
        logger._console.log("User accepted the install prompt");
      } else {
        logger._console.log("User dismissed the install prompt");
      }
    });
  };

  return (
    <div className="z-[60]">
      <div
        className={`${
          installable && reminderExpired && !skippedRoute ? "display" : "hidden"
        } h-[70px] w-full lg:w-[500px] ${
          location.pathname === "/login" ? "fixed bottom-0" : "fixed bottom-[60px]"
        } left-1/2 z-50 -translate-x-1/2 transform bg-[#141033] p-2 lg:bottom-0`}
      >
        <div className="flex h-full items-center justify-center text-sm font-normal text-white">
          <div className="flex-none cursor-pointer p-2">
            <CloseButton
              onClick={closeHandler}
              className="text-white"
            />
          </div>
          <div className="w-64 flex-none">
            <div className="flex items-center gap-2">
              <div className="flex-none">
                <img
                  className="w-[32px]"
                  src="/images/brand/ilv-icon-dark.png"
                  alt="ilove-icon"
                />
              </div>
              <div className="flex w-64 flex-col">
                <div className="font-bold">{t("_Add_to_Home_Screen")}</div>
                <div className="capitalize">{t("get free credit everyday")}!</div>
              </div>
            </div>
          </div>
          <div className="flex-none p-2">
            <button
              onClick={handleInstallClick}
              type="button"
              className="btn-orange add-button p-2 text-[13px] capitalize md:w-[150px] lg:text-base"
            >
              <div className="capitalize">{t("Add")}</div>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddToHomeScreen;
