import { ProfileView } from "src/main/views/component";
import { AddressUpdateContent } from "./components";

interface Props {
  isManage?: boolean;
}

export default function AddressUpdatePage({ isManage = false }: Props) {
  if (!isManage) return <AddressUpdateContent />;

  return (
    <ProfileView title="manage address">
      <AddressUpdateContent isManage={isManage} />
    </ProfileView>
  );
}
