import { Page } from "src/main/components";
import { ProfileView } from "src/main/views/component";
import FAQsContent from "./components/FAQsContent";

const FAQs: React.FC = () => {
  return (
    <Page paddingTop>
      <ProfileView title="FAQ">
        <FAQsContent />
      </ProfileView>
      <div className="hidden w-full text-center text-[12px] lg:block  lg:hidden">
        <p className=" inline leading-[18px] leading-[normal] text-white">
          <span className="m-0 inline font-bold  leading-[normal]">Need help? </span>
          Contact our support team or go through our FAQs.
        </p>
      </div>
    </Page>
  );
};

export default FAQs;
