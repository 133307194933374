export default function SpreadShadow() {
  return (
    <svg
      width="320"
      height="106"
      viewBox="0 0 343 106"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="opacity-60"
    >
      <mask
        id="mask0_1588_6897"
        className="[mask-type:alpha]"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="343"
        height="106"
      >
        <path
          d="M247.989 105.022H95.7434C91.4999 105.022 87.4303 103.336 84.4297 100.335L66.3023 82.208C63.3017 79.2074 59.232 77.5217 54.9885 77.5217H0.115967V0.521729H342.116V77.5217H288.743C284.5 77.5217 280.43 79.2074 277.43 82.208L259.302 100.335C256.302 103.336 252.232 105.022 247.989 105.022Z"
          fill="url(#paint0_linear_1588_6897)"
        />
      </mask>
      <g mask="url(#mask0_1588_6897)">
        <path
          d="M244.989 105.022H92.7434C88.4999 105.022 84.4303 103.336 81.4297 100.335L63.3023 82.208C60.3017 79.2074 56.232 77.5217 51.9885 77.5217H-2.88403V0.521729H339.116V77.5217H285.743C281.5 77.5217 277.43 79.2074 274.43 82.208L256.302 100.335C253.302 103.336 249.232 105.022 244.989 105.022Z"
          fill="url(#paint1_linear_1588_6897)"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_1588_6897"
          x1="309.939"
          y1="60.219"
          x2="0.115966"
          y2="60.219"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopOpacity="0" />
          <stop offset="0.221126" />
          <stop offset="0.703609" />
          <stop
            offset="1"
            stopOpacity="0"
          />
        </linearGradient>
        <linearGradient
          id="paint1_linear_1588_6897"
          x1="168.616"
          y1="0.521729"
          x2="168.616"
          y2="105.522"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopOpacity="0" />
          <stop
            offset="1"
            stopOpacity="0.4"
          />
        </linearGradient>
      </defs>
    </svg>
  );
}
