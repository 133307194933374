import { clsx } from "clsx";
import { forwardRef, useCallback, useEffect, useImperativeHandle, useState } from "react";
import { useTranslation } from "react-i18next";
import { FaClipboardCheck, FaGift, FaUser } from "react-icons/fa6";
import { useLocation, useNavigate } from "react-router-dom";
import IcoChat from "src/assets/IcoChat";
import { ContactModal } from "src/main/components";
import { useActions, useTypedSelector } from "src/main/hooks";
import { getDeployEnv, Paths } from "src/main/utils";
import { PlayNowIcon } from "src/main/views/component";
import { NavItem } from "./components";
import { useNavIndicator } from "../hooks";

export interface BottomNavigationRef {
  toggleChat: () => void;
}

const currentEnv = getDeployEnv();

const BottomNavigation = forwardRef<BottomNavigationRef, BottomNavigationProps>((props, ref) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const state = useTypedSelector((state) => state);
  const actions = useActions();
  const { claimableMissionState } = useNavIndicator();

  // for toggling the chat menu
  const [openChat, setOpenChat] = useState(false);

  const toggleContactModal = useCallback(() => {
    const networks = currentEnv.contactPlatforms;
    if (networks.length === 1) {
      return window.open(networks[0].url, "_blank");
    }

    setOpenChat((o) => !o);
  }, []);

  useImperativeHandle(
    ref,
    () => {
      return {
        toggleChat() {
          toggleContactModal();
        },
      };
    },
    [toggleContactModal],
  );

  useEffect(() => {
    setOpenChat(false);
  }, [location.pathname]);

  const handleOnClickNavigate = (path: string) => {
    // close play now modal
    if (state.layout.playnow.launch) {
      actions.launchPlaynow({ launch: false });
    }
    // navigate to path
    navigate(path);
  };

  return (
    <>
      <div>
        <div className={`text-center text-white`}>
          <div className="left-0 flex h-[61px] flex-col items-end gap-1">
            <div className="h-20 w-full drop-shadow-lg">
              <div className="left-0 top-3 h-[70px] w-full" />
              <div className="absolute left-0 top-0 inline-flex w-full items-end justify-center bg-[#0F0725]">
                <div className="flex flex-grow flex-row justify-around">
                  <NavItem
                    active={location.pathname === Paths.Private.Home}
                    label={t("rewards")}
                    icon={
                      <FaGift
                        color="#B9A3F1"
                        className="m-0.5 h-5 w-5 group-hover:text-white group-hover:drop-shadow-[0_0_6px_#FFF0D1]"
                      />
                    }
                    onClick={() => handleOnClickNavigate(Paths.Private.Home)}
                  />
                  <NavItem
                    indicator={claimableMissionState ? "claimable-indicator" : undefined}
                    active={location.pathname === Paths.Private.Missions}
                    label={t("Missions")}
                    icon={
                      <FaClipboardCheck
                        color="#B9A3F1"
                        className="my-0.5 h-5 w-5 group-hover:text-white group-hover:drop-shadow-[0_0_6px_#FFF0D1]"
                      />
                    }
                    onClick={() => handleOnClickNavigate(Paths.Private.Missions)}
                  />

                  <PlayNowIcon />

                  <NavItem
                    active={location.pathname === Paths.Private.Profile}
                    label={t("profile")}
                    icon={
                      <FaUser
                        color="#B9A3F1"
                        className="my-0.5 h-5 w-5 group-hover:text-white group-hover:drop-shadow-[0_0_6px_#FFF0D1]"
                      />
                    }
                    onClick={() => handleOnClickNavigate(Paths.Private.Profile)}
                  />
                  <NavItem
                    active={false}
                    label={t("chat")}
                    icon={
                      <IcoChat
                        color="#B9A3F1"
                        className={clsx({
                          "text-white drop-shadow-[0_0_6px_#FFF0D1]": openChat,
                        })}
                      />
                    }
                    onClick={toggleContactModal}
                    indicator="chat-indicator"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/*CONTACT MODAL*/}
      <ContactModal
        onClose={toggleContactModal}
        show={openChat}
      />
    </>
  );
});

export default BottomNavigation;

BottomNavigation.defaultProps = {};

interface BottomNavigationProps {}
