import React, { useMemo } from "react";

import { useTranslation } from "react-i18next";
import { NavigateFunction, useNavigate, useParams } from "react-router-dom";
import RedeemedSuccessfulOrderInfo from "../component/RedeemedSuccessfulOrderInfo";
import ItemOrderDetailCard from "./components/ItemOrderDetailCard";
import "./OrderDetail.css";

import { siteApi } from "src/main/api";
import { Button, Page } from "src/main/components";
import { useTypedSelector } from "src/main/hooks";
import { getItemOrderName, truncate } from "src/main/utils";
import { CopyText } from "../../components";
import { ClockIcon } from "../../components/Icon";
import { convertUTCToLocal } from "../../utils/misc";
import { SkeletonLoader } from "./components";

interface RouteParams {
  id: string;
}

interface Address {
  city: string;
  state: string;
  address: string;
  country: string;
  postalCode: string;
}

const generateAddress = (data: Address) =>
  `${data.address}, ${data.city}, ${data.state}, ${data.postalCode}, ${data.country}`;

const OrderDetail: React.FC = () => {
  // const state = useTypedSelector(state => state)
  const { t } = useTranslation();
  const { id } = useParams<keyof RouteParams>() as RouteParams;

  const { data: orderResponse, isFetching: isFetchingOrder } = siteApi.useGetOrderHistoryDetailQuery({ id });

  const order = useMemo(() => orderResponse?.model, [orderResponse?.model]);

  let navigate: NavigateFunction = useNavigate();

  const localLanguage = useTypedSelector((state) => state.preference.language);

  const isExchangeGameCredit = useMemo(() => {
    // if any item in order is exchange game credit
    return order?.orderItems.some((item) => !!item.info?.variant?.virtualFulfilment);
  }, [order]);

  const brand = useMemo(() => {
    return order?.orderItems?.[0]?.info?.pointExchangeInfo?.brand;
  }, [order]);

  const shortId = useMemo(() => truncate(order?.id, 0, 8), [order?.id]);

  return (
    <Page
      loading={isFetchingOrder}
      loader={<SkeletonLoader />}
      paddingX
      title={t("order detail")}
      footer={false}
    >
      {!!order && (
        <>
          {/*MOBILE VIEW*/}
          <div className="h-full text-white lg:hidden">
            <div className="rounded-lg border-t border-l border-[rgba(137,118,255,0.24)] bg-gradient-to-b from-[rgba(38,18,92,0.90)] to-[rgba(23,11,55,0.90)] p-4">
              <div className="max-w-full rounded-md bg-[rgba(0,0,0,0.3)] p-5 [box-shadow-width:2px] [box-shadow:0px_0px_0px_1px_rgba(255,_255,_255,_0.2)_inset]">
                <div className="flex flex-wrap items-center justify-between">
                  <div className="flex items-center justify-center space-x-2 font-extrabold">
                    <ClockIcon
                      width={22}
                      height={22}
                      className="text-secondary"
                    />
                    <span>
                      {order.createdAt && convertUTCToLocal(order.createdAt as unknown as string).format("DD MMM YYYY")}
                    </span>
                  </div>
                  <div className="text-right text-sm text-secondary first-letter:capitalize">
                    {t("order id")}: <CopyText value={shortId?.toUpperCase()} />
                  </div>
                </div>

                <p className="my-4 text-sm font-extrabold capitalize md:text-2xl lg:text-sm">
                  {t("status")}:<span className="font-normal"> {t(order.status ?? "")}</span>
                </p>

                {!!brand && (
                  <p className="mt-2 text-sm font-extrabold capitalize md:text-2xl lg:text-sm">
                    {t("Brand")}
                    <span className="font-normal">
                      {": "}
                      {brand}
                    </span>
                  </p>
                )}
                {!isExchangeGameCredit && (
                  <>
                    {/* update later */}
                    <p className="mt-2 break-words text-sm font-extrabold capitalize md:text-2xl lg:text-sm">
                      {t("recipient")}:<span className="font-normal"> {order.shipment?.recipientName}</span>
                    </p>
                    <p className="mt-2 break-words text-sm font-extrabold capitalize md:text-2xl lg:text-sm">
                      {t("mobile")}:<span className="font-normal"> {order.shipment?.recipientMobile}</span>
                    </p>
                    <p className="mt-2 break-words text-sm font-extrabold capitalize md:text-2xl lg:text-sm">
                      {t("address")}:
                      <span className="font-normal"> {order.shipment && generateAddress(order.shipment)}</span>
                    </p>
                    <p className="mt-5 break-words text-sm font-extrabold capitalize md:text-2xl">
                      {t("remark")}:<span className="font-normal"> {order.shipment?.remarks}</span>
                    </p>
                  </>
                )}
              </div>

              <div className="mt-5">
                {order.orderItems.length && (
                  <p className="mt-2 text-sm font-bold uppercase text-secondary md:text-[20px]">
                    {t("_Your_Item(s)")}:
                  </p>
                )}

                <div>
                  {order.orderItems.map((item, index) => (
                    <ItemOrderDetailCard
                      key={index}
                      is_exchange_game_credit={isExchangeGameCredit}
                      quantity={item.quantity}
                      sub_total={item.subtotal ?? ""}
                      image={(item.info?.variant?.imageUrl || item.info?.product.imageUrl) ?? ""}
                      name={getItemOrderName(item, t, localLanguage)}
                      logistics={
                        order.fulfilment
                          ? {
                              name: order.fulfilment.shipmentProviderInfo ?? "",
                              tracking_url: order.fulfilment.trackingLink ?? "",
                            }
                          : undefined
                      }
                    />
                  ))}
                </div>
              </div>

              <div className="mt-4 flex w-full items-center justify-between self-stretch rounded-lg bg-[#1E0E4A] p-4 text-left font-bold">
                <p className="m-0 text-[15px] capitalize leading-[normal] text-white md:text-[25px]">
                  {t("totalOrderAmount")}:
                </p>
                <div className="flex text-xs font-normal text-white md:text-[25px]">
                  <img
                    src="/images/icon/heart_icon_yellow.svg"
                    alt="heart-icon"
                    className="w-[20px]"
                  />
                  <span className="ml-2 text-xl font-bold text-[rgba(246,189,102,1)] md:text-[25px]">
                    {Math.trunc(parseInt(order.total)).toLocaleString()}
                  </span>
                </div>
              </div>
              {/* <div className="px-2.5 mt-8 w-full flex justify-between items-center self-stretch text-left font-bold">
                                <p className="text-white m-0 text-[15px] md:text-[25px] leading-[normal] capitalize">
                                    {t("balance point", { ns: ['main', 'home'] })}:
                                </p>
                                <div className="flex text-xs md:text-[25px] font-normal text-white">
                                    <img src="/images/icon/heart_icon_yellow.svg" alt="heart-icon" />
                                    <span className="text-[rgba(246,189,102,1)] text-xl md:text-[25px] font-bold ml-2">
                                        {Math.trunc(parseInt(state.orderDetail.balance_amt)).toLocaleString()}
                                    </span>
                                </div>
                            </div> */}
              <div className="pt-6">
                <Button
                  onClick={() => {
                    navigate("/order-history", { replace: true });
                  }}
                  variant="blue-violet"
                  outlined
                  size="xl"
                >
                  {" "}
                  <div className="flex items-center space-x-2">
                    <img
                      className="h-5 w-5 rotate-180"
                      src="/images/icon/ico-right-arrow.svg"
                      alt="Back icon"
                    />
                    <span>{t("_Back_to_Order_History")}</span>
                  </div>
                </Button>
              </div>
            </div>
          </div>

          {/*DESKTOP VIEW*/}
          <div className="hidden lg:mt-40 lg:block">
            <div className="grid grid-cols-9 gap-4">
              <div className="col-start-1 col-end-6 h-full rounded-md border-t border-l border-[rgba(137,118,255,0.24)] bg-gradient-to-b from-[rgba(38,18,92,0.90)] to-[rgba(23,11,55,0.90)] p-4">
                <p className="p-4 text-lg font-bold capitalize text-secondary">{t("order detail")}</p>
                <RedeemedSuccessfulOrderInfo
                  referenceNo={order.id}
                  status={order.status}
                  createdAt={order.createdAt as unknown as string}
                  isExchangeGameCredit={!!isExchangeGameCredit}
                  shipmentInfo={order.shipment}
                  brand={brand ?? undefined}
                  showSuccessBanner={false}
                />
              </div>

              <div className="col-span-4 col-end-10 h-full rounded-md bg-gradient-to-b from-[rgba(38,18,92,0.90)] to-[rgba(23,11,55,0.90)] p-4">
                {/* <p className="p-4 text-lg font-bold text-[#F7BD66] capitalize"></p> */}
                <p className="pt-4 pb-2 text-[15px] font-medium uppercase text-secondary">{t("_Your_Item(s)")}:</p>
                {order.orderItems.map((item, index) => (
                  <ItemOrderDetailCard
                    key={index}
                    is_exchange_game_credit={isExchangeGameCredit}
                    quantity={item.quantity}
                    sub_total={item.subtotal ?? ""}
                    image={(item.info?.variant?.imageUrl || item.info?.product.imageUrl) ?? ""}
                    name={getItemOrderName(item, t, localLanguage)}
                    logistics={
                      order.fulfilment
                        ? {
                            name: order.fulfilment.shipmentProviderInfo ?? "",
                            tracking_url: order.fulfilment.trackingLink ?? "",
                          }
                        : undefined
                    }
                  />
                ))}
                {/* <div className="p-4 mt-8 w-full flex justify-between items-center self-stretch text-left font-bold bg-[#1E0E4A] rounded-lg"></div> */}
                <div className="mt-8 flex w-full items-center justify-between self-stretch rounded-lg bg-[#1E0E4A] p-4 text-left font-bold">
                  <p className="m-0 text-[15px] capitalize leading-[normal] text-white md:text-[25px] lg:text-[15px]">
                    {t("totalOrderAmount")}:
                  </p>
                  <div className="flex text-xs font-normal text-white md:text-[25px]">
                    <img
                      src="/images/icon/heart_icon_yellow.svg"
                      alt="heart-icon"
                      className="w-[20px]"
                    />
                    <span className="ml-2 text-xl font-bold text-[rgba(246,189,102,1)] md:text-[20px]">
                      {Math.trunc(parseInt(order.total)).toLocaleString()}
                    </span>
                  </div>
                </div>
                {/* <div className="px-2.5 mt-8 w-full flex justify-between items-center self-stretch text-left font-bold">
                                    <p className="text-white m-0 text-[15px] md:text-[25px] lg:text-[15px] leading-[normal] capitalize">
                                        {t("balance point", { ns: ['main', 'home'] })}:
                                    </p>
                                    <div className="flex text-xs md:text-[25px] font-normal text-white">
                                        <img src="/images/icon/heart_icon_yellow.svg" alt="heart-icon" />
                                        <span className="text-[rgba(246,189,102,1)] text-xl md:text-[20px] font-bold ml-2">
                                            {Math.trunc(parseInt(state.orderDetail.balance_amt)).toLocaleString()}
                                        </span>
                                    </div>
                                </div> */}
                <div className="pt-4">
                  <Button
                    onClick={() => {
                      navigate("/order-history#order", { replace: true });
                    }}
                    variant="blue-violet"
                    outlined
                    className="w-full"
                    size="lg"
                  >
                    {" "}
                    <div className="flex items-center space-x-2">
                      <img
                        className="h-5 w-5 rotate-180"
                        src="/images/icon/ico-right-arrow.svg"
                        alt="Back icon"
                      />
                      <span>{t("_Back_to_Order_History")}</span>
                    </div>
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </Page>
  );
};

export default OrderDetail;
