import React from "react";
import { useTranslation } from "react-i18next";
import { CheckoutItemCard } from "src/main/components";
import { CartItemModel } from "src/main/types";

export interface CheckoutListProps {
  variants: CartItemModel[];
}

const CheckoutItemList: React.FC<CheckoutListProps> = ({ variants }) => {
  const { t } = useTranslation();

  return (
    <div>
      <p className="text-sm font-bold text-content-primary">{t("_Your_Item(s)")}:</p>
      <div className="mt-1 space-y-1">
        {variants?.map((item, index) => (
          <CheckoutItemCard
            key={item.variantId}
            item={item}
          />
        ))}
      </div>
    </div>
  );
};

export default CheckoutItemList;
