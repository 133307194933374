import { ImgHTMLAttributes } from "react";

interface IcoPointProps extends ImgHTMLAttributes<HTMLImageElement> {}

function IcoPoint(props: IcoPointProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      xmlSpace="preserve"
      id="b"
      x={0}
      y={0}
      style={{
        // @ts-ignore
        enableBackground: "new 0 0 24 24",
      }}
      viewBox="0 0 24 24"
      {...props}
    >
      <style>{".st3{fill:#fff}.st8{opacity:.5}"}</style>
      <linearGradient
        id="SVGID_1_"
        x1={12}
        x2={12}
        y1={26}
        y2={2}
        gradientTransform="matrix(1 0 0 -1 0 26)"
        gradientUnits="userSpaceOnUse"
      >
        <stop
          offset={0}
          style={{
            stopColor: "#fcf1a3",
          }}
        />
        <stop
          offset={0.23}
          style={{
            stopColor: "#debd4e",
          }}
        />
        <stop
          offset={0.631}
          style={{
            stopColor: "#b57f00",
          }}
        />
        <stop
          offset={1}
          style={{
            stopColor: "#d8b442",
          }}
        />
      </linearGradient>
      <path
        d="M12 0c6.6 0 12 5.4 12 12s-5.4 12-12 12S0 18.6 0 12 5.4 0 12 0z"
        style={{
          fill: "url(#SVGID_1_)",
        }}
      />
      <defs>
        <filter
          id="Adobe_OpacityMaskFilter"
          width={24}
          height={24}
          x={0}
          y={0}
          filterUnits="userSpaceOnUse"
        >
          <feColorMatrix
            colorInterpolationFilters="sRGB"
            result="source"
            values="-1 0 0 0 1 0 -1 0 0 1 0 0 -1 0 1 0 0 0 1 0"
          />
        </filter>
      </defs>
      <mask
        id="SVGID_00000045585719616527132940000003287224596990262176_"
        width={24}
        height={24}
        x={0}
        y={0}
        maskUnits="userSpaceOnUse"
      >
        <g
          style={{
            filter: "url(#Adobe_OpacityMaskFilter)",
          }}
        >
          <image
            xlinkHref="8580740E.jpg"
            width={28}
            height={28}
            style={{
              overflow: "visible",
            }}
            transform="translate(-2 -2)"
          />
        </g>
      </mask>
      <path
        d="M12 0c6.6 0 12 5.4 12 12s-5.4 12-12 12S0 18.6 0 12 5.4 0 12 0z"
        className="st3"
        style={{
          opacity: 0.5,
          mask: "url(#SVGID_00000045585719616527132940000003287224596990262176_)",
        }}
      />
      <path
        d="M9 6.1c-1.4 0-2.7.5-3.7 1.4-.1 0-.2.1-.2.1-.1.1-.1.2-.2.2-.9 1-1.4 2.3-1.4 3.6 0 1.4.6 2.8 1.6 3.8l3.3 3.2 1.1 1c.7.7 1.7 1.1 2.8 1.1 1.1 0 2.1-.4 2.8-1.1l4.4-4.3c1-1 1.6-2.4 1.6-3.8 0-1.3-.5-2.6-1.4-3.6l-.2-.2-.2-.2c-1-.9-2.3-1.4-3.7-1.4-1.2 0-2.4.4-3.3 1.1-1-.5-2.1-.9-3.3-.9z"
        className="st3"
        style={{
          opacity: 0.4,
        }}
      />
      <linearGradient
        id="SVGID_00000059290962517281224990000006665745433837465783_"
        x1={12.384}
        x2={12.197}
        y1={19.779}
        y2={6.068}
        gradientUnits="userSpaceOnUse"
      >
        <stop
          offset={0.02}
          style={{
            stopColor: "#ae6b18",
          }}
        />
        <stop
          offset={0.089}
          style={{
            stopColor: "#945511",
          }}
        />
        <stop
          offset={0.169}
          style={{
            stopColor: "#7e420b",
          }}
        />
        <stop
          offset={0.263}
          style={{
            stopColor: "#6d3406",
          }}
        />
        <stop
          offset={0.38}
          style={{
            stopColor: "#622902",
          }}
        />
        <stop
          offset={0.544}
          style={{
            stopColor: "#5b2401",
          }}
        />
        <stop
          offset={1}
          style={{
            stopColor: "#592200",
          }}
        />
      </linearGradient>
      <path
        d="M9 5.7c-1.4 0-2.7.5-3.7 1.3l-.2.2c-.1.1-.1.2-.2.2-.9 1-1.4 2.3-1.4 3.6 0 1.4.6 2.8 1.6 3.8L8.4 18l1.1 1c.7.7 1.7 1.1 2.8 1.1 1.1 0 2.1-.4 2.8-1.1l4.4-4.3c1-1 1.6-2.4 1.6-3.8 0-1.3-.5-2.6-1.4-3.6l-.2-.2-.2-.2c-1-.9-2.3-1.4-3.7-1.4-1.2 0-2.4.4-3.3 1.1-1-.6-2.1-.9-3.3-.9z"
        style={{
          fill: "url(#SVGID_00000059290962517281224990000006665745433837465783_)",
        }}
      />
      <linearGradient
        id="SVGID_00000180332754639097718970000003181605027341786047_"
        x1={12.226}
        x2={12.226}
        y1={9.518}
        y2={16.367}
        gradientUnits="userSpaceOnUse"
      >
        <stop
          offset={0}
          style={{
            stopColor: "#fcf1a3",
          }}
        />
        <stop
          offset={0.23}
          style={{
            stopColor: "#debd4e",
          }}
        />
        <stop
          offset={0.684}
          style={{
            stopColor: "#b57f00",
          }}
        />
        <stop
          offset={1}
          style={{
            stopColor: "#c69922",
          }}
        />
      </linearGradient>
      <path
        d="m18 8.8-.1-.1-.1-.1c-1.4-1.2-3.5-1.2-4.8.1l-.7.7-.7-.7c-1.3-1.3-3.4-1.4-4.8-.1l-.1.1-.1.1c-.6.6-.9 1.5-.9 2.4 0 .9.4 1.8 1 2.5l3.2 3.2 1 1c.4.4.9.6 1.4.6.5 0 1-.2 1.4-.6l4.2-4.2c.7-.7 1-1.5 1-2.5 0-.9-.4-1.8-.9-2.4z"
        style={{
          fill: "url(#SVGID_00000180332754639097718970000003181605027341786047_)",
        }}
      />
      <linearGradient
        id="SVGID_00000060744375824724268980000016362314533096375431_"
        x1={17.143}
        x2={13.622}
        y1={8.66}
        y2={13.449}
        gradientUnits="userSpaceOnUse"
      >
        <stop
          offset={0}
          style={{
            stopColor: "#fcf1a3",
          }}
        />
        <stop
          offset={0.23}
          style={{
            stopColor: "#debd4e",
          }}
        />
        <stop
          offset={0.684}
          style={{
            stopColor: "#b57f00",
          }}
        />
        <stop
          offset={1}
          style={{
            stopColor: "#c69922",
          }}
        />
      </linearGradient>
      <path
        d="m17.1 14.3.7-.7c.7-.7 1-1.5 1-2.5 0-.9-.3-1.7-.9-2.3l-.2-.2c-1.4-1.2-3.5-1.2-4.8.1l-.7.7 4.9 4.9z"
        style={{
          opacity: 0.5,
          fill: "url(#SVGID_00000060744375824724268980000016362314533096375431_)",
        }}
      />
      <g className="st8">
        <path
          d="m18 8.8-.1-.1-.1-.1c-1.4-1.2-3.5-1.2-4.8.1l-.7.7c1.9-2 4.1-1.7 5.5-.5l.1.1.1.1c.5.6.9 1.4.9 2.2v-.2c0-.8-.4-1.7-.9-2.3zM12.6 9.8l-1.1-1.1c-1.3-1.3-3.4-1.4-4.8-.1l-.1.1-.1.1c-.6.6-.9 1.5-.9 2.4v.2c0-.8.3-1.6.9-2.2l.1-.1.1-.1c.5-.5 1.6-1.3 3.3-.8 1.3.2 1.9 1.1 2.6 1.6z"
          className="st3"
        />
      </g>
      <g className="st8">
        <path
          d="M12 1.1c6.4 0 11.7 5.1 12 11.5v-.5c0-6.6-5.4-12-12-12S0 5.4 0 12v.5C.3 6.2 5.6 1.1 12 1.1z"
          className="st3"
        />
      </g>
    </svg>
  );
}

export default IcoPoint;
