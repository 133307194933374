import BigNumber from "bignumber.js";
import getSymbolFromCurrency from "currency-symbol-map";
import { bnOrZero, fNumber } from "src/main/utils/number";

type CurrencyInputValue = string | number | null | undefined | BigNumber;

class _Currency {
  private value: BigNumber;
  constructor(value: CurrencyInputValue) {
    this.value = bnOrZero(value);
  }

  public format(currency: string, options?: Intl.NumberFormatOptions) {
    const valueInNumber = this.value.toNumber();
    const symbol = getSymbolFromCurrency(currency) || currency.toUpperCase();
    try {
      return Intl.NumberFormat("en-US", {
        style: "currency",
        currency,
        ...options,
      })
        .format(valueInNumber)
        .replace(`${currency} `, symbol);
    } catch (error) {
      // in case browser does not support currency
      return `${symbol}${fNumber(valueInNumber)}`;
    }
  }
}

const Currency = (value: CurrencyInputValue) => new _Currency(value);
Currency.prototype = _Currency.prototype;

export default Currency;
