import { clsx } from "clsx";
import { DetailedHTMLProps, InputHTMLAttributes } from "react";
import { PropsWithThemeConfig, ThemeSize } from "src/main/components";
import { styledClassBuilder } from "src/main/utils";

type RadioVariant = "filled" | "outlined";

interface RadioProps
  extends Omit<DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>, "size">,
    PropsWithThemeConfig<ThemeSize, RadioVariant> {}

const buildStyledClass = styledClassBuilder<ThemeSize, RadioVariant>({
  size: {
    xs: "h-3 w-3 border-2 checked:!border-2",
    sm: "h-4 w-4 border-2 checked:!border-2",
    md: "h-5 w-5 border-2 checked:!border-2",
    lg: "h-8 w-8 border-2 checked:!border-2",
    xl: "h-8 w-8 border-2 checked:!border-2",
  },
  variant: {
    filled: "bg-white border-midnight-50 checked:bg-violet-500 checked:border-violet-500",
    outlined:
      "border-primary-200 bg-transparent text-primary-50 checked:border-primary-200 checked:bg-transparent focus:outline-none",
  },
});

const Radio = ({ className, size = "md", variant = "outlined", ...props }: RadioProps) => {
  return (
    <input
      type="radio"
      className={clsx(
        "appearance-none !outline-none ![box-shadow:none] ",
        buildStyledClass({ size, variant }),
        className,
      )}
      {...props}
    />
  );
};

export default Radio;
