import React, { useCallback, useState } from "react";

type ScrollPositionOptions = {
  scrollOffset?: number;
  defaultAtTop?: boolean;
  defaultAtBottom?: boolean;
};

/**
 * Hook to detect the scroll position of a container
 * @param containerRef
 * @param options
 * @returns isScrollbarAtBottom, isScrollbarAtTop, checkPosition()
 */
export const useScrollPositionDetector = <TRefObject extends HTMLElement>(
  containerRef: React.RefObject<TRefObject>,
  options?: ScrollPositionOptions,
) => {
  const { scrollOffset = 2, defaultAtTop = true, defaultAtBottom = true } = options ?? {};

  const [isScrollbarAtBottom, setIsScrollbarAtBottom] = useState(defaultAtBottom);
  const [isScrollbarAtTop, setIsScrollbarAtTop] = useState(defaultAtTop);

  const checkPosition = useCallback(() => {
    if (containerRef.current) {
      const element = containerRef.current;
      const isBottom = element.scrollHeight - element.clientHeight - element.scrollTop <= scrollOffset;
      const isTop = element.scrollTop <= scrollOffset;
      setIsScrollbarAtBottom(isBottom);
      setIsScrollbarAtTop(isTop);
    } else {
      setIsScrollbarAtTop(true);
    }
  }, [containerRef, scrollOffset]);

  return { isScrollbarAtBottom, isScrollbarAtTop, checkPosition };
};
