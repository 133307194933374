import { clsx } from "clsx";
import { PropsWithChildren } from "react";
import { PropsWithClassName } from "src/main/components";

interface FloatingBarProps extends PropsWithChildren, PropsWithClassName {
  placement?: "top" | "bottom";
  offset?: number;
  autoOffset?: boolean;
  maxWidth?: "mobile" | "desktop";
  contentClassName?: string;
}

const FloatingBar = ({
  children,
  className,
  autoOffset,
  contentClassName,
  placement = "top",
  maxWidth = "desktop",
  offset = 0,
}: FloatingBarProps) => {
  return (
    <div
      className={clsx(
        "fixed left-0 z-[51] w-full",
        {
          "lg:!top-0": autoOffset && placement === "top",
          "lg:!bottom-0": autoOffset && placement === "bottom",
        },
        className,
      )}
      style={{
        [placement]: `${offset}px`,
      }}
    >
      <div
        className={clsx(
          "mx-auto w-full",
          {
            "lg:!max-w-md": maxWidth === "mobile",
          },
          contentClassName,
        )}
      >
        {children}
      </div>
    </div>
  );
};

export default FloatingBar;
