import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
import { CartItemModel, CartModel, Meta, ProductModel, ShippingAddressModel } from "src/main/types";
import { getStorageItem } from "src/main/utils";
import { PaginationType } from "../types";

export const PaginationInitialState: PaginationType = {
  count: 0,
  current_page: 0,
  per_page: 0,
  total: 0,
  total_pages: 0,
};

const CheckoutStorageKey = "ilovevip-checkout-cart-items";
const persistCheckoutCartItem = (items: CartItemModel[]) => {
  try {
    localStorage.setItem(CheckoutStorageKey, JSON.stringify(items));
  } catch (error) {}
};

export const getPersistCartItems = () => {
  try {
    const items = getStorageItem(CheckoutStorageKey, [] as CartItemModel[], JSON.parse);
    return items;
  } catch (error) {
    return [];
  }
};

export type ProductState = {
  products?: ProductModel[] | null;
  productsMeta?: Meta | null;
  topProducts?: ProductModel[] | null;
  cart?: CartModel | null;
  checkoutCartItems?: CartItemModel[];
  checkoutShippingInfo?: ShippingAddressModel | null;
};

export const initialState: ProductState = {
  products: undefined,
  productsMeta: undefined,
  topProducts: undefined,
  cart: undefined,
  checkoutCartItems: getPersistCartItems(),
  checkoutShippingInfo: undefined,
};

export const productSlice = createSlice({
  name: "productSlice",
  initialState,
  reducers: {
    initState(state, action: PayloadAction<ProductState | null>) {
      let { products = null, topProducts = null, cart = null, productsMeta = null } = action.payload ?? {};
      state.products = products;
      state.topProducts = topProducts;
      state.cart = cart;
      state.productsMeta = productsMeta;
    },
    updateProducts(
      state,
      action: PayloadAction<{
        products: ProductModel[];
        meta?: Meta | null;
      } | null>,
    ) {
      state.products = action.payload?.products ?? [];
      state.productsMeta = action.payload?.meta ?? { limit: 10, offset: 0 };
    },
    addToProductsList(
      state,
      action: PayloadAction<{
        products: ProductModel[];
        meta?: Meta | null;
      } | null>,
    ) {
      state.products = (state.products ?? []).concat(action.payload?.products ?? []);
      state.productsMeta = action.payload?.meta ?? null;
    },
    updateTopProducts(state, action: PayloadAction<ProductModel[] | null>) {
      state.topProducts = action.payload;
    },
    updateCart(state, action: PayloadAction<CartModel | null>) {
      state.cart = action.payload;
    },
    updateCheckoutCart(state, action: PayloadAction<CartItemModel[] | null>) {
      state.checkoutCartItems = action.payload ?? [];
      persistCheckoutCartItem(action.payload ?? []);
    },
    updateShippingInfo(state, action: PayloadAction<ShippingAddressModel | null>) {
      state.checkoutShippingInfo = action.payload;
    },
  },
});
export default productSlice;
