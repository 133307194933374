import BigNumber from "bignumber.js";
import { BrandModel, ExchangeTargetModel, ExchangeVariantModel } from "src/main/models";
import { OrderHistoryModel } from "src/main/types";

export enum CashbackStep {
  PointSelection = "pointSelection",
  AccountSelection = "accountSelection",
  CashbackSuccess = "cashbackSuccess",
  SignUpInstruction = "signUpInstruction",
}

export type MemberBrand = {
  brandId: string;
  identifier: string;
  brand?: BrandModel;
};

export type AccountSelectionPayload = {
  account: MemberBrand | undefined;
  order: OrderHistoryModel;
  bonusPercentage?: number;
};

export type SignUpBrandSelectionPayload = {
  account: MemberBrand | undefined;
  bonusPercentage: number;
  exchangeCash: BigNumber;
  originalExchangeCash: BigNumber;
};

export interface PointSelectionPayload {
  points: BigNumber;
  amount: BigNumber;
  exchangeVariant: ExchangeVariantModel;
  exchangeTarget: ExchangeTargetModel;
}
