import { useTranslation } from "react-i18next";
import { FaHome } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { Button } from "src/main/components";
import { OrderItem } from "src/main/types";
import { fNumber } from "src/main/utils";
import RedeemSuccessfulItemCard from "../RedeemSuccessfulItemCard";

/**
 * @deprecated Will be removed in V4
 */
function RedeemedSuccessfulItemList(props: RedeemSuccessfulItemPros) {
  const { grandTotal, balanceAmount, orderDetails } = props;
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <>
      <div className="my-4 w-full">
        <p className="text-xxs font-bold uppercase">{t("_Your_Item(s)")}:</p>
        <div>
          {orderDetails?.map((orderItem, index) => (
            <RedeemSuccessfulItemCard
              key={index}
              orderItem={orderItem}
            />
          ))}
        </div>
      </div>

      <div className="flex w-full items-center justify-between self-stretch text-left font-bold">
        <p className="m-0 text-[16px] capitalize leading-[normal]">{t("grand total")}:</p>
        <div className="flex text-xs font-normal">
          <img
            src="/images/icon/heart_icon_yellow.svg"
            className="w-[20px]"
            alt="heart-icon"
          />
          <span className="ml-2 text-xl font-bold text-[rgba(246,189,102,1)]">{fNumber(grandTotal)}</span>
        </div>
      </div>
      <div className="flex w-full items-center justify-between self-stretch text-left font-bold">
        <p className="m-0 text-[16px] capitalize leading-[normal]">{t("balance point")}:</p>
        <div className="flex text-xs font-normal">
          <img
            src="/images/icon/heart_icon_yellow.svg"
            className="w-[20px]"
            alt="heart-icon"
          />
          <span className="ml-2 text-xl font-bold text-[rgba(246,189,102,1)]">{fNumber(balanceAmount, "floor")}</span>
        </div>
      </div>

      <Button
        onClick={() => {
          navigate("/home", { replace: true });
        }}
      >
        <span className="flex items-center justify-center">
          {t("back to homepage")}
          <FaHome className="mx-2 inline-block" />
        </span>
      </Button>
      <p className="m-0 mt-2 w-full text-center text-[12px] font-normal leading-[normal] text-[#8A93A4] first-letter:capitalize">
        {t("all orders that you redeemed will be received within 2 weeks")}
      </p>
    </>
  );
}

RedeemedSuccessfulItemList.defaultProps = {
  grandTotal: "0",
  balanceAmount: 0,
};

interface RedeemSuccessfulItemPros {
  grandTotal: string;
  balanceAmount: string;
  orderDetails: OrderItem[];
}

export default RedeemedSuccessfulItemList;
