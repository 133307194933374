import { ThemeSize } from "src/main/components";

type MapKeyType = string | number | symbol;

interface StyledClassMap<TSizeKey extends MapKeyType, TVariantKey extends MapKeyType, TColorKey extends MapKeyType> {
  size?: Record<TSizeKey, string>;
  variant?: Record<TVariantKey, string>;
  color?: Record<TColorKey, string>;
  rounded?: Record<TSizeKey, string>;
}

// this function return a function which receives size and variant and returns the correct className
export const styledClassBuilder = <
  TSizeKey extends MapKeyType = ThemeSize,
  TVariantKey extends MapKeyType = string,
  TColorKey extends MapKeyType = string,
>(
  styledClassMap: StyledClassMap<TSizeKey, TVariantKey, TColorKey>,
) => {
  return (configs: Partial<Record<keyof StyledClassMap<TSizeKey, TVariantKey, TColorKey>, string>>) => {
    let classes = [];
    for (const configKey in configs) {
      const configValue = configs[configKey as keyof typeof configs];
      if (!configValue) continue;
      const classMapForKey = styledClassMap[configKey as keyof StyledClassMap<TSizeKey, TVariantKey, TColorKey>];
      if (classMapForKey) {
        classes.push(classMapForKey[configValue as keyof typeof classMapForKey]);
      }
    }
    return classes.join(" ");
  };
};
