import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { siteApi } from "src/main/api";
import { Loader } from "src/main/components";
import { useTypedSelector } from "src/main/hooks";
import productSlice, { getPersistCartItems } from "src/main/store/product/slices";
import userSlice from "src/main/store/user/slices";
import { CartItemModel } from "src/main/types";
import { CartInDrawer } from "src/main/views";
import { PlayNowDrawer, PopupBanner } from "src/main/views/component";
import { MainBottomBar, MainTopBar } from "./components";

const BANNER_POLLING_INTERVAL = 5 * 1000 * 60; // 5 minutes

const MainBody = (props: React.PropsWithChildren) => {
  const { children } = props;
  const state = useTypedSelector((state) => state);
  const dispatch = useDispatch();
  const cartRes = siteApi.useGetCartDetailQuery({}, { skip: !state.auth.self });
  const [isDisplayPopupBanner, setIsDisplayPopupBanner] = useState(false);

  const bannerRes = siteApi.useListBannersQuery(
    {
      meta: { limit: 1000, offset: 0, sort: "sortPriority:DESC" },
    },
    { skip: !state.auth.self, pollingInterval: BANNER_POLLING_INTERVAL },
  );

  const brands = siteApi.useListBrandsQuery(
    {
      meta: { limit: 100, offset: 0 },
    },
    { skip: !state.auth.self },
  );

  useEffect(() => {
    if (cartRes.data?.model) {
      dispatch(productSlice.actions.updateCart(cartRes.data.model ?? null));
      const persistCheckoutItems: CartItemModel[] = getPersistCartItems();
      let validCheckoutItems: CartItemModel[] = [];

      const cartItems = cartRes.data.model.data.items;
      if (cartItems && cartItems.length) {
        validCheckoutItems = persistCheckoutItems.filter((i) =>
          cartItems.find((baseItem) => baseItem.variantId === i.variantId),
        );
      }
      dispatch(productSlice.actions.updateCheckoutCart(validCheckoutItems));
    }
  }, [cartRes.data?.model, dispatch]);

  useEffect(() => {
    if (brands.data?.entries) {
      // Update new brands to store
      dispatch(userSlice.actions.updateUserBrand(brands.data?.entries ?? null));
    }
  }, [brands.data?.entries, dispatch]);

  useEffect(() => {
    if (bannerRes.data?.entries) {
      // Update new banners to store
      dispatch(userSlice.actions.updateBanners(bannerRes.data?.entries ?? null));
      // Check if there is a pop-up banner from response
      if (bannerRes.data?.entries?.some((banner) => banner.category === "pop-up")) {
        // Display pop-up banner
        setIsDisplayPopupBanner(true);
      }
    }
  }, [bannerRes.data?.entries, dispatch]);

  return (
    <div className="flex h-full flex-col pb-20 lg:relative lg:pb-0">
      <MainTopBar />

      <main className="mt-[60px] flex-1 lg:absolute lg:left-0 lg:right-0 lg:m-auto">
        <Loader />
        <CartInDrawer />
        {children}
      </main>

      <PopupBanner open={isDisplayPopupBanner} />
      <PlayNowDrawer open={state.layout.playnow.launch} />

      <MainBottomBar />
    </div>
  );
};

export default MainBody;
