import { clsx } from "clsx";

interface BoxSkeletonProps {
  className?: string;
  variant?: "primary" | "secondary";
}

const VARIANT_CLASS_MAP = {
  primary: "bg-primary-500",
  secondary: "bg-gray-300",
};

function BoxSkeleton({ className, variant = "primary" }: BoxSkeletonProps) {
  return (
    <div
      className={clsx("flex h-full w-full animate-pulse space-x-4 rounded-md", className, VARIANT_CLASS_MAP[variant])}
    />
  );
}

export default BoxSkeleton;
