const LineChatIcon = () => {
  const handleClick = () => {
    window.open("https://line.me/R/ti/p/@645ojnqc", "_blank");
  };
  return (
    <div className="fixed bottom-[60px] right-0 z-50 m-5 lg:bottom-0">
      <img
        src="/images/icon/sticky-line.7601577.png"
        height="65"
        width="65"
        alt="line-chat"
        onClick={handleClick}
      />
    </div>
  );
};

export default LineChatIcon;
