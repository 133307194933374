import { DeployCountry } from "src/main/types";
import * as Yup from "yup";

const phoneReg10Digits = /^0\d{9}$/;
const phoneReg11Digits = /^0\d{10}$/;

export const loginSchema = Yup.object().shape({
  user_name: Yup.string().required("username is required"),
  password: Yup.string().required("password is required"),
  brand: Yup.string().required("brand is required"),
});

export const phoneBrandLoginSchema = Yup.object().shape({
  phone: Yup.string()
    .matches(phoneReg10Digits, "phone number must start with 0 and have 10 digits")
    .required("phone number is required"),
  brand: Yup.string().required("brand is required"),
});

export const phoneLoginSchema = Yup.object().shape({
  phone: Yup.string()
    .matches(phoneReg10Digits, "phone number must start with 0 and have 10 digits")
    .required("phone number is required"),
  password: Yup.string().required("password is required"),
});

export const passwordSchema = Yup.object().shape({
  password: Yup.string().required("password is required"),
});

export const thPhoneSchema = Yup.object().shape({
  phone: Yup.string()
    .matches(phoneReg10Digits, "phone number must start with 0 and have 10 digits")
    .required("phone number is required"),
});
export const phPhoneSchema = Yup.object().shape({
  phone: Yup.string()
    .matches(phoneReg11Digits, "phone number must start with 0 and have 11 digits")
    .required("phone number is required"),
});

export const phoneSchemas: Record<DeployCountry, unknown> = {
  th: thPhoneSchema,
  ph: phPhoneSchema,
};
