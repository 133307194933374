import queryString from "query-string";
import { Meta } from "../types";
import { SimpleMap } from "./type";

export const parseMeta = (meta: SimpleMap<string | number> | Partial<Meta> | undefined) => {
  if (!meta) return "";
  let query: SimpleMap = {};
  Object.entries(meta).forEach(([key, val], index) => {
    if (key === "count") return;

    if (typeof val === "string" || typeof val === "number") {
      query[key] = val.toString();
    }

    if (Array.isArray(val)) {
      query[key] = val.join(",");
    }
  });

  return "?" + queryString.stringify(query);
};
