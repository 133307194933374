import { clsx } from "clsx";
import { DetailedHTMLProps, InputHTMLAttributes } from "react";
import { PropsWithThemeConfig, ThemeSize } from "src/main/components/types";
import { styledClassBuilder } from "src/main/utils";

type CheckboxVariant = "filled" | "outlined";

interface CheckboxProps
  extends Omit<DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>, "size">,
    PropsWithThemeConfig<ThemeSize, CheckboxVariant> {}

const Checkbox = ({ className, size = "md", variant = "outlined", ...props }: CheckboxProps) => {
  return (
    <input
      type="checkbox"
      className={clsx(
        "no-tap-highlight cursor-pointer appearance-none rounded border-2 border-midnight-50  text-white ![box-shadow:none] focus:outline-none",
        buildStyledClass({ size, variant }),
        className,
      )}
      {...props}
    />
  );
};

const buildStyledClass = styledClassBuilder<ThemeSize, CheckboxVariant>({
  size: {
    xs: "h-3 w-3",
    sm: "h-4 w-4",
    md: "h-5 w-5",
    lg: "h-8 w-8",
    xl: "h-8 w-8"
  },
  variant: {
    filled: "bg-white checked:border-violet-500 checked:bg-violet-500",
    outlined: "border-primary-200 bg-transparent checked:border-primary-200 text-primary-200 checked:bg-transparent",
  },
});

export default Checkbox;
