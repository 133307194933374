const ClockIcon = ({ width = 24, height = 24, viewBox = "0 0 17 17", className = "", ...rest }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox={viewBox}
      className={`fill-current ${className}`}
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M8.27539 2.58252C5.03827 2.58252 2.40039 5.22039 2.40039 8.45752C2.40039 11.6946 5.03827 14.3325 8.27539 14.3325C11.5125 14.3325 14.1504 11.6946 14.1504 8.45752C14.1504 5.22039 11.5125 2.58252 8.27539 2.58252ZM12.3233 8.55152C11.2011 8.76302 8.77477 9.20364 8.47514 9.20364C8.05214 9.20364 7.71139 8.86289 7.71139 8.43989C7.71139 8.14027 8.16377 5.23802 8.36939 4.06302C8.38702 3.95139 8.55152 3.95727 8.56914 4.06889L9.14489 7.73489L12.3115 8.32827C12.4408 8.35764 12.4408 8.53389 12.3233 8.55152Z"
        fill="current"
      />
    </svg>
  );
};

export default ClockIcon;
