import { clsx } from "clsx";
import React, { PropsWithChildren } from "react";

interface ModalContentProps extends PropsWithChildren {
  children?: React.ReactNode;
  title?: string | null;
  className?: string;
  titleClassName?: string;
}

function ModalHeader({ children, title, className, titleClassName }: ModalContentProps) {
  return (
    <div className={clsx(className)}>
      {title && <h2 className={clsx("-mt-3 text-lg lg:text-2xl", titleClassName)}>{title}</h2>}
      {children}
    </div>
  );
}

export default ModalHeader;
