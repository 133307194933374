export default function IconmonstrInfo51(props: IconmonstrInfo51Props) {
  return (
    <div className="absolute left-[10.16%] right-[8.59%] top-[10.16%] bottom-[8.59%]">
      <svg
        width="100%"
        height="100%"
        preserveAspectRatio="none"
        viewBox="0 0 26 26"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_1550_512)">
          <path
            d="M 13 0.25 C 5.959 0.25 0.25 5.959 0.25 13 C 0.25 20.041 5.959 25.75 13 25.75 C 20.041 25.75 25.75 20.041 25.75 13 C 25.75 5.959 20.041 0.25 13 0.25 Z M 12.999 6.359 C 13.732 6.359 14.328 6.954 14.328 7.688 C 14.328 8.421 13.732 9.016 12.999 9.016 C 12.266 9.016 11.672 8.421 11.672 7.688 C 11.672 6.954 12.266 6.359 12.999 6.359 Z M 15.125 19.375 H 10.875 V 18.313 C 11.389 18.122 11.938 18.099 11.938 17.532 V 12.785 C 11.938 12.218 11.389 12.129 10.875 11.939 V 10.876 H 14.063 V 17.533 C 14.063 18.101 14.612 18.126 15.125 18.314 V 19.375 Z"
            fill={props.color}
          />
        </g>
        <defs>
          <clipPath id="clip0_1550_512">
            <rect
              width="25.5"
              height="25.5"
              fill={props.color}
              transform="translate(0.25 0.25)"
            />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
}

IconmonstrInfo51.defaultProps = {
  color: "white",
};

interface IconmonstrInfo51Props {
  color?: string;
}
