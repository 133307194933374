import { useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { clearVipStatusData, generateVipStatusToken, getVipStatus, logout } from "../store/actions";

import { useMemo } from "react";
import { launchPlaynow } from "src/main/store/layout/actions";

const combinedActions = {
  logout,
  getVipStatus,
  generateVipStatusToken,
  launchPlaynow,
  clearVipStatusData,
};

const useActions = () => {
  const dispatch = useDispatch();
  return useMemo(() => {
    return bindActionCreators(combinedActions, dispatch);
  }, [dispatch]);
};

export default useActions;
