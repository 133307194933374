import { clsx } from "clsx";
import { PropsWithClassName } from "src/main/components";
import { mergeKeys } from "src/main/utils";
import "./StarFieldBackground.css";

interface StarFieldBackgroundProps extends PropsWithClassName {}

const STAR_LAYERS = 6;

const StarFieldBackground = ({ className }: StarFieldBackgroundProps) => {
  return (
    <div className={clsx("star-field-background", className)}>
      {Array.from({ length: STAR_LAYERS }, (_, index) => (
        <div
          key={mergeKeys("star-layer", index)}
          className="stars"
        />
      ))}
    </div>
  );
};

export default StarFieldBackground;
