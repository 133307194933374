import { clsx } from "clsx";
import React, { useState } from "react";
import { FaSliders } from "react-icons/fa6";
import { SortingModal } from "./components";

interface SortingProps {
  onUpdate: (sort: string) => void;
  querySort: string;
  className?: string;
}

const ProductSortingButton: React.FC<SortingProps> = ({ onUpdate, querySort, className }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div>
      <button
        type="button"
        onClick={openModal}
        className={clsx(
          "inline-flex h-8 w-8 items-center justify-center text-center text-sm  font-medium text-white",
          className,
        )}
      >
        <div className="relative">
          <FaSliders className="h-5 w-5 text-token-icon" />
          <div className="absolute -top-2 -right-2 flex h-4 w-4 items-center justify-center rounded-full bg-tertiary-600 text-xxs ">
            <span className="absolute left-1/2 top-1/2 h-[18px] -translate-x-1/2 -translate-y-1/2 text-center text-white">
              {!!querySort ? querySort.split(",").length : 0}
            </span>
          </div>
        </div>
      </button>
      <SortingModal
        isOpen={isModalOpen}
        onClose={closeModal}
        onSubmit={onUpdate}
        querySort={querySort}
      />
    </div>
  );
};

export default ProductSortingButton;
