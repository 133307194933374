export const MISSION_TIMER = "MISSION_TIMER";

export enum MissionUIStatus {
  IN_PROGRESS = "IN_PROGRESS",
  CLAIMABLE = "CLAIMABLE",
  CLAIMED = "CLAIMED",
  EXPIRED = "EXPIRED",
}

export interface MissionClaimButtonRef {
  count: () => void;
}
