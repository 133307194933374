import { clsx } from "clsx";
import { PropsWithChildren } from "react";
import ClaimButton from "src/main/views/RewardsPage/components/ClaimButton";
import CoinLabel from "src/main/views/RewardsPage/components/CoinLabel";
import { CoinLabelRef } from "src/main/views/RewardsPage/components/CoinLabel/CoinLabel";
import { RewardBoxStatus, RewardInfoData } from "src/main/views/RewardsPage/types";
import TimerBar from "../TimerBar";

interface ClaimPointsSectionProps extends PropsWithChildren {
  onPointsCountEnd: () => void;
  onTimerComplete: () => void;
  onClaim: () => void;
  isClaiming: boolean;
  status: RewardBoxStatus;
  refCoinLabel: React.Ref<CoinLabelRef>;
  isGetMorePoints: boolean;
  rewardData: RewardInfoData;
}

const ClaimPointsSection = ({
  onPointsCountEnd,
  status,
  isGetMorePoints,
  refCoinLabel,
  onTimerComplete,
  isClaiming,
  onClaim,
  rewardData,
}: ClaimPointsSectionProps) => {
  return (
    <div className="relative z-10 mt-auto aspect-[165/134] min-h-[60%] w-full">
      {/* price section */}
      <div
        className="absolute top-0 left-0 z-[1] ml-[-2px] flex aspect-[163/76] min-h-[50%] w-[calc(100%+4px)] items-center justify-center overflow-hidden bg-cover"
        style={{
          backgroundImage: "url('/images/body-bg/bg-reward-point.svg')",
        }}
      >
        <div className="absolute top-0 left-0 flex h-full w-full items-center justify-center">
          <CoinLabel
            endVal={0}
            startVal={rewardData.claimPoints ?? 0}
            ref={refCoinLabel}
            onCountEnd={onPointsCountEnd}
          />
        </div>
      </div>

      <div className="flex h-full max-h-full w-full flex-col overflow-hidden">
        <div className="w-full bg-transparent pb-[calc(38/163*100%)]"></div>

        <div className="relative w-full shrink-0 grow overflow-hidden bg-[#1D104A] max-[620px]:min-h-[25vw]">
          <div
            className="absolute h-full w-full"
            style={{
              background: "radial-gradient(69.92% 50% at 50% 50%, #4526B0 0%, rgba(29, 16, 74, 0) 100%)",
              backgroundPositionY: "-30px",
              backgroundRepeat: "no-repeat",
            }}
          ></div>
          <div
            className={clsx(
              "absolute top-0 left-0 flex h-full w-full translate-y-0 flex-col items-center justify-between p-1.5 transition-transform duration-[400ms] ease-linear min-[387px]:p-3 min-[456px]:pb-[18px] min-[524px]:pb-6 min-[596px]:pb-[30px]",
              {
                "-translate-y-full": status !== RewardBoxStatus.LOCKED,
                "opacity-0": isGetMorePoints,
              },
            )}
          >
            <div className="aspect-[163/38] w-full bg-transparent"></div>
            <TimerBar
              remainingSeconds={rewardData.remainingSeconds}
              totalSeconds={rewardData.periodInSeconds}
              className={clsx("w-full")}
              onComplete={onTimerComplete}
            />
          </div>
          <div
            className={clsx(
              "absolute top-0 left-0 flex h-full w-full translate-y-0 flex-col items-center justify-between p-1.5 transition-transform duration-[400ms] ease-linear min-[387px]:p-3 min-[456px]:pb-[18px] min-[524px]:pb-6 min-[596px]:pb-[30px]",
              {
                "translate-y-full": status === RewardBoxStatus.LOCKED && !isGetMorePoints,
              },
            )}
          >
            <div className="aspect-[163/38] w-full bg-transparent"></div>
            <ClaimButton
              onClick={onClaim}
              status={isGetMorePoints ? RewardBoxStatus.GET_MORE_POINTS : status}
              loading={isClaiming}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ClaimPointsSection;
