import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
import moment from "moment";
import { authApi, publicApi } from "src/main/api";
import { Defaults } from "src/main/contants";
import { AccountModel, AuthToken, MemberModel, ProfileModel, SelfEntityModel } from "src/main/types";
import { getStorageItem, logger } from "src/main/utils";
import { VipStatusToken } from "../types/types";
import { InitAuthPayload } from "./types";

export type AuthState = {
  mobileLogin: {
    otpRequest: number;
    otpVerified: boolean;
    phoneValid: boolean;
    mobileNo: string;
  };
  oauth?: AuthToken | null;
  self?: SelfEntityModel | null;
  accounts?: AccountModel[] | null;
  vipStatusToken?: VipStatusToken | null;
  memberOnboard?: Partial<MemberModel>;
  mobile: string;
  utcOffset: number | null;
};

interface otpLocalStorage {
  last_request?: number;
  count?: number;
}

const getStoredOtp = (): number => {
  try {
    const otpData: otpLocalStorage = getStorageItem("_otp", [] as any, JSON.parse);
    return otpData?.count ?? 0;
  } catch (error) {
    // console.error('Error parsing JSON:', error);
  }
  return 0;
};

const persistToken = (token: AuthToken | null) => {
  if (token?.accessToken) localStorage.setItem(Defaults.AccessTokenKey, token.accessToken);
  else localStorage.removeItem(Defaults.AccessTokenKey);
};

const getPersistMember = (): MemberModel | undefined => {
  const json = localStorage.getItem("persist-member");
  try {
    if (!json) throw new Error();
    return JSON.parse(json) as MemberModel;
  } catch (error) {
    return undefined;
  }
};

const generateSelf = (data: SelfEntityModel | undefined, utcOffset: number | null): SelfEntityModel | null => {
  const self = data ? { ...data } : null;
  if (self) {
    self.claimedDailyBonus = false;
    const lastDailyRedeemedAt = self.member?.lastDailyRedeemedAt;
    if (lastDailyRedeemedAt) {
      const claimedAt = moment.utc(lastDailyRedeemedAt).utcOffset(utcOffset ?? 0);
      const today = moment().startOf("day");
      const didClaim = claimedAt.isAfter(today);
      if (didClaim) {
        self.claimedDailyBonus = true;
      }
    }
  }
  return self;
};

export const initialState: AuthState = {
  mobileLogin: {
    // otpRequest: JSON.parse(localStorage.getItem("_otp") ?? '') ?? 0
    otpRequest: getStoredOtp(),
    otpVerified: false,
    phoneValid: false,
    mobileNo: "",
  },
  memberOnboard: getPersistMember(),
  mobile: localStorage.getItem("persist-mobile") ?? "",
  utcOffset: null,
};

export const authSlice = createSlice({
  name: "updateMobileOtpRequest",
  initialState,
  reducers: {
    // updateMobileValidity(state, { payload }: PayloadAction<boolean>) {
    //     state.mobileLogin.phoneValid = payload
    // },
    // updateMobileOtpRequest(state, action: PayloadAction<requstOtpApiPayload>) {
    //     state.mobileLogin.otpRequest = state.mobileLogin.otpRequest + 1
    // },
    // updateMobileOTPVerified(state, { payload }: PayloadAction<boolean>) {
    //     state.mobileLogin.otpVerified = payload
    // },
    // updateMobileNumber(state, { payload }: PayloadAction<string>) {
    //     state.mobileLogin.mobileNo = payload
    // },

    // updateVipStatusToken(state, action: PayloadAction<VipStatusToken>) {
    //     state.vipStatusToken = action.payload;
    // },
    initAuthState(state, action: PayloadAction<InitAuthPayload | null>) {
      let { token = null, accounts = null } = action.payload ?? {};
      state.oauth = token;
      state.accounts = accounts;
      state.self = generateSelf(action.payload?.self, state.utcOffset);

      persistToken(token);
    },
    updateMemberOnboardStatus(state, { payload }: PayloadAction<Partial<MemberModel>>) {
      state.memberOnboard = payload;
      try {
        localStorage.setItem("persist-member", JSON.stringify(payload));
      } catch (error) {
        logger._console.log(error);
      }
    },
    updateAccessToken(state, action: PayloadAction<AuthToken | null>) {
      logger._console.log("update access token", action);
      state.oauth = action.payload;
      persistToken(action.payload);
    },
    updateProfile(state, action: PayloadAction<ProfileModel | null>) {
      state.accounts = action.payload?.accounts ?? null;
      state.self = generateSelf(action.payload?.self, state.utcOffset);
    },
    logout(state, action: PayloadAction<void>) {
      state.oauth = null;
      state.self = null;
      state.accounts = null;

      persistToken(null);
    },
    setMobile(state, { payload }: PayloadAction<string>) {
      state.mobile = payload;
      localStorage.setItem("persist-mobile", payload);
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(authApi.endpoints.claimTodayBonus.matchFulfilled, (state) => {
      state.self!.claimedDailyBonus = true;
    });
    builder.addMatcher(publicApi.endpoints.getPublicInfo.matchFulfilled, (state, { payload }) => {
      if (payload.utcOffset) {
        state.utcOffset = payload.utcOffset;
      }
    });
  },
});

// export const { updateAccessToken, initAuthState, updateVipStatusToken, updateMobileOtpRequest, updateMobileOTPVerified, updateMobileValidity, updateMobileNumber } = authSlice.actions

export default authSlice;
