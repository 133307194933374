// import { useLocation } from "react-router-dom";
// import useActions from "../hooks/useActions";
// import useTypedSelector from "../hooks/useTypedSelector";
import { getStorageItem } from "../utils";

// const parseJwt = (token: string) => {
//   try {
//     return JSON.parse(atob(token.split(".")[1]));
//   } catch (e) {
//     return null;
//   }
// };

export const clearLocalstorage = () => {
  localStorage.removeItem("VIP_TOKEN");
  localStorage.removeItem("VIP_BRAND");
  localStorage.removeItem("VIP_USER");
  localStorage.removeItem("user-token");
  localStorage.removeItem("VIP_SHOPPING_CART");
  localStorage.removeItem("_locked_timer");
  localStorage.removeItem("_locked_user");
  localStorage.removeItem("_locked_brand");
};

export const getCurrentUser = () => {
  const user = getStorageItem("VIP_USER", {} as any, JSON.parse, "{}");
  if (user)
    return {
      firstTimeLogin: user.first_time_login,
      last_login: user.last_login,
      mobileLogin: user.mobile_login,
      user: user,
    };

  return {
    firstTimeLogin: null,
    last_login: null,
    mobileLogin: null,
    user: null,
  };
};

// interface authVerifyProps {
//   logOut: () => void;
// }
//
// export const AuthVerify = (props: authVerifyProps) => {
//   let location = useLocation();
//   const actions = useActions();
//   const { user, fetchBannerFlag, showDailyBonus } = useTypedSelector((state) => state);
//
//   // useEffect(() => {
//   //     if (!user.profile?.has_view_pop_up_banner && (!showDailyBonus.show || user.profile?.is_redeemed_daily_bonus))
//   //         actions.getBannerApi({})
//   // }, [fetchBannerFlag]);
//
//   // const getProfilePoints = () => {
//   //     actions.user({});
//   // }
//
//   // useEffect(() => {
//   //     getProfilePoints()
//   //     const token = getStorageItem("VIP_TOKEN", null)
//   //     if (token) {
//   //         const decodedJwt = parseJwt(token);
//   //         let oneMinitExpiration = decodedJwt?.exp
//   //         if (oneMinitExpiration * 1000 < Date.now()) {
//   //             props.logOut();
//   //         }
//   //     }
//   // }, [location]);
//
//   return null;
// };

export const isAccessTokenStored = () => {
  return !!getStorageItem("VIP_TOKEN", null);
};
