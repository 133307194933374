import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import {
  brandInitialState,
  changePasswordInitialState,
  langInitialState,
  OrderDetailInitialState,
  PaginationInitialState,
  PopupBannerInitialState,
  productDetailInitialState,
  profileInitialState,
  shippingDetailInitialState,
  ShowDailyBonusInitialState,
  ShowPopupBonusInitialState,
  tokenInitialState,
  UserDailyBonusInitialState,
  VipStatusInitialState,
  VipStatusTokenInitialState,
} from "./initialValue";
import {
  BrandType,
  LangType,
  OrderDetailInfoType,
  OrderDetailType,
  PaginationType,
  PointDetailInfoType,
  ProfileType,
  ShippingDetailType,
  TokenType,
} from "./types";
import {
  PopupBannerType,
  ProductType,
  ShowDailyBonusType,
  ShowPopupBannerType,
  UserCartItemType,
  UserDailyBonusType,
  VipStatusTokenType,
  VipStatusType,
} from "./types/types";

export const products = createSlice({
  name: "products",
  initialState: [] as Array<ProductType>,
  reducers: {
    setProducts: (state, { payload }: PayloadAction<Array<ProductType>>) => payload,
  },
});

export const checkoutProducts = createSlice({
  name: "checkoutProducts",
  initialState: [] as Array<ProductType>,
  reducers: {
    setCheckoutProducts: (state, { payload }: PayloadAction<Array<ProductType>>) => payload,
  },
});

export const productDetail = createSlice({
  name: "productDetail",
  initialState: productDetailInitialState,
  reducers: {
    setProductDetail: (state, { payload }: PayloadAction<ProductType>) => payload,
  },
});

export const isLoading = createSlice({
  name: "isLoading",
  initialState: false,
  reducers: {
    setIsLoading: (state, { payload }: PayloadAction<boolean>) => payload,
  },
});

export const loginFailed = createSlice({
  name: "loginFailed",
  initialState: false,
  reducers: {
    setLoginFailed: (state, { payload }: PayloadAction<boolean>) => payload,
  },
});

export const loginLocked = createSlice({
  name: "loginLocked",
  initialState: "",
  reducers: {
    setLoginLocked: (state, { payload }: PayloadAction<string>) => payload,
  },
});

export const message = createSlice({
  name: "message",
  initialState: "",
  reducers: {
    setMessage: (state, { payload }: PayloadAction<string>) => payload,
  },
});

// export const updateCart = createSlice({
//     name: 'updateCart',
//     initialState: 0,
//     reducers: {
//         setUpdateCart: (state, { payload }: PayloadAction<number>) => payload,
//     },
// })

// export const cartGrandTotal = createSlice({
//     name: 'cartGrandTotal',
//     initialState: productGrandTotalInitialState,
//     reducers: {
//         setCartGrandTotal: (state, { payload }: PayloadAction<ProductGrandTotalType>) => payload,
//     },
// })

export const shippingDetail = createSlice({
  name: "shippingDetail",
  initialState: shippingDetailInitialState,
  reducers: {
    setShippingDetail: (state, { payload }: PayloadAction<ShippingDetailType>) => payload,
  },
});

export const getProfile = createSlice({
  name: "getProfile",
  initialState: profileInitialState,
  reducers: {
    setProfile: (state, { payload }: PayloadAction<ProfileType>) => payload,
  },
});

export const changePassword = createSlice({
  name: "changePassword",
  initialState: changePasswordInitialState,
  reducers: {
    // setProfile: (state, { payload }: PayloadAction<ProfileType>) => payload,
  },
});

export const cartSelectedAll = createSlice({
  name: "cartSelectedAll",
  initialState: false,
  reducers: {
    setCartSelectedAll: (state, { payload }: PayloadAction<boolean>) => payload,
  },
});

export const orderHistory = createSlice({
  name: "orderHistory",
  initialState: [] as Array<OrderDetailInfoType>,
  reducers: {
    setOrderHistory: (state, { payload }: PayloadAction<Array<OrderDetailInfoType>>) => payload,
  },
});

export const pointHistory = createSlice({
  name: "pointHistory",
  initialState: [] as Array<PointDetailInfoType>,
  reducers: {
    setPointHistory: (state, { payload }: PayloadAction<Array<PointDetailInfoType>>) => payload,
  },
});

export const orderDetail = createSlice({
  name: "orderDetail",
  initialState: OrderDetailInitialState,
  reducers: {
    setOrderDetail: (state, { payload }: PayloadAction<OrderDetailType>) => payload,
  },
});

export const pagination = createSlice({
  name: "pagination",
  initialState: PaginationInitialState,
  reducers: {
    setPagination: (state, { payload }: PayloadAction<PaginationType>) => payload,
  },
});

export const userProfile = createSlice({
  name: "userProfile",
  initialState: profileInitialState,
  reducers: {
    setUserProfile: (state, { payload }: PayloadAction<ProfileType>) => payload,
    logout: (state) => {},
  },
});

export const brand = createSlice({
  name: "brand",
  initialState: brandInitialState,
  reducers: {
    setBrand: (state, { payload }: PayloadAction<BrandType>) => payload,
  },
});

export const token = createSlice({
  name: "token",
  initialState: tokenInitialState,
  reducers: {
    setToken: (state, { payload }: PayloadAction<TokenType>) => payload,
  },
});

export const language = createSlice({
  name: "language",
  initialState: langInitialState,
  reducers: {
    setLanguage: (state, { payload }: PayloadAction<LangType>) => payload,
  },
});

export const userCart = createSlice({
  name: "userCart",
  initialState: [] as Array<UserCartItemType>,
  reducers: {
    setUserCart: (state, { payload }: PayloadAction<Array<UserCartItemType>>) => payload,
  },
});

export const userDailyBonus = createSlice({
  name: "userDailyBonus",
  initialState: UserDailyBonusInitialState,
  reducers: {
    setUserDailyBonus: (state, { payload }: PayloadAction<UserDailyBonusType>) => payload,
  },
});

export const showDailyBonus = createSlice({
  name: "showDailyBonus",
  initialState: ShowDailyBonusInitialState,
  reducers: {
    setShowDailyBonus: (state, { payload }: PayloadAction<ShowDailyBonusType>) => payload,
  },
});

export const launchPopupBanner = createSlice({
  name: "setLaunchPopupBanner",
  initialState: ShowPopupBonusInitialState,
  reducers: {
    setLaunchPopupBanner: (state, { payload }: PayloadAction<ShowPopupBannerType>) => payload,
  },
});

export const popupBanner = createSlice({
  name: "setPopupBanner",
  initialState: PopupBannerInitialState,
  reducers: {
    setPopupBanner: (state, { payload }: PayloadAction<PopupBannerType>) => payload,
  },
});

export const fetchBannerFlag = createSlice({
  name: "setFetchBannerFlag",
  initialState: ShowPopupBonusInitialState,
  reducers: {
    setFetchBannerFlag: (state, { payload }: PayloadAction<ShowPopupBannerType>) => payload,
  },
});

export const vipStatus = createSlice({
  name: "setVipStatus",
  initialState: VipStatusInitialState,
  reducers: {
    setVipStatus: (state, { payload }: PayloadAction<VipStatusType>) => payload,
  },
});

export const vipStatusToken = createSlice({
  name: "generateVipStatusToken",
  initialState: VipStatusTokenInitialState,
  reducers: {
    generateVipStatusToken: (state, { payload }: PayloadAction<VipStatusTokenType>) => payload,
  },
});

export const { setProducts } = products.actions;
// export const { setGifts } = gifts.actions
// export const { setTopProducts } = topProducts.actions
export const { setProductDetail } = productDetail.actions;

// export const { setUpdateCart } = updateCart.actions
export const { setIsLoading } = isLoading.actions;
export const { setLoginFailed } = loginFailed.actions;

// export const { setCartGrandTotal } = cartGrandTotal.actions
export const { setShippingDetail } = shippingDetail.actions;
export const { setCheckoutProducts } = checkoutProducts.actions;

export const { setProfile } = getProfile.actions;

export const { setCartSelectedAll } = cartSelectedAll.actions;

export const { setOrderHistory } = orderHistory.actions;

export const { setPointHistory } = pointHistory.actions;

export const { setOrderDetail } = orderDetail.actions;

export const { setPagination } = pagination.actions;

export const { setBrand } = brand.actions;

export const { setToken } = token.actions;

export const { setMessage } = message.actions;

export const { setUserProfile, logout } = userProfile.actions;

export const { setLanguage } = language.actions;

export const { setUserCart } = userCart.actions;

export const { setUserDailyBonus } = userDailyBonus.actions;

export const { setShowDailyBonus } = showDailyBonus.actions;

export const { setPopupBanner } = popupBanner.actions;

export const { setLaunchPopupBanner } = launchPopupBanner.actions;

export const { setFetchBannerFlag } = fetchBannerFlag.actions;

export const { setLoginLocked } = loginLocked.actions;

export const { setVipStatus } = vipStatus.actions;

export const { generateVipStatusToken } = vipStatusToken.actions;
