import IcoLineRounded from "src/assets/IcoLineRounded";
import IcoMessengerRounded from "src/assets/IcoMessengerRounded";
import IcoTelegramRounded from "src/assets/IcoTelegramRounded";
import { SubNavItem } from "src/main/components/_layouts/BottomNavigation/components";
import { SOCIAL_MEDIA_TYPE } from "src/main/contants";
import { getDeployEnv } from "src/main/utils";

const SOCIAL_ICON_MAP = {
  [SOCIAL_MEDIA_TYPE.LINE]: <IcoLineRounded />,
  [SOCIAL_MEDIA_TYPE.Telegram]: <IcoTelegramRounded />,
  [SOCIAL_MEDIA_TYPE.Messenger]: <IcoMessengerRounded />,
};

const currentEnv = getDeployEnv();

export default function SocialButtons() {
  return (
    <>
      {currentEnv.contactPlatforms.map((socialNetwork, index) => (
        // {all.map((socialNetwork, index) => (
        <SubNavItem
          icon={SOCIAL_ICON_MAP[socialNetwork.type]}
          onClick={() => {
            window.open(socialNetwork.url, "_blank");
          }}
          key={socialNetwork.type}
          title={socialNetwork.title}
        />
      ))}
    </>
  );
}
