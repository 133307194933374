import { Dialog, Transition } from "@headlessui/react";
import { clsx } from "clsx";
import { Fragment, PropsWithChildren, useEffect, useState } from "react";
import { CloseButton } from "src/main/components";
import { TRANSITION_IN_SLIDE_UP, TRANSITION_OUT_SLIDE_DOWN } from "src/main/contants";
import { ModalBackdrop, ModalContent, ModalFooter, ModalHeader } from "./components";

interface ModalProps extends PropsWithChildren {
  show: boolean;
  onClose: () => void;
  panelClassName?: string;
  backdropClassName?: string;
  className?: string;
  appear?: boolean;
  showCloseButton?: boolean;
  preload?: boolean;
}

function Modal(props: ModalProps) {
  const {
    show = false,
    backdropClassName,
    onClose,
    className,
    showCloseButton = true,
    panelClassName,
    appear = false,
    children,
    preload,
  } = props;
  const [isShowing, setIsShowing] = useState(show);

  useEffect(() => {
    setIsShowing(show);
  }, [show]);

  return (
    <Transition
      show={isShowing}
      as={Fragment}
      appear={appear}
      unmount={!preload}
    >
      <Dialog onClose={onClose}>
        <ModalBackdrop className={backdropClassName} />

        <Transition.Child
          as="div"
          {...TRANSITION_IN_SLIDE_UP}
          {...TRANSITION_OUT_SLIDE_DOWN}
          appear={appear}
          unmount={!preload}
          className={clsx("fixed inset-0 z-[80] w-screen overflow-y-auto", className)}
        >
          <div className="flex min-h-full items-center justify-center p-4">
            <Dialog.Panel
              className={clsx(
                "relative min-w-[80vw] rounded-lg border-[1px] border-token-border bg-gradient-to-r from-container-1 to-container-3 p-4 py-6 text-white shadow filter backdrop-blur lg:min-w-[500px]",
                panelClassName,
              )}
            >
              {children}
              {showCloseButton && (
                <div className="absolute top-2 right-4 z-[90] w-7">
                  <CloseButton
                    onClick={onClose}
                    className="absolute -right-4 w-fit flex-shrink-0 rounded-full"
                    tabIndex={1}
                  />
                </div>
              )}
            </Dialog.Panel>
          </div>
        </Transition.Child>
      </Dialog>
    </Transition>
  );
}

Modal.Backdrop = ModalBackdrop;
Modal.Content = ModalContent;
Modal.Header = ModalHeader;
Modal.Footer = ModalFooter;

export default Modal;
