import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Modal as RawModal, Radio } from "src/main/components";

interface SortingModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (sort: string) => void;
  querySort: string;
}

const SortingModal = ({ isOpen, onClose, onSubmit, querySort }: SortingModalProps) => {
  const [pointSortingOption, setPointSortingOption] = useState<string | undefined>();
  const [nameSortingOption, setNameSortingOption] = useState<string | undefined>();

  const { t } = useTranslation();

  const doFilter = useCallback(
    (sortingOptionList: (string | undefined)[]) => {
      const availableOptions = sortingOptionList.filter((str) => !!str);
      const sort = availableOptions.join(",");
      onSubmit(sort);
      onClose();
    },
    [onClose, onSubmit],
  );

  const handleSubmit = useCallback(
    (e: React.FormEvent) => {
      e.preventDefault();
      doFilter([pointSortingOption, nameSortingOption]);
    },
    [doFilter, pointSortingOption, nameSortingOption],
  );

  const handleOptionAChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPointSortingOption(e.target.value);
  };

  const handleOptionAClick = (value: string) => {
    if (value === pointSortingOption) {
      setPointSortingOption("");
    }
  };

  const handleOptionBChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNameSortingOption(e.target.value);
  };

  const handleOptionBClick = (value: string) => {
    if (value === nameSortingOption) {
      setNameSortingOption("");
    }
  };

  const reset = () => {
    setPointSortingOption("");
    setNameSortingOption("");
    doFilter([]);
  };

  const closeWithoutApply = () => {
    if (!querySort) {
      setPointSortingOption("");
      setNameSortingOption("");
    }
    onClose();
  };

  return (
    <RawModal
      show={isOpen}
      onClose={closeWithoutApply}
    >
      <RawModal.Header title={t("Sort by")} />
      <RawModal.Content>
        <hr className="mb-2 mt-4 h-px border-0 bg-[#4F4881]" />
        <form onSubmit={handleSubmit}>
          <div className="text-sm">
            <div>
              <div className="mb-4 flex items-center justify-between">
                <label className="text-xxs font-extrabold text-gray-500">{t("POINTS")}</label>
              </div>
              <div className="mb-6 flex items-center justify-between">
                <label
                  className="mr-2"
                  htmlFor="option1"
                >
                  <span className="font-extrabold">{t("Points to Redeem")} </span>({t("Low")} → {t("High")})
                </label>
                <Radio
                  id="point_low_to_high"
                  value="basePrice:asc"
                  checked={pointSortingOption === "basePrice:asc"}
                  onChange={handleOptionAChange}
                  onClick={() => handleOptionAClick("basePrice:asc")}
                />
              </div>
              <div className="mb-6 flex items-center justify-between">
                <label
                  className="mr-2"
                  htmlFor="option1"
                >
                  <span className="font-extrabold">{t("Points to Redeem")} </span>({t("High")} → {t("Low")})
                </label>
                <Radio
                  type="radio"
                  id="point_high_to_low"
                  value="basePrice:desc"
                  checked={pointSortingOption === "basePrice:desc"}
                  onChange={handleOptionAChange}
                  onClick={() => handleOptionAClick("basePrice:desc")}
                />
              </div>
            </div>
            <hr className="mb-2 h-px border-0 bg-[#4F4881]" />
            <div>
              <div className="mb-4 flex items-center justify-between">
                <label className="text-xxs font-extrabold text-gray-500">{t("NAME")}</label>
              </div>
              <div className="mb-6 flex items-center justify-between">
                <label
                  className="mr-2"
                  htmlFor="option1"
                >
                  <span className="font-extrabold">{t("Alphabetical")} </span>({t("A")} → {t("Z")})
                </label>
                <Radio
                  id="alphabetical_a_to_z"
                  value="name:asc"
                  checked={nameSortingOption === "name:asc"}
                  onChange={handleOptionBChange}
                  onClick={() => handleOptionBClick("name:asc")}
                />
              </div>
              <div className="mb-6 flex items-center justify-between">
                <label
                  className="mr-2"
                  htmlFor="option1"
                >
                  <span className="font-extrabold">{t("Alphabetical")} </span>({t("Z")} → {t("A")})
                </label>
                <Radio
                  type="radio"
                  id="alphabetical_z_to_a"
                  value="name:desc"
                  checked={nameSortingOption === "name:desc"}
                  onChange={handleOptionBChange}
                  onClick={() => handleOptionBClick("name:desc")}
                />
              </div>
            </div>
          </div>
          <Button size="xl" variant="blue-violet">{t("Apply Setting")}</Button>
          <button
            type="button"
            data-bs-dismiss="offcanvas"
            onClick={reset}
            className="relative mt-3 w-full rounded-lg border-0 py-4 px-5 text-center text-[15px] font-normal leading-[normal] text-white"
          >
            {t("Clear")}
          </button>
        </form>
      </RawModal.Content>
    </RawModal>
  );
};

export default SortingModal;
