import React, { useMemo } from "react";
import { BonusBadge } from "src/main/components";
import { useTypedSelector } from "src/main/hooks";
import { BrandModel } from "src/main/models";

interface SiteButtonProps {
  brandId?: string;
  brand?: BrandModel;
  bonusPercent?: number;
  hasAccount?: boolean;
}

const SiteButton: React.FC<SiteButtonProps> = ({ brandId, brand, bonusPercent, hasAccount }) => {
  const { brands } = useTypedSelector((state) => state.user);

  const displayBrand = useMemo(() => {
    return brand ?? brands?.find((brand) => brand.id === brandId);
  }, [brands, brandId, brand]);

  const brandDomainParts = useMemo(() => {
    if (!!displayBrand && displayBrand.domain) {
      const [hostname, ...domains] = displayBrand.domain.split(".");
      return {
        hostname,
        domains: "." + domains.join("."),
      };
    }
  }, [displayBrand]);

  if (!displayBrand) return null;

  return (
    <div className="relative flex h-full w-[100px] flex-col justify-start justify-items-center gap-2 text-center">
      <div className="flex w-full justify-center">
        <a
          href={displayBrand.siteUrl}
          target="_blank"
          rel="noopener noreferrer"
        >
          <div
            className="bg-lvl-1-card-gradient !relative h-20 w-20 rounded-lg
                    p-2 shadow-[1px_1px_0px_0px_rgba(137,118,255,0.24)_inset]"
          >
            {!!bonusPercent && (
              <BonusBadge
                className="!absolute -top-[3px] -right-[3px] w-fit"
                percent={bonusPercent}
              />
            )}
            <img
              src={displayBrand.icon?.url}
              alt="icon"
              className="h-full w-full object-contain object-center"
            />
            {!hasAccount && (
              <img
                src="/images/icon/badge-signup.svg"
                alt="signup"
                className="absolute bottom-0 left-1/2 inline-block h-4 w-[48px] -translate-x-1/2 rounded-t bg-white bg-opacity-[0.1]  px-0.5 pt-0.5"
              />
            )}
          </div>
        </a>
      </div>
      <div className="flex flex-col text-sm uppercase">
        <span className="text-white">{brandDomainParts?.hostname || displayBrand.name}</span>
        {!!brandDomainParts && <span className="text-xs text-token-secondary-2">{brandDomainParts.domains}</span>}
      </div>
    </div>
  );
};

SiteButton.defaultProps = {};

export default SiteButton;
