export default function Avatar(props: AvatarProps) {
  return (
    <div className="relative h-[84px] w-[84px]">
      <div className="avatar-gradient h-[84px] w-[84px] rounded-full"></div>
      <div className="absolute top-[2px] left-[2px] h-[80px] w-[80px] rounded-full bg-black"></div>
      <img
        src="/images/header/profile-ilv.png"
        alt="avatar"
        className="absolute top-[5px] left-[5px] h-[74px] w-[74px] rounded-full"
      />
    </div>
  );
}

Avatar.defaultProps = {};

interface AvatarProps {}
