import BigNumber from "bignumber.js";
import { useMemo } from "react";
import CountUp from "react-countup";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { IcoCash, IcoPoint } from "src/assets";
import IcoClock from "src/assets/IcoClock";
import { Button, Card, CardLogo, CardTitle } from "src/main/components";
import { useTypedSelector } from "src/main/hooks";
import { bnOrZero, currencySymbol, fNumber, Paths } from "src/main/utils";
import { BonusDetail } from "src/main/views/CashbackPage/components";
import { AccountSelectionPayload, PointSelectionPayload } from "../../types";

interface CashbackSuccessProps {
  selectedAccountPayload: AccountSelectionPayload;
  selectedPointPayload: PointSelectionPayload;
}

function StepSuccess({ selectedAccountPayload, selectedPointPayload }: CashbackSuccessProps) {
  const { account } = selectedAccountPayload || {};
  const { self } = useTypedSelector((state) => state.auth);
  const currentBalance = useMemo(
    () => bnOrZero(self?.member?.primaryWallet?.balance).integerValue(BigNumber.ROUND_FLOOR),
    [self],
  );
  const { t } = useTranslation();
  const navigate = useNavigate();
  const orderItem = useMemo(() => {
    return selectedAccountPayload.order.orderItems[0];
  }, [selectedAccountPayload]);

  const { exchangeCash, hasBonus } = useMemo(() => {
    const exchangeCash = bnOrZero(orderItem.info?.variant?.virtualFulfilment?.data?.totalCredit);
    const hasBonus = !!selectedAccountPayload.bonusPercentage;

    return {
      exchangeCash,
      hasBonus,
    };
  }, [orderItem, selectedAccountPayload]);

  return (
    <div className="px-4 pt-8 lg:mt-24">
      <Card
        variant="gradient-l"
        className="mx-auto pt-4 lg:max-w-lg"
      >
        <CardLogo
          float
          src="/images/icon/cash-back-logo.gif"
          className="h-auto w-20"
        />
        <CardTitle>{t("Order confirmed")}!</CardTitle>
        <Card
          variant="container-light"
          border={false}
          className="mt-2 gap-y-1 !bg-black !bg-opacity-20 shadow-xl"
        >
          <div className="mx-auto flex items-center gap-x-2 rounded-full bg-black bg-opacity-50 px-3 py-1 text-white">
            <IcoCash
              width={24}
              height={24}
            />
            <CountUp
              start={0}
              end={exchangeCash.toNumber()}
              delay={0}
              duration={1}
              separator=","
              decimals={2}
              decimal="."
              prefix={currencySymbol(selectedPointPayload.exchangeTarget.currency)}
            >
              {({ countUpRef }) => (
                <span
                  className="text-xl font-bold"
                  ref={countUpRef}
                />
              )}
            </CountUp>
          </div>
          <div className="flex animate-[fadeIn_.8s_forwards_.5s] flex-col gap-y-1 opacity-0">
            {hasBonus && (
              <div className="mx-auto">
                <BonusDetail
                  exchangeCash={exchangeCash}
                  originalExchangeCash={selectedPointPayload.amount}
                  bonusPercentage={selectedAccountPayload.bonusPercentage}
                  currency={selectedPointPayload.exchangeTarget.currency}
                />
              </div>
            )}
            <div className="mt-5 flex items-center justify-center gap-x-3 text-white">
              <span className="text-xxs font-bold">{t("To")}: </span>
              <div className="flex gap-x-3">
                <div className="padding-1 flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-lg bg-[rgba(255,255,255,0.1)]">
                  <img
                    src={account?.brand?.icon?.url}
                    alt={account?.brand?.name}
                    height={32}
                    width={32}
                    className="object-contain"
                  />
                </div>
                <div className="flex flex-col justify-between">
                  <h2 className="text-sm text-white">{account?.identifier}</h2>
                  <p className="text-xxs text-token-secondary-2">{account?.brand?.name}</p>
                </div>
              </div>
            </div>

            <hr className="mt-5 mb-1 border-token-divider" />
            <div className="flex items-center justify-center gap-x-1 text-center">
              <IcoClock className="text-primary-200" />
              <span className="text-xs text-white">{t("Your order will be processed in 2 hours")}</span>
            </div>
          </div>
        </Card>
        <div className="animate-[fadeIn_.8s_forwards_.5s] opacity-0">
          <div className="mt-7 flex flex-col gap-y-2">
            <div className="flex justify-between">
              <div className="flex flex-col justify-around gap-y-2">
                <span className="text-sm text-white">{t("Points deducted")}:</span>
                <span className="text-sm text-white">{t("New Points Balance")}:</span>
              </div>
              <div className="flex flex-col gap-y-2">
                <div className="flex min-w-[5rem] items-center gap-x-1 rounded-full bg-black bg-opacity-50 px-2 py-0.5">
                  <IcoPoint
                    width={14}
                    height={14}
                    className="flex-shrink-0 opacity-25 grayscale"
                  />
                  <span className="flex-1 text-right text-sm text-error-500">
                    -{fNumber(selectedPointPayload.points)}
                  </span>
                </div>
                <div className="flex min-w-[5rem] items-center gap-x-1 rounded-full bg-black bg-opacity-50 px-2 py-0.5">
                  <IcoPoint
                    width={14}
                    height={14}
                    className="flex-shrink-0"
                  />
                  <span className="flex-1 text-right text-sm font-semibold text-white">{fNumber(currentBalance)}</span>
                </div>
              </div>
            </div>

            <div className="mt-7">
              <Button
                outlined
                variant="blue-violet"
                onClick={() => {
                  navigate(Paths.Private.OrderHistory, {
                    replace: true,
                  });
                }}
              >
                {t("order history")}
              </Button>
              <Button
                onClick={() => {
                  navigate(Paths.Private.Home, {
                    replace: true,
                  });
                }}
                variant="bare"
              >
                {t("back to homepage")}
              </Button>
            </div>
          </div>
        </div>
      </Card>
    </div>
  );
}

export default StepSuccess;
