import { fork, put, takeLatest } from "@redux-saga/core/effects";
import { PayloadAction } from "@reduxjs/toolkit";
import moment from "moment";
import { authApi, publicApi } from "src/main/api";
import { ProfileResponse } from "src/main/api/auth/types";
import { Defaults } from "src/main/contants";
import { AuthToken, DeployEnv, ProfileModel } from "src/main/types";
import { getDeployEnv, logger, retrieveNewVoluumCid } from "src/main/utils";
import { execMutation, execQuery } from "src/main/utils/saga";
import authSlice from "./slices";
import { InitAuthPayload } from "./types";

function* loadAccessToken() {
  try {
    yield* execQuery(publicApi.endpoints.getPublicInfo.initiate());

    logger._console.log("loadAccessToken");
    const accessToken = localStorage.getItem(Defaults.AccessTokenKey);
    if (typeof accessToken !== "string") return null;

    const [, _payload] = accessToken.split(".");
    const payload = JSON.parse(atob(_payload));

    if (typeof payload.exp !== "number") throw new Error("cannot validate token expiry");

    const expiresAt = moment.unix(payload.exp);
    if (expiresAt.isBefore(moment())) {
      logger._console.debug("token expired");
      return null;
    }

    const response: ProfileResponse = yield* execQuery(authApi.endpoints.authenticate.initiate(accessToken));
    logger._console.log("init", { response });
    if (!response) {
      logger._console.debug("cannot load profile from token");
      return null;
    }

    const result: InitAuthPayload = {
      self: response.self,
      accounts: response.accounts,
      token: {
        accessToken,
        expiresAt,
        tokenType: "bearer",
      },
    };
    return result;
  } catch (error) {
    logger._console.error("error loading access token");
    logger._console.error(error);
    return null;
  }
}

function* handleAction(action: PayloadAction<AuthToken>) {
  logger._console.log("handle action", action);
  if (action.payload.accessToken && action.payload.expiresAt.isAfter(moment())) {
    logger._console.log({ action });
    const profile: ProfileResponse = yield* execQuery(authApi.endpoints.profile.initiate());
    logger._console.log("update profile", profile);

    yield put(authSlice.actions.updateProfile(profile));
  }
}

function* handleVoluumCheck(action: PayloadAction<ProfileModel | null>) {
  // only run in production
  const env = getDeployEnv();
  if (env.deploymentType !== DeployEnv.Production) return;

  const profile = action.payload;
  if (!profile?.self.member) return;

  const member = profile.self.member;
  const storageKey = `${Defaults.VoluumCidKey}/${member.id}`;
  let voluumCid: string | null = localStorage.getItem(storageKey);

  // if voluumId is registered but not stored in localStorage, store it.
  if (member.voluumCid && !voluumCid) {
    voluumCid = member.voluumCid;
    localStorage.setItem(storageKey, voluumCid);
  }

  // if already registered, quit.
  if (member.voluumCid) return;

  // if voluumId not stored and not registered, try retrieving a new ID.
  if (!voluumCid) {
    try {
      voluumCid = yield retrieveNewVoluumCid(env.deployCountry);
    } catch (error) {
      console.warn("failed to retrieve voluum ID");
      console.warn(error);
    }

    // if retrieve failed, quit.
    if (!voluumCid) return;

    // store and register new voluum ID.
    localStorage.setItem(storageKey, voluumCid);
  }
  yield* execMutation(authApi.endpoints.updateVoluum.initiate(voluumCid));
}

function* init() {
  const result = yield* loadAccessToken();
  if (!result) {
    yield put(authSlice.actions.initAuthState(null));
  } else {
    yield put(authSlice.actions.initAuthState(result));
  }
}

function* saga() {
  yield takeLatest(authSlice.actions.updateAccessToken.type, handleAction);
  yield takeLatest(authSlice.actions.updateProfile.type, handleVoluumCheck);

  yield fork(init);
}

export default saga;
