import "./CoinAmount.css";
interface CoinAmountProps {
  text: string;
}

export default function CoinAmount({ text }: CoinAmountProps) {
  return (
    <div className="CoinAmount-container">
      <div className="CoinAmount-layer1">{text}</div>
      <div className="CoinAmount-layer2">{text}</div>
      <div className="CoinAmount-layer2a">{text}</div>
      <div className="CoinAmount-layer3">{text}</div>
    </div>
  );
}
