import { SerializedError } from "@reduxjs/toolkit";
import { FetchBaseQueryError } from "@reduxjs/toolkit/dist/query";
import moment from "moment";
import { SimpleMap } from "./type";

export interface APIValidationErrorItem {
  param: string;
  value: string;
  msg: string;
}

export interface APIBadRequestErrorItem {
  message: string;
}

export interface APIValidationError {
  type: "ValidationError";
  errors: SimpleMap<APIValidationErrorItem>;
  message: string;
}

export interface APIBadRequestError {
  type: "BadRequestError";
  errors: SimpleMap<APIBadRequestErrorItem>;
  message: string;
  code: number;
}
export type APIError = APIValidationError | APIBadRequestError; // | add more errors
export interface QueryError<E = unknown> {
  status?: number | string;
  error?: string;
  data?: E;
}
export interface ErrorResponse {
  error: APIError;
}
export type APIResult =
  | {
      data: unknown;
    }
  | {
      error: FetchBaseQueryError | SerializedError;
    };
export const handleApiResponse = <T = unknown>(result: APIResult) => {
  if ("data" in result) {
    return { data: result.data as T, error: null };
  } else if ("error" in result) {
    const error = result.error as QueryError<ErrorResponse>;
    return { data: null, error };
  }
  console.error(result);
  throw new Error("unable to parse api response");
};

export interface Prioritisable {
  sortPriority: number;
}
export const sortByPriority = (lhs: Prioritisable, rhs: Prioritisable) => {
  return lhs.sortPriority - rhs.sortPriority;
};

export const transformAccessToken = (input: any) => {
  return {
    tokenType: input.token_type,
    accessToken: input.access_token,
    refreshToken: input.refresh_token,
    expiresAt: moment.unix(input.expires_at),
    refreshExpiresAt: moment.unix(input.refresh_expires_at),
  };
};

export const require200Status = (response: Response) => response.status === 200;
