import { useMemo } from "react";
import { Fade } from "react-awesome-reveal";
import { useTranslation } from "react-i18next";
import { Card, LazyImage, PointDisplay } from "src/main/components";
import LocalizationDisplay from "src/main/components/LocalizationDisplay";
import { DEFAULT_PRODUCT_IMAGE_URL } from "src/main/contants";
import { CartItemModel } from "src/main/types";
import { bnOrZero } from "src/main/utils";

export interface CheckoutListItemProps {
  item: CartItemModel;
}

function CheckoutItemCard(props: CheckoutListItemProps) {
  const { item } = props;
  const { variant, quantity } = item;

  const { t } = useTranslation();

  const { imageUrl, price } = useMemo(() => {
    const singleFullPrice = bnOrZero(variant?.price ?? "0")
      .plus(variant?.product?.basePrice ?? "0")
      .toNumber();
    return {
      imageUrl: (variant?.image?.url || variant?.product?.image?.url) ?? "",
      subtotal: singleFullPrice * quantity,
      price: singleFullPrice,
    };
  }, [variant, quantity]);

  const hasOneVariation = useMemo(() => {
    return item.variant?.name === item.variant?.product?.name;
  }, [item.variant?.name, item.variant?.product?.name]);

  return (
    <Card
      className="!flex-row space-x-2 px-4 pb-4 pt-2.5"
      variant="solid-inverted-surface"
    >
      <div className="relative h-[70px] w-[70px] flex-shrink-0">
        <Fade triggerOnce>
          <LazyImage
            src={imageUrl}
            className="absolute top-0 left-0 z-10 h-full w-full rounded-lg object-contain"
            alt="card"
            fallbackSrc={DEFAULT_PRODUCT_IMAGE_URL}
          />
        </Fade>
      </div>

      <div className="flex w-full flex-grow flex-col justify-between space-y-2 self-stretch pt-1.5 overflow-x-hidden">
        <LocalizationDisplay
          field="name"
          localisations={item?.variant?.product?.localisations}
          defaultValue={item?.variant?.product?.name}
          className="line-clamp-1 text-xs font-bold text-gray-700"
        />

        <div className="h-4.5">
          {!hasOneVariation && (
            <LocalizationDisplay
              field="name"
              localisations={item?.variant?.localisations}
              defaultValue={item?.variant?.name}
              className="line-clamp-1 text-xs text-gray-700"
            />
          )}
        </div>

        <div className="flex items-center justify-between">
          <PointDisplay points={price} />
          <span className="text-sm text-content-inverted-primary">{`${t("Qty")}: ${quantity}`}</span>
        </div>
      </div>
    </Card>
  );
}

export default CheckoutItemCard;
