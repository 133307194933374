import { Skeleton } from "src/main/components";

interface SkeletonLoaderProps {}

const SkeletonLoader = (props: SkeletonLoaderProps) => {
  return (
    <div>
      <div className="mb-4 flex items-center space-x-3">
        <Skeleton
          component={Skeleton.Box}
          variant="secondary"
          className={"h-28 w-28 flex-shrink-0 rounded-md"}
        />
        <div className="flex-grow space-y-2">
          <Skeleton
            component={Skeleton.Box}
            variant="secondary"
            className={"!h-5 w-full rounded-md"}
            count={2}
          />
        </div>
      </div>
      <div className="mb-4 grid grid-cols-3 gap-3">
        <Skeleton
          component={Skeleton.Box}
          variant="secondary"
          className={"!h-8 w-full rounded-md"}
          count={3}
        />
      </div>

      <Skeleton
        component={Skeleton.Box}
        variant="secondary"
        className={"mt-3 mb-5 !h-10 w-full rounded-md"}
      />
    </div>
  );
};

export default SkeletonLoader;
