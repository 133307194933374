import React, { EffectCallback, useEffect } from "react";

export const abbreviateNumber = (value: number) => {
  // if (value < 999) {
  //     return value.toString()
  // }

  //return new Intl.NumberFormat().format(Math.trunc(value)).toString()
  return new Intl.NumberFormat("en-US", { maximumFractionDigits: 2 }).format(value);
};

export const useAfterMountEffect = (effect: EffectCallback, deps?: any[]) => {
  const isMounted = React.useRef(false);

  useEffect(() => {
    if (isMounted.current) {
      return effect();
    } else {
      isMounted.current = true;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);
};
