import { forwardRef, SVGProps } from "react";

interface IcoPointProps extends SVGProps<SVGSVGElement> {}

const IcoPointV4 = forwardRef<any, IcoPointProps>((props, ref) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.width || "100"}
      height={props.height || "100"}
      viewBox="0 0 100 100"
      fill="none"
      {...props}
      ref={ref}
    >
      <path
        d="M49.9199 0.104736C77.2182 0.104736 99.5532 22.4397 99.5532 49.738C99.5532 77.0363 77.2182 99.3713 49.9199 99.3713C22.6216 99.3713 0.286621 77.0363 0.286621 49.738C0.286621 22.4397 22.6216 0.104736 49.9199 0.104736Z"
        fill="url(#paint0_radial_153_1768)"
      />
      <path
        d="M38.0138 23.6807C32.4363 23.6807 27.2572 25.7488 23.2732 29.0577C22.8748 29.4713 22.4765 29.8849 22.4765 29.8849C22.0781 30.2985 22.0781 30.7121 21.6797 30.7121C18.0941 34.8482 16.1022 40.2252 16.1022 45.6021C16.1022 51.3926 18.4925 57.1832 22.4765 61.3193L35.6234 74.5548L40.0057 78.691C42.7945 81.5862 46.7784 83.2407 51.1607 83.2407C55.5431 83.2407 59.527 81.5862 62.3157 78.691L79.845 60.9057C83.8289 56.7696 86.2193 50.979 86.2193 45.1885C86.2193 39.8116 84.2273 34.4346 80.6418 30.2985C80.2434 29.8849 80.2434 29.8849 79.845 29.4713C79.4466 29.0577 79.4466 29.0577 79.0482 28.6441C75.0643 24.9216 69.8852 22.8535 64.3077 22.8535C59.527 22.8535 54.7463 24.508 51.1607 27.4032C47.1768 24.9216 42.7945 23.6807 38.0138 23.6807Z"
        fill="url(#paint1_radial_153_1768)"
      />
      <path
        d="M74.7365 36.5026L74.3229 36.089L73.9093 35.6754C68.1188 30.712 59.4329 30.712 54.056 36.089L51.1607 38.9842L48.2655 36.089C42.8885 30.712 34.2027 30.2984 28.4121 35.6754L27.9985 36.089L27.5849 36.5026C25.1033 38.9842 23.8624 42.7067 23.8624 46.4292C23.8624 50.1517 25.5169 53.8742 27.9985 56.7695L41.2341 70.005L45.3702 74.1411C47.0246 75.7956 49.0927 76.6228 51.1607 76.6228C53.2288 76.6228 55.2968 75.7956 56.9513 74.1411L74.3229 56.7695C77.2182 53.8742 78.459 50.5653 78.459 46.4292C78.459 42.7067 76.8046 38.9842 74.7365 36.5026Z"
        fill="url(#paint2_linear_153_1768)"
      />
      <path
        d="M74.7365 36.5026C76.8046 38.9842 78.459 42.7067 78.459 46.4292C78.459 50.5653 77.2182 53.8742 74.3229 56.7695L56.9513 74.1411C55.2968 75.7956 53.2288 76.6228 51.1607 76.6228C49.0927 76.6228 47.0246 75.7956 45.3702 74.1411L27.9985 56.7695C25.5169 53.8742 23.8624 50.1517 23.8624 46.4292C23.8624 42.7067 25.1033 38.9842 27.5849 36.5026L28.4121 35.6754C32.6744 31.7176 38.5053 30.8971 43.4306 32.884C38.7951 31.1346 33.375 31.9578 29.3917 35.649L28.6031 36.436C26.2371 38.7973 25.0541 42.3391 25.0541 45.881C25.0541 49.4228 26.6314 52.9647 28.9974 55.7194L45.5595 72.248C47.1369 73.8222 49.1085 74.6093 51.0802 74.6093C53.0519 74.6093 55.0236 73.8222 56.6009 72.248L73.163 55.7194C75.9234 52.9647 77.1064 49.8163 77.1064 45.881C77.1064 42.3391 75.529 38.7973 73.5574 36.436L72.7687 35.649C69.2041 32.5998 64.4886 31.5194 60.2146 32.5138C64.8334 31.249 70.0233 32.3445 73.9093 35.6754L74.7365 36.5026Z"
        fill="url(#paint3_radial_153_1768)"
      />
      <path
        d="M47.8518 36.089L52.4015 40.6387C51.6225 40.0823 50.8734 39.4061 50.0899 38.6987C47.9612 36.7771 45.5778 34.6256 41.6476 34.0209C34.6162 31.9529 30.0665 35.2618 27.9984 37.3298L27.1712 38.157C24.6896 40.6387 23.4487 43.9476 23.4487 47.2565V46.4292C23.4487 42.7067 24.6896 38.9842 27.1712 36.5026L27.9984 35.6754C33.789 30.2984 42.4748 30.712 47.8518 36.089Z"
        fill="url(#paint4_linear_153_1768)"
      />
      <path
        d="M74.7365 36.5026C76.8045 38.9843 78.459 42.7068 78.459 46.0157V46.8429C78.459 43.534 76.8045 40.2251 74.7365 37.7435L73.9093 36.9163C68.3443 32.1462 59.7231 30.8144 52.0843 38.0607L54.056 36.089C59.4329 30.7121 68.1187 30.7121 73.9093 35.6754L74.7365 36.5026Z"
        fill="url(#paint5_linear_153_1768)"
      />
      <path
        d="M51.1607 38.9843C51.4668 38.6621 51.7747 38.3544 52.0843 38.0607L51.1607 38.9843Z"
        fill="url(#paint6_linear_153_1768)"
      />
      <path
        d="M49.9199 4.27392C76.391 4.27392 98.3123 25.5368 99.5532 52.2196C99.5532 51.3857 99.5532 50.5519 99.5532 50.135C99.5532 22.6183 77.2182 0.104736 49.9199 0.104736C22.6216 0.104736 0.286621 22.2014 0.286621 49.7181C0.286621 50.5519 0.286621 51.3857 0.286621 51.8026C1.52745 25.5368 23.4488 4.27392 49.9199 4.27392Z"
        fill="url(#paint7_linear_153_1768)"
      />
      <path
        d="M16.1379 46.0059C16.4492 51.3781 18.775 56.6491 22.4765 60.4919L35.6234 73.7274L40.0057 77.8635C42.7945 80.7588 46.7784 82.4133 51.1607 82.4133C55.5431 82.4133 59.527 80.7588 62.3157 77.8635L79.845 60.0783C83.5464 56.2355 85.8723 50.9645 86.1835 45.5923C86.2073 46.0088 86.2193 46.4259 86.2193 46.843C86.2193 52.6335 83.8289 58.4241 79.845 62.5602L62.3157 80.3454C59.527 83.2407 55.5431 84.8951 51.1607 84.8951C46.7784 84.8951 42.7945 83.2407 40.0057 80.3454L35.6234 76.2093L22.4765 62.9738C18.4925 58.8377 16.1022 53.0471 16.1022 47.2566C16.1022 46.8395 16.1142 46.4224 16.1379 46.0059Z"
        fill="url(#paint8_linear_153_1768)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M49.5935 95.7198C74.7705 95.7198 95.1805 75.3098 95.1805 50.1328C95.1805 24.9559 74.7705 4.5459 49.5935 4.5459C24.4166 4.5459 4.00659 24.9559 4.00659 50.1328C4.00659 75.3098 24.4166 95.7198 49.5935 95.7198ZM49.6921 94.3867C73.7823 94.3867 93.3113 74.8577 93.3113 50.7675C93.3113 26.6773 73.7823 7.14833 49.6921 7.14833C25.6019 7.14833 6.07293 26.6773 6.07293 50.7675C6.07293 74.8577 25.6019 94.3867 49.6921 94.3867Z"
        fill="url(#paint9_linear_153_1768)"
      />
      <path
        d="M9.73669 72.8384C17.0405 84.3397 29.401 92.4564 44.0169 93.8137C69.0809 96.1411 91.2861 77.7094 93.6136 52.6454C94.5274 42.8047 92.2411 33.4046 87.6098 25.4753C92.9751 33.7632 95.699 43.846 94.7153 54.4395C92.3874 79.5086 70.1777 97.944 45.1086 95.616C29.7392 94.1888 16.8633 85.2888 9.73669 72.8384Z"
        fill="url(#paint10_linear_153_1768)"
      />
      <path
        d="M23.2732 29.0577C27.2572 25.7488 32.4363 23.6807 38.0138 23.6807C42.7945 23.6807 47.1768 24.9216 51.1607 27.4032C54.7463 24.508 59.527 22.8535 64.3077 22.8535C69.8852 22.8535 75.0643 24.9216 79.0482 28.6441L80.6418 30.2985C84.2273 34.4346 86.2193 39.8116 86.2193 45.1885C86.2193 45.5681 86.209 45.9477 86.1887 46.3268C85.9254 41.3414 83.9702 36.4327 80.6418 32.5932L79.0482 30.9388C75.0643 27.2163 69.8852 25.1482 64.3077 25.1482C59.527 25.1482 54.7463 26.8027 51.1607 29.698C47.1768 27.2163 42.7945 25.9755 38.0138 25.9755C32.4363 25.9755 27.2572 28.0435 23.2732 31.3524L22.4765 32.1796C22.3243 32.3376 22.2302 32.4956 22.1499 32.6305C22.0199 32.8489 21.9259 33.0068 21.6797 33.0068C18.3513 36.8463 16.3961 41.755 16.1328 46.7404C16.1124 46.3613 16.1022 45.9817 16.1022 45.6021C16.1022 40.2252 18.0941 34.8482 21.6797 30.7121C21.9259 30.7121 22.0199 30.5541 22.1499 30.3358C22.2302 30.2009 22.3243 30.0429 22.4765 29.8849L23.2732 29.0577Z"
        fill="url(#paint11_linear_153_1768)"
      />
      <defs>
        <radialGradient
          id="paint0_radial_153_1768"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(40.8232 0.104737) rotate(84.9246) scale(102.827 89.4829)"
        >
          <stop stopColor="white" />
          <stop
            offset="0.397268"
            stopColor="#F4E48E"
          />
          <stop
            offset="0.613719"
            stopColor="#EFB900"
          />
          <stop
            offset="0.91342"
            stopColor="#713E02"
          />
          <stop
            offset="0.9999"
            stopColor="#C89B00"
          />
          <stop
            offset="1"
            stopColor="#DE9D00"
          />
        </radialGradient>
        <radialGradient
          id="paint1_radial_153_1768"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(33.3856 29.5901) rotate(68.0037) scale(57.8624 67.9194)"
        >
          <stop stopColor="#FED539" />
          <stop
            offset="0.254"
            stopColor="#E0A100"
          />
          <stop
            offset="0.615734"
            stopColor="#754600"
          />
          <stop
            offset="1"
            stopColor="#371F09"
          />
        </radialGradient>
        <linearGradient
          id="paint2_linear_153_1768"
          x1="31.4793"
          y1="36.5445"
          x2="55.2066"
          y2="76.6228"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFF398" />
          <stop
            offset="0.324"
            stopColor="#E2C946"
          />
          <stop
            offset="0.660183"
            stopColor="#CC8D00"
          />
          <stop
            offset="1"
            stopColor="#824E00"
          />
        </linearGradient>
        <radialGradient
          id="paint3_radial_153_1768"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(37.1341 65.3183) rotate(-13.6203) scale(36.7817 64.2223)"
        >
          <stop
            offset="0.0839996"
            stopColor="#4B1C00"
          />
          <stop
            offset="0.296535"
            stopColor="#823C00"
          />
          <stop
            offset="0.466404"
            stopColor="#FEA91A"
          />
          <stop
            offset="1"
            stopColor="#9F4300"
          />
        </radialGradient>
        <linearGradient
          id="paint4_linear_153_1768"
          x1="45.948"
          y1="31.8435"
          x2="46.3214"
          y2="43.6365"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F4E5AF" />
          <stop
            offset="1"
            stopColor="#F4E5AF"
            stopOpacity="0"
          />
        </linearGradient>
        <linearGradient
          id="paint5_linear_153_1768"
          x1="45.948"
          y1="31.8435"
          x2="46.3214"
          y2="43.6365"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F4E5AF" />
          <stop
            offset="1"
            stopColor="#F4E5AF"
            stopOpacity="0"
          />
        </linearGradient>
        <linearGradient
          id="paint6_linear_153_1768"
          x1="45.948"
          y1="31.8435"
          x2="46.3214"
          y2="43.6365"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F4E5AF" />
          <stop
            offset="1"
            stopColor="#F4E5AF"
            stopOpacity="0"
          />
        </linearGradient>
        <linearGradient
          id="paint7_linear_153_1768"
          x1="49.9199"
          y1="0.104736"
          x2="49.9199"
          y2="52.2196"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop
            offset="1"
            stopColor="white"
            stopOpacity="0"
          />
        </linearGradient>
        <linearGradient
          id="paint8_linear_153_1768"
          x1="21.1835"
          y1="61.3507"
          x2="79.839"
          y2="62.9519"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFCA2A" />
          <stop
            offset="0.350224"
            stopColor="#AE6400"
          />
          <stop
            offset="1"
            stopColor="#FDDD61"
          />
        </linearGradient>
        <linearGradient
          id="paint9_linear_153_1768"
          x1="25.6451"
          y1="109.889"
          x2="109.225"
          y2="45.2401"
          gradientUnits="userSpaceOnUse"
        >
          <stop
            stopColor="#642700"
            stopOpacity="0"
          />
          <stop
            offset="0.111955"
            stopColor="#653100"
          />
          <stop
            offset="0.824903"
            stopColor="#653100"
          />
          <stop
            offset="0.99"
            stopColor="#642700"
            stopOpacity="0"
          />
        </linearGradient>
        <linearGradient
          id="paint10_linear_153_1768"
          x1="94.3292"
          y1="44.6108"
          x2="16.1771"
          y2="83.0992"
          gradientUnits="userSpaceOnUse"
        >
          <stop
            stopColor="#FAD85D"
            stopOpacity="0"
          />
          <stop
            offset="0.261997"
            stopColor="#FAD85D"
          />
          <stop
            offset="0.420095"
            stopColor="#FEFDFA"
          />
          <stop
            offset="0.601442"
            stopColor="#F8D55A"
          />
          <stop
            offset="1"
            stopColor="#F8D55A"
            stopOpacity="0"
          />
        </linearGradient>
        <linearGradient
          id="paint11_linear_153_1768"
          x1="52.1197"
          y1="21.6437"
          x2="51.8928"
          y2="46.2765"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#4B1C00" />
          <stop
            offset="1"
            stopColor="#4B1C00"
            stopOpacity="0"
          />
        </linearGradient>
      </defs>
    </svg>
  );
});

export default IcoPointV4;
