import { clsx } from "clsx";
import { DailyGift } from "src/main/views/component/DailyGift";
// import { SocialMediaLink } from "src/main/views/component";
import { useTypedSelector } from "src/main/hooks";
import { LayoutState } from "src/main/store/layout/slices";

function FloatingButtons() {
  const layoutState = useTypedSelector<LayoutState>((state) => state.layout);

  return (
    <div
      className={clsx("fixed bottom-[100px] right-0 m-8", {
        "z-50": layoutState.playnow.launch,
        "z-[75]": !layoutState.playnow.launch,
      })}
    >
      <DailyGift />
      {/* <SocialMediaLink /> */}
    </div>
  );
}

export default FloatingButtons;
