import React, { useCallback, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { publicApi } from "src/main/api";
import useTypedSelector from "src/main/hooks/useTypedSelector";
import { DeployCountry } from "src/main/types";
import { getDeployEnv, getStorageItem } from "src/main/utils";
import { Topbar } from "./component";

type Props = {};

const styles = {
  divClass: `px-4 py-4 w-full inline-flex justify-between items-center self-stretch rounded-lg text-white text-left font-bold drop-shadow-lg bg-gradient-to-b from-[rgba(126,125,217,0.4)] to-[rgba(20,36,117,0.4)]`,
};

const vipFeatureList = [
  "Unlock Amount VIP Points Instant",
  "Points Percent On Every Play",
  "Free Cash And Premium Rewards",
  "Monthly Exclusive Deals",
];

const VipStatusInfo: React.FC<Props> = () => {
  const { t } = useTranslation();
  const state = useTypedSelector((state) => state);

  const currentEnv = getDeployEnv();

  const selectedLang = getStorageItem("lng", currentEnv.defaultLang);

  const brandRes = publicApi.useGetBrandQuery({ brand: currentEnv.brand ?? "" }, { skip: !currentEnv.brand });
  const brand = useMemo(() => brandRes.data?.model, [brandRes]);

  useEffect(() => {
    document.body.style.backgroundImage = `url("/images/body-bg/bg_lg_wavy.webp")`;
    document.body.style.backgroundRepeat = "no-repeat";
    document.body.style.backgroundSize = "cover";
    document.body.style.backgroundAttachment = "fixed";
  }, []);

  const handleOnClickPlayNow = useCallback(() => {
    if (!!brand) window.open(brand?.siteUrl, "_blank");
  }, [brand]);

  const depositAmount = useMemo(() => {
    switch (currentEnv.deployCountry) {
      case DeployCountry.PH:
        return t("amount peso", {
          lng: selectedLang,
          amount: "10,000",
        });
      case DeployCountry.TH:
        return t("amount baht", {
          lng: selectedLang,
          amount: "10,000",
        });
      default:
        return "";
    }
  }, [selectedLang, t, currentEnv.deployCountry]);

  return (
    <div className="flex h-screen justify-center text-white">
      <div className="h-100 w-full max-w-[500px]">
        <div className="w-full">
          <Topbar />

          <div className="gap-2.5 p-5">
            <div className="flex justify-between pb-2">
              <div>
                <div className="flex flex-col gap-2 text-xl font-bold">
                  <div className="leading-3 text-[#F03F96]">
                    Hi, {state.vipStatusToken?.data?.mobile ?? state.auth.mobile}
                  </div>
                  <div>{t("You are not a VIP yet", { lng: selectedLang })}</div>
                </div>
              </div>
              <img
                className="h-[45px] w-[45px]"
                src="/images/icon/ico-vip-disabled.svg"
                alt="VIP badge"
              />
            </div>

            <div className="mt-5">
              <div className={`${styles.divClass}`}>
                <div className="flex w-full flex-col gap-2">
                  <div className="flex items-center justify-center">
                    <img
                      className="h-[76px] w-[76px]"
                      src="/images/icon/gift-coupons.svg"
                      alt="VIP badge"
                    />
                    <div className="text-base font-bold leading-5">
                      {t("Join VIP Club More Rewards", { lng: selectedLang })}
                    </div>
                  </div>

                  <div className="flex flex-col gap-4">
                    {vipFeatureList.map((feature) => (
                      <div
                        className="flex items-center justify-start"
                        key={feature}
                      >
                        <img
                          className="h-[16px] w-[16px]"
                          src="/images/icon/ico-check-gold.svg"
                          alt="VIP badge"
                        />
                        <div className="px-2 text-[14px] font-normal leading-5">
                          {t(feature, {
                            lng: selectedLang,
                          })}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>

            {/*Display play now button on individual brand site only*/}
            {!currentEnv.isMain && !!brand && (
              <div className="mt-3 flex flex-col gap-2">
                <span className="w-full text-left text-base font-bold leading-5 drop-shadow-lg">
                  {t("Deposit amount to join vip rewards club", {
                    lng: selectedLang,
                    amount: depositAmount,
                  })}
                </span>
                <button
                  type="button"
                  className="btn-orange mr-2 mt-4 inline-flex w-full items-center justify-center rounded-lg px-6 py-4 text-base font-bold"
                  onClick={handleOnClickPlayNow}
                >
                  {t("Play Now", { lng: selectedLang })}
                  <img
                    src="/images/icon/ico-right-arrow.svg"
                    alt="arrow-right"
                    className="mx-1 inline-block h-[18px]"
                  />
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default VipStatusInfo;
