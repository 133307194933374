import useTypedSelector from "src/main/hooks/useTypedSelector";
import store from "src/main/store";
import { LocalisationListItem } from "src/main/types";
import DefaultStyleSection from "../DefaultStyleSection";

interface Props {
  field: string;
  localisations?: LocalisationListItem[];
  defaultValue?: string | null | undefined;
  displayHtml?: boolean;
  className?: string;
}

export default function LocalizationDisplay({
  field,
  localisations = [],
  defaultValue = "",
  displayHtml = false,
  className = "",
}: Props) {
  const localLanguage = useTypedSelector((state) => state.preference.language);

  const localisation = localisations.find((item) => item.locale.code === localLanguage);

  const value = localisation ? localisation.content[field] : defaultValue;

  if (displayHtml)
    return (
      <DefaultStyleSection>
        <div
          className={className}
          dangerouslySetInnerHTML={{
            __html: value ?? "",
          }}
        />
      </DefaultStyleSection>
    );

  return <div className={className}>{value}</div>;
}

export function localizationValue(
  field: string,
  localisations: LocalisationListItem[] | undefined,
  defaultValue: string | undefined = "",
) {
  const localLanguage = store.getState().preference.language;

  const localisation = localisations?.find((item) => item.locale.code === localLanguage);

  const value = localisation ? localisation.content[field] : defaultValue;

  return value;
}
