import { ProfileType } from "src/main/store/types";
import { BaseResponseType } from "src/main/store/types/response";
import { QueryResult, RewardInfo, RewardType } from "src/main/types";
import { BuilderProp, require200Status } from "..";

export interface GetProfilePointsResponse extends BaseResponseType {
  detail: string | null;
  data: ProfileType | null;
}

export interface GetProfilePointsRequest {}

export const getProfilePoints = (builder: BuilderProp) => {
  return builder.query<GetProfilePointsResponse, GetProfilePointsRequest>({
    providesTags: ["user"],
    query: () => ({
      url: `/profilePoints`,
      validateStatus: require200Status,
    }),
    transformResponse: (response: GetProfilePointsResponse) => {
      return response;
    },
  });
};

export interface ChangePasswordRequest {
  password: string;
}

export interface ChangePasswordResponse extends QueryResult {}

export const changePassword = (builder: BuilderProp) => {
  return builder.mutation<ChangePasswordResponse, ChangePasswordRequest>({
    invalidatesTags: ["user"],
    query: ({ password }) => ({
      url: "/member/password/update",
      method: "POST",
      body: {
        password,
      },
      validateStatus: require200Status,
    }),
    transformResponse: (response: QueryResult<ChangePasswordResponse>) => {
      return response.result;
    },
  });
};

export interface GetRewardStatusResponse {
  result: {
    [key in RewardType]: RewardInfo;
  };
}

export const getRewardStatus = (builder: BuilderProp) => {
  return builder.query<GetRewardStatusResponse, void>({
    // DON'T cache here. cache will effect to animations of shared components
    query: () => ({
      url: `/member/wallet/reward/status`,
      validateStatus: require200Status,
    }),
    transformResponse: (response: GetRewardStatusResponse) => {
      return response;
    },
  });
};

export interface ClaimDailyRewardResponse {
  result: number;
}
export type ClaimOtherRewardsResponse = unknown;

export interface ClaimRewardRequest {
  type: RewardType;
}

export const claimReward = (builder: BuilderProp) => {
  return builder.mutation<ClaimDailyRewardResponse | ClaimOtherRewardsResponse, ClaimRewardRequest>({
    query: ({ type }) => ({
      url: `/member/wallet/reward/claim?type=${type}`,
      method: "POST",
      validateStatus: require200Status,
    }),
    transformResponse: (response: QueryResult<ClaimDailyRewardResponse | ClaimOtherRewardsResponse>) => {
      return response.result;
    },
  });
};
