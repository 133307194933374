import BigNumber from "bignumber.js";
import { clsx } from "clsx";
import { throttle } from "lodash";
import { forwardRef, PropsWithChildren, useCallback, useImperativeHandle, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { FaXmark } from "react-icons/fa6";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { IconArrowRightGradient, IconCartGradient } from "src/assets";
import { siteApi } from "src/main/api";
import { Button, Drawer, LazyImage, LocalizationDisplay } from "src/main/components";
import QuantitySelector from "src/main/components/_inputs/QuantitySelector";
import { useAsyncTask, useProfileBalance } from "src/main/hooks";
import { RootState } from "src/main/store";
import productSlice, { ProductState } from "src/main/store/product/slices";
import { CartModel, ProductModel, ProductVariantModel, UpdateCartProps } from "src/main/types";
import { bnOrZero, fNumber, getProductPrice, Paths } from "src/main/utils";

type AddToCartMode = "redeem" | "add";

const MIN_INPUT_QUANTITY = 1;
const MAX_INPUT_QUANTITY = 99;

interface UpdateOrRedeemValues {
  type: AddToCartMode;
  quantity?: number;
  cart: CartModel | null | undefined;
}

export interface AddToCartDrawerRef {
  open: (mode: AddToCartMode) => void;
}

interface AddToCartDrawerProps extends PropsWithChildren {
  product: ProductModel;
  selectedVariant?: ProductVariantModel;
  onChangeVariant: (variant: ProductVariantModel) => void;
}

const AddToCartDrawer = forwardRef<{}, AddToCartDrawerProps>(({ product, selectedVariant, onChangeVariant }, ref) => {
  const { t } = useTranslation();
  const [isOpenDrawer, setIsOpenDrawer] = useState(false);
  const [addToCartMode, setAddToCartMode] = useState<AddToCartMode>("add");
  const [quantity, setQuantity] = useState<number>(1);
  const [runAddToCart] = useAsyncTask("add/to/cart");
  const [addToCart] = siteApi.useUpdateCartMutation();
  const shouldDisplayVariations = useMemo(
    () => product?.productVariants && product?.productVariants?.length > 1,
    [product?.productVariants],
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { cart } = useSelector<RootState, ProductState>((state) => state.products);
  const { balance } = useProfileBalance();

  useImperativeHandle(ref, () => ({
    open: openDrawer,
  }));

  const openDrawer = useCallback((mode: AddToCartMode) => {
    setIsOpenDrawer(true);
    setAddToCartMode(mode);
  }, []);

  const displayingImage = useMemo(() => {
    return selectedVariant?.image?.url ?? product?.image?.url ?? "";
  }, [selectedVariant, product]);

  const variantPrice = useMemo(() => {
    return product ? getProductPrice(product, selectedVariant?.id) : 0;
  }, [product, selectedVariant]);

  const updateQuantity = (bnQuantity: BigNumber) => {
    let quantity = bnQuantity.toNumber();
    if (bnQuantity.lt(MIN_INPUT_QUANTITY)) {
      quantity = MIN_INPUT_QUANTITY;
    }
    if (bnQuantity.gt(MAX_INPUT_QUANTITY)) {
      quantity = MAX_INPUT_QUANTITY;
    }
    setQuantity(quantity);
  };

  const handleOnIncrementQuantity = useCallback(() => {
    updateQuantity(bnOrZero(quantity).plus(1));
  }, [quantity]);

  const handleOnDecrementQuantity = useCallback(() => {
    updateQuantity(bnOrZero(quantity).minus(1));
  }, [quantity]);

  const addOrRedeemProduct = useCallback(
    ({ type, quantity, cart }: UpdateOrRedeemValues) => {
      runAddToCart(async () => {
        if (!quantity) {
          toast.error(t("Please enter quantity"));
          return;
        }
        if (!selectedVariant) {
          toast.error(t("You have not selected any items for checkout"));
          return;
        }
        let data = {
          items: [...(cart?.data.items ?? [])],
          version: cart?.data.version ?? "1",
        } as unknown as UpdateCartProps;

        const newItem = [{ quantity, variantId: selectedVariant.id }];
        if (!!data.items.length) {
          const existItem = data.items.find((item) => item.variantId === selectedVariant.id);

          if (!!existItem) {
            try {
              data.items.forEach((item, index) => {
                if (item.variantId === selectedVariant.id) {
                  data.items[index] = {
                    ...item,
                    quantity: type === "redeem" ? quantity : item.quantity + quantity,
                  };
                }
              });
            } catch (error) {
              console.error(error);
            }
          } else {
            data.items = data.items.concat(newItem);
          }
        } else {
          data.items = newItem;
        }

        await addToCart({
          data,
        });

        if (type === "add") {
          toast.success(`${t("Add to cart successfully")}`);
          setIsOpenDrawer(false);
          setTimeout(() => {
            setQuantity(1);
          }, 500);
        } else {
          const redeemNowVariant = product?.productVariants?.find((pVar) => pVar.id === selectedVariant.id);
          if (!!product && !!redeemNowVariant) {
            // validate balance
            const totalPrice = bnOrZero(getProductPrice(product, redeemNowVariant.id)).multipliedBy(
              newItem[0].quantity,
            );

            if (balance.lt(totalPrice)) {
              toast.error(t("Insufficient balance"));
              return;
            }

            dispatch(
              productSlice.actions.updateCheckoutCart([
                {
                  ...newItem[0],
                  variant: {
                    ...redeemNowVariant,
                    product,
                  },
                },
              ]),
            );
          }
          navigate(Paths.Private.Checkout);
        }
      });
    },
    [runAddToCart, selectedVariant, addToCart, t, product, navigate, balance, dispatch],
  );

  const throttleAddOrRedeem = useMemo(
    () =>
      throttle(addOrRedeemProduct, 500, {
        leading: true,
        trailing: false,
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedVariant],
  );

  const handleOnCloseDrawer = useCallback(() => {
    setIsOpenDrawer(false);
  }, []);

  return (
    <>
      <div className="fixed bottom-[60px] flex w-full space-x-2 bg-gray-50 px-4 pt-3 pb-5 drop-shadow lg:hidden">
        <Button
          variant="secondary"
          className="flex-grow bg-[#1B0E2C] uppercase"
          iconPlacement="right"
          icon={<IconArrowRightGradient className="h-4 w-4" />}
          onClick={() => openDrawer("redeem")}
        >
          {t("Check out")}
        </Button>
        <Button
          className="flex-grow uppercase"
          iconPlacement="right"
          icon={<IconCartGradient className="h-4 w-4" />}
          onClick={() => openDrawer("add")}
        >
          {t("Add to Cart")}
        </Button>
      </div>

      <Drawer
        show={isOpenDrawer}
        onClose={handleOnCloseDrawer}
        placement="bottom"
        contentClassName="fixed left-0 bottom-[60px] w-full rounded-t-xl !bg-gray-50 px-4 pt-4"
        backdropClassName="bg-black bg-opacity-50"
        closeButton={
          <button className="absolute right-0 top-0 z-[60]">
            <FaXmark
              className="h-5 w-5 text-gray-300"
              onClick={handleOnCloseDrawer}
            />
          </button>
        }
      >
        {/*PRODUCT INFO*/}
        <div className="mb-4 flex items-center space-x-3">
          <LazyImage
            src={displayingImage}
            alt={selectedVariant?.name ?? product?.name}
            className="h-28 w-28 rounded-md bg-white"
          />
          <div>
            <h1 className="leading-4">
              <LocalizationDisplay
                field="name"
                localisations={selectedVariant?.localisations ?? product?.localisations}
                defaultValue={selectedVariant?.name ?? product?.name}
                className="line-clamp-2 inline text-base font-bold leading-5 text-gray-800"
              />
            </h1>
            <div className="mt-2 flex items-center space-x-1">
              <img
                src="/images/icon/heart_icon_yellow.svg"
                className="h-5 w-5"
                alt="point"
              />
              <span className="text-sm text-gray-800">{fNumber(variantPrice)}</span>
            </div>
          </div>
        </div>

        {/*VARIANT SELECTOR*/}
        {shouldDisplayVariations && (
          <div className="mb-4 flex flex-wrap gap-3">
            {product?.productVariants?.map((variant) => (
              <button
                key={variant.id}
                className={clsx(
                  "line-clamp-1 max-w-full rounded border border-[#DAD7ED] px-2.5 py-2 text-xs text-gray-700",
                  {
                    "bg-primary-400 px-2 font-bold !text-white": selectedVariant?.id === variant.id,
                  },
                )}
                onClick={() => onChangeVariant(variant)}
              >
                <LocalizationDisplay
                  field="name"
                  localisations={variant.localisations}
                  defaultValue={variant.name}
                  className="line-clamp-1"
                />
              </button>
            ))}
          </div>
        )}
        <hr />

        {/*QUANTITY*/}
        <div className="mt-2 mb-4 flex items-center justify-between">
          <span className="text-sm font-bold text-gray-700">{t("Quantity")}:</span>
          <QuantitySelector
            onIncrease={handleOnIncrementQuantity}
            onDecrease={handleOnDecrementQuantity}
            value={quantity}
            minValue={MIN_INPUT_QUANTITY}
            maxValue={MAX_INPUT_QUANTITY}
          />
        </div>

        <div className="w-full pt-3 pb-5">
          <Button
            size="md"
            iconPlacement="right"
            disabled={!selectedVariant}
            icon={
              addToCartMode === "redeem" ? (
                <IconArrowRightGradient className="h-4 w-4 text-[#FFFFFFCC]" />
              ) : (
                <IconCartGradient className="h-4 w-4 text-[#FFFFFFCC]" />
              )
            }
            onClick={() => {
              throttleAddOrRedeem({
                type: addToCartMode,
                quantity,
                cart,
              });
            }}
          >
            {t(addToCartMode === "redeem" ? "Check out" : "Add to Cart")}
          </Button>
        </div>
      </Drawer>
    </>
  );
});

export default AddToCartDrawer;
