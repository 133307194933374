import { clsx } from "clsx";
import { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { FaCaretLeft, FaCaretRight } from "react-icons/fa6";
import { localizationValue } from "src/main/components";
import { TOP_PRODUCT_SLUG } from "src/main/contants";
import { CategoryModel, Meta } from "src/main/types";
import GiftCategoryButton from "src/main/views/GiftsPage/components/GiftCategoryButton";

interface CategoryListProps {
  categories: CategoryModel[];
  loadNewCategory: (category: string | null, meta?: Meta) => void;
  selectedCategory: string | null;
  className?: string;
}

export default function CategoryList({ categories, loadNewCategory, selectedCategory, className }: CategoryListProps) {
  const { t } = useTranslation();
  const categoriesRef = useRef<HTMLDivElement>(null);

  const [canScrollLeft, setCanScrollLeft] = useState(false);
  const [canScrollRight, setCanScrollRight] = useState(false);

  const handleScroll = () => {
    if (categoriesRef.current) {
      setCanScrollLeft(categoriesRef.current.scrollLeft > 0);
      setCanScrollRight(
        categoriesRef.current.scrollLeft < categoriesRef.current.scrollWidth - categoriesRef.current.clientWidth,
      );
    }
  };

  useEffect(() => {
    const categoryList = categoriesRef.current;
    if (categoryList) {
      categoryList.addEventListener("scroll", handleScroll);
      window.addEventListener("resize", handleScroll);
    }
    return () => {
      if (categoryList) {
        categoryList.removeEventListener("scroll", handleScroll);
        window.removeEventListener("resize", handleScroll);
      }
    };
  }, [categoriesRef]);

  useEffect(() => {
    handleScroll();
  }, [categories]);

  const scrollCategories = useCallback((toRight = true) => {
    const scrollStep = 200;
    if (categoriesRef.current) {
      if (toRight) categoriesRef.current.scrollLeft += scrollStep;
      else categoriesRef.current.scrollLeft -= scrollStep;
    }
  }, []);

  return (
    <div className={clsx("flex items-center", className)}>
      <FaCaretLeft
        className={`mx-2 inline-block flex-shrink-0 ${canScrollLeft ? "text-white" : "text-gray-400"}`}
        onClick={() => canScrollLeft && scrollCategories(false)}
      />

      <div
        className="hide-scroll-bar flex flex-grow overflow-x-scroll scroll-smooth"
        ref={categoriesRef}
      >
        <div className="">
          <div className="overflow-hidden">
            <GiftCategoryButton
              name={t("All Gifts")}
              id={"-1"}
              active={!selectedCategory}
              onClick={() => loadNewCategory(null)}
            />
          </div>
        </div>

        {categories
          .filter((cat) => cat.slug !== TOP_PRODUCT_SLUG)
          .map((category) => {
            return (
              <div
                className="px-1"
                key={category.id}
              >
                <div className="overflow-hidden">
                  <GiftCategoryButton
                    name={localizationValue("name", category.localisations, category.name)}
                    id={category.id}
                    logo={category.logo}
                    active={selectedCategory === category.slug}
                    onClick={() => {
                      if (selectedCategory !== category.slug) loadNewCategory(category.slug ?? "");
                    }}
                  />
                </div>
              </div>
            );
          })}
      </div>

      <FaCaretRight
        className={`mx-2 inline-block flex-shrink-0 ${canScrollRight ? "text-white" : "text-gray-400"}`}
        onClick={() => canScrollRight && scrollCategories(true)}
      />
    </div>
  );
}
