import moment from "moment";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from "react-i18next";
import InfiniteScroll from "react-infinite-scroll-component";
import { Link, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { siteApi } from "src/main/api";
import { Button, DateRangePicker, Page, Range } from "src/main/components";
import { useTypedSelector } from "src/main/hooks";
import { Meta, WalletTxSourceType } from "src/main/types";
import { getItemOrderName, logger } from "src/main/utils";
import OrderHistoryCard from "../OrderHistoryCard";
import PointHistoryCard from "../PointHistoryCard";
import SkeletonLoader from "../SkeletonLoader";

const walletTxSrcTypeFilters = [
  {
    label: "reward-claim",
    // Combine 'daily-bonus' with 'reward-claim', 'mission-reward'
    value: [WalletTxSourceType.REWARD_CLAIM, WalletTxSourceType.DAILY_BONUS, WalletTxSourceType.MISSION_REWARD].join(
      ",",
    ),
  },
  {
    label: "manual-credit",
    value: [
      WalletTxSourceType.REVERT,
      WalletTxSourceType.REFUND,
      WalletTxSourceType.MANUAL_CREDIT,
      WalletTxSourceType.WELCOME_BONUS,
      WalletTxSourceType.TURNOVER_BONUS,
      WalletTxSourceType.TRANSFER,
      WalletTxSourceType.SECRET_TRANSFER,
      WalletTxSourceType.OTHER_BONUS,
    ].join(","),
  },
  {
    label: "credit-redeem",
    value: WalletTxSourceType.CREDIT_REDEEM,
  },
  {
    label: "point-exchange",
    value: WalletTxSourceType.POINT_EXCHANGE,
  },
];

const walletTxSrcTypeAllFilters = walletTxSrcTypeFilters.map((filter) => filter.value).join(",");

const itemsPerPage = 6;
const initialMeta = {
  count: 0,
  limit: itemsPerPage,
  offset: 0,
  sort: "createdAt:desc",
  fulfilmenttype: "delivery,self-collect",
};

const OrderHistoryContent: React.FC = () => {
  const { t } = useTranslation();
  const { hash } = useLocation();
  const showPoint = useMemo(() => hash === "#point", [hash]);
  const localLanguage = useTypedSelector((state) => state.preference.language);

  const [getOrderHistory, { data: orderData, isFetching: isFetchingOrders }] = siteApi.useLazyGetOrderHistoryQuery();
  const [getPointHistory, { data: pointData, isFetching: isFetchingPoints }] = siteApi.useLazyGetPointHistoryQuery();

  const [startDate, setStartDate] = useState<Date>();
  const [endDate, setEndDate] = useState<Date>();
  const [srcTypeFilter, setSrcTypeFilter] = useState<string>("");

  const [meta, setMeta] = useState<Meta>(initialMeta);

  const persistMeta = useMemo(() => {
    if (!showPoint) {
      return {
        ...meta,
        ...orderData?.meta,
      };
    } else {
      return {
        ...meta,
        ...pointData?.meta,
      };
    }
  }, [meta, orderData?.meta, pointData?.meta, showPoint]);

  useEffect(() => {
    const start = startDate ? moment(startDate).startOf("day").valueOf() / 1000 : "";
    const end = endDate ? Math.floor(moment(endDate).endOf("day").valueOf() / 1000) : "";
    const createdAt = `${start},${end}`;
    // change dates => reset meta
    if (start !== "" || end !== "") {
      setMeta({
        ...initialMeta,
        createdAt,
      });
    } else {
      setMeta(initialMeta);
    }
  }, [endDate, startDate]);

  const hasMore = useMemo(() => {
    if (!showPoint) {
      return (persistMeta?.count ?? 0) > (orderData?.entries.length ?? 0);
    } else {
      return (persistMeta?.count ?? 0) > (pointData?.entries.length ?? 0);
    }
  }, [orderData?.entries.length, persistMeta?.count, pointData?.entries.length, showPoint]);

  const fetchMore = useCallback(() => {
    setMeta((prev) => ({ ...prev, limit: prev.limit + itemsPerPage }));
  }, []);

  useEffect(() => {
    (async () => {
      try {
        if (!showPoint) {
          await getOrderHistory({ meta }).unwrap();
        } else {
          const { fulfilmenttype, ...rest } = meta;
          // in filter all case, we only filter by available sourceTypes
          const newMeta = { ...rest, srcType: !!srcTypeFilter ? srcTypeFilter : walletTxSrcTypeAllFilters };
          await getPointHistory({
            meta: newMeta,
          }).unwrap();
        }
      } catch (error: any) {
        logger._console.log(error?.data?.error?.message);
        toast(error?.data?.error?.message ?? "Something went wrong", {
          type: "error",
        });
      }
    })();
  }, [getOrderHistory, getPointHistory, meta, showPoint, srcTypeFilter]);

  const isFirstFetching = useMemo(() => {
    const isOrderFirstFetching = !orderData && isFetchingOrders;
    const isPointFirstFetching = !pointData && isFetchingPoints;
    return isOrderFirstFetching || isPointFirstFetching;
  }, [orderData, isFetchingOrders, pointData, isFetchingPoints]);

  return (
    <>
      <Page
        paddingX
        loading={isFirstFetching}
        loader={<SkeletonLoader />}
        title={showPoint ? t("points history") : t("order history")}
        hideTitleOn="desktop"
        className="lg:mt-0"
      >
        <div className="flex justify-center lg:justify-end">
          <div className="flex w-full max-w-md flex-col">
            <div className="flex w-full items-center justify-center lg:justify-end">
              <DateRangePicker
                expose={({ startDate, endDate }: Range) => {
                  setStartDate(startDate);
                  setEndDate(endDate);
                }}
              />
            </div>
            {showPoint && (
              <select
                className="mt-2 w-full rounded-lg border-2 border-[rgba(87,85,148,0.669)] bg-[#0A0013] text-sm text-white"
                onChange={(e) => setSrcTypeFilter(e.target.value)}
                defaultValue={srcTypeFilter}
              >
                <option value="">{t("All")}</option>
                {walletTxSrcTypeFilters.map(({ label, value }, index) => (
                  <option
                    key={index}
                    value={value}
                  >
                    {t(label)}
                  </option>
                ))}
              </select>
            )}
          </div>
        </div>

        <div className="mt-8 flex items-center justify-center space-x-2 text-center text-xs font-bold lg:justify-start">
          <Link to="#order">
            {!showPoint ? (
              <Button
                outlined
                variant="blue-violet"
                size="sm"
              >
                {t("order history")}
              </Button>
            ) : (
              <span className="text-xs font-normal capitalize text-white">{t("order history")}</span>
            )}
          </Link>
          <Link to="#point">
            {showPoint ? (
              <Button
                outlined
                variant="blue-violet"
                size="sm"
              >
                {t("points history")}
              </Button>
            ) : (
              <span className="text-xs font-normal capitalize text-white">{t("points history")}</span>
            )}
          </Link>
        </div>

        {!showPoint && orderData?.entries.length ? (
          <InfiniteScroll
            dataLength={orderData.entries.length ?? 0}
            className="h-full"
            hasMore={hasMore}
            next={fetchMore}
            loader={
              isFetchingOrders && (
                <div className="flex w-full items-center justify-center p-8">
                  <div className="loader"></div>
                </div>
              )
            }
          >
            {orderData?.entries.map((item, index) => (
              <div key={[item.id, index].join("-")}>
                <OrderHistoryCard
                  id={item.id}
                  createdAt={item.createdAt}
                  item={getItemOrderName(item.orderItems[0], t, localLanguage)}
                  status={item.status}
                  total={item.total}
                />
              </div>
            ))}
          </InfiniteScroll>
        ) : (
          <></>
        )}

        {showPoint && pointData?.entries.length ? (
          <InfiniteScroll
            hasMore={hasMore}
            next={fetchMore}
            loader={
              isFetchingPoints && (
                <div className="flex w-full items-center justify-center p-8">
                  <div className="loader"></div>
                </div>
              )
            }
            dataLength={pointData.entries.length ?? 0}
          >
            {pointData.entries.map((item, index) => (
              <PointHistoryCard
                key={index}
                amount={item.amount}
                srcWalletType={item.srcWalletType}
                sourceType={item.sourceType}
                createdAt={item.createdAt}
                orderItem={item.orderItem}
              />
            ))}
          </InfiniteScroll>
        ) : (
          <></>
        )}
      </Page>
    </>
  );
};

export default OrderHistoryContent;
