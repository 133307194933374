import { Transition } from "@headlessui/react";
import { clsx } from "clsx";
import { HTMLAttributes } from "react";
import { TRANSITION_IN_FADE, TRANSITION_OUT_FADE } from "src/main/contants";

interface ModalBackdropProps extends HTMLAttributes<HTMLDivElement> {
  className?: string;
}

function ModalBackdrop({ className, ...props }: ModalBackdropProps) {
  return (
    <Transition.Child
      as="div"
      {...TRANSITION_IN_FADE}
      {...TRANSITION_OUT_FADE}
      {...props}
      className={clsx("fixed top-0 left-0 z-[80] h-screen w-screen bg-black bg-opacity-90 backdrop-blur", className)}
    />
  );
}

export default ModalBackdrop;
