import { clsx } from "clsx";
import { useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { localizationValue } from "src/main/components/LocalizationDisplay";
import { ProductModel } from "src/main/types";
import { fNumber, isTopProduct } from "src/main/utils";
import "./ProductCard.css";

interface ProductCardProps {
  product: ProductModel;
}

const DEFAULT_URL = "/images/brand/ilv-heart-icon.svg";

function ProductCard({ product }: ProductCardProps) {
  const { slug, image, basePrice } = product;
  const name = localizationValue("name", product.localisations, product.name);

  const [productImageSrc, setProductImageSrc] = useState(DEFAULT_URL);

  useEffect(() => {
    if (!image?.url) return;
    const img = new Image();
    img.onload = () => {
      setProductImageSrc(image.url!);
    };
    img.src = image.url;
  }, [image]);

  const isDisplayHotBadge = useMemo(() => {
    return isTopProduct(product);
  }, [product]);

  return (
    <div className="relative rounded-lg bg-white sm:m-1">
      <Link to={`/product/${slug}`}>
        <div className="relative aspect-square rounded-t-lg bg-white">
          <img
            className={clsx(
              "absolute top-0 left-0 z-10 mx-auto h-full w-full rounded-t-lg object-contain object-center",
              productImageSrc === DEFAULT_URL ? "p-7 opacity-50 lg:p-8" : "mx-auto w-full",
            )}
            src={productImageSrc}
            alt={product.name}
            key={productImageSrc === DEFAULT_URL ? "default" : ""}
          />
        </div>

        <div className="mt-0.5 h-24 px-3 text-xs sm:text-sm md:text-xl lg:h-[100px]">
          <p className="text-product-card mb-2 h-14 max-h-14 text-sm font-bold tracking-tight text-neutral-800 lg:h-14">
            {isDisplayHotBadge && (
              <span className="mr-1 inline-block -translate-y-[2px] rounded bg-token-tertiary px-1 py-1 align-middle text-xxs font-normal leading-[0.9] tracking-wide text-white">
                HOT
              </span>
            )}
            {name}
          </p>
          <div className="flex items-center">
            <img
              src="/images/icon/heart_icon_yellow.svg"
              width="22"
              alt="points-icon"
              className="-ml-1"
            />
            <p className="text-primary-900 px-1 text-sm leading-4 md:px-2">{fNumber(basePrice)}</p>
          </div>
        </div>
      </Link>
    </div>
  );
}

export default ProductCard;
