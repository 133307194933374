import { keyframes } from "@emotion/react";
import { Reveal, RevealProps } from "react-awesome-reveal";

interface FadeUpSmallProps extends RevealProps {}

const fadeUpSmallKeyframes = keyframes`
  from {
    opacity: 0;
    transform: translate3d(0, 16px, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
`;

const FadeUpSmall = ({ keyframes, ...rest }: FadeUpSmallProps) => {
  return (
    <Reveal
      keyframes={fadeUpSmallKeyframes}
      {...rest}
    />
  );
};

export default FadeUpSmall;
