import createSagaMiddleware from "@redux-saga/core";

import { AnyAction, combineReducers, configureStore } from "@reduxjs/toolkit";
import mainSaga from "./saga";

import authSlices from "./auth/slices";
import * as layoutSlices from "./layout/slices";
import preferenceSlice from "./preference/slice";
import productSlices from "./product/slices";
import * as slices from "./slices";
import userSlices from "./user/slices";

import { createBrowserHistory } from "history";
import { createReduxHistoryContext } from "redux-first-history";
import { apiMiddlewares, apiReducers } from "../api";

const { createReduxHistory, routerMiddleware, routerReducer } = createReduxHistoryContext({
  history: createBrowserHistory(),
});

const sagaMiddleware = createSagaMiddleware();

const combinedReducer = combineReducers({
  // posts: slices.posts.reducer,
  // topProducts: slices.topProducts.reducer,
  // products: slices.products.reducer,
  // gifts: slices.gifts.reducer,
  productDetail: slices.productDetail.reducer,
  // updateCart: slices.updateCart.reducer,
  isLoading: slices.isLoading.reducer,
  // cartGrandTotal: slices.cartGrandTotal.reducer,
  shippingDetail: slices.shippingDetail.reducer,
  checkoutProducts: slices.checkoutProducts.reducer,
  getProfile: slices.getProfile.reducer,
  changePassword: slices.changePassword.reducer,
  cartSelectedAll: slices.cartSelectedAll.reducer,
  orderHistory: slices.orderHistory.reducer,
  pointHistory: slices.pointHistory.reducer,
  orderDetail: slices.orderDetail.reducer,
  pagination: slices.pagination.reducer,
  brand: slices.brand.reducer,
  token: slices.token.reducer,
  message: slices.message.reducer,
  userProfile: slices.userProfile.reducer,
  language: slices.language.reducer,
  userCart: slices.userCart.reducer,
  router: routerReducer,
  dailyBonus: slices.userDailyBonus.reducer,
  showDailyBonus: slices.showDailyBonus.reducer,
  popupBanner: slices.popupBanner.reducer,
  launchPopupBanner: slices.launchPopupBanner.reducer,
  fetchBannerFlag: slices.fetchBannerFlag.reducer,
  loginFailed: slices.loginFailed.reducer,
  loginLocked: slices.loginLocked.reducer,
  vipStatus: slices.vipStatus.reducer,
  vipStatusToken: slices.vipStatusToken.reducer,
  user: userSlices.reducer,
  layout: layoutSlices.layout.reducer,
  auth: authSlices.reducer,
  preference: preferenceSlice.reducer,
  products: productSlices.reducer,
  ...apiReducers,
});

const rootReducer = (state: any, action: AnyAction) => {
  if (action.type === "userProfile/logout") {
    state = undefined;
  }
  return combinedReducer(state, action);
};

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      thunk: true,
      serializableCheck: false,
    }).concat([routerMiddleware, sagaMiddleware, ...apiMiddlewares]),
});

export const history = createReduxHistory(store);

sagaMiddleware.run(mainSaga);

export type RootState = ReturnType<typeof store.getState>;

export default store;
