import BigNumber from "bignumber.js";
export const SOCIAL_MEDIA_TYPE = {
  Facebook: "Facebook",
  Telegram: "Telegram",
  LINE: "LINE",
  Messenger: "Messenger",
};

export const Defaults = {
  AccessTokenKey: "VIP_TOKEN",
  BrandKey: "ILV_BRAND_INFO",
  ShoppingCardKey: "ILV_SHOPPING_CART",
  PopupBannerHistoryKey: "ILV_POPUP_BANNER_HISTORY",
  VoluumCidKey: "V_CID",
};

// UNITS
export const BN_ZERO = new BigNumber(0);
export const BN_ONE = new BigNumber(1);
export const ONE_DAY_IN_SECONDS = 86400; // 24 * 60 * 60

export const PUSH_NOTIFICATION_STORAGE_KEY = "push-notification-register-status";
export const PUSH_NOTIFICATION_MEMBER_STORAGE_KEY = "push-notification-member-register";
export const REGISTERED = "REGISTERED";

export const FULFILMENT_TYPE = {
  Delivery: "delivery",
  SelfCollect: "self-collect",
  Virtual: "virtual",
};

export const TOP_PRODUCT_SLUG = "top-product";

export const DEFAULT_PRODUCT_IMAGE_URL = "/images/products/placeholder.svg";
