import { PropsWithChildren } from "react";
import { useTranslation } from "react-i18next";
import { Button, Modal } from "src/main/components";

interface ConfirmModalProps extends PropsWithChildren {
  title: string;
  message: string;
  warningMessage?: string | null;
  onConfirm: () => void;
  onCancel: () => void;
  confirmText?: string | null;
  cancelText?: string | null;
  show: boolean;
  confirmLoading?: boolean;
  cancelLoading?: boolean;
}

const ConfirmModal = ({
  show,
  cancelLoading,
  confirmLoading,
  title,
  message,
  onConfirm,
  onCancel,
  confirmText,
  cancelText,
  warningMessage
}: ConfirmModalProps) => {
  const { t } = useTranslation();

  return (
    <Modal
      show={show}
      onClose={onCancel}
      showCloseButton={false}
    >
      <Modal.Header
        title={title}
        className="mb-3 text-center text-lg"
      />
      <Modal.Content className="pb-5 pt-2.5 text-sm">
        <p>{message}</p>
        <p className="mt-3 text-[#EA3E93]">{warningMessage}</p>
      </Modal.Content>
      <Modal.Footer className="grid grid-cols-2 gap-x-5 pt-1.5">
        <Button
          variant="blue-violet"
          outlined
          onClick={onCancel}
          loading={cancelLoading}
          disabled={confirmLoading}
        >
          {cancelText ?? t("Cancel")}
        </Button>
        <Button
          variant="blue-violet"
          onClick={onConfirm}
          loading={confirmLoading}
          disabled={cancelLoading}
        >
          {confirmText ?? t("Confirm")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ConfirmModal;
