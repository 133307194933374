import { clsx } from "clsx";
import { forwardRef, MouseEvent, useCallback, useImperativeHandle, useMemo, useRef } from "react";
import { useCountUp } from "react-countup";
import { useTranslation } from "react-i18next";
import { FaCheck, FaHourglassEnd, FaLock } from "react-icons/fa6";
import { IcoPointV4 } from "src/assets";
import IcoSpinner from "src/assets/IcoSpinner";
import { PropsWithClassName, PropsWithThemeConfig, ThemeSize } from "src/main/components";
import { ID_TOP_BAR_COIN_ICON } from "src/main/contants";
import { useBreakPoint } from "src/main/hooks";
import { fNumber, generateFlyingCoins, styledClassBuilder } from "src/main/utils";
import { MissionClaimButtonRef, MissionUIStatus } from "src/main/views/MissionsPage/types";
import "./ClaimButton.css";

type ClaimButtonVariant = MissionUIStatus;

interface ClaimButtonProps extends PropsWithClassName, PropsWithThemeConfig<ThemeSize, ClaimButtonVariant> {
  onClick?: (e: MouseEvent<HTMLButtonElement>) => void;
  points: number;
  loading?: boolean;
  onPointsCountEnd?: () => void;
  withFlyingCoins?: boolean;
  withCountDown?: boolean;
}

const NUMBER_OF_FLYING_COINS = 5;
const COIN_SIZE_DESKTOP = 32;
const COIN_SIZE_MOBILE = 20;

const ClaimButton = forwardRef<MissionClaimButtonRef, ClaimButtonProps>(
  (
    {
      variant = MissionUIStatus.CLAIMABLE,
      points,
      loading = false,
      onClick,
      onPointsCountEnd,
      withFlyingCoins = false,
      withCountDown = false,
    },
    ref,
  ) => {
    const { t } = useTranslation();
    const countRef = useRef<HTMLSpanElement | null>(null);
    const coinIconRef = useRef(null);

    const { isMobile } = useBreakPoint();

    const renderFlyingCoins = useCallback(() => {
      if (coinIconRef.current) {
        const topBarCoinIcon = isMobile ? ID_TOP_BAR_COIN_ICON.MOBILE : ID_TOP_BAR_COIN_ICON.DESKTOP;
        const targetPosition = document.getElementById(topBarCoinIcon)?.getBoundingClientRect();
        if (targetPosition) {
          generateFlyingCoins(
            coinIconRef.current,
            targetPosition,
            NUMBER_OF_FLYING_COINS,
            {
              source: {
                width: COIN_SIZE_MOBILE,
                height: COIN_SIZE_MOBILE,
              },
              target: {
                width: isMobile ? COIN_SIZE_MOBILE : COIN_SIZE_DESKTOP,
                height: isMobile ? COIN_SIZE_MOBILE : COIN_SIZE_DESKTOP,
              },
            },
            {
              durations: {
                bounceDown: 0,
                delay: 0.1,
                skipBounceDown: true,
                withTrail: false,
              },
              positions: {
                fixed: {
                  bounceDown: {
                    x: 0,
                    y: 0,
                  },
                },
              },
            },
          );
        }
      }
    }, [isMobile]);

    const { start } = useCountUp({
      end: withCountDown ? 0 : points,
      delay: 0,
      start: points,
      duration: 1.5,
      ref: countRef,
      startOnMount: false,
      startVal: points,
      onEnd: onPointsCountEnd,
      formattingFn: (value) => fNumber(value),
    });

    useImperativeHandle(ref, () => ({
      count: () => {
        start();
        withFlyingCoins && renderFlyingCoins();
      },
    }));

    const buttonIcon = useMemo(() => {
      switch (variant) {
        case MissionUIStatus.CLAIMABLE:
          return (
            <span className="mb-0.5 -mt-[1px] animate-fade-in text-sm font-bold uppercase leading-4 text-content-primary [text-shadow:1px_1px_2px_rgba(0,0,0,0.25)]">
              {t("claim")}
            </span>
          );
        case MissionUIStatus.EXPIRED:
          return (
            <span className="animate-fade-in">
              <FaHourglassEnd className="mb-0.5 -mt-[1px] h-4 w-4 flex-shrink-0 pb-[1px] text-white opacity-30" />
            </span>
          );
        case MissionUIStatus.CLAIMED:
          return (
            <FaCheck className="-mt-0.5 h-4.5 w-4.5 flex-shrink-0 animate-fade-in text-content-positive-primary" />
          );
        case MissionUIStatus.IN_PROGRESS:
          return <FaLock className="mb-[1px] h-3.5 w-3.5 flex-shrink-0 animate-fade-in pb-[1px] text-white" />;
      }
    }, [t, variant]);

    return (
      <button
        className={clsx(
          "relative flex h-[50px] w-fit min-w-[72px] flex-col items-center justify-between overflow-hidden rounded-md border py-1.5 transition-colors duration-1000",
          buildStyledButtonClass({
            variant,
          }),
          {
            "pointer-events-none !justify-center": loading,
          },
        )}
        onClick={onClick}
      >
        <div
          className={clsx("claim-button__light-sweep", {
            hidden: loading || variant !== MissionUIStatus.CLAIMABLE,
          })}
        />

        <div
          className={clsx("animate-fade-in", {
            hidden: !loading,
          })}
        >
          <IcoSpinner className="!mx-0 !my-0 h-5 w-5" />
        </div>

        <div
          className={clsx("flex h-[38px] w-fit min-w-[72px] flex-col items-center justify-between", {
            hidden: loading,
          })}
        >
          {buttonIcon}
          <span
            className={clsx(
              "flex animate-fade-in items-center space-x-0.5 rounded-full bg-black bg-opacity-[24%] pr-1 pl-0.5",
              {
                "!opacity-50": [MissionUIStatus.EXPIRED, MissionUIStatus.CLAIMED].includes(variant),
              },
            )}
          >
            <IcoPointV4
              className="h-4 w-4"
              ref={coinIconRef}
            />
            <span
              className="text-sm font-bold text-amber-400"
              ref={countRef}
            >
              {fNumber(points)}
            </span>
          </span>
        </div>
      </button>
    );
  },
);
const buildStyledButtonClass = styledClassBuilder<ThemeSize, ClaimButtonVariant>({
  variant: {
    [MissionUIStatus.IN_PROGRESS]: `
      bg-[linear-gradient(180deg,#353160_0%,#272456_100%)]
      shadow-[0px_4px_4px_0px_#0000003D,0px_0px_10px_0px_#443378,0px_4px_6px_0px_#FFFFFF29_inset]
      border-[#FFFFFF3F]
      `,
    [MissionUIStatus.CLAIMABLE]: `
      bg-[radial-gradient(50%_100%_at_50%_0%,#A3E635_0%,#4D7C0F_100%)]
      shadow-[0px_4px_4px_0px_#00000040,0px_0px_10px_0px_#84CC168F,0px_4px_6px_0px_#FFFFFF33_inset]
      border-[#FFFFFF3F]
      hover:opacity-90
      transition-opacity
      active:bg-[radial-gradient(50%_100%_at_50%_0%,#A3E635_0%,#456619_100%)]
    `,
    [MissionUIStatus.EXPIRED]: `
      bg-black bg-opacity-[4%]
      border-[#FFFFFF3F]
    `,
    [MissionUIStatus.CLAIMED]: `
      bg-black bg-opacity-[4%]
      border-[#FFFFFF3F]
    `,
  },
});

export default ClaimButton;
