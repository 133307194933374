import { ResourceType } from "src/main/types";

const MIME_DB: Record<ResourceType, string[]> = {
  [ResourceType.Image]: ["jpg", "jpeg", "png", "gif", "bmp", "svg"],
  [ResourceType.Video]: ["mp4", "avi", "mov", "mkv", "webm", "flv", "wmv"],
};

const MIME_TYPE_GROUP = ["image", "video", "audio", "text", "application", "font", "model", "message", "multipart"];

export const extensionToResourceType = (extension: string): ResourceType | null => {
  for (const [type, extensions] of Object.entries(MIME_DB)) {
    if (extensions.includes(extension)) {
      return type as ResourceType;
    }
  }
  return null;
};

export const fileTypeToResourceType = (fileType: string): ResourceType | null => {
  const fileTypeGroup = fileType.split("/")[0];
  if (!MIME_TYPE_GROUP.includes(fileTypeGroup)) return null;
  return (fileTypeGroup as ResourceType) ?? null;
};

export const getResourceType = (fileTypeOrFileName: string): ResourceType | null => {
  let type = fileTypeToResourceType(fileTypeOrFileName);
  if (type) return type;
  const extension = fileTypeOrFileName.split(".").pop();
  if (!extension) return null;
  type = extensionToResourceType(extension);
  return type;
};
