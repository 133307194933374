export * from "./address";
export * from "./animation";
export * from "./api";
export * from "./deployEnv";
export * from "./exchangePoint";
export { default as logger } from "./logger";
export * from "./mime";
export * from "./misc";
export * from "./navigator";
export * from "./number";
export * from "./parseMeta";
export { default as Paths, isPrivatePath } from "./paths";
export * from "./phone";
export * from "./postback";
export * from "./product";
export * from "./pushAPI";
export * from "./storageHandler";
export * from "./theme";
export * from "./type";

const slicePart = (input: string, start = 4, end = 0, caps?: boolean) => {
  if (input.length < start + end) return input;
  let ellipsis = "…";
  if (start === 0 || end === 0) ellipsis = "";

  let res = "";
  if (!end) {
    res = input.slice(0, start);
  } else {
    res = [input.slice(0, start), input.slice(-end)].join(ellipsis);
  }

  if (caps) {
    return res.toUpperCase();
  }
  return res;
};

export const truncate = (str?: string | null | undefined, start?: number, end?: number, caps?: boolean) => {
  if (typeof str !== "string") return str;
  const parts = str.split(/[x:]/i);
  if (parts.length < 2) return slicePart(str, start, end, caps);

  return slicePart(parts[1], start, end, caps);
};
