import { Middleware } from "@reduxjs/toolkit";
import authApi from "./auth";
import publicApi from "./public";
import siteApi from "./site";

export const apiReducers = {
  [siteApi.reducerPath]: siteApi.reducer,
  [publicApi.reducerPath]: publicApi.reducer,
  [authApi.reducerPath]: authApi.reducer,
};

export const apiMiddlewares: Middleware[] = [siteApi.middleware, publicApi.middleware, authApi.middleware];

export { siteApi, publicApi, authApi };
