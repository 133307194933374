import { put, takeLatest } from "@redux-saga/core/effects";
import * as actions from "./actions";
import * as layoutSliceAction from "./slices";

function* launchPlaynow(action: ReturnType<typeof actions.launchPlaynow>) {
  const { payload } = action;

  yield put(layoutSliceAction.updatePlaynow(payload));
}

export function* layoutSaga() {
  yield takeLatest(actions.launchPlaynow.type, launchPlaynow);
}

export default layoutSaga;
