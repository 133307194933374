import { Language } from "src/main/types";
import { getDeployEnv, getStorageItem } from "../utils";

export interface LanguageType {
  [key: string]: number;
}

export const localLanguage: string = getStorageItem("lng", Language.TH);

export const languageIds: LanguageType = {
  en: 1,
  th: 2,
};

export const userLang = languageIds[localLanguage];

export default function authHeader() {
  const token = getStorageItem("VIP_TOKEN", null);
  if (!!token) {
    return { Authorization: `Bearer ${token}` };
  } else {
    return { Authorization: "" };
  }
}

export function getLangId() {
  const currentEnv = getDeployEnv();
  const localLanguage: string = getStorageItem("lng", currentEnv.defaultLang);
  return languageIds[localLanguage];
}
