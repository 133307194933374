import { PropsWithChildren } from "react";
import { Page } from "src/main/components";
import { RewardBoxDaily, RewardBoxMonthly, RewardBoxVip, RewardBoxWeekly, TopSection } from "./components";
import "./RewardsPage.css";

interface RewardsPageProps extends PropsWithChildren {}

const RewardsPage = (props: RewardsPageProps) => {
  return (
    <Page
      className="-mt-[60px] bg-[#1B0E3A] lg:mt-20 lg:!bg-transparent"
      paddingTop
    >
      <TopSection />

      <div className="flex flex-row justify-center space-x-4 p-4 pt-6">
        <RewardBoxVip />
        <RewardBoxDaily />
      </div>
      <div className="flex flex-row justify-center space-x-4 p-4 pt-0">
        <RewardBoxWeekly />
        <RewardBoxMonthly />
      </div>
    </Page>
  );
};

export default RewardsPage;
