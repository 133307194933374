import { clsx } from "clsx";
import React, { HTMLInputTypeAttribute, useMemo } from "react";
import CurrencyInput, { CurrencyInputProps } from "react-currency-input-field";
import { useTranslation } from "react-i18next";
import { FaLock } from "react-icons/fa6";
import { PropsWithThemeConfig, ThemeSize } from "src/main/components";
import { styledClassBuilder } from "src/main/utils";

type InputFieldVariant = "filled" | "outlined";
type InputFieldDisabledVariant = "opacity" | "lock-icon";

interface FloatingInputLabelProps
  extends Omit<React.HTMLProps<HTMLInputElement>, "size">,
    PropsWithThemeConfig<ThemeSize, InputFieldVariant> {
  name: string;
  label: string;
  startIcon?: any;
  endButton?: any;
  error?: boolean;
  helperText?: string | null;
  helperTextClassName?: string;
  value?: string | number;
  language?: string;
  onChange?: (evt: React.ChangeEvent<HTMLInputElement>) => void;
  inputClassName?: string;
  startIconClassName?: string;
  type?: HTMLInputTypeAttribute | "currency" | "multiline";
  currencyProps?: CurrencyInputProps;
  helperTextPreload?: boolean;
  disabledVariant?: InputFieldDisabledVariant;
  rows?: number;
}

const InputField: React.FC<FloatingInputLabelProps> = ({
  helperTextClassName,
  disabled,
  name,
  startIcon,
  label,
  value,
  error = false,
  language,
  endButton,
  helperText,
  onChange,
  inputClassName,
  startIconClassName,
  type = "text",
  currencyProps,
  ref,
  defaultValue,
  step,
  size,
  variant = "outlined",
  helperTextPreload = true,
  disabledVariant = "opacity",
  rows = 3,
  ...props
}) => {
  const { t } = useTranslation();

  const sharedInputProps = useMemo(() => {
    return {
      id: name,
      disabled: disabled,
      className: clsx(
        "peer block w-full appearance-none rounded-lg border-2 border-token-border px-2.5 pb-2.5 pt-4 text-sm text-white !outline-none transition-colors duration-200  focus:border-2 focus:border-token-text-secondary focus:ring-transparent dark:text-red-500",
        {
          "opacity-50": disabled,
          "pl-10": !!startIcon,
          "border-error-500": error || helperText,
          "focus:ring-token-text-secondary": !error,
        },
        buildInputStyledClass({ size, variant }),
        inputClassName,
      ),
    };
  }, [disabled, error, helperText, inputClassName, name, size, startIcon, variant]);

  return (
    <>
      <div className="relative w-full rounded-lg bg-container-2">
        {!!type && type === "currency" && (
          <CurrencyInput
            {...sharedInputProps}
            onValueChange={(value) => {
              onChange?.({
                // @ts-ignore
                currentTarget: {
                  value: value || "",
                  name,
                },
              });
            }}
            value={value}
            {...currencyProps}
            {...props}
          />
        )}
        {!!type && type === "multiline" && (
          <textarea
            {...sharedInputProps}
            value={value}
            onChange={(event) => {
              onChange?.({
                // @ts-ignore
                currentTarget: {
                  value: event.target.value,
                  name,
                },
                // @ts-ignore
                target: {
                  value: event.target.value,
                  name,
                },
              });
            }}
            defaultValue={defaultValue}
            rows={rows}
          />
        )}
        {!!type && !["currency", "multiline"].includes(type) && (
          <input
            {...sharedInputProps}
            type={type}
            ref={ref}
            value={value}
            onChange={onChange}
            defaultValue={defaultValue}
            step={step}
            {...props}
          />
        )}
        {!!label && (
          <label
            htmlFor={name}
            className={`
                    absolute top-2
                    z-10 origin-[0]
                    -translate-y-4
                    scale-75 transform rounded-lg bg-container-2 px-2 text-sm uppercase
                    text-white
                    duration-300
                    peer-placeholder-shown:top-1/2
                    peer-placeholder-shown:-translate-y-1/2
                    peer-placeholder-shown:scale-100
                    peer-placeholder-shown:capitalize
                    peer-focus:px-2
                    dark:bg-gray-900
                    dark:text-gray-400
                    peer-focus:dark:text-blue-500
                    ${startIcon && "peer-placeholder-shown:ml-8"}
                    peer-autofill-hover:bg-container-2
                    peer-autofill-focus:bg-container-2
                    peer-autofill-active:bg-container-2
                    left-1
                    peer-placeholder-shown:bg-transparent
                    peer-autofill:top-2
                    peer-autofill:ml-0
                    peer-autofill:-translate-y-4
                    peer-autofill:bg-container-2 peer-autofill:uppercase
                    peer-autofill:text-white
                    peer-focus:top-2
                    peer-focus:ml-0
                    peer-focus:-translate-y-4
                    peer-focus:scale-75
                    peer-focus:bg-container-2
                    peer-focus:uppercase
                    peer-focus:text-white
                    ${buildLabelStyledClass({ size, variant })}
                `}
          >
            {t(label, { lng: language })}
          </label>
        )}
        {startIcon && (
          <div
            className={clsx(
              "absolute bottom-1 left-0 translate-x-1/2 -translate-y-1/2 transform pl-1 text-secondary peer-placeholder-shown:text-primary-300",
              startIconClassName,
            )}
          >
            {startIcon}
          </div>
        )}

        {endButton && <div className="absolute right-1">{endButton}</div>}
        {disabledVariant === "lock-icon" && (
          <FaLock className="absolute top-4 right-4 h-4 w-4 text-content-secondary" />
        )}
      </div>
      {(!!helperText || helperTextPreload) && (
        <div
          className={clsx(
            "mt-2 text-left text-xs text-error-500 transition-all overflow-y-hidden",
            {
              "!min-h-0": !helperText,
            },
            helperTextClassName,
          )}
        >
          {t(helperText ?? "")}
        </div>
      )}
    </>
  );
};

const buildInputStyledClass = styledClassBuilder<ThemeSize, InputFieldVariant>({
  variant: {
    filled: "bg-overlay border-midnight-700 disabled:opacity-100 pl-3.5",
    outlined: "bg-transparent",
  },
});

const buildLabelStyledClass = styledClassBuilder<ThemeSize, InputFieldVariant>({
  variant: {
    filled: "bg-overlay left-2.5 peer-focus:!bg-overlay",
    outlined: "",
  },
});

export default InputField;
