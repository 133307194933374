import React from "react";
import { useTranslation } from "react-i18next";
import { AuthBody } from "src/main/components";
import { LoginFormBox } from "src/main/views/login/components";
import UpdatePasswordForm from "src/main/views/login/ForceUpdatePasswordForm";

interface ForceUpdatePasswordProps {}

const ForceUpdatePassword: React.FC<ForceUpdatePasswordProps> = () => {
  const { t } = useTranslation();

  return (
    <AuthBody flexibleHeight={true}>
      <LoginFormBox>
        <div className="flex flex-col items-center">
          <img
            className="h-[99px]"
            src="/images/login/ilv_slots_animated.gif"
            alt="Frame 99"
          />
          <div className="text-xl font-bold capitalize text-white">{t(`reset password`)}</div>
        </div>
        <UpdatePasswordForm />
      </LoginFormBox>
    </AuthBody>
  );
};

export default ForceUpdatePassword;
