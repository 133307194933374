import { MissionModel } from "src/main/types";

export { default } from "./MissionDetailDrawer";
export const SEND_MISSION_DETAIL = "SEND_MISSION_DETAIL";
export const missionDetailSender = (data: MissionModel) => {
  window.dispatchEvent(
    new CustomEvent(SEND_MISSION_DETAIL, {
      detail: {
        data,
      },
    }),
  );
};
