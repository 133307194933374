import { matchPath } from "react-router-dom";

const Paths = {
  Private: {
    Cart: "/cart",
    Home: "/home",
    Missions: "/missions",
    Gifts: "/gifts",
    OrderHistory: "/order-history",
    Profile: "/profile",
    PointExchange: "/point-exchange",
    Product: "/product/:id",
    RedeemSuccessful: "/redeemed-success",
    RedeemFailed: "/redeem-failed",
    ChangePassword: "/change-password",
    Checkout: "/checkout",
    TermsAndCondition: "/terms-and-condition",
    FAQs: "/faqs",
    ChangeLanguage: "/change-language",
    OrderDetail: "/order-detail/:id",
    AddressList: "/address",
    AddressDetail: "/address/:id",
    AddressNew: "/address/new",
    ManageAddress: "/manage-address",
    ManageAddressNew: "/manage-address/new",
    ManageAddressDetail: "/manage-address/:id",
    CashBack: "/cash-back",
  },
  Public: {
    Denied: "/denied",
    Maintenance: "/maintenance",
    VipStatus: "/vipstatus/",
    VipStatusUsername: "/vipstatus/:username",
    VipStatusSignup: "/vipstatus/signup",
    MobileLogin: "/mobile-login",
    Login: "/login",
    MobileOtp: "/mobile-otp",
    UsernameResetPassword: "/username-reset-password",
    MobileResetPassword: "/mobile-reset-password",
    MobileSetupPassword: "/mobile-setup-password",
    Landing: "/landing",
    VipCheck: "/vipcheck",
    ForceUpdatePassword: "/force-update-password",
    Authorize: "/authorize",
    SessionCheck: "/session-check",
    Logout: "/logout",
  },
};

export const PathsToExcludeLoader = [Paths.Private.Home, Paths.Private.Profile, Paths.Private.OrderHistory];

export const PathsToExcludeResetPassword = [Paths.Public.MobileOtp, Paths.Private.ChangePassword];

export const makeRelativePath = (rootPath: string) => {
  return (path: string, suffix = "") => path.replace(rootPath, "").concat(suffix);
};

export const isPrivatePath = (path: string) => {
  return Object.values(Paths.Private).some((privatePath) => matchPath(privatePath, path));
};

export default Paths;
