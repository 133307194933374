import { useEffect } from "react";
import { initI18n } from "src/i18n";
import { publicApi } from "src/main/api";
import { AppSkeleton, Toast } from "src/main/components";
import "./App.css";
import useTypedSelector from "./main/hooks/useTypedSelector";
import ScrollToTop from "./main/utils/ScrollTop";
import AppRoutes from "./route";

import CacheBuster from "react-cache-buster";
import { DeployEnv } from "src/main/types";
import { getDeployEnv } from "src/main/utils";

const appVersion = process.env.REACT_APP_VERSION;

function App() {
  const {
    auth: { oauth },
    preference: { language },
  } = useTypedSelector((state) => state);

  const { data: serverCentralConfig } = publicApi.useGetCentralServerConfigQuery();

  useEffect(() => {
    if (serverCentralConfig?.underMaintenance === true) {
      if (window.location.pathname !== "/maintenance") {
        window.location.href = "/maintenance";
      }
    }
  }, [serverCentralConfig]);

  useEffect(() => {
    initI18n(language as string);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const currentEnv = getDeployEnv();

  if (oauth === undefined) {
    return <AppSkeleton />;
  }

  return (
    <CacheBuster
      currentVersion={appVersion}
      isEnabled={currentEnv.deploymentType === DeployEnv.Production || currentEnv.deploymentType === DeployEnv.Staging} //If false, the library is disabled.
      isVerboseMode={false} //If true, the library writes verbose logs to console.
      metaFileDirectory={"."} //If public assets are hosted somewhere other than root on your server.
    >
      <ScrollToTop>
        <Toast />
        <AppRoutes />
      </ScrollToTop>
    </CacheBuster>
  );
}

export default App;
