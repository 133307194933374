import { ReactNode } from "react";
import { Skeleton } from "src/main/components";

interface SkeletonLoaderProps {
  children?: ReactNode;
}

const SkeletonLoader = (props: SkeletonLoaderProps) => {
  return (
    <>
      <div className="flex w-full animate-fade-in flex-col px-3">
        <Skeleton
          component={Skeleton.Box}
          className="my-8 mx-auto !h-8 w-1/2 lg:hidden"
        />
        <div className="grid grid-cols-2 gap-x-2 lg:ml-auto lg:max-w-md">
          <Skeleton
            component={Skeleton.Box}
            count={2}
            className="!h-10 w-full"
          />
        </div>
        <Skeleton
          component={Skeleton.Box}
          className="mx-auto mt-8 !h-7 w-2/3 lg:mr-auto"
        />
        <Skeleton
          component={Skeleton.Box}
          count={4}
          className="mt-3 !h-40 w-full"
        />
      </div>
    </>
  );
};

export default SkeletonLoader;
