import {
  CartModel,
  Meta,
  OrderHistoryModel,
  PrimaryWalletPointHistoryModel,
  QueryResult,
  UpdateCartProps,
} from "src/main/types";
import { parseMeta } from "src/main/utils";
import { BuilderProp, require200Status } from "..";

export interface GetPointHistoryResponse {
  meta: Meta;
  entries: PrimaryWalletPointHistoryModel[];
}

export interface GetPointHistoryRequest {
  meta: Meta;
}

export const getPointHistory = (builder: BuilderProp) => {
  return builder.query<GetPointHistoryResponse, GetPointHistoryRequest>({
    providesTags: ["product"],
    query: ({ meta }) => ({
      url: `/member/point/history${parseMeta(meta)}`,
      validateStatus: require200Status,
    }),
    transformResponse: (response: QueryResult<GetPointHistoryResponse>) => {
      return response.result;
    },
  });
};

export interface GetOrderHistoryResponse {
  meta: Meta;
  entries: OrderHistoryModel[];
}

export interface GetOrderHistoryRequest {
  meta: Meta;
}

export const getOrderHistory = (builder: BuilderProp) => {
  return builder.query<GetOrderHistoryResponse, GetOrderHistoryRequest>({
    providesTags: ["product"],
    query: ({ meta }) => ({
      url: `/member/order/list${parseMeta(meta)}`,
      validateStatus: require200Status,
    }),
    transformResponse: (response: QueryResult<GetOrderHistoryResponse>) => {
      return response.result;
    },
  });
};

// new cart
interface CommonCartResponse {
  model: CartModel;
}

interface GetCartDetailRequest {}

export const getCartDetail = (builder: BuilderProp) => {
  return builder.query<CommonCartResponse, GetCartDetailRequest>({
    providesTags: ["cart"],
    query: () => ({
      url: `/member/cart/detail`,
      validateStatus: require200Status,
    }),
    transformResponse: (response: QueryResult<CommonCartResponse>) => {
      return response.result;
    },
  });
};

export interface UpdateCartRequest {
  data: UpdateCartProps;
}

export const updateCart = (builder: BuilderProp) => {
  return builder.mutation<CommonCartResponse, UpdateCartRequest>({
    invalidatesTags: ["cart"],
    query: (body) => ({
      url: `/member/cart/update`,
      validateStatus: require200Status,
      method: "POST",
      body,
    }),
    transformResponse: (response: QueryResult<CommonCartResponse>) => {
      return response.result;
    },
  });
};

interface DeleteCartRequest {}

export const deleteCart = (builder: BuilderProp) => {
  return builder.mutation<CommonCartResponse, DeleteCartRequest>({
    invalidatesTags: ["cart"],
    query: () => ({
      url: `/member/cart/discard`,
      method: "DELETE",
      validateStatus: require200Status,
    }),
    transformResponse: (response: QueryResult<CommonCartResponse>) => {
      return response.result;
    },
  });
};

export interface CheckoutCartRequest {
  checkoutItems?: string[];
  brandId?: string;
  identifier?: string;
}

export interface CheckoutCartResponse {
  model: OrderHistoryModel[];
}

export const checkoutCart = (builder: BuilderProp) => {
  return builder.mutation<CheckoutCartResponse, CheckoutCartRequest>({
    invalidatesTags: ["cart"],
    query: (body) => ({
      url: `/member/cart/submit`,
      method: "POST",
      validateStatus: require200Status,
      body,
    }),
    transformResponse: (response: QueryResult<CheckoutCartResponse>) => {
      return response.result;
    },
  });
};

interface GetOrderHistoryDetailRequest {
  id: string;
}

interface GetOrderHistoryDetailResponse {
  model: OrderHistoryModel;
}

export const getOrderHistoryDetail = (builder: BuilderProp) => {
  return builder.query<GetOrderHistoryDetailResponse, GetOrderHistoryDetailRequest>({
    providesTags: ["product"],
    query: ({ id }) => ({
      url: `/member/order/${id}/detail`,
      validateStatus: require200Status,
    }),
    transformResponse: (response: QueryResult<GetOrderHistoryDetailResponse>) => {
      return response.result;
    },
  });
};
