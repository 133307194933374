import { FormikHelpers, useFormik } from "formik";
import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { IcoPhone } from "src/assets";
import { siteApi } from "src/main/api";
import { Button, ContactSupportButton, InputField } from "src/main/components";
import useTypedSelector from "src/main/hooks/useTypedSelector";
import auth from "src/main/store/auth";
import { formatPhoneNumber, getDeployEnv, logger } from "src/main/utils";
import * as Yup from "yup";

type UpdatePasswordFormProps = {};

interface PasswordFormType {
  secret: string;
  confirmSecret: string;
}

const initialValues: PasswordFormType = {
  secret: "",
  confirmSecret: "",
};

const ForceUpdatePasswordForm = (props: UpdatePasswordFormProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const state = useTypedSelector((state) => state);
  const currentEnv = getDeployEnv();
  const dispatch = useDispatch();

  const [changePasswordMutation, { isLoading: isChangePasswordLoading }] = siteApi.useChangePasswordMutation();

  const phone = state.auth.mobile;

  const phoneNumber = useMemo(
    () => formatPhoneNumber(phone, currentEnv.country_code ?? ""),
    [currentEnv.country_code, phone],
  );

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        secret: Yup.string().required(t("password is required") ?? "Password is required"),
        confirmSecret: Yup.string()
          .required(t("retype to confirm") ?? "Confirm Password is required")
          .oneOf([Yup.ref("secret"), null], t("passwords must match") ?? "Passwords must match"),
      }),
    [t],
  );

  const backToLogin = useCallback(() => {
    dispatch(auth.slice.actions.logout());
    navigate("/login", { replace: true });
  }, [dispatch, navigate]);

  const handleSubmit = useCallback(
    async (formValue: PasswordFormType, helper: FormikHelpers<PasswordFormType>) => {
      const { secret } = formValue;
      try {
        await changePasswordMutation({ password: secret }).unwrap();
        toast.success(t("Password updated successfully!"));
        backToLogin();
      } catch (error: any) {
        logger._console.log(error);
        toast.error(t(error?.data?.error?.message ?? "Something went wrong"));
      }
    },
    [backToLogin, changePasswordMutation, t],
  );

  const {
    errors,
    touched,
    values,
    handleChange,
    handleSubmit: handleSubmitFormik,
  } = useFormik({
    initialValues,
    validationSchema,
    onSubmit: handleSubmit,
  });

  return (
    <div className="login-form">
      <form onSubmit={handleSubmitFormik}>
        <div className="flex flex-col gap-4 text-center text-white">
          <div>
            <div className="w-full justify-center py-2 text-2xl font-bold">
              <div className="flex w-full items-center justify-center text-primary-200">
                <IcoPhone
                  width="24"
                  height="24"
                />
                <div className="text-white">{phoneNumber}</div>
              </div>
            </div>
          </div>

          <div className="mb-2 flex flex-col items-center gap-3">
            <InputField
              type="password"
              autoComplete="new-password"
              value={values.secret}
              onChange={handleChange}
              name="secret"
              label="enter a new password"
              error={touched.secret && !!errors.secret}
              helperText={touched.secret && !!errors.secret ? errors.secret : ""}
            />

            <InputField
              type="password"
              autoComplete="new-password"
              value={values.confirmSecret}
              onChange={handleChange}
              name="confirmSecret"
              label="retype to confirm"
              error={touched.confirmSecret && !!errors.confirmSecret}
              helperText={touched.confirmSecret && !!errors.confirmSecret ? errors.confirmSecret : ""}
            />
          </div>

          <Button
            loading={isChangePasswordLoading}
            variant="blue-violet"
            type="submit"
          >
            {t("Update Password")}
          </Button>

          <ContactSupportButton />
          <div className="text-center text-[12px] text-secondary">
            <div
              className="px-4 py-2 text-sm font-semibold text-white"
              onClick={backToLogin}
            >
              {t("Back to login")}
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default ForceUpdatePasswordForm;
