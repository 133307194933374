import { PropsWithChildren } from "react";
import { Skeleton } from "src/main/components";

interface SkeletonLoaderProps extends PropsWithChildren {}

const SkeletonLoader = (props: SkeletonLoaderProps) => {
  return (
    <>
      <div className="flex w-full animate-fade-in flex-col px-3">
        <Skeleton
          component={Skeleton.Box}
          className="my-8 mx-auto !h-8 w-1/2 lg:hidden"
        />
        <Skeleton
          component={Skeleton.Box}
          count={4}
          className="mt-3 !h-40 w-full"
        />
      </div>
    </>
  );
};

export default SkeletonLoader;
