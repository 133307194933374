import { clsx } from "clsx";
import { PropsWithChildren } from "react";
import { RewardBoxStatus } from "src/main/views/RewardsPage/types";

interface AnimatedBackgroundProps extends PropsWithChildren {
  status: RewardBoxStatus;
}

const AnimatedBackground = ({ children, status }: AnimatedBackgroundProps) => {
  return (
    <div className={clsx("absolute top-0 left-0 h-full w-full ")}>
      <div
        className={clsx("absolute top-0 left-0 h-full w-full transition duration-500", {
          "opacity-1 bg-[#1D104A]": status === RewardBoxStatus.CLAIMABLE,
          "opacity-1 !bg-[#142A2F] [transition-delay:3s]": status === RewardBoxStatus.CLAIMED,
          "bg-transparent opacity-0": status === RewardBoxStatus.LOCKED,
        })}
      >
        <div className="absolute top-0 left-0 aspect-[165/104] w-full bg-gradient-to-b from-[#8F790500] to-[#F5CF09] mix-blend-soft-light"></div>
        <div className="absolute top-0 left-[50%] aspect-[380/214] h-[65%] w-full translate-x-[-50%] mix-blend-luminosity">
          <img
            alt=" "
            src="/images/body-bg/reward-card-bg.gif"
            className="min-h-full min-w-full object-cover"
          />
        </div>
        <div className="absolute top-0 left-0 h-1/2 w-full bg-[radial-gradient(114.31%_100%_at_50.3%_32.21%,rgba(32,10,81,0)_37.68%,#12072B_76.53%)] mix-blend-soft-light"></div>
      </div>

      {children}
    </div>
  );
};

export default AnimatedBackground;
