import { fNumber } from "src/main/utils";
import { ProgressBar } from "./components";

interface ProgressBoxProps {
  percentage: number; //float number [0-1]
  value: number;
  total: number;
}
export default function ProgressBox({ percentage, value, total }: ProgressBoxProps) {
  return (
    <div className="flex !h-6 w-full min-w-max flex-row items-center justify-center gap-2 rounded-b-lg bg-[#34256A] px-2 text-xs font-normal leading-[18px] shadow-[0px_-4px_8px_0px_rgba(0,0,0,0.25)]">
      <div className="grow">
        <ProgressBar percentage={percentage} />
      </div>
      <div className="w-fit min-w-[85px] flex-shrink-0 text-right">
        <span className="text-white">{fNumber(value)}</span>
        <span className="text-midnight-200">/{fNumber(total)}</span>
      </div>
    </div>
  );
}
