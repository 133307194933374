import { Form, Formik } from "formik";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { FaAngleRight } from "react-icons/fa6";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Button, InputField } from "src/main/components";
import productSlice from "src/main/store/product/slices";
import { ShippingAddressModel } from "src/main/types";
import { Paths } from "src/main/utils";
import { AddressItem } from "src/main/views/AddressListPage/components";
import * as Yup from "yup";

const requiredMsg = "This field is required!";

interface AddressInfoType {
  recipientName: string;
  recipientMobile: string;
}

interface Props {
  address: ShippingAddressModel;
}

export default function AddressInfo({ address }: Props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [isQuickEditMode, setIsQuickEditMode] = useState<boolean>(false);

  const Schema = useMemo(
    () =>
      Yup.object().shape({
        recipientName: Yup.string().required(t(requiredMsg) ?? requiredMsg),
        recipientMobile: Yup.string().required(t(requiredMsg) ?? requiredMsg),
      }),
    [t],
  );

  const initialValues: AddressInfoType = useMemo(
    () => ({
      recipientName: address?.recipientName ?? "",
      recipientMobile: address?.recipientMobile ?? "",
    }),
    [address],
  );

  const handleSaveAddress = (values: AddressInfoType) => {
    dispatch(productSlice.actions.updateShippingInfo({ ...address, ...values }));
    setIsQuickEditMode(false);
  };

  return (
    <div>
      {isQuickEditMode && (
        <Formik
          enableReinitialize={true}
          initialValues={initialValues}
          validationSchema={Schema}
          onSubmit={(values) => {
            handleSaveAddress(values);
          }}
        >
          {({ errors, touched, values, handleChange }) => (
            <Form className="mt-4 w-full">
              <div className="flex flex-col gap-4">
                <div className="flex flex-col gap-2">
                  <InputField
                    value={values.recipientName}
                    onChange={handleChange}
                    name="recipientName"
                    label={t("Recipient's Full Name")}
                    error={!!errors.recipientName && !!touched.recipientName}
                    helperText={touched.recipientName ? errors.recipientName : ""}
                  />
                </div>
                <div className="flex flex-col gap-2">
                  <InputField
                    value={values.recipientMobile}
                    onChange={handleChange}
                    name="recipientMobile"
                    inputMode="tel"
                    label={t("Recipient's Mobile")}
                    error={!!errors.recipientMobile && !!touched.recipientMobile}
                    helperText={touched.recipientMobile ? errors.recipientMobile : ""}
                  />
                </div>
              </div>

              <Button variant="blue-violet">{t("Update")}</Button>
            </Form>
          )}
        </Formik>
      )}

      {!isQuickEditMode && (
        <div className="flex rounded-md bg-overlay p-4 pt-3.5">
          <AddressItem
            address={address}
            shortMode
            backTo={Paths.Private.Checkout}
          />
        </div>
      )}

      <button
        className="flex w-full cursor-pointer items-center justify-center gap-2 py-5 text-xs font-bold uppercase opacity-80"
        onClick={() => {
          navigate(Paths.Private.AddressList);
        }}
      >
        <span>{t("Change Address")}</span>
        <FaAngleRight className="inline-block h-4 w-4" />
      </button>
    </div>
  );
}
