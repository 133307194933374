import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
import dayjs from "dayjs";
import { DomainInfos } from "src/main/models";
import { Language } from "src/main/types";
import { SimpleMap } from "src/main/utils";
import { LaunchPayload, LoadingTasks, NewLoadingTask } from "./types";

export type LayoutState = {
  playnow: LaunchPayload;
  loadingTasks: LoadingTasks;
  tasksRegistry: SimpleMap<any>;
  envInfo?: DomainInfos;
};

export const initialState: LayoutState = {
  playnow: {},
  loadingTasks: {},
  tasksRegistry: {},
  envInfo: {
    domain: null,
    country_iso: null,
    deploymentType: null,
    isMain: null,
    country_code: null,
    currency: "",
    defaultLang: Language.EN,
    brand: null,
    mainUrl: null,
  },
};

export const layout = createSlice({
  name: "updateLayout",
  initialState,
  reducers: {
    updatePlaynow(state, action: PayloadAction<LaunchPayload>) {
      state.playnow = action.payload;
    },
    addLoadingTask(state, action: PayloadAction<NewLoadingTask>) {
      const newTask = action.payload;
      const loadingTask = state.loadingTasks[newTask.name] || {};
      loadingTask[newTask.uuid] = dayjs();
      state.loadingTasks[newTask.name] = loadingTask;
      state.tasksRegistry[newTask.uuid] = newTask.name;
    },
    removeLoadingTask(state, action: PayloadAction<string>) {
      const uuid = action.payload;
      const taskName = state.tasksRegistry[uuid];
      if (!taskName) return state;

      const loadingTask = state.loadingTasks[taskName];
      if (!loadingTask || !loadingTask[uuid]) return state;

      delete loadingTask[uuid];
      if (!Object.keys(loadingTask).length) delete state.loadingTasks[taskName!];

      delete state.tasksRegistry[uuid];
    },
  },
});

export const { updatePlaynow } = layout.actions;

export default layout;
