import { SVGProps } from "react";

interface FaCirclePlusGradientProps extends SVGProps<SVGSVGElement> {}

const FaCirclePlusGradient = (props: FaCirclePlusGradientProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
      {...props}
    >
      <g
        id="Before Icon"
        filter="url(#filter0_i_1424_3006)"
      >
        <path
          id="vector"
          d="M10.7507 19.1666C13.1818 19.1666 15.5134 18.2009 17.2325 16.4818C18.9515 14.7627 19.9173 12.4311 19.9173 9.99998C19.9173 7.56883 18.9515 5.23725 17.2325 3.51817C15.5134 1.79908 13.1818 0.833313 10.7507 0.833313C8.3195 0.833313 5.98792 1.79908 4.26884 3.51817C2.54976 5.23725 1.58398 7.56883 1.58398 9.99998C1.58398 12.4311 2.54976 14.7627 4.26884 16.4818C5.98792 18.2009 8.3195 19.1666 10.7507 19.1666ZM9.89128 13.151V10.8594H7.59961C7.12337 10.8594 6.74023 10.4762 6.74023 9.99998C6.74023 9.52374 7.12337 9.1406 7.59961 9.1406H9.89128V6.84894C9.89128 6.3727 10.2744 5.98956 10.7507 5.98956C11.2269 5.98956 11.61 6.3727 11.61 6.84894V9.1406H13.9017C14.3779 9.1406 14.7611 9.52374 14.7611 9.99998C14.7611 10.4762 14.3779 10.8594 13.9017 10.8594H11.61V13.151C11.61 13.6273 11.2269 14.0104 10.7507 14.0104C10.2744 14.0104 9.89128 13.6273 9.89128 13.151Z"
          fill="url(#paint0_linear_1424_3006)"
        />
      </g>
      <defs>
        <filter
          id="filter0_i_1424_3006"
          x="0.75"
          y="0"
          width="20"
          height="20"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood
            floodOpacity="0"
            result="BackgroundImageFix"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset
            dx="0.25"
            dy="0.5"
          />
          <feComposite
            in2="hardAlpha"
            operator="arithmetic"
            k2="-1"
            k3="1"
          />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0"
          />
          <feBlend
            mode="normal"
            in2="shape"
            result="effect1_innerShadow_1424_3006"
          />
        </filter>
        <linearGradient
          id="paint0_linear_1424_3006"
          x1="10.7507"
          y1="0.833313"
          x2="10.7507"
          y2="19.1666"
          gradientUnits="userSpaceOnUse"
        >
          <stop
            stopColor="white"
            stopOpacity="0.66"
          />
          <stop
            offset="1"
            stopColor="white"
            stopOpacity="0.33"
          />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default FaCirclePlusGradient;
