import { clsx } from "clsx";
import { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { siteApi } from "src/main/api";
import { RewardType } from "src/main/types";
import { getErrorMessage } from "src/main/utils";
import { ClaimPointsSection } from "src/main/views/RewardsPage/components";
import { CoinLabelRef } from "src/main/views/RewardsPage/components/CoinLabel/CoinLabel";
import RewardCard from "src/main/views/RewardsPage/components/RewardCard";
import { RewardBoxStatus } from "src/main/views/RewardsPage/types";
import { useGetRewardsInfo } from "../../hooks";
import AnimatedBackground from "../AnimatedBackground";
import "./RewardBoxVip.css";

const RewardBoxVip = () => {
  const { t } = useTranslation();
  const refCoinLabel = useRef<CoinLabelRef>(null);

  const { data, /* isFetching, */ refetch, isLoading, isGetMorePoints, initialRewardStatus } = useGetRewardsInfo(
    RewardType.VIP,
  );
  const [rewardStatus, setRewardStatus] = useState<RewardBoxStatus | undefined>(initialRewardStatus);

  const [claim, { isLoading: isClaiming }] = siteApi.useClaimRewardMutation();

  useEffect(() => {
    if (data) {
      const { remainingSeconds } = data.vip;
      if (remainingSeconds !== 0) {
        setRewardStatus(RewardBoxStatus.LOCKED);
      } else {
        setRewardStatus(RewardBoxStatus.CLAIMABLE);
      }
    }
  }, [data]);

  const handleOnClaim = useCallback(async () => {
    try {
      await claim({ type: RewardType.VIP }).unwrap();
      setRewardStatus(RewardBoxStatus.CLAIMED);
      const timer = setTimeout(() => {
        refCoinLabel.current?.count();
        clearTimeout(timer);
      }, 3000);
    } catch (error: any) {
      console.log(error);
      toast.error(getErrorMessage(error, t));
      refetch();
    }
  }, [t, claim, refetch]);

  const handleOnCountDownEnd = useCallback(() => {
    refetch();
  }, [refetch]);

  const handleOnTimerComplete = useCallback(() => {
    setRewardStatus(RewardBoxStatus.CLAIMABLE);
  }, []);

  return (
    <RewardCard
      title={t("VIP")}
      loading={isLoading}
      borderEffect={rewardStatus === RewardBoxStatus.CLAIMABLE && !isGetMorePoints}
      className="animate-fade-in opacity-0"
    >
      {!!data && !!rewardStatus && (
        <>
          <div className="relative z-[-1] flex h-full flex-col">
            <AnimatedBackground status={isGetMorePoints ? RewardBoxStatus.LOCKED : rewardStatus}>
              <div className="absolute top-0 left-0 h-1/2 w-full">
                {/*LIGHT*/}
                <div
                  className={clsx(
                    "absolute left-1/2 bottom-[-10px] h-full w-[90%] -translate-x-1/2 bg-contain bg-no-repeat opacity-80 transition-[opacity,bottom] duration-[2s]",
                    {
                      "!bottom-2": rewardStatus === RewardBoxStatus.CLAIMED,
                      "!opacity-0": rewardStatus === RewardBoxStatus.LOCKED || isGetMorePoints,
                    },
                  )}
                  style={{
                    backgroundImage: "url('/images/body-bg/card-light.svg')",
                    backgroundPositionY: "bottom",
                  }}
                />

                {/* Cup */}
                <div
                  className={clsx(
                    "absolute left-1/2 bottom-0 aspect-[131/122] w-[79%] -translate-x-1/2 translate-y-[60%]",
                    {
                      "animate-[cup-raise_10s_infinite]":
                        rewardStatus === RewardBoxStatus.CLAIMABLE && !isGetMorePoints,
                    },
                  )}
                >
                  <div
                    className={clsx("relative h-full w-full", {
                      "translate-y-[30%] scale-[1.4] transition duration-[1500ms] ease-linear":
                        rewardStatus === RewardBoxStatus.CLAIMED,
                      "translate-y-[0] scale-[1] transition duration-[1500ms] ease-linear":
                        rewardStatus === RewardBoxStatus.LOCKED,
                    })}
                  >
                    <img
                      className={clsx("relative z-[1] transition-opacity duration-500 ease-linear", {
                        "opacity-20": rewardStatus === RewardBoxStatus.LOCKED || isGetMorePoints,
                        "opacity-100": rewardStatus === RewardBoxStatus.CLAIMABLE && !isGetMorePoints,
                      })}
                      src="/images/body-bg/Cup.png"
                      alt="cup"
                    />

                    {/* left coin */}
                    <div
                      className={clsx(
                        "absolute top-0 left-1/2 z-[0] aspect-square w-[28%] translate-x-[-95%] translate-y-[45%] rounded-full",
                        {
                          "translate-y-[-60%] transition duration-[1500ms] ease-linear":
                            rewardStatus === RewardBoxStatus.CLAIMED,
                        },
                      )}
                    >
                      <div
                        className={clsx("relative h-full w-full", {
                          "animate-[coin-in-cup-moving_10s_linear_infinite]":
                            rewardStatus === RewardBoxStatus.CLAIMABLE && !isGetMorePoints,
                        })}
                      >
                        <img
                          src="/images/icon/reward-coin.svg"
                          className="h-full w-full transition-opacity duration-500"
                          alt="coin"
                        />
                        <img
                          src="/images/icon/reward-coin-tick.svg"
                          className={clsx(
                            "absolute top-0 left-0 z-[1] h-full w-full opacity-0 transition-opacity duration-[1500ms]",
                            {
                              "opacity-100 [transition-delay:3s]": rewardStatus === RewardBoxStatus.CLAIMED,
                            },
                          )}
                          alt="coin"
                        />
                      </div>
                    </div>
                    {/*right coin */}
                    <div
                      className={clsx(
                        "absolute top-0 left-1/2 z-[0] aspect-square w-[28%] translate-x-[-5%] translate-y-[45%] rounded-full",
                        {
                          "translate-y-[-60%] transition duration-[1500ms] ease-linear":
                            rewardStatus === RewardBoxStatus.CLAIMED && !isGetMorePoints,
                        },
                      )}
                    >
                      <div
                        className={clsx("relative h-full w-full", {
                          "animate-[coin-in-cup-moving_10s_linear_infinite]":
                            rewardStatus === RewardBoxStatus.CLAIMABLE && !isGetMorePoints,
                        })}
                      >
                        <img
                          src="/images/icon/reward-coin.svg"
                          className="h-full w-full transition-opacity duration-500"
                          alt="coin"
                        />
                        <img
                          src="/images/icon/reward-coin-tick.svg"
                          className={clsx(
                            "absolute top-0 left-0 z-[1] h-full w-full opacity-0 transition-opacity duration-[1500ms]",
                            {
                              "opacity-100 [transition-delay:3s]": rewardStatus === RewardBoxStatus.CLAIMED,
                            },
                          )}
                          alt="coin"
                        />
                      </div>
                    </div>

                    {/*mid coin */}
                    <div
                      className={clsx(
                        "absolute top-0 left-1/2 z-[0] aspect-square w-[28%] -translate-x-1/2 translate-y-1/3 rounded-full",
                        {
                          "!translate-y-[-70%] transition duration-[1500ms] ease-linear":
                            rewardStatus === RewardBoxStatus.CLAIMED,
                        },
                      )}
                    >
                      <div
                        className={clsx("relative h-full w-full", {
                          "animate-[coin-in-cup-moving_10s_linear_infinite]":
                            rewardStatus === RewardBoxStatus.CLAIMABLE && !isGetMorePoints,
                        })}
                      >
                        <img
                          src="/images/icon/reward-coin.svg"
                          className="h-full w-full transition-opacity duration-500"
                          alt="coin"
                        />
                        <img
                          src="/images/icon/reward-coin-tick.svg"
                          className={clsx(
                            "absolute top-0 left-0 z-[1] h-full w-full opacity-0 transition-opacity duration-[1500ms]",
                            {
                              "opacity-100 [transition-delay:3s]": rewardStatus === RewardBoxStatus.CLAIMED,
                            },
                          )}
                          alt="coin"
                        />
                      </div>
                    </div>

                    <div
                      className="absolute top-0 left-0 z-[0] h-full w-full bg-[#1F0A51]"
                      style={{
                        maskImage: "url(/images/body-bg/Cup.png)",
                        maskSize: "cover",
                      }}
                    ></div>
                  </div>
                </div>
              </div>
            </AnimatedBackground>
            <ClaimPointsSection
              onPointsCountEnd={handleOnCountDownEnd}
              onTimerComplete={handleOnTimerComplete}
              onClaim={handleOnClaim}
              isClaiming={isClaiming}
              status={rewardStatus}
              refCoinLabel={refCoinLabel}
              isGetMorePoints={isGetMorePoints}
              rewardData={data.vip}
            />
          </div>
        </>
      )}
    </RewardCard>
  );
};

export default RewardBoxVip;
