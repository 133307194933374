import { clsx } from "clsx";
import { throttle } from "lodash";
import { useCallback, useEffect, useState } from "react";
import { TopNavigation } from "src/main/components";
import { TopBarLogo } from "./components";

const TOP_BAR_BG_SCROLL_OFFSET = 100;

function TopBar() {
  // const actions = useActions();

  const [showTopbarBg, setShowTopbarBg] = useState(false);

  const changeTopbarBg = () => {
    window.scrollY >= TOP_BAR_BG_SCROLL_OFFSET ? setShowTopbarBg(true) : setShowTopbarBg(false);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const throttleOnChangeTopBarBg = useCallback(
    throttle(changeTopbarBg, 100, {
      trailing: false,
    }),
    [],
  );

  useEffect(() => {
    window.addEventListener("scroll", throttleOnChangeTopBarBg);
    return () => {
      window.removeEventListener("scroll", throttleOnChangeTopBarBg);
    };
  }, [throttleOnChangeTopBarBg]);

  return (
    <div
      className={clsx(
        "flex h-top-bar w-[calc(100%+1px)] items-center justify-between bg-[#0F0725] bg-opacity-[0.98] px-2 py-3 text-white shadow-[0px_1px_4px_-1px_#A995DB4D] transition-colors lg:px-6 lg:shadow-none lg:backdrop-blur-0",
        {
          "lg:!bg-transparent": !showTopbarBg,
        },
      )}
    >
      <TopBarLogo />
      <TopNavigation />
    </div>
  );
}

export default TopBar;
