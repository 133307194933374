import { Transition } from "@headlessui/react";
import { clsx } from "clsx";
import React, { DetailedHTMLProps, HTMLAttributes, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { TRANSITION_IN_FADE, TRANSITION_OUT_FADE } from "src/main/contants";

interface SliderFieldProps extends DetailedHTMLProps<HTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  name: string;
  label?: string;
  error?: boolean;
  helperText?: string | null;
  helperTextClassName?: string;
  value: number;
  onChange: (evt: React.ChangeEvent<HTMLInputElement>) => void;
  inputClassName?: string;
  min: number;
  max: number;
  quickPoints?: number[];
  skipEventOnQuickPoints?: number[];
}

function Slider({
  helperText,
  helperTextClassName,
  inputClassName,
  quickPoints,
  onChange,
  skipEventOnQuickPoints,
  ...props
}: SliderFieldProps) {
  const { t } = useTranslation();
  const [isShowTooltip, setIsShowTooltip] = useState(false);

  const currentPercent = useMemo(() => {
    // get percentage of current value
    return Math.round(((props.value - props.min) / (props.max - props.min)) * 100);
  }, [props.max, props.min, props.value]);

  const handleOnChangeSlider = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange(e);
  };

  const handleOnClickQuickPoint = (point: number) => {
    onChange({ currentTarget: { value: point } } as unknown as React.ChangeEvent<HTMLInputElement>);
    setIsShowTooltip(true);
    setTimeout(() => {
      setIsShowTooltip(false);
    }, 500);
  };

  const handleOnMouseDown = () => {
    setIsShowTooltip(true);
  };

  const handleOnMouseUp = () => {
    setIsShowTooltip(false);
  };

  return (
    <>
      <div className="relative w-full">
        <div className="relative w-full">
          {/*Background*/}
          <div className="absolute h-1 w-full rounded bg-token-border bg-opacity-30" />
          {/*Active bar*/}
          <div
            className="absolute h-1 w-full rounded bg-token-secondary"
            style={{
              width: `${currentPercent}%`,
            }}
          />
          {/*Quick points*/}
          {quickPoints?.map((point, index) => {
            const pointToPercent = ((point - props.min) / (props.max - props.min)) * 100;
            return (
              <div
                key={[point, index].join("-")}
                className={clsx("absolute top-1/2 h-2 w-2 -translate-x-1/2 -translate-y-0.5 rounded-full", {
                  "bg-primary-200": pointToPercent > currentPercent,
                  "bg-token-secondary-1": pointToPercent <= currentPercent,
                })}
                style={{
                  left: `${pointToPercent}%`,
                }}
              />
            );
          })}
          {/*Thumb*/}
          <div
            className="absolute h-5 w-5 -translate-y-2 rounded-full bg-token-secondary drop-shadow-xl"
            style={{
              left: `calc(${currentPercent}% - 10px)`,
            }}
          />
          {/*Tooltip*/}
          <Transition
            show={isShowTooltip}
            {...TRANSITION_IN_FADE}
            {...TRANSITION_OUT_FADE}
            leave="transition-all duration-300 delay-500"
            as="div"
            className="drop-shadow-xl"
          >
            <div
              className="absolute -top-9 -translate-x-1/2 rounded border-token-border bg-primary-400 px-1.5 py-0.5 text-xs text-white"
              style={{
                left: `calc(${currentPercent}%)`,
              }}
            >
              {currentPercent}%
              <div className="absolute -bottom-1.5 left-1/2 h-1.5 w-1.5 -translate-y-1 -translate-x-1/2 rotate-45 bg-primary-400" />
            </div>
          </Transition>
        </div>
        <input
          type="range"
          {...props}
          onChange={handleOnChangeSlider}
          className={clsx("absolute -top-1 w-full opacity-0", inputClassName, props.className)}
          onMouseDown={handleOnMouseDown}
          onMouseUp={handleOnMouseUp}
          onTouchStart={handleOnMouseDown}
          onTouchEnd={handleOnMouseUp}
        />
        {/*Quick points trigger*/}
        {quickPoints?.map((point, index) => {
          const pointToPercent = ((point - props.min) / (props.max - props.min)) * 100;
          return (
            <div
              key={[point, index].join("-")}
              className={clsx("absolute top-1/2 h-2 w-2 -translate-x-1/2 -translate-y-0.5 rounded-full opacity-0", {
                "pointer-events-none": skipEventOnQuickPoints?.includes(point),
              })}
              onClick={() => handleOnClickQuickPoint(point)}
              style={{
                left: `${pointToPercent}%`,
              }}
            />
          );
        })}
      </div>
      <div
        className={clsx(
          "mt-2 text-left text-xs text-error-500 transition-all overflow-y-hidden",
          {
            "!min-h-0": !helperText,
          },
          helperTextClassName,
        )}
      >
        {t(helperText ?? "")}
      </div>
    </>
  );
}

export default Slider;
