import { Knob } from "./components";

interface ProgressBarProps {
  percentage: number; //float number [0-1]
}

export default function ProgressBar({ percentage }: ProgressBarProps) {
  const safePercentage = percentage <= 0 ? 0 : percentage >= 1 ? 1 : percentage;

  return (
    <div className="relative h-3 w-full">
      {/* gradient bg */}
      <div className="h-3 w-full rounded-lg border-[1px] border-black bg-[linear-gradient(90deg,#FCD34D_0%,#22C55E_50%)]"></div>

      {/* dark bg */}
      <div
        className="absolute top-0 right-0 z-[1] h-3 rounded-lg bg-midnight-900"
        style={{
          width: `${(1 - safePercentage) * 100}%`,
        }}
      ></div>

      {/* dash container */}
      <div className="absolute top-0 left-0 z-[2] flex h-3 w-full flex-row items-center justify-between">
        <div></div> {/* head */}
        <div className="h-1.5 w-[1px] rounded-lg bg-midnight-900/30"></div>
        <div className="h-1.5 w-[1px] rounded-lg bg-midnight-900/30"></div>
        <div className="h-1.5 w-[1px] rounded-lg bg-midnight-900/30"></div>
        <div className="h-1.5 w-[1px] rounded-lg bg-midnight-900/30"></div>
        <div className="h-1.5 w-[1px] rounded-lg bg-midnight-900/30"></div>
        <div></div> {/* tail */}
      </div>

      {/* knob */}
      <div
        className="absolute top-0 z-[3] translate-x-[calc(-50%)] translate-y-[calc(-50%+5px)]"
        style={{
          /** to ensure the Knob never outside the bar
           * X = safePercentage * 100
           *
           * X   | left
           * ----------
           * 0   | 8px
           * 100 | 100% - 8px
           *
           * => X = X*1% + 8px - 0.16*X
           */
          left: `calc(${safePercentage * 100}% + 8px - ${16 * safePercentage}px)`,
        }}
      >
        <Knob />
      </div>
    </div>
  );
}
