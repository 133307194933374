import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router";
import { Button, Card, Page } from "src/main/components";
import useTypedSelector from "src/main/hooks/useTypedSelector";
import { CartItemModel } from "src/main/types";
import { ErrorResponse, Paths, QueryError } from "src/main/utils";
import { CheckoutItemList } from "src/main/views/CheckoutPage/components";

const RedeemFailedPage: React.FC = () => {
  const { t } = useTranslation();
  const { state: submitError } = useLocation() as {
    state: QueryError<ErrorResponse>;
  };

  const redeemFailedMessage = useMemo(() => {
    let errorMessage = submitError?.data?.error?.message ?? "Cannot checkout cart, please try again later";
    if (errorMessage.includes("variant not found")) errorMessage = "variant not found";
    return t(errorMessage);
  }, [t, submitError]);

  const { cart, checkoutCartItems = [] } = useTypedSelector((state) => state.products);

  const checkoutItems = useMemo(() => {
    return (cart?.data?.items ?? [])
      .map((item) => {
        if (checkoutCartItems.map((ite) => ite.variantId).includes(item.variantId)) return item;
        return null;
      })
      .filter((ite) => !!ite) as CartItemModel[];
  }, [cart, checkoutCartItems]);

  return (
    <Page
      paddingX
      mobileView
      title={`${t("redeem failed")}!`}
      titleClassName="capitalize"
      footer={false}
    >
      <Card
        variant="container-gradient"
        className="p-4"
        rounded="lg"
      >
        <Card
          variant="solid-overlay"
          rounded="lg"
          border={false}
          className="px-4 pt-2 pb-4"
        >
          <div className="my-4 flex flex-col">
            <h2 className="text-center text-base font-bold capitalize text-content-primary">{redeemFailedMessage}</h2>
          </div>
          <Button
            className="mt-2 w-full uppercase"
            href={Paths.Private.Home}
          >
            {t("back to homepage")}
          </Button>
        </Card>

        <Card
          className="mt-8 pb-8"
          rounded="lg"
          variant="solid-overlay"
          border={false}
        >
          <CheckoutItemList variants={checkoutItems} />
        </Card>
      </Card>
    </Page>
  );
};

export default RedeemFailedPage;
