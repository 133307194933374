import moment, { Moment } from "moment";
import { MemberMissionApprovalStatus, MissionModel, MissionStatus } from "src/main/types";
import { MissionUIStatus } from "src/main/views/MissionsPage/types";

/**
 * Convert a mission model to friendly UI status.
 */
export const getMissionUIStatus = (mission: MissionModel, offset: number): MissionUIStatus => {
  let status = null;

  if (!!mission.memberMissions && mission.memberMissions[0]) {
    const memberMission = mission.memberMissions[0];
    switch (memberMission.approvalStatus) {
      case MemberMissionApprovalStatus.Approved:
        status = MissionUIStatus.CLAIMABLE;
        break;
      case MemberMissionApprovalStatus.Claimed:
        status = MissionUIStatus.CLAIMED;
        break;
      case MemberMissionApprovalStatus.Pending:
        status = MissionUIStatus.IN_PROGRESS;
        break;
      case MemberMissionApprovalStatus.None:
        status = MissionUIStatus.IN_PROGRESS;
        break;
      default:
        status = MissionUIStatus.IN_PROGRESS;
        break;
    }
  }

  // Only check for expired status if the mission is not claimed.
  if (status !== MissionUIStatus.CLAIMED) {
    // The mission is expired and member has not claimed it.
    if (mission.status === MissionStatus.Expired) return MissionUIStatus.EXPIRED;

    const { expiredDate } = getMissionTimeRange(mission, offset);
    if (!!expiredDate) {
      if (moment().isAfter(expiredDate)) {
        return MissionUIStatus.EXPIRED;
      }
    }
  }

  return status ?? MissionUIStatus.IN_PROGRESS;
};

export const getClaimButtonStatus = (mission: MissionModel): MissionUIStatus => {
  if (!!mission.memberMissions && mission.memberMissions[0]) {
    const memberMission = mission.memberMissions[0];
    switch (memberMission.approvalStatus) {
      case MemberMissionApprovalStatus.Approved:
        return MissionUIStatus.CLAIMABLE;
      case MemberMissionApprovalStatus.Claimed:
        return MissionUIStatus.CLAIMED;
      case MemberMissionApprovalStatus.Pending:
        return MissionUIStatus.IN_PROGRESS;
      case MemberMissionApprovalStatus.None:
        return MissionUIStatus.EXPIRED;
      default:
        return MissionUIStatus.IN_PROGRESS;
    }
  } else {
    return MissionUIStatus.IN_PROGRESS;
  }
};

export const getMissionTimeRange = (mission: MissionModel, offset: number) => {
  let expiredDate: Moment | null = null,
    startDate: Moment | null = null;
  const missionPublishDate = moment.utc(mission.publishDate).utcOffset(offset);
  const missionExpiredDate = mission.expiredDate ? moment.utc(mission.expiredDate).utcOffset(offset) : null;

  // Admin can set the expired date directly.
  if (!!missionExpiredDate) {
    startDate = moment(missionPublishDate);
    expiredDate = moment(missionExpiredDate);
  } else {
    // Or base on mission info.
    // 1. The mission has no required prerequisites mission.
    // - startDate = publishDate
    // - expiredDate = startDate + expiresAfter
    if (!!mission.info.expiresAfter && (!mission.prerequisites || mission.prerequisites.length === 0)) {
      startDate = missionPublishDate;
      expiredDate = moment(startDate).add(mission.info.expiresAfter, "hours");
    }
    // 2. The mission has required prerequisites mission.
    // - startDate = memberMission.createdAt, when the last mission ends, BE will create memberMission.
    // - expiredDate = startDate + expiresAfter
    else if (!!mission.info.expiresAfter && !!mission.prerequisites && mission.prerequisites.length > 0) {
      const lastMissionStartedDate = mission.memberMissions?.[0]?.createdAt;
      if (!!lastMissionStartedDate) {
        startDate = moment.utc(lastMissionStartedDate).utcOffset(offset);
      } else {
        // Fallback to publishing date.
        startDate = missionPublishDate;
      }
      expiredDate = moment(startDate).add(mission.info.expiresAfter, "hours");
    }
  }

  return {
    startDate,
    expiredDate,
    noExpiredDate: !expiredDate,
  };
};

export const getTimerData = (mission: MissionModel, offset: number) => {
  let remainingSeconds = 0;
  let totalSeconds = 0;
  const missionStatus = getMissionUIStatus(mission, offset);
  const { startDate, expiredDate } = getMissionTimeRange(mission, offset);

  // Calculate the total seconds.
  if (!!startDate) {
    if ([MissionUIStatus.IN_PROGRESS, MissionUIStatus.CLAIMABLE].includes(missionStatus) && !!expiredDate) {
      totalSeconds = Math.ceil(moment.duration(expiredDate.diff(startDate)).as("seconds"));
    } else {
      totalSeconds = 0;
    }
  }

  // Calculate the remaining seconds.
  if (missionStatus === MissionUIStatus.EXPIRED || !expiredDate) {
    remainingSeconds = 0;
  } else {
    const today = moment();
    // If expired date is before today, set remaining seconds to 0.
    if (expiredDate.isBefore(today)) {
      remainingSeconds = 0;
    } else {
      // Otherwise, calculate the remaining seconds.
      remainingSeconds = Math.ceil(moment.duration(expiredDate.diff(today)).as("seconds"));
    }
  }

  return {
    expiredDate,
    remainingSeconds,
    totalSeconds,
  };
};
