import { useNavigate } from "react-router-dom";
import { Paths } from "src/main/utils";

interface AnimatedLogoProps {
  brandIcon?: string;
}

export default function AnimatedIcon(props: AnimatedLogoProps) {
  const { brandIcon } = props;
  const navigate = useNavigate();

  const redirectHandler = () => {
    navigate(Paths.Private.Home);
  };

  return (
    <img
      onClick={redirectHandler}
      className="no-tap-highlight h-[30px] cursor-pointer"
      src={brandIcon || "/images/header/vip-horizontal-logo.svg"}
      alt="brand"
    />
  );
}
