import { ReactNode } from "react";
import IcoSpinner from "src/assets/IcoSpinner";

interface AppSkeletonProps {
  children?: ReactNode;
}

const AppSkeleton = (props: AppSkeletonProps) => {
  return (
    <>
      <div className="flex h-screen w-full animate-fade-in items-center justify-center p-4">
        <IcoSpinner className="!mr-0 !h-8 !w-8 opacity-30" />
      </div>
    </>
  );
};

export default AppSkeleton;
