import BigNumber from "bignumber.js";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { authApi } from "src/main/api";
import { useTypedSelector } from "src/main/hooks/index";
import authSlice from "src/main/store/auth/slices";
import { bnOrZero } from "src/main/utils";

const useProfileBalance = () => {
  const { self } = useTypedSelector((state) => state.auth);
  const profileQuery = authApi.useProfileQuery();
  const balance = useMemo(() => {
    return bnOrZero(self?.member?.primaryWallet?.balance).dp(0, BigNumber.ROUND_FLOOR);
  }, [self?.member?.primaryWallet?.balance]);
  const dispatch = useDispatch();

  const [prevBalance, setPrevBalance] = useState(balance);
  const [nextBalance, setNextBalance] = useState(balance);

  useEffect(() => {
    setPrevBalance(nextBalance);
    setNextBalance(balance);
    // To make number run
    // eslint-disable-next-line
  }, [balance]);

  const refetch = useCallback(async () => {
    const profileResult = await profileQuery.refetch();
    if (profileResult?.data) {
      dispatch(authSlice?.actions?.updateProfile(profileResult.data));
    }
  }, [dispatch, profileQuery]);

  return { balance, refetch, prevBalance, nextBalance };
};

export default useProfileBalance;
