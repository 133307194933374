import { ReactNode, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import Slider from "react-slick";
import { siteApi } from "src/main/api";
import { LoadingContainer, Skeleton } from "src/main/components";
import { TOP_PRODUCT_SLUG } from "src/main/contants";
import ProductCard from "src/main/views/GiftsPage/components/ProductCard";

interface TopProductProps {
  children?: ReactNode;
}

const TopProductSection = (props: TopProductProps) => {
  const { t } = useTranslation();
  const [slidesToShow, setSlidesToShow] = useState(1);

  const { data: topProductData, isFetching: isFetchingTopProduct } = siteApi.useListCategoryProductsQuery({
    meta: { limit: 100, offset: 0 },
    slug: TOP_PRODUCT_SLUG,
  });

  const topProducts = useMemo(() => {
    return topProductData?.entries ?? [];
  }, [topProductData]);

  useEffect(() => {
    const updateSlidesToShow = () => {
      if (window.matchMedia("(min-width: 1024px) and (max-width: 1650px)").matches) {
        setSlidesToShow(4);
      } else {
        setSlidesToShow(5);
      }
    };
    updateSlidesToShow();
    window.addEventListener("resize", updateSlidesToShow);
    return () => {
      window.removeEventListener("resize", updateSlidesToShow);
    };
  }, []);

  const sliderSettings = useMemo(
    () => ({
      dots: false,
      infinite: false,
      slidesToShow: slidesToShow,
      slidesToScroll: 1,
      autoplay: false,
      arrows: true,
    }),
    [slidesToShow],
  );

  return (
    <div>
      {/** TOP PRODUCTS for mobile **/}
      <LoadingContainer
        loading={isFetchingTopProduct}
        loader={
          <Skeleton
            component={Skeleton.Box}
            className="ml-3 !h-8 !w-1/2 lg:hidden"
          />
        }
      >
        {(isFetchingTopProduct || !!topProducts?.length) && (
          <div className="px-4 lg:hidden">
            <p className="text-base font-bold capitalize leading-5 text-white">{t("popular items")}</p>
          </div>
        )}
      </LoadingContainer>

      <div className="hide-scroll-bar mt-2 flex flex-grow overflow-x-scroll lg:hidden lg:px-52">
        {!isFetchingTopProduct &&
          topProducts?.map((item) => (
            <div
              className="w-full min-w-[146px] max-w-[calc(50vw-0.5rem)] px-1 first:ml-3 last:mr-3"
              key={item.id}
            >
              <div className="overflow-hidden">
                <ProductCard product={item} />
              </div>
            </div>
          ))}
        {isFetchingTopProduct && (
          <Skeleton
            component={Skeleton.Box}
            count={3}
            className="h-[218px] w-full min-w-[146px] max-w-[calc(50vw-0.5rem)] flex-grow px-1  lg:h-[100px]"
          />
        )}
      </div>

      {/** TOP PRODUCTS for desktop using react-slick **/}
      {(isFetchingTopProduct || !!topProducts?.length) && (
        <div
          className="mt-2 ml-2 hidden rounded-md
                border-4
                border-[rgba(153,182,255,0.2)]
                p-3
                lg:block"
        >
          <div className="hidden p-3 lg:block">
            <p
              className="font-bold capitalize text-white"
              style={{ fontSize: "24px" }}
            >
              {t("popular items")}
            </p>
          </div>
          <Slider {...sliderSettings}>
            {!isFetchingTopProduct &&
              topProducts?.map((item) => (
                <div
                  className="width-container-top-products px-1"
                  key={item.id}
                >
                  <div>
                    <ProductCard product={item} />
                  </div>
                </div>
              ))}
            {isFetchingTopProduct && (
              <Skeleton
                component={Skeleton.Box}
                count={3}
                className="width-container-top-products h-[218px] px-1  lg:h-[100px]"
              />
            )}
          </Slider>
        </div>
      )}
    </div>
  );
};

export default TopProductSection;
