import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { publicApi } from "src/main/api";
import { BasicBody, ContactSupportButton } from "src/main/components";
import { Slogan } from "src/main/views/component";
import InfiniteRotate from "src/main/views/component/AnimatedIcon/AnimationIcon";
import useTypedSelector from "../../hooks/useTypedSelector";

type Props = {};

const Maintenance: React.FC<Props> = () => {
  const { t } = useTranslation();
  const { language } = useTypedSelector((state) => state);

  const { data: serverCentralConfig } = publicApi.useGetCentralServerConfigQuery(undefined, {
    refetchOnMountOrArgChange: true,
  });

  useEffect(() => {
    if (serverCentralConfig?.underMaintenance === false) {
      window.location.href = "/";
    }
  }, [serverCentralConfig]);

  return (
    <BasicBody withFooter={false}>
      <header className=" my-4 flex items-center justify-center text-white">
        <div>
          <InfiniteRotate />
        </div>
        <div>
          <Slogan style={`text-xs px-2 hidden lg:block ${language.lang === 1 ? "w-[140px]" : ""}`} />
        </div>
      </header>

      <div className="mt-8 flex flex-col items-center justify-center gap-x-16 lg:mt-20 lg:flex-row">
        <div className="text-center">
          <img
            className="h-full max-h-[240px] w-full max-w-[240px] lg:max-h-[360px] lg:max-w-[360px]"
            src="/images/body-bg/maintenance-robot.gif"
            alt="maintenance-icon"
          />
        </div>
        <div className="text-center">
          <div className="flex max-w-full flex-col px-8 lg:max-w-[33rem]">
            <div>
              <div className="flex flex-row items-center justify-center gap-x-4">
                <div className="flex-shrink-0">
                  <img
                    src="/images/icon/ico-offline-unplug.svg"
                    width="56"
                    className="mt-1 w-[26px] lg:w-[56px]"
                    alt="offline-icon"
                  />
                </div>
                <div className="mt-6 lg:mt-0">
                  <p className="text-left text-5xl font-bold text-white lg:max-w-[25rem] lg:text-6xl">
                    {t("maintenance_page_title")}
                  </p>
                </div>
              </div>
            </div>
            <div className="mt-6">
              <p className="text-[14px] leading-[1.2] text-white lg:text-[20px]">
                {t("maintenance_page_content_line_1")}
              </p>
              <div className="mt-8 flex flex-col gap-0.5 lg:flex-row">
                <span className="text-[12px] text-white">{t("maintenance_page_content_line_2")}</span>
                <span className="text-[12px] text-white">{t("maintenance_page_content_line_3")}</span>
              </div>
            </div>
            <div className="mt-4">
              <ContactSupportButton />
            </div>
          </div>
        </div>
      </div>
    </BasicBody>
  );
};

export default Maintenance;
