import CountUp from "react-countup";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { IcoGiftGradient, IcoSwap } from "src/assets";
import { Button } from "src/main/components/_inputs/_core";
import { useProfileBalance } from "src/main/hooks";
import { delay, Paths } from "src/main/utils";

export default function TopSection() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { nextBalance, prevBalance } = useProfileBalance();

  return (
    <div className="relative m-auto h-[260px] w-full min-w-[320px] max-w-[640px] overflow-hidden rounded-b-[20px] border-b border-b-[rgba(153,153,153,0)] bg-[radial-gradient(circle,#4E2AC3_0%,#25145D_92.3%)] shadow-[0px_12px_20px_0px_rgba(0,0,0,0.64)] lg:rounded-t-[20px]">
      {/* layer mask */}
      <div className="absolute top-0 left-0 right-0 bottom-0 z-[11] h-full w-full bg-[linear-gradient(180deg,#ffffff00_67.88%,rgba(0,0,0,24%)_100%)]"></div>

      {/* layer big star */}
      <div
        className="absolute top-[50%] left-[50%] z-10 h-full w-[393px] translate-x-[-50%] translate-y-[-55%] opacity-80"
        style={{
          background: "url(/images/icon/big-star.svg) no-repeat center",
        }}
      ></div>
      {/* layer moving bg */}
      <div className="absolute top-[50%] left-[50%] z-[8] aspect-square min-w-[150%] translate-x-[-50%] translate-y-[-50%]">
        <div
          className="h-full w-full animate-[rotate-fw-bw_30s_linear_infinite]"
          style={{
            maskImage: "url(/images/icon/spot-light-circle.svg)",
            maskSize: "cover",
            maskRepeat: "no-repeat",
          }}
        >
          <div
            className="h-full w-full"
            style={{
              background:
                "radial-gradient(43.6% 61.69% at 50% 46.84%, rgba(206, 191, 255, 0.00) 15.91%, rgba(206, 191, 255, 0.27) 58.82%, rgba(206, 191, 255, 0.00) 89.79%)",
            }}
          ></div>
        </div>
      </div>

      {/* lg shining & moving stars */}
      <div
        className="absolute top-7 left-0 h-[131px] w-[114px] -rotate-12 animate-[assemble-spreadout_10s_linear_1s_infinite] opacity-0"
        style={{
          background: "url(/images/icon/star-cluster.png)",
          backgroundSize: "contain",
        }}
      ></div>
      <div
        className="absolute top-7 right-0 h-[131px] w-[114px] animate-[spreadout-assemble_10s_linear_1s_infinite] opacity-80"
        style={{
          background: "url(/images/icon/star-cluster.png)",
          backgroundSize: "contain",
        }}
      ></div>
      <div
        className="absolute top-[50%] left-[50%] h-14 w-14 translate-x-[-90px] translate-y-[-55px] animate-[slow-shining-reverse_10s_linear_infinite] opacity-0"
        style={{
          background: "url(/images/icon/shining1.png)",
        }}
      ></div>
      {/* layer video */}
      <div className="absolute top-[50%] left-[50%] z-10 h-[110%] w-[110%] translate-x-[-50%] translate-y-[-50%] opacity-20">
        <img
          alt=" "
          src="/images/body-bg/bg-colorblobs.svg"
          className="min-h-full min-w-full object-cover"
        />
      </div>

      {/* layer content */}
      <div className="absolute top-0 left-0 right-0 bottom-0 z-[12] mt-[76px] flex h-full w-full flex-col items-center">
        <div className="text-sm font-bold uppercase leading-6 text-[#CCC2ED]">{t("my vip points")}</div>
        <div className="text-[64px] font-semibold uppercase leading-[64px] text-white [font-feature-settings:'tnum']">
          <CountUp
            start={prevBalance.toNumber()}
            end={nextBalance.toNumber()}
            duration={1.5}
            separator=","
          />
        </div>
        <div className="mt-4 flex w-full space-x-2 px-4">
          <Button
            onClick={() => {
              delay().then(() => {
                navigate(Paths.Private.CashBack);
              });
            }}
            className="h-12 uppercase"
            variant="green"
            size="md"
            icon={<IcoSwap />}
          >
            {t("cashBack")}
          </Button>
          <Button
            onClick={() => {
              delay().then(() => {
                navigate(Paths.Private.Gifts);
              });
            }}
            className="h-12 uppercase"
            variant="violet"
            size="md"
            icon={<IcoGiftGradient />}
          >
            {t("redeem gifts")}
          </Button>
        </div>
      </div>
    </div>
  );
}
