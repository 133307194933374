import { clsx } from "clsx";
import { cloneElement, isValidElement, ReactNode } from "react";
import IcoRedDot from "src/assets/IcoRedDot";

interface NavItemProps {
  active: boolean;
  indicator?: "red-indicator" | "chat-indicator" | "claimable-indicator";
  icon: ReactNode;
  label: string;
  onClick: () => void;
}

export default function NavItem({ active, indicator, icon, label, onClick }: NavItemProps) {
  return (
    <div
      className={clsx(
        "no-tap-highlight group relative flex h-[60px] w-16 cursor-pointer select-none flex-col items-center gap-0.5 bg-transparent pt-[8px]",
        {
          "px-[9px]": !active,
        },
      )}
      onClick={onClick}
    >
      {/* layer 0 */}
      <div className="absolute top-0 left-0 z-[0] h-full w-full rounded-full bg-transparent blur-md transition-colors duration-500 group-active:bg-primary-350"></div>
      <div className="absolute top-0 left-0 bottom-0 right-0 z-[-1] h-[70px] w-16"></div>

      {active && (
        <div className="absolute top-0 left-0 bottom-0 right-0 z-[2] flex h-[60px] w-16 flex-col justify-between">
          <div
            className="h-[1.6px] w-16 rounded-lg"
            style={{
              background: `linear-gradient(
                  270deg,
                  rgba(255, 245, 193, 0) 2.63%,
                  #fff5c1 54.54%,
                  rgba(255, 245, 193, 0) 101.32%
                )`,
            }}
          ></div>
          <div
            className="mx-auto h-5 w-[60px]"
            style={{
              background: `radial-gradient(
                50.52% 83.64% at 50.52% 100%,
                #fff5c1 0%,
                rgba(246, 161, 146, 0.3) 61.48%,
                rgba(254, 1, 68, 0) 100%
              )`,
            }}
          ></div>
        </div>
      )}

      {/*  */}
      <span className="relative">
        {indicator === "red-indicator" && (
          <span className="absolute top-[-2px] right-[-4px] z-10 overflow-hidden rounded-full">
            <IcoRedDot />
          </span>
        )}
        {indicator === "chat-indicator" && (
          <>
            <div className="absolute right-[-7px] top-[-5px] z-10 flex h-4 w-4 items-center justify-center rounded-full">
              <div className="h-2 w-2 animate-scale-pulse rounded-full border-[3px] border-transparent bg-[#39FF14] drop-shadow-[0_0_6px_#39FF14] filter" />
            </div>
          </>
        )}
        {indicator === "claimable-indicator" && (
          <div className="absolute left-[-3px] top-[-22px] z-10 flex h-12 w-12 items-center justify-center rounded-full">
            <img
              src="/images/icon/badge-coin-pulse.svg"
              height="32"
              width="32"
              className="absolute mx-auto mt-1 border-transparent"
              alt="points-icon"
            />
          </div>
        )}
        {active && isValidElement(icon)
          ? cloneElement(icon, {
              ...icon.props,
              color: "white",
              filter: "drop-shadow(0px 0px 6px #FFF0D1)",
            })
          : icon}
      </span>
      <p
        className={clsx("z-10 text-xxs capitalize group-active:font-bold", {
          "font-bold": active,
        })}
      >
        {label}
      </p>
    </div>
  );
}
