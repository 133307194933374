import { useCallback, useMemo } from "react";
import { ResourceView, type ResourceViewProps } from "src/main/components";
import { useActions } from "src/main/hooks";
import { BannerAction, BannerModel } from "src/main/models";
import { ResourceType } from "src/main/types";
import { getResourceType } from "src/main/utils";

interface BannerProps extends Omit<ResourceViewProps, "src" | "onClick"> {
  data: BannerModel;
  ignoreActionOnTypes?: ResourceType[];
  onClick?: () => void;
}

function Banner({ data, ignoreActionOnTypes, onClick, ...props }: BannerProps) {
  const actions = useActions();
  const allowAction = useMemo(() => {
    const type = getResourceType(data?.image?.url ?? "");
    if (!type) return true; // allow by default
    return !ignoreActionOnTypes?.includes(type);
  }, [data, ignoreActionOnTypes]);

  const onClickHandler = useCallback(() => {
    if (!allowAction) return;
    switch (data.action) {
      case BannerAction.LINK:
        window.open(data.siteUrl ?? "", "_blank", "noopener noreferrer");
        break;
      case BannerAction.PLAY_DIALOG:
        actions.launchPlaynow({ launch: true });
        break;
      default:
        return null;
    }
    onClick?.();
  }, [allowAction, data.action, data.siteUrl, onClick, actions]);

  return (
    <ResourceView
      onClick={onClickHandler}
      src={data?.image?.url ?? ""}
      className="mx-auto h-auto max-h-[80vh] w-auto max-w-full"
      alt={data?.name}
      videoProps={{
        autoPlay: true,
        loop: true,
      }}
      {...props}
    />
  );
}

export default Banner;
