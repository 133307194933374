import { useTranslation } from "react-i18next";
import { FaArrowLeft } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";

const BackButton: React.FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const goBack = () => {
    navigate(-1);
  };
  return (
    <button
      type="button"
      onClick={goBack}
      className="
                relative m-0 flex
                w-full
                items-center
                justify-center
                rounded-lg
                border-0
                py-4
                px-5
                text-center
                text-base
                font-bold
                capitalize
                leading-[normal]
                text-white"
    >
      <FaArrowLeft className="mx-2 inline-block" />
      {t("back")}
    </button>
  );
};
export default BackButton;
