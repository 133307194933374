import { BrandType, ProfileType, ShippingDetailType } from "src/main/store/types";
import { AuthToken, MemberModel, QueryResult } from "src/main/types";
import { logger, standardizePhoneNumber, transformAccessToken } from "src/main/utils";
import { BuilderProp, require200Status } from "..";
import { LoginStatusModel } from "../types";
export interface CheckOnboardResponse {
  member: Partial<MemberModel>;
}

export interface CheckOnboardRequest {
  phone: string;
  brand?: string;
}

export const checkOnboard = (builder: BuilderProp) => {
  return builder.mutation<CheckOnboardResponse, CheckOnboardRequest>({
    invalidatesTags: ["self"],
    query: ({ phone, brand }) => ({
      url: "/onboard/check",
      method: "POST",
      body: {
        phone: standardizePhoneNumber(phone),
        brand,
      },
      validateStatus: require200Status,
    }),
    transformResponse: (response: QueryResult<CheckOnboardResponse>) => {
      return response.result;
    },
  });
};

interface RequestOnboardRequest {
  phone: string;
  resetPassword?: boolean;
}

export const requestOnboard = (builder: BuilderProp) => {
  return builder.mutation<boolean, RequestOnboardRequest>({
    invalidatesTags: ["self"],
    query: ({ phone, resetPassword }) => ({
      url: "/onboard/request",
      method: "POST",
      body: {
        phone: standardizePhoneNumber(phone),
        resetPassword,
      },
      validateStatus: require200Status,
    }),
    transformResponse: (response: QueryResult<boolean>) => {
      return response.result;
    },
  });
};

export interface VerifyOnboardRequest {
  phone: string;
  otp: string;
}

export const verifyOnboard = (builder: BuilderProp) => {
  return builder.mutation<boolean, VerifyOnboardRequest>({
    invalidatesTags: ["self"],
    query: ({ phone, otp }) => ({
      url: "/onboard/verify",
      method: "POST",
      body: {
        phone: standardizePhoneNumber(phone),
        otp,
      },
      validateStatus: require200Status,
    }),
    transformResponse: (response: QueryResult<boolean>) => {
      return response.result;
    },
  });
};

interface SetupPasswordRequest {
  phone: string;
  otp: string;
  secret: string;
}

interface SetupPasswordResponse {
  model: MemberModel;
}

export const setupPassword = (builder: BuilderProp) => {
  return builder.mutation<SetupPasswordResponse, SetupPasswordRequest>({
    invalidatesTags: ["self"],
    query: ({ phone, otp, secret }) => ({
      url: "/onboard/confirm",
      method: "POST",
      body: {
        phone: standardizePhoneNumber(phone),
        otp,
        secret,
      },
      validateStatus: require200Status,
    }),
    transformResponse: (response: QueryResult<SetupPasswordResponse>) => {
      return response.result;
    },
  });
};

export interface CheckUsernameResponse {
  detail: string | null;
  message: string;
  success: boolean;
  data: LoginStatusModel;
}

export interface CheckUsernameRequest {
  mobile: string;
  token: string;
}

export const checkUsername = (builder: BuilderProp) => {
  return builder.mutation<CheckUsernameResponse, CheckUsernameRequest>({
    invalidatesTags: ["self"],
    query: ({ mobile, token }) => ({
      url: "/check-username",
      method: "POST",
      body: {
        mobile: standardizePhoneNumber(mobile),
        token,
      },
      validateStatus: require200Status,
    }),
    transformResponse: (response: QueryResult<CheckUsernameResponse>) => {
      return response.result;
    },
  });
};

export interface LoginUnlockTimeResponse {
  detail: string | null;
  message: string;
  success: boolean;
  data: any;
}

export interface LoginUnlockTimeRequest {
  capthca_token: string;
  mobile: string;
  password: string;
}

export const loginUnlockTime = (builder: BuilderProp) => {
  return builder.mutation<LoginUnlockTimeResponse, LoginUnlockTimeRequest>({
    invalidatesTags: ["self"],
    query: ({ mobile, capthca_token, password }) => ({
      url: "/auth/login/unlocktime",
      method: "POST",
      body: {
        mobile: standardizePhoneNumber(mobile),
        capthca_token,
        password,
      },
      validateStatus: require200Status,
    }),
    transformResponse: (response: QueryResult<LoginUnlockTimeResponse>) => {
      return response.result;
    },
  });
};

export interface MobileLoginRequest {
  client_id: string;
  client_secret: string;
  grant_type?: string;
}

export const mobileLogin = (builder: BuilderProp) => {
  return builder.mutation<AuthToken, MobileLoginRequest>({
    invalidatesTags: ["self"],
    query: ({ client_id, client_secret, grant_type = "password" }) => ({
      url: "/oauth/access_token",
      method: "POST",
      body: {
        client_id: standardizePhoneNumber(client_id),
        client_secret,
        grant_type,
      },
      validateStatus: require200Status,
    }),
    transformResponse: (response: QueryResult<AuthToken>) => {
      logger._console.log({ response });
      return transformAccessToken(response);
    },
  });
};

export interface RequestOTPRequest {
  mobile: string;
}

export interface RequestOTPResponse {
  success: boolean;
  message: string;
}

export const requestOTP = (builder: BuilderProp) => {
  return builder.mutation<RequestOTPResponse, RequestOTPRequest>({
    invalidatesTags: ["self"],
    query: ({ mobile }) => ({
      url: "/auth/generate-otp",
      method: "POST",
      body: {
        mobile: standardizePhoneNumber(mobile),
      },
      validateStatus: require200Status,
    }),
    transformResponse: (response: QueryResult<RequestOTPResponse>) => {
      return response.result;
    },
  });
};

export interface SubmitOTPRequest {
  mobile: string;
  otp: string;
}

export interface SubmitOTPResponse {
  success: boolean;
  data?: {
    token: string;
    brand: BrandType;
    member_address: string;
    member_address_default: ShippingDetailType;
    token_type: string;
    user_info: ProfileType;
    errors?: {
      message: string;
      unlockDateTime: string;
    };
  };
}

export const submitOTP = (builder: BuilderProp) => {
  return builder.mutation<SubmitOTPResponse, SubmitOTPRequest>({
    invalidatesTags: ["self"],
    query: ({ mobile, otp }) => ({
      url: "/auth/verify-otp",
      method: "POST",
      body: {
        mobile: standardizePhoneNumber(mobile),
        otp,
      },
      validateStatus: require200Status,
    }),
    transformResponse: (response: QueryResult<SubmitOTPResponse>) => {
      return response.result;
    },
  });
};

export interface ChangePasswordRequest {
  new_password: string;
  confirm_new_password: string;
}

export interface ChangePasswordResponse {
  message: string;
  success: boolean;
}
export const changePassword = (builder: BuilderProp) => {
  return builder.mutation<ChangePasswordResponse, ChangePasswordRequest>({
    invalidatesTags: ["self"],
    query: ({ new_password, confirm_new_password }) => ({
      url: "/auth/change-password",
      method: "POST",
      body: {
        new_password,
        confirm_new_password,
      },
      validateStatus: require200Status,
    }),
    transformResponse: (response: QueryResult<ChangePasswordResponse>) => {
      return response.result;
    },
  });
};

interface ClaimTodayBonusResponse {
  amount: string;
}

export const claimTodayBonus = (builder: BuilderProp) => {
  return builder.mutation<ClaimTodayBonusResponse, void>({
    invalidatesTags: ["self"],
    query: () => ({
      url: `/member/daily-bonus`,
      validateStatus: require200Status,
      method: "POST",
    }),
    transformResponse: (response: QueryResult<ClaimTodayBonusResponse>) => {
      return response.result;
    },
  });
};
