export default function IcoProfile(props: any) {
  return (
    <svg
      width="25"
      height="25"
      viewBox="3 2 19 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M8.22535 8.55592C8.22535 10.637 9.91892 12.3306 12 12.3306C14.0811 12.3306 15.7747 10.637 15.7747 8.55592C15.7747 6.47482 14.0811 4.78125 12 4.78125C9.91892 4.78125 8.22535 6.47482 8.22535 8.55592ZM18.7106 20.7188H19.5494V19.8799C19.5494 16.6429 16.9146 14.0082 13.6777 14.0082H10.3224C7.08457 14.0082 4.45068 16.6429 4.45068 19.8799V20.7188H18.7106Z"
        fill="currentColor"
      />
    </svg>
  );
}

IcoProfile.defaultProps = {};
