import { useAutoAnimate } from "@formkit/auto-animate/react";
import { clsx } from "clsx";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { FaTrashCan } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { IcoGiftGradient } from "src/assets";
import { UpdateCartRequest } from "src/main/api/site/Cart";
import { Button, CartItemCard, Checkbox, ConfirmModal, Page } from "src/main/components";
import { useCart } from "src/main/hooks";
import { CartItemModel, ProductVariantModel, UpdateCartProps } from "src/main/types";
import { getErrorMessage, logger, Paths, SimpleMap, waitForTransitionEnd } from "src/main/utils";
import { CartBottomBar, CartVariationDrawer } from "./components";

interface CartProps {
  container?: string;
}

const CartPage: React.FC<CartProps> = ({ container = "AppRoutes" }) => {
  const [itemListParent] = useAutoAnimate();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [cartError, setCartError] = useState<SimpleMap>({} as SimpleMap);
  const [currentItemUpdate, setCurrentItemUpdate] = useState<CartItemModel>();
  const [isVariantDrawerOpen, setIsVariantDrawerOpen] = useState(false);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [itemsToDelete, setItemsToDelete] = useState<CartItemModel[]>([]);

  const {
    cart,
    grandTotal,
    setCheckoutCartItems,
    checkoutCartItems,
    updateCart,
    isUpdatingCart,
    validateCheckoutCartItems,
    isValidating,
  } = useCart();

  const checkAll = useCallback(
    (isSelectAll: boolean) => {
      let newSelected: CartItemModel[] = [];
      if (isSelectAll) {
        newSelected = cart?.data.items ?? [];
      } else {
        newSelected = [];
      }
      setCheckoutCartItems(newSelected);
    },
    [cart?.data.items, setCheckoutCartItems],
  );

  const checkoutCartHandler = useCallback(async () => {
    try {
      const validationResult = await validateCheckoutCartItems();

      if (validationResult.isValid) {
        // close cart drawer
        const closeCartBtn: HTMLElement = document.querySelector('[data-bs-dismiss="offcanvas"]') as HTMLElement;
        if (closeCartBtn) {
          closeCartBtn.click();
        }
        // navigate to checkout page
        navigate(Paths.Private.Checkout);
      }

      if (!validationResult.isValid) {
        if (validationResult.error.message) {
          toast.error(validationResult.error.message);
        }
        setCartError(validationResult.error.items ?? {});
      }
    } catch (error) {
      logger._console.log(error);
    }
  }, [validateCheckoutCartItems, navigate]);

  const selectAll = useMemo(() => {
    let hasAll = true;

    cart?.data.items?.forEach((ordItem) => {
      if (!checkoutCartItems.map((cartItem) => cartItem.variantId).includes(ordItem.variantId)) {
        hasAll = false;
      }
    });

    return hasAll;
  }, [cart, checkoutCartItems]);

  const cartAvailableItems = useMemo(() => {
    return cart?.data?.items?.filter((item) => !!item.variant) ?? [];
  }, [cart]);

  // re-update cart when includes invalid items
  useEffect(() => {
    const cartLength = cart?.data.items?.length ?? 0;
    const cartAvailableLength = cartAvailableItems.length;
    if (cartLength > 0 && cartLength !== cartAvailableLength) {
      let data = {
        ...cart?.data,
        items: cartAvailableItems,
        version: cart?.data.version ?? "1",
      } as unknown as UpdateCartProps;

      updateCart({
        data,
      });
    }
  }, [cartAvailableItems, cart, updateCart]);

  const handleOnClickUpdateVariant = useCallback((item: CartItemModel) => {
    setCurrentItemUpdate(item);
    setIsVariantDrawerOpen(true);
  }, []);

  const handleChangeVariant = useCallback((oldVariant: ProductVariantModel, newVariant: ProductVariantModel) => {}, []);

  const handleCloseDrawer = useCallback(() => {
    setIsVariantDrawerOpen(false);
  }, []);

  const handleDeleteItems = useCallback(async () => {
    const remainingCartItems = [...(cart?.data.items ?? [])].filter(
      (item) => !itemsToDelete.map((cartItem) => cartItem.variantId).includes(item.variantId),
    );

    const updateCartRequest: UpdateCartRequest = {
      data: {
        items: remainingCartItems,
        version: String(cart?.data.version ?? 1),
      },
    };

    try {
      await updateCart(updateCartRequest).unwrap();
      toast.success(t("Removed item from cart!"));
    } catch (error) {
      toast.error(getErrorMessage(error, t));
      logger._console.error(error);
    } finally {
      setIsConfirmModalOpen(false);
    }
  }, [cart?.data.items, cart?.data.version, t, updateCart, itemsToDelete]);

  const handleCancelDelete = useCallback(() => {
    setIsConfirmModalOpen(false);
    waitForTransitionEnd(() => {
      setItemsToDelete([]);
    });
  }, []);

  const handleOpenConfirmDelete = useCallback((itemsToDelete: CartItemModel[]) => {
    setItemsToDelete(itemsToDelete);
    setIsConfirmModalOpen(true);
  }, []);

  return (
    <>
      <Page
        className={clsx(
          `relative flex min-h-[calc(100vh-140px)] w-full flex-col text-white lg:mx-auto lg:mt-20 lg:max-w-md`,
          {
            "lg:mt-0": container === "CartInDrawer",
          },
        )}
        footer={false}
        title={
          container === "CartInDrawer" ? null : (
            <div className={clsx("flex flex-shrink-0 items-center justify-between")}>
              <h1 className="text-lg font-bold capitalize">{t("my cart")}</h1>
              <button
                className={clsx("flex items-center justify-center space-x-1 text-xs text-rose-500", {
                  "opacity-50": checkoutCartItems.length === 0,
                })}
                onClick={() => handleOpenConfirmDelete(checkoutCartItems)}
              >
                <span>
                  {t("Remove")}
                  {checkoutCartItems.length > 0 ? ` (${checkoutCartItems.length})` : ""}
                </span>
                <FaTrashCan className="inline-block h-4 w-4" />
              </button>
            </div>
          )
        }
      >
        {!!cartAvailableItems.length && (
          <div className="mb-20 bg-container-base-gradient p-4 shadow-[1px_1px_0_0_#FFFFFF0A_inset]">
            <div className="mb-3 flex items-center justify-between">
              <div className="flex items-center">
                <Checkbox
                  onChange={() => checkAll(!selectAll)}
                  checked={selectAll}
                  id="checkbox-select-all"
                  variant="filled"
                />
                <label
                  htmlFor="checkbox-select-all"
                  className="ml-2 text-xs capitalize text-white"
                >
                  {t("select all")}
                </label>
              </div>
              {container === "CartInDrawer" && (
                <button
                  className={clsx("flex items-center justify-center space-x-1 text-xs text-rose-500", {
                    "opacity-50": checkoutCartItems.length === 0,
                  })}
                  onClick={() => handleOpenConfirmDelete(checkoutCartItems)}
                >
                  <span>
                    {t("Remove")}
                    {checkoutCartItems.length > 0 ? ` (${checkoutCartItems.length})` : ""}
                  </span>
                  <FaTrashCan className="inline-block h-4 w-4" />
                </button>
              )}
            </div>

            <div
              ref={itemListParent}
              className="space-y-1"
            >
              {cartAvailableItems.map((item) => (
                <CartItemCard
                  key={item.variantId}
                  item={item}
                  selectedVariants={checkoutCartItems}
                  onUpdateSelectedVariants={setCheckoutCartItems}
                  error={cartError[item.variantId]}
                  cartContainer={container}
                  onClickUpdateVariant={handleOnClickUpdateVariant}
                  onDelete={() => handleOpenConfirmDelete([item])}
                />
              ))}
            </div>
          </div>
        )}

        {/*EMPTY STATE*/}
        {!cartAvailableItems.length && (
          <div className="-mb-5 h-full flex-grow bg-container-base-gradient p-4 pt-6 shadow-[1px_1px_0_0_#FFFFFF0A_inset] lg:-mb-10">
            <div className="flex flex-col items-center justify-center p-4">
              <h1 className="mb-1 text-lg font-bold text-content-primary">{t("Your cart is empty")}</h1>
              <p className="mb-3 text-xs text-content-secondary">{t("Start browsing and add items you love")}</p>
              <img
                src="/images/products/cart-empty.png"
                alt="empty-cart"
                className="mb-3 h-auto w-48"
              />
              <div className="px-10 w-full">
              <Button
                className="uppercase"
                onClick={() => navigate(Paths.Private.Gifts)}
                icon={<IcoGiftGradient className="h-4 w-4 drop-shadow-[-0.25px_-0.25px_0_white]" />}
                iconPlacement="right"
              >
                {t("Start shopping now")}
              </Button>
              </div>
            </div>
          </div>
        )}
      </Page>

      {/*BOTTOM BAR*/}
      {cartAvailableItems.length > 0 && (
        <CartBottomBar
          total={grandTotal}
          itemsCount={checkoutCartItems.length}
          onCheckout={checkoutCartHandler}
          loading={isValidating}
        />
      )}

      {/*VARIANT DRAWER*/}
      {!!currentItemUpdate && currentItemUpdate.variant?.product?.slug && (
        <CartVariationDrawer
          show={isVariantDrawerOpen}
          onClose={handleCloseDrawer}
          cartItem={currentItemUpdate}
          productSlug={currentItemUpdate.variant.product.slug}
          onChangeVariant={handleChangeVariant}
        />
      )}

      {/*CONFIRM MODAL*/}
      <ConfirmModal
        title={t("Confirm Delete")}
        message={t("Deleting selected number items from your cart. Proceed?", {
          count: itemsToDelete.length,
        })}
        onConfirm={handleDeleteItems}
        onCancel={handleCancelDelete}
        show={isConfirmModalOpen}
        confirmText={t("Delete")}
        confirmLoading={isUpdatingCart}
      />
    </>
  );
};

export default CartPage;
