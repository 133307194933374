import { Form, Formik, FormikProps } from "formik";
import React, { useCallback, useEffect, useRef, useState } from "react";
import ReactPixel from "react-facebook-pixel";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { IcoLock, IcoPhone } from "src/assets";
import { authApi } from "src/main/api";
import { Button, ContactSupportButton, InputField } from "src/main/components";
import { passwordSchema } from "src/main/components/validation/loginSchema";
import { useAsyncTask } from "src/main/hooks";
import useTypedSelector from "src/main/hooks/useTypedSelector";
import auth from "src/main/store/auth";
import { AuthState } from "src/main/store/auth/slices";
import { MemberStatusType } from "src/main/types";
import { formatDuration, getBaseUrl, getDeployEnv, getStorageItem, logger, Paths } from "src/main/utils";
import { tokenToUri } from "src/main/utils/EncodePathURL";
import { isPasswordExpired } from "src/main/utils/profile";
import LoginTimer from "src/main/views/login/LoginTimer";
import { MyLoginFormValues } from "../../LoginType";

type LoginFormProps = {
  loginByPhone: boolean;
};

type LoginFormType = {
  user_name: string;
  password: string;
  brand: string;
  phone: string;
};

const initialValues: MyLoginFormValues = {
  user_name: "",
  password: "",
  brand: "",
  phone: "",
  token: "",
};

// type ReCaptchaRef = ReCAPTCHA | null;

const currentEnv = getDeployEnv();

const LoginForm = (props: LoginFormProps) => {
  const { loginByPhone } = props;
  const location = useLocation();
  const { t } = useTranslation();
  // const [token, setToken] = useState<string | null>("");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const state = useTypedSelector((state) => state);

  const [checkOnboardStatus] = authApi.useCheckOnboardMutation();

  const [timing, setTiming] = useState(() => getStorageItem("duration", 0, parseInt, "0") as number);
  const timerRef = useRef<undefined | ReturnType<typeof setInterval>>();
  const clonedTiming = useRef(timing);

  const savedLng = state.preference.language || currentEnv.defaultLang;
  const [runLogin, loginLoading] = useAsyncTask("mobile/login");
  const [mobileLogin] = authApi.useMobileLoginMutation();

  const { memberOnboard, mobile } = useTypedSelector<AuthState>((state) => state.auth);
  const [shouldForceResetPassword, setShouldForceResetPassword] = useState(false);

  // call api to check blocked status. if not, remove timer
  useEffect(() => {
    (async () => {
      try {
        if (!mobile) return;
        const { member } = await checkOnboardStatus({ phone: mobile }).unwrap();
        if (member.isBlocked === false) {
          localStorage.removeItem("duration");
          setTiming(0);
          clonedTiming.current = 0;
        }
        // check if force member reset password
        const needReset = isPasswordExpired(member);
        setShouldForceResetPassword(needReset);
      } catch (error) {
        logger._console.log(error);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!memberOnboard) {
      navigate(`/vipstatus/signup`);
    }
    // eslint-disable-next-line
  }, [memberOnboard]);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const brandKeyValue = searchParams.get("brand");
    if (brandKeyValue) {
      initialValues.brand = brandKeyValue;
    }
    // eslint-disable-next-line
  }, []);

  const handleLogin = useCallback(
    (formValue: LoginFormType) => {
      runLogin(async () => {
        const { password } = formValue;
        const logPayload = {
          client_secret: password,
          client_id: mobile,
        };

        try {
          const result = await mobileLogin(logPayload).unwrap();
          // check if force member reset password
          if (!shouldForceResetPassword) {
            toast.success(t("Login successfully!"));
          }
          if (currentEnv.isMain) {
            dispatch(auth.slice.actions.updateAccessToken(result));
            try {
              ReactPixel.trackCustom("LoginSuccess", {
                brand: currentEnv.brand,
              });
            } catch (error) {
              console.error(error);
            }
          } else {
            const key = tokenToUri(result) ?? "";

            const baseUrl = getBaseUrl() + "/";
            const redirect = `${currentEnv.mainUrl}authorize?d=` + baseUrl + `&key=` + key + `&lng=` + savedLng;
            window.location.replace(redirect);
          }
        } catch (error: any) {
          logger._console.log(error);
          if ((error?.data?.error?.message as string)?.includes("account temporary locked")) {
            const duration = (error.data.error?.errors?.remaining as number) ?? 300;
            setTiming(duration);
            localStorage.setItem("duration", "" + duration);
            toast.error(
              t("Too many failed attempts. Try again in {{sec}} sec", {
                sec: duration,
              }),
            );
            return;
          }
          toast.error(t("The mobile number or password is incorrect, please try again."));
        }

        // if (!result) toast.error(result);

        // dispatch(auth.slice.actions.updateAccessToken(result));
      });
    },
    [dispatch, mobile, mobileLogin, runLogin, savedLng, t, shouldForceResetPassword],
  );

  const handleResetUser = () => {
    localStorage.removeItem("duration");
    setTiming(0);
    clonedTiming.current = 0;
    initialValues.brand = "";
    initialValues.user_name = "";
    myRef.current?.resetForm();
    navigate(Paths.Public.Landing);
  };

  useEffect(() => {
    console.log(timing, timerRef.current);
    clonedTiming.current = timing;

    if (timing === 0 && timerRef.current !== undefined) {
      clearInterval(timerRef.current);
      timerRef.current = undefined;
      localStorage.setItem("duration", "0");
    }

    if (timing > 0 && timerRef.current === undefined) {
      timerRef.current = setInterval(() => {
        setTiming((prev) => {
          if (prev > 0) {
            return prev - 1;
          } else {
            return 0;
          }
        });
      }, 1000);
    }
  }, [timing]);

  // when unmount
  useEffect(() => {
    const persistDuration = () => {
      if (timerRef.current) {
        clearInterval(timerRef.current);
        timerRef.current = undefined;
        localStorage.setItem("duration", "" + clonedTiming.current);
      }
    };

    window.addEventListener("beforeunload", persistDuration);

    return () => {
      persistDuration();
      window.removeEventListener("beforeunload", persistDuration);
    };
  }, []);

  const myRef = React.useRef<FormikProps<LoginFormType>>(null);

  return (
    <div className="login-form">
      {timing === 0 && (
        <Formik
          innerRef={myRef}
          initialValues={initialValues}
          validationSchema={passwordSchema}
          onSubmit={(values) => {
            handleLogin(values);
          }}
        >
          {({ errors, touched, setFieldValue, isValid, submitCount, values, handleChange }) => (
            <Form>
              <div className="mb-2 items-center">
                {!!memberOnboard?.phoneVerified && (
                  <InputField
                    value={mobile}
                    onChange={handleChange}
                    name="phone"
                    inputMode="tel"
                    label="phone"
                    error={!!errors.phone && !!touched.phone}
                    helperText={touched.phone ? errors.phone : errors.phone}
                    startIcon={
                      <IcoPhone
                        width="19"
                        height="19"
                      />
                    }
                    disabled={true}
                  />
                )}
              </div>
              <div className="mb-3">
                <InputField
                  value={values.password}
                  onChange={handleChange}
                  name="password"
                  label="password"
                  autoComplete="true"
                  error={!!errors.password && !!touched.password}
                  helperText={touched.password ? errors.password : ""}
                  startIcon={<IcoLock width="16" />}
                  disabled={timing > 0}
                  type="password"
                />
              </div>
              <div className="mb-3">
                <Link
                  to={loginByPhone ? Paths.Public.MobileResetPassword : Paths.Public.UsernameResetPassword}
                  relative="path"
                >
                  <p className="text-sm font-normal capitalize text-white">{t("forgot password")}?</p>
                </Link>
              </div>

              <Button
                loading={loginLoading}
                type={"submit"}
                variant="blue-violet"
                disabled={timing > 0 || !isValid}
                size="xl"
              >
                {t("Login")}
              </Button>

              {/* {(sitekey && !accountLocked) && (
                            <div className='fixed bottom-0 right-0 origin-[0%_0%] scale-[1%] lg:scale-[1%]'>
                                <ReCAPTCHA
                                    ref={recaptchaRef}
                                    key={lng + state.loginFailed + accountLocked}
                                    hl={lng}
                                    theme="dark"
                                    size="invisible"
                                    sitekey={sitekey}
                                    onChange={onChange}
                                />
                            </div>
                        )} */}

              {memberOnboard?.phoneVerified && memberOnboard?.status === MemberStatusType.ACTIVATED && (
                <div className="text-center text-[12px] text-secondary">
                  <div className="pt-4">
                    {t("Not")} {mobile}?
                  </div>
                  <Link
                    to="/vipcheck"
                    relative="path"
                  >
                    <div className="px-4 py-2 text-[13px] font-semibold text-white">{t("Switch Account")}</div>
                  </Link>
                </div>
              )}

              {!!memberOnboard && currentEnv.isMain && (
                <div className="mt-2 w-full text-center">
                  <ContactSupportButton />
                </div>
              )}
            </Form>
          )}
          {/* </form> */}
        </Formik>
      )}
      {timing > 0 && (
        <div className="mt-5">
          <LoginTimer onClick={handleResetUser}>
            {t("try again in")} {formatDuration(timing)}
          </LoginTimer>
        </div>
      )}
    </div>
  );
};

export default LoginForm;
