import { clsx } from "clsx";
import { Moment } from "moment";
import { CSSProperties, MouseEvent, useCallback, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { siteApi } from "src/main/api";
import { localizationValue } from "src/main/components";
import { ONE_DAY_IN_SECONDS } from "src/main/contants";
import { useProfileBalance, useSharedTimerConsumer, useTypedSelector } from "src/main/hooks";
import { RootState } from "src/main/store";
import { MissionModel } from "src/main/types";
import { bnOrZero, getErrorMessage } from "src/main/utils";
import { missionDetailSender } from "src/main/views/MissionsPage/components";
import { MissionClaimButtonRef, MissionUIStatus, MISSION_TIMER } from "src/main/views/MissionsPage/types";
import { getClaimButtonStatus, getMissionUIStatus, getTimerData } from "src/main/views/MissionsPage/utils/defaults";
import { ClaimButton, MissionCardBottom, MissionIcon, ProgressBox, TimerBadge } from "./components";
import "./MissionCard.css";

interface MissionCardProps {
  data: MissionModel;
  style: CSSProperties;
  onTimeout?: () => void;
}

const MissionCard = ({ data, style, onTimeout }: MissionCardProps) => {
  const timezoneOffset = useTypedSelector((state: RootState) => state.auth.utcOffset);

  // Member mission data of the current mission
  const memberMission = useMemo(() => {
    return data.memberMissions?.[0];
  }, [data.memberMissions]);

  // Mission progress data
  const { missionExpiredDate, missionTimerData, canDisplayDrawer, missionStatus, missionProgress } = useMemo(() => {
    let missionProgress = null;
    if (!!data.info.requirement) {
      const complete = memberMission
        ? bnOrZero(memberMission.progressInfo?.days ?? memberMission.progressInfo?.points ?? 0).toNumber()
        : 0;
      const total = bnOrZero(data.info.requirement).toNumber();
      missionProgress = {
        complete,
        total,
        percentage: complete / total,
      };
    }

    const timerData = getTimerData(data, timezoneOffset ?? 0);

    return {
      missionStatus: getMissionUIStatus(data, timezoneOffset ?? 0),
      missionExpiredDate: timerData.expiredDate,
      missionProgress,
      claimButtonStatus: getClaimButtonStatus(data),
      missionTimerData: timerData,
      canDisplayDrawer: data.isCsButtonEnabled || data.isInstructionVisible,
    };
  }, [data, timezoneOffset, memberMission]);

  // Display claim points in the claim button
  const claimPoints = useMemo(() => {
    let claimPointsStr;
    // If users have already claimed the mission, display the claimed points
    if (missionStatus === MissionUIStatus.CLAIMED && !!memberMission) {
      claimPointsStr = memberMission.claimedPoints ?? data.rewardPoints;
    } else {
      claimPointsStr = data.rewardPoints;
    }
    return bnOrZero(claimPointsStr).toNumber();
  }, [data.rewardPoints, memberMission, missionStatus]);

  const { t } = useTranslation();
  const [claimMissionReward, { isLoading: isClaiming }] = siteApi.useClaimMissionRewardMutation();
  const { refetch: refetchBalance } = useProfileBalance();

  const layerElevation = useMemo(() => {
    if (
      [MissionUIStatus.CLAIMABLE, MissionUIStatus.CLAIMED, MissionUIStatus.EXPIRED].includes(missionStatus) ||
      (missionStatus === MissionUIStatus.IN_PROGRESS && !!data.memberMissions)
    ) {
      return {
        topSection: 3,
        bottomSection: 2,
        lightSweepSection: 1,
      };
    }
    return {
      topSection: 3,
      bottomSection: 1,
      lightSweepSection: 2,
    };
  }, [data.memberMissions, missionStatus]);

  const [remainingSeconds, setRemainingSeconds] = useState(missionTimerData.remainingSeconds);

  const [isEnableTimer, setIsEnableTimer] = useState(
    missionTimerData.remainingSeconds > 0 && missionTimerData.remainingSeconds <= 2 * ONE_DAY_IN_SECONDS,
  );

  const handleOnTimerTick = useCallback(
    (v: CustomEventInit<Moment>) => {
      setRemainingSeconds((remaining) => Math.max(remaining - 1, 0));
      if (remainingSeconds <= 0) {
        onTimeout?.();
        setIsEnableTimer(false);
      }
    },
    [onTimeout, remainingSeconds],
  );

  useSharedTimerConsumer({
    eventName: MISSION_TIMER,
    onTick: handleOnTimerTick,
    enabled: isEnableTimer,
  });

  const refClaimButton = useRef<null | MissionClaimButtonRef>(null);

  const onClickClaimReward = useCallback(
    async (e: MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();
      // validate status
      if (missionStatus !== MissionUIStatus.CLAIMABLE) return;

      try {
        await claimMissionReward(data.id).unwrap();
        refClaimButton.current?.count();
        await refetchBalance();
      } catch (error) {
        toast.error(getErrorMessage(error, t));
        console.error(error);
      }
    },
    [missionStatus, claimMissionReward, data.id, refetchBalance, t],
  );

  const handleOpenMissionDrawer = useCallback(() => {
    if (canDisplayDrawer) {
      missionDetailSender(data);
    }
  }, [data, canDisplayDrawer]);

  return (
    <div
      className="no-tap-highlight group relative h-[94px] w-full max-w-lg animate-fade-in cursor-pointer rounded-lg bg-[#34256A] opacity-0 shadow-[0px_8px_64px_0px_#00000080] transition-opacity"
      style={style}
      onClick={handleOpenMissionDrawer}
    >
      {/*TOP SECTION*/}
      <div
        className={clsx(
          "absolute left-0.5 top-0.5 h-[68px] w-[calc(100%-4px)] rounded-t-lg bg-[radial-gradient(74.61%_74.61%_at_25.39%_25.94%,#32265B_0%,#201649_100%)] transition-colors group-hover:bg-[radial-gradient(74.61%_74.61%_at_25.39%_25.94%,#302160_0%,#1b104b_100%)]",
        )}
        style={{
          zIndex: layerElevation.topSection,
        }}
      >
        <div className="flex h-full w-full flex-row items-center gap-2 pr-2.5">
          <div className="h-[68px] min-w-[70px]">
            <MissionIcon
              isExpired={missionStatus === MissionUIStatus.EXPIRED}
              iconSrc={data.image?.url}
            />
          </div>
          <div className="flex min-w-[170px] grow flex-col gap-1.5">
            <div className="truncate text-sm font-bold text-midnight-50">
              {localizationValue("title", data.localisations, data.title)}
            </div>
            {missionExpiredDate && [MissionUIStatus.IN_PROGRESS, MissionUIStatus.CLAIMABLE].includes(missionStatus) ? (
              <TimerBadge
                totalSeconds={missionTimerData.totalSeconds}
                remainingSeconds={remainingSeconds}
              />
            ) : (
              <div className="h-6 w-full"></div>
            )}
          </div>
          <ClaimButton
            ref={refClaimButton}
            loading={isClaiming}
            onClick={onClickClaimReward}
            points={claimPoints}
            variant={missionStatus}
            withFlyingCoins
          />
        </div>
      </div>

      {/*BOTTOM SECTION*/}
      <div
        className={clsx(
          "absolute bottom-0.5 left-0.5 h-[22px] w-[calc(100%-4px)] overflow-hidden rounded-b-lg bg-[#34256A]",
        )}
        style={{
          zIndex: layerElevation.bottomSection,
        }}
      >
        {missionStatus === MissionUIStatus.IN_PROGRESS && !!missionProgress ? (
          <ProgressBox
            value={missionProgress.complete}
            total={missionProgress.total}
            percentage={missionProgress.percentage}
          />
        ) : (
          <MissionCardBottom
            status={missionStatus}
            displayReadMoreLabel={canDisplayDrawer}
          />
        )}
      </div>

      {/*LIGHT SWEEP SECTION*/}
      <div className="absolute top-0 right-0 h-full w-full overflow-hidden rounded-lg">
        <div
          className={clsx("mission-card__light-sweep", {
            "light-sweep--in-progress": missionStatus === MissionUIStatus.IN_PROGRESS,
            "light-sweep--claimable": missionStatus === MissionUIStatus.CLAIMABLE,
            "light-sweep--with-progress": missionStatus === MissionUIStatus.IN_PROGRESS && !!missionProgress,
            "light-sweep--none-progress": missionStatus === MissionUIStatus.IN_PROGRESS && !missionProgress,
            hidden: [MissionUIStatus.CLAIMED, MissionUIStatus.EXPIRED].includes(missionStatus),
          })}
          style={{
            zIndex: layerElevation.lightSweepSection,
          }}
        />
      </div>
    </div>
  );
};

export default MissionCard;
