import { TFunction } from "i18next";
import { abbreviateNumber } from "src/main/services/common.service";
import { CartItemModel, CartModel, OrderItem, ProductModel } from "src/main/types";
import { BN_ZERO, TOP_PRODUCT_SLUG } from "../contants";
import { bnOrZero, currencySymbol, fNumber } from "./number";

export const getProductPrice = (product: ProductModel, variantId?: string | null) => {
  const basePrice = bnOrZero(product.basePrice);
  if (!variantId) return basePrice.toNumber();
  const selectedVariantModel = product?.productVariants?.find((variant) => variant.id === variantId);
  return basePrice.plus(bnOrZero(selectedVariantModel?.price ?? "0")).toNumber();
};

export const getItemCartPrice = (item: CartItemModel) => {
  return bnOrZero(item.variant?.product?.basePrice).plus(bnOrZero(item.variant?.price)).toNumber();
};

export const getItemOrderPrice = (item: OrderItem) => {
  return bnOrZero(item.info?.product?.basePrice).plus(bnOrZero(item.info?.variant?.price)).toNumber();
};

export const getItemCartSubTotalPrice = (item: CartItemModel) => {
  return getItemCartPrice(item) * item.quantity;
};

export const getCartTotalPrice = (cart: CartModel, selectedItems: CartItemModel[]) => {
  const selectedItemIds = selectedItems.map((item) => item.variantId);
  const stagedCartItems = cart.data?.items?.filter((item) => selectedItemIds.includes(item.variantId));
  return (
    stagedCartItems?.reduce((total, item) => {
      return total.plus(getItemCartPrice(item) * item.quantity);
    }, BN_ZERO) ?? BN_ZERO
  ).toNumber();
};

export const getItemOrderName = (item: OrderItem, t: TFunction, localLanguage: string | null) => {
  // check if item is virtual
  if (!!item.info?.variant?.virtualFulfilment) {
    return t("Exchange points item title", {
      points: abbreviateNumber(bnOrZero(item.subtotal).toNumber()),
      amount: fNumber(bnOrZero(item.info?.variant?.virtualFulfilment?.data?.totalCredit)),
      currency: currencySymbol(item.info?.variant?.virtualFulfilment?.data?.currency ?? ""),
    });
  } else {
    if (item.productVariant) {
      const localisation = item.productVariant?.localisations?.find((item) => item.locale.code === localLanguage);
      return localisation?.content?.name ?? item.productVariant?.name ?? "";
    }
    if (item.product) {
      const localisation = item.product?.localisations?.find((item) => item.locale.code === localLanguage);
      return localisation?.content?.name ?? item.product?.name ?? "";
    }
    return item.info?.variant?.name ?? item.info?.product?.name ?? "";
  }
};

export const isTopProduct = (product: ProductModel) => {
  return product.categories?.some((category) => category.slug === TOP_PRODUCT_SLUG);
};
