import { Moment } from "moment";
import { BrandModel } from "../models";
import { SimpleMap } from "../utils";
import { AccountModel } from "./identity";

export interface BaseModel {
  createdAt?: Moment;
  updatedAt?: Moment;
  deletedAt?: Moment | null;
  id: string;
}

export type EditModel<T extends BaseModel> = Partial<Omit<T, "id" | "createdAt" | "updatedAt" | "deletedAt">>;

export interface ShippingDetailModel {
  full_name: string;
  mobile_no: string;
  shipping_address1: string;
  shipping_address2: string;
  shipping_city_code: string;
  shipping_country_code: string;
  shipping_postcode: string;
  shipping_state_code: string;
}

export interface LocaleModel extends BaseModel {
  accountId: string;
  code: string;
  displayName: string;
  account?: AccountModel;
}

export interface LocalisationListItem {
  id: string;
  locale: Locale;
  content: SimpleMap;
}

export interface ProductLocalization extends BaseModel {
  accountId: string;
  ownerType: string;
  ownerId: string;
  localeId: string;
  content: SimpleMap;
  account?: AccountModel;
  locale?: LocaleModel;
}

export interface AssetModel extends BaseModel {
  accountId: string | null;

  viewableType?: string | null;
  viewableId?: string | null;
  type: string | null;
  filename?: string | null;
  mimeType?: string | null;
  contentLength?: number | null;
  url: string | null;
  sourceUrl?: string | null;
  sourceKey?: string;
  host?: string;
  cacheStatus?: string | null;
}

export interface BannerModel extends BaseModel {
  accountId: string;
  name: string;
  action?: string | null;
  category?: string | null;
  sortPriority?: number | null;
  status?: string;
  siteUrl?: string | null;
  account?: AccountModel;
  brand?: BrandModel;
  image?: AssetModel;
}

export enum RewardType {
  MONTHLY = "monthly",
  WEEKLY = "weekly",
  DAILY = "daily",
  VIP = "vip",
}

export interface RewardInfo {
  balance: string;
  lastClaimedAt: Moment | null;
  currentPeriodResetDate: Moment | null;
  nextPeriodResetDate: Moment | null;
}

export enum MissionTaskType {
  FirstRedeem = "first-redeem",
  PwaDownload = "pwa-download",
  NotiEnabled = "noti-enabled",
  DailyClaim = "daily-claim",
  PointRedeemed = "point-redeemed",
  LifetimeRedeemed = "lifetime-redeemed",
}

export enum MissionApprovalType {
  Manual = "manual",
  Auto = "auto",
}

export interface MissionInfo {
  approvalType: MissionApprovalType;
  expiresAfter?: number;
  taskType: MissionTaskType;
  requirement: any;
}

export enum MissionStatus {
  Expired = "expired",
  NotStarted = "not-started",
  OnGoing = "ongoing",
}

export interface MemberMissionProgressInfo {
  points?: string;
  days?: number;
}

export enum MemberMissionApprovalStatus {
  None = "none",
  Pending = "pending",
  Approved = "approved",
  Claimed = "claimed",
}

export interface MemberMissionModel extends BaseModel {
  memberId: string;
  missionId: string;
  progressInfo?: MemberMissionProgressInfo;
  approvalStatus: MemberMissionApprovalStatus;
  processedAt: string | null;
  processorId: string;
  claimedAt: string | null;
  remarks: string;
  mission?: MissionModel | null;
  claimedPoints: string | null;
}

export interface MissionModel extends BaseModel {
  id: string;
  accountId: string;
  title: string;
  info: MissionInfo;
  status: MissionStatus;
  rewardPoints: string;
  publishDate: string;
  expiredDate: string;
  hideExpired: boolean;
  autoStart: boolean;
  memberMissions: MemberMissionModel[] | null;
  image?: AssetModel;
  localisations: LocalisationListItem[];
  isVisible: boolean;
  isCsButtonEnabled: boolean;
  sortPriority: number | null;
  prerequisites: string[];
  instructions: string;
  isInstructionVisible?: boolean;
}
