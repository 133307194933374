import { clsx } from "clsx";
import React, { useMemo } from "react";
import { ResourceType } from "src/main/types";
import { getResourceType } from "src/main/utils";

type VideoProps = React.DetailedHTMLProps<React.VideoHTMLAttributes<HTMLVideoElement>, HTMLVideoElement>;
type ImgProps = React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>;
type DivProps = React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>;
export interface ResourceViewProps extends DivProps {
  src: string;
  className?: string;
  wrapperClassName?: {
    [typeName in ResourceType]?: string;
  };
  alt?: string;
  videoProps?: VideoProps;
  imgProps?: ImgProps;
}
function ResourceView({ src, className, wrapperClassName, videoProps, imgProps, alt, ...divProps }: ResourceViewProps) {
  const srcType = useMemo(() => getResourceType(src), [src]);

  const content = useMemo(() => {
    if (!srcType) return null;
    switch (srcType) {
      case ResourceType.Image:
        return (
          <img
            src={src}
            className={clsx(className)}
            alt={alt ?? "resource"}
            {...imgProps}
          />
        );
      case ResourceType.Video:
        return (
          <video
            src={src}
            className={clsx(className)}
            {...videoProps}
          >
            Your browser does not support the video tag.
          </video>
        );
      default:
        return <></>;
    }
  }, [src, alt, className, srcType, videoProps, imgProps]);

  return (
    <div
      className={clsx("h-fit w-fit", wrapperClassName?.[srcType!])}
      {...divProps}
    >
      {content}
    </div>
  );
}

export default ResourceView;
