import { BuilderProp } from "src/main/api/site";
import { ExchangeTargetModel } from "src/main/models";
import { OrderHistoryModel, QueryMeta, QueryResult, QueryResultEntries, QueryResultEntry } from "src/main/types";
import { parseMeta, require200Status } from "src/main/utils";

export interface ListExchangePointTargetsResponse extends QueryResultEntries<ExchangeTargetModel[]> {}

export interface ListExchangePointTargetsRequest extends QueryMeta {}

export const listExchangePointTargets = (builder: BuilderProp) => {
  return builder.query<ListExchangePointTargetsResponse, ListExchangePointTargetsRequest>({
    providesTags: ["exchangePoint"],
    query: ({ meta }) => ({
      url: `/member/exchange/target/list${parseMeta(meta)}`,
      validateStatus: require200Status,
    }),
    transformResponse: (response: QueryResult<ListExchangePointTargetsResponse>) => {
      return response.result;
    },
  });
};

export interface ListExchangePointVariantsResponse {
  targets: {
    [id: string]: ExchangeTargetModel;
  };
}

export interface ListExchangePointVariantsRequest extends QueryMeta {}

export const listExchangePointVariants = (builder: BuilderProp) => {
  return builder.query<ListExchangePointVariantsResponse, ListExchangePointVariantsRequest>({
    providesTags: ["exchangePoint"],
    query: ({ meta }) => ({
      url: `/member/exchange/target/variant${parseMeta(meta)}`,
      validateStatus: require200Status,
    }),
    transformResponse: (response: QueryResult<ListExchangePointVariantsResponse>) => {
      return response.result;
    },
  });
};

export interface SubmitExchangePointRequest {
  brandId: string;
  identifier: string;
  variantId: string;
  quantity: number;
}

export interface SubmitExchangePointResponse extends QueryResultEntry<OrderHistoryModel> {}

export const submitExchangePoint = (builder: BuilderProp) => {
  return builder.mutation<SubmitExchangePointResponse, SubmitExchangePointRequest>({
    query: (body) => ({
      url: "/member/point/exchange",
      method: "POST",
      body,
      validateStatus: require200Status,
    }),
    invalidatesTags: ["cart"],
    transformResponse: (response: QueryResult<SubmitExchangePointResponse>) => {
      return response.result;
    },
  });
};
