// This optional code is used to register a service worker.
// register() is not called by default.

import {
  PUSH_NOTIFICATION_STORAGE_KEY,
  PUSH_NOTIFICATION_MEMBER_STORAGE_KEY,
} from "./main/contants";
import { getDeployEnv } from "./main/utils";

// This lets the app load faster on subsequent visits in production, and gives
// it offline capabilities. However, it also means that developers (and users)
// will only see deployed updates on subsequent visits to a page, after all the
// existing tabs open on the page have been closed, since previously cached
// resources are updated in the background.

// To learn more about the benefits of this model and instructions on how to
// opt-in, read https://cra.link/PWA

const isLocalhost = Boolean(
  window.location.hostname === "localhost" ||
    // [::1] is the IPv6 localhost address.
    window.location.hostname === "[::1]" ||
    // 127.0.0.0/8 are considered localhost for IPv4.
    window.location.hostname.match(/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/),
);

type Config = {
  onSuccess?: (registration: ServiceWorkerRegistration) => void;
  onUpdate?: (registration: ServiceWorkerRegistration) => void;
};

type Version = {
  version: number;
}

const PUSH_NOTIFICATION_ENDPOINT = process.env.REACT_APP_PUSH_NOTIFICATION_ENDPOINT;
const TEST_NOTIFICATION_PARAM = process.env.REACT_APP_TEST_NOTIFICATION_PARAM;
const deployEnv = getDeployEnv();
const accountId = deployEnv.accountId

export function register(config?: Config) {
  if (process.env.NODE_ENV === "production" && "serviceWorker" in navigator) {
    // The URL constructor is available in all browsers that support SW.
    const publicUrl = new URL(process.env.PUBLIC_URL, window.location.href);
    if (publicUrl.origin !== window.location.origin) {
      // Our service worker won't work if PUBLIC_URL is on a different origin
      // from what our page is served on. This might happen if a CDN is used to
      // serve assets; see https://github.com/facebook/create-react-app/issues/2374
      return;
    }

    window.addEventListener("load", () => {
      const swUrl = `${process.env.PUBLIC_URL}/service-worker.js`;

      if (isLocalhost) {
        // This is running on localhost. Let's check if a service worker still exists or not.
        checkValidServiceWorker(swUrl, config);

        // Add some additional logging to localhost, pointing developers to the
        // service worker/PWA documentation.
        navigator.serviceWorker.ready.then(() => {
          console.log(
            "This web app is being served cache-first by a service " +
              "worker. To learn more, visit https://cra.link/PWA",
          );
        });
      } else {
        // Is not localhost. Just register service worker
        registerValidSW(swUrl, config);
      }
    });
  }
}

async function subscribe(serviceWorkerReg: ServiceWorkerRegistration) {
  if (!PUSH_NOTIFICATION_ENDPOINT) return;

  let version: string = "";
  try {
    const versionJson = await fetch(process.env.PUBLIC_URL + "/version.json").then(res => res.json()) as Version;
    version = (versionJson.version || "").toString();
    if (!version) throw new Error("Invalid version");

    const registeredAtVersion = sessionStorage.getItem(PUSH_NOTIFICATION_STORAGE_KEY);

    if (version && registeredAtVersion === version) {
      console.log("Already registered SW", version, registeredAtVersion)
      return
    } else {
      console.log("SW version mismatch", version, registeredAtVersion)
    };
  } catch (error) {
    console.log("Error on fetching version:", error);
  }

  const notificationInfoResponse = await fetch(PUSH_NOTIFICATION_ENDPOINT + "/notification/info", { method: "GET" });

  const {
    result: {
      subscribeEndpoint,
      vapidKey,
      // skip
    },
  } = await notificationInfoResponse.json();

  // if (skip) return;

  const params = new URLSearchParams(window.location.search);
  const notifClient = params.get(TEST_NOTIFICATION_PARAM ?? "");

  if (!!subscribeEndpoint) {
    let subscription = await serviceWorkerReg.pushManager.getSubscription();

    //
    if (!subscription && !!vapidKey) {
      let unsub = false;
      try {
        subscription = await serviceWorkerReg.pushManager.subscribe({
          userVisibleOnly: true,
          applicationServerKey: vapidKey,
        });
      } catch (error: any) {
        console.log(error);
        if ("message" in error) {
          if (error.message.startsWith("Registration failed - A subscription with a different applicationServerKey")) {
            unsub = true;
          }
        }
      }

      if (unsub && !!subscription) {
        await subscription.unsubscribe();
        // subscription = await serviceWorkerReg.pushManager.subscribe({
        //   userVisibleOnly: true,
        //   applicationServerKey: vapidKey,
        // });
      }
    }
    if (!!subscription) {
      const domain = window.location.hostname ?? "";
      try {
        await fetch(PUSH_NOTIFICATION_ENDPOINT + subscribeEndpoint, {
          method: "POST",
          body: JSON.stringify({
            subscription,
            client: notifClient ?? domain,
            accountId,
            memberId: sessionStorage.getItem(PUSH_NOTIFICATION_MEMBER_STORAGE_KEY) ?? undefined,
          }),
          headers: {
            "Content-Type": "application/json",
          },
        });

        sessionStorage.setItem(PUSH_NOTIFICATION_STORAGE_KEY, version);
      } catch (error) {
        console.log("Subscribe Error", error);
      }
    }
  }
}

function registerValidSW(swUrl: string, config?: Config) {
  navigator.serviceWorker
    .register(swUrl)
    .then((registration) => {
      registration.onupdatefound = () => {
        const installingWorker = registration.installing;
        if (installingWorker == null) {
          return;
        }
        installingWorker.onstatechange = () => {
          if (installingWorker.state === "installed") {
            if (navigator.serviceWorker.controller) {
              // At this point, the updated precached content has been fetched,
              // but the previous service worker will still serve the older
              // content until all client tabs are closed.
              console.log(
                "New content is available and will be used when all " +
                  "tabs for this page are closed. See https://cra.link/PWA.",
              );

              // Execute callback
              if (config && config.onUpdate) {
                config.onUpdate(registration);
              } else {
                // reload to get new assets
                registration.update().then(() => {
                  window.location.reload();
                });
              }
            } else {
              // At this point, everything has been precached.
              // It's the perfect time to display a
              // "Content is cached for offline use." message.
              console.log("Content is cached for offline use.");

              // Execute callback
              if (config && config.onSuccess) {
                config.onSuccess(registration);
              }
            }
          }
        };
      };
      return navigator.serviceWorker.ready;
    })
    .then(async (registration) => {
      await subscribe(registration);
    })
    .catch((error) => {
      console.error("Error during service worker registration:", error);
    });
}

function checkValidServiceWorker(swUrl: string, config?: Config) {
  // Check if the service worker can be found. If it can't reload the page.
  fetch(swUrl, {
    headers: { "Service-Worker": "script" },
  })
    .then((response) => {
      // Ensure service worker exists, and that we really are getting a JS file.
      const contentType = response.headers.get("content-type");
      if (response.status === 404 || (contentType != null && contentType.indexOf("javascript") === -1)) {
        // No service worker found. Probably a different app. Reload the page.
        navigator.serviceWorker.ready.then((registration) => {
          registration.unregister().then(() => {
            sessionStorage.clear();
            window.location.reload();
          });
        });
      } else {
        // Service worker found. Proceed as normal.
        registerValidSW(swUrl, config);
      }
    })
    .catch(() => {
      console.log("No internet connection found. App is running in offline mode.");
    });
}

export function unregister() {
  if ("serviceWorker" in navigator) {
    navigator.serviceWorker.ready
      .then((registration) => {
        registration.unregister().then(() => sessionStorage.clear());
      })
      .catch((error) => {
        console.error(error.message);
      });
  }
}
