import { Disclosure, Transition } from "@headlessui/react";
import { clsx } from "clsx";
import { t } from "i18next";
import { FaCircleMinus, FaCirclePlus } from "react-icons/fa6";
import { LocalizationDisplay } from "src/main/components";
import { TRANSITION_IN_FADE, TRANSITION_OUT_FADE } from "src/main/contants";
import { LocalisationListItem } from "src/main/types";

export interface ExpandableCardProps {
  index?: number;
  title: string;
  content?: string | null;
  localisations?: LocalisationListItem[];
  localeField?: string;
  showByDefault?: boolean;
  contentStyles?: string;
  displayHtml?: boolean;
}

const ExpandableCard = ({
  index,
  title,
  content,
  localisations,
  localeField,
  showByDefault = false,
  contentStyles,
  displayHtml = false,
}: ExpandableCardProps) => {
  return (
    <Disclosure
      defaultOpen={showByDefault}
      as="div"
      className="mt-3 h-full rounded-lg border-0 bg-[radial-gradient(84.58%_106.92%_at_83.69%_0%,#26125C_0%,#170B37_100%)] text-white [box-shadow:1px_1px_0px_0px_rgba(137,118,255,0.24)_inset]"
    >
      {({ open }) => (
        <>
          <Disclosure.Button className="relative flex w-full items-center justify-between rounded-lg border-0 p-4 text-left text-[14px] font-bold capitalize leading-[18px] text-white md:text-2xl lg:text-sm ">
            {t(title)}

            {open ? (
              <FaCircleMinus
                className="h-4 w-4"
                color="#B9A3F1"
              />
            ) : (
              <FaCirclePlus
                className="h-4 w-4"
                color="#B9A3F1"
              />
            )}
          </Disclosure.Button>

          <Disclosure.Panel
            as="div"
            className={clsx("lg:scrollbar-primary px-8 pt-1 pb-6 lg:h-full lg:overflow-auto", contentStyles)}
          >
            <Transition
              show={open}
              {...TRANSITION_IN_FADE}
              {...TRANSITION_OUT_FADE}
            >
              <LocalizationDisplay
                field={localeField || "info"}
                localisations={localisations}
                defaultValue={content}
                displayHtml
              />
            </Transition>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
};

export default ExpandableCard;
