import { BaseQueryFn, FetchArgs, FetchBaseQueryError, FetchBaseQueryMeta } from "@reduxjs/toolkit/dist/query";
import { EndpointBuilder } from "@reduxjs/toolkit/dist/query/endpointDefinitions";
import { createApi } from "@reduxjs/toolkit/dist/query/react";
import {
  listExchangePointTargets,
  listExchangePointVariants,
  submitExchangePoint,
} from "src/main/api/site/ExchangePoint";
import { claimMissionReward, listMission, updateMemberMissionInfo } from "src/main/api/site/Mission";
import { queryWithRefresh } from "../base";
import { listBanners } from "./Banner";
import { getBrandInfo, listBrands } from "./Brand";
import {
  checkoutCart,
  deleteCart,
  getCartDetail,
  getOrderHistory,
  getOrderHistoryDetail,
  getPointHistory,
  updateCart,
} from "./Cart";
import { getCategories, getProductDetail, listCategoryProducts, listProducts } from "./Product";
import {
  changePassword,
  claimReward,
  createShippingAddress,
  deleteShippingAddress,
  getProfilePoints,
  getRewardStatus,
  getShippingAddress,
  listShippingAddress,
  updateShippingAddress,
} from "./User";

export const require200Status = (response: Response) => response.status === 200;

export type SiteTagType = "brand" | "product" | "cart" | "user" | "banner" | "exchangePoint" | "mission";
export type BuilderProp = EndpointBuilder<
  BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError, {}, FetchBaseQueryMeta>,
  SiteTagType,
  "siteApi"
>;

export const accountTags: SiteTagType[] = ["brand", "product", "cart", "user", "banner", "exchangePoint", "mission"];

const siteApi = createApi({
  reducerPath: "siteApi",
  baseQuery: queryWithRefresh,
  tagTypes: accountTags,
  endpoints: (builder) => ({
    getBrandInfo: getBrandInfo(builder),
    listBrands: listBrands(builder),

    listProducts: listProducts(builder),
    getCategories: getCategories(builder),
    listCategoryProducts: listCategoryProducts(builder),
    getProductDetail: getProductDetail(builder),

    getProfilePoints: getProfilePoints(builder),
    createShippingAddress: createShippingAddress(builder),
    listShippingAddress: listShippingAddress(builder),
    getShippingAddress: getShippingAddress(builder),
    updateShippingAddress: updateShippingAddress(builder),
    deleteShippingAddress: deleteShippingAddress(builder),

    updateCart: updateCart(builder),
    getCartDetail: getCartDetail(builder),
    deleteCart: deleteCart(builder),
    checkoutCart: checkoutCart(builder),
    getPointHistory: getPointHistory(builder),
    getOrderHistory: getOrderHistory(builder),
    getOrderHistoryDetail: getOrderHistoryDetail(builder),
    changePassword: changePassword(builder),

    listBanners: listBanners(builder),

    // Rewards
    listExchangePointTargets: listExchangePointTargets(builder),
    listExchangePointVariants: listExchangePointVariants(builder),
    submitExchangePoint: submitExchangePoint(builder),
    getRewardStatus: getRewardStatus(builder),
    claimReward: claimReward(builder),

    // Missions
    listMission: listMission(builder),
    updateMemberMissionInfo: updateMemberMissionInfo(builder),
    claimMissionReward: claimMissionReward(builder),
  }),
});

export default siteApi;
