import { Skeleton } from "src/main/components";

interface SkeletonLoaderProps {}

const SkeletonLoader = (props: SkeletonLoaderProps) => {
  return (
    <Skeleton
      component={Skeleton.Box}
      className="!h-[94px] w-full max-w-lg px-4"
      count={5}
    />
  );
};

export default SkeletonLoader;
