import { clsx } from "clsx";
import { PropsWithChildren, useCallback, useLayoutEffect, useRef } from "react";
import { useScrollPositionDetector } from "src/main/hooks/useScrollPositionDetector";

interface ScrollableContainerProps extends PropsWithChildren {
  className?: string;
  contentClassName?: string;
  delayScrollCheck?: number;
}

/**
 * A scrollable container with shadows at top and bottom
 */
const ScrollBox = ({ className, contentClassName, children, delayScrollCheck = 0 }: ScrollableContainerProps) => {
  const refContainer = useRef<HTMLDivElement>(null);

  const { checkPosition, isScrollbarAtBottom, isScrollbarAtTop } = useScrollPositionDetector(refContainer);

  useLayoutEffect(() => {
    setTimeout(() => {
      checkPosition();
    }, delayScrollCheck);
  }, [checkPosition, delayScrollCheck, children]);

  const handleScroll = useCallback(() => {
    checkPosition();
  }, [checkPosition]);

  return (
    <div className={clsx("relative overflow-y-hidden", className)}>
      <div
        className={clsx("scrollbar-primary overflow-y-auto", contentClassName)}
        ref={refContainer}
        onScroll={handleScroll}
      >
        {children}
      </div>

      {/*TOP SHADOW*/}
      {!isScrollbarAtTop && (
        <div className="absolute top-0 left-0 z-[65] h-0 w-full shadow-[0_0_20px_8px_grey] shadow-primary-700" />
      )}

      {/*BOTTOM SHADOW*/}
      {!isScrollbarAtBottom && (
        <div className="absolute bottom-0 left-0 z-[65] h-0 w-full shadow-[0_0_20px_8px_grey] shadow-primary-700" />
      )}
    </div>
  );
};

export default ScrollBox;
