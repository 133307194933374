import moment from "moment";
import React, { PropsWithChildren, useEffect, useState } from "react";
import { BackButton, CopyRight } from "src/main/components";
import LanguangeSwitch from "src/main/components/languageselector/Languageselector";
import { DeployCountry } from "src/main/types";
import { getDeployEnv } from "src/main/utils";

interface Props extends PropsWithChildren {
  backButton?: boolean;
  lineSupportButton?: boolean;
}

const PUBLIC_URL = process.env.PUBLIC_URL;
const LoginFormBox: React.FC<Props> = (props) => {
  const { children, backButton = false } = props;

  const currentEnv = getDeployEnv();

  const [versionNumber, setVersionNumber] = useState<string | undefined>();

  const getVersionNumber = async () => {
    try {
      const response = await fetch(PUBLIC_URL + "/version.json");
      const { version } = await response.json();
      const versionNum = moment(parseInt(version + "000")).format("YYYYMMDDHHmmss");
      setVersionNumber(versionNum);
    } catch (error) {}
  };

  useEffect(() => {
    getVersionNumber();
    return () => {};
  }, []);

  return (
    <div className="w-[360px]">
      {/* <ToastContainer
        enableMultiContainer
        closeOnClick
        icon={false}
        autoClose={5000}
        hideProgressBar
      /> */}
      <div className="bg-lvl-1-card-gradient m-auto flex w-[320px] flex-col gap-6 rounded-xl border-[1px] border-token-border px-5 pt-2.5 [backdrop-filter:blur(5px)] lg:w-auto lg:px-8">
        <div className={`flex ${backButton || !!versionNumber ? "justify-between" : "justify-end"}`}>
          {backButton && <BackButton />}
          {versionNumber && !backButton && (
            <div className="text-xxs font-bold text-secondary text-white opacity-30">V{versionNumber}</div>
          )}
          {currentEnv.deployCountry === DeployCountry.TH && <LanguangeSwitch />}
        </div>
        {children}

        <div className="mt-12 mb-10 grid place-items-center lg:mb-20">
          <CopyRight />
        </div>
      </div>
    </div>
  );
};

export default LoginFormBox;
