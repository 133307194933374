import { useLocation } from "react-router-dom";
import { PathsToExcludeLoader } from "src/main/utils/paths";
import useTypedSelector from "../../hooks/useTypedSelector";

const Spinner: React.FC = () => {
  const state = useTypedSelector((state) => state);
  const location = useLocation();
  return (
    <>
      {state.isLoading && !PathsToExcludeLoader.includes(location.pathname) ? (
        <div className="fixed top-0 left-0 right-0 bottom-0 z-50 flex h-screen w-full flex-col items-center justify-center overflow-hidden bg-gray-700 opacity-75">
          <div className="loader mb-4 h-12 w-12 rounded-full border-4 border-t-4 border-gray-200 ease-linear"></div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default Spinner;
