import React, { useState } from "react";

interface ImageComponentProps extends React.PropsWithChildren {
  path: string;
  aspectRatio?: number;
}

const ImageComponent: React.FC<ImageComponentProps> = (props) => {
  const { path, children, aspectRatio = 1.78 } = props;
  const [isLoaded, setIsLoaded] = useState(false);

  const handleImageLoad = () => {
    setIsLoaded(true);
  };

  const paddingBottom = `${(1 / aspectRatio) * 100}%`;

  return (
    <div
      className="relative h-0 w-full"
      style={{ paddingBottom: paddingBottom }}
    >
      {!isLoaded && (
        <div className="absolute inset-0 flex items-center justify-center">
          {/* Placeholder content */}
          <span className="text-gray-600">Loading...</span>
        </div>
      )}
      <img
        className={`absolute inset-0 h-full w-full object-cover ${isLoaded ? "" : "hidden"}`}
        src={path}
        alt=""
        onLoad={handleImageLoad}
      />
      {children}
    </div>
  );
};

export default ImageComponent;
