import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import useTypedSelector from "src/main/hooks/useTypedSelector";
import preference from "src/main/store/preference";
import { Language } from "src/main/types";

interface LanguageSelectorProps {
  label?: boolean;
}

const LanguageSelector: React.FC<LanguageSelectorProps> = (props) => {
  const { label = false } = props;
  const { i18n } = useTranslation();
  const dispatch = useDispatch();
  const lang = useTypedSelector((state) => state.preference.language);

  const changeLanguageHandler = () => {
    let newLang = lang === Language.EN ? Language.TH : Language.EN;
    i18n.changeLanguage(newLang);
    dispatch(preference.slice.actions.setLanguage(newLang));
  };

  return (
    <div className="flex items-center gap-2 py-2 text-white">
      <img
        onClick={changeLanguageHandler}
        className="h-[18px] cursor-pointer"
        src={lang === Language.EN ? "/images/icon/flag-en.svg" : "/images/icon/flag-th.svg"}
        alt="flag_th"
      />
      {label && (lang === Language.EN ? "English" : "ภาษาไทย")}
    </div>
  );
};

export default LanguageSelector;
