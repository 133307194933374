import BigNumber from "bignumber.js";
import { clsx } from "clsx";
import moment from "moment";
import {
  CSSProperties,
  HTMLAttributes,
  PropsWithChildren,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { bnOrZero } from "src/main/utils";

type DisplayMode = "seconds" | "days";

const DAY_IN_SECONDS = 24 * 60 * 60;

interface TimerBarProps extends PropsWithChildren, HTMLAttributes<HTMLDivElement> {
  remainingSeconds: number;
  totalSeconds: number;
  onComplete?: () => void;
}

const TimerBar = ({ remainingSeconds, totalSeconds, onComplete, ...props }: TimerBarProps) => {
  const { t } = useTranslation();
  const timer = useRef<NodeJS.Timeout>();
  const [currentRemainingSeconds, setCurrentRemainingSeconds] = useState(remainingSeconds);

  useEffect(() => {
    setCurrentRemainingSeconds(remainingSeconds);
  }, [totalSeconds, remainingSeconds]);

  const onTick = useCallback(() => {
    setCurrentRemainingSeconds((prev) => {
      if (prev <= 0) {
        clearInterval(timer.current);
        onComplete?.();
        return 0;
      }
      return prev - 1;
    });
  }, [onComplete]);

  useEffect(() => {
    // sync with machine clock
    const clock = setTimeout(() => {
      timer.current = setInterval(onTick, 1000);
    }, 1000 - new Date().getMilliseconds());
    return () => {
      clearInterval(timer.current);
      clearTimeout(clock);
    };
  }, [onTick, remainingSeconds]);

  const progressPercent = useMemo(() => {
    const passedSeconds = totalSeconds - currentRemainingSeconds;
    const percent = bnOrZero(passedSeconds).div(totalSeconds).times(100).dp(0, BigNumber.ROUND_UP);
    return percent.toNumber();
  }, [currentRemainingSeconds, totalSeconds]);

  const displayMode: DisplayMode = useMemo(() => {
    return currentRemainingSeconds >= DAY_IN_SECONDS ? "days" : "seconds";
  }, [currentRemainingSeconds]);

  const formattedTime = useMemo(() => {
    if (displayMode === "days") {
      const days = bnOrZero(currentRemainingSeconds).div(DAY_IN_SECONDS).dp(0, BigNumber.ROUND_DOWN).toNumber();
      return t("day", { count: days });
    }
    if (displayMode === "seconds") {
      return moment.utc(currentRemainingSeconds * 1000).format("HH:mm:ss");
    }
  }, [t, currentRemainingSeconds, displayMode]);

  const thumbStyles = useMemo(() => {
    let styles: CSSProperties = {
      left: `${progressPercent}%`,
      backgroundColor: "#2B834E",
      boxShadow: `
        0px 4px 4px 0px #00000040,
        0px 0px 20px 0px #81D2338F,
        0px 4px 12px 0px #FFFFFF33 inset,
        0px 0px 4px 0px #79E22540
      `,
    };
    if (progressPercent > 50) {
      styles.backgroundColor = "#54B438";
      styles.boxShadow = `
        0px 4px 4px 0px #00000040,
        0px 0px 20px 0px #81D2338F,
        0px 4px 12px 0px #FFFFFF33 inset,
        0px 0px 4px 0px #79E22540
      `;
    }
    if (progressPercent > 75) {
      styles.backgroundColor = "54B438";
      styles.boxShadow = `
        0px 4px 4px 0px #00000040,
        0px 0px 20px 0px #81D2338F,
        0px 4px 12px 0px #FFFFFF33 inset,
        0px 0px 4px 0px #79E22540,
        0px 0px 12px 0px #79E225
       `;
    }

    return styles;
  }, [progressPercent]);

  return (
    <div
      {...props}
      className={clsx("", props.className)}
    >
      <div className="flex w-full items-center">
        <span className="text-2xs uppercase text-white">{t("time left")}</span>
        <span
          className="ml-auto text-right text-sm font-bold text-white"
          style={{
            fontFeatureSettings: "'tnum'",
          }}
        >
          {formattedTime}
        </span>
      </div>
      <div className="relative mt-2 h-1.5 rounded-full bg-primary-350">
        <div className="absolute top-0 left-0 h-full w-full">
          <div
            className="absolute top-[1px] left-[1px] h-1 w-full rounded-full bg-no-repeat"
            style={{
              backgroundImage: `linear-gradient(#3D2C61,#3D2C61),
                                linear-gradient(90deg, #126959 0%, #49A83B 54.41%, #81D233 107.26%)`,
              backgroundPosition: "right,left",
              backgroundSize: `${100 - progressPercent}% 100%,100% 100%`,
            }}
          />
        </div>

        <div className="absolute top-0 left-0 ml-1 h-full w-[calc(100%-6px)]">
          <div
            style={thumbStyles}
            className={clsx(
              "absolute top-[-1px] h-2 w-2 -translate-x-1/2 rounded-full border border-white border-opacity-40 bg-[#54B438]",
            )}
          />
        </div>
      </div>
    </div>
  );
};

export default TimerBar;
