import React from "react";
import { ProfileView } from "src/main/views/component";
import ChangeLanguageContent from "./components/ChangeLanguageContent";

const ChangeLanguage: React.FC = () => {
  return (
    <ProfileView title="Change Language">
      <ChangeLanguageContent />
    </ProfileView>
  );
};

export default ChangeLanguage;
