import { Moment } from "moment/moment";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { ClockIcon } from "src/main/components/Icon";
import { convertUTCToLocal, fNumber, SimpleMap, truncate } from "src/main/utils";

const statusColorClass: SimpleMap = {
  placed: "text-yellow-400",
  processing: "text-blue-400",
  "in-delivery": "text-purple-500",
  completed: "text-green-400",
  cancelled: "text-red-600",
  void: "text-gray-400",
};

interface OrderHistoryCardProps {
  id: string;
  createdAt: Moment | undefined;
  status: string;
  item: string;
  total: string;
}

function OrderHistoryCard(props: OrderHistoryCardProps) {
  const { id, createdAt, status, item, total } = props;
  const { t } = useTranslation();

  return (
    <div className="mt-3 flex items-center rounded-lg border-t border-l border-[rgba(137,118,255,0.24)] bg-gradient-to-b from-[rgba(38,18,92,0.90)] to-[rgba(23,11,55,0.90)] lg:border-none">
      <div className="w-full p-3 lg:border-l-4 lg:border-[#F03F96]">
        <div className="flex flex-wrap items-center justify-between">
          <div className="flex items-center justify-center space-x-2 font-bold text-white">
            <ClockIcon
              width={22}
              height={22}
              className="text-secondary"
            />
            <span>{createdAt && convertUTCToLocal(createdAt as unknown as string).format("DD MMM YYYY")}</span>
          </div>
          {/* <div className="text-sm text-secondary first-letter:capitalize">
            {t("order id")}: {truncate(id, 0, 8)?.toUpperCase()}
          </div> */}
          <p className="text-sm font-bold capitalize text-white md:text-2xl lg:text-sm">
            {t("status")}:<span className={statusColorClass[status] ?? ""}> {t(status)}</span>
          </p>
        </div>

        <p className="mt-4 text-sm font-bold text-white first-letter:capitalize md:text-2xl lg:text-sm">
          {t("order id")}:<span className="font-normal"> {truncate(id, 0, 8)?.toUpperCase()}</span>
        </p>
        <p className="mt-4 break-words text-sm font-bold text-white first-letter:capitalize md:text-2xl lg:text-sm">
          {t("Item")}:<span className="font-normal"> {item}</span>
        </p>
        <p className="mt-4 text-sm font-bold text-white first-letter:capitalize md:text-2xl lg:text-sm">
          {t("total")}:<span className="font-normal"> {fNumber(total)}</span>
        </p>
        {/* <p className="mt-2 text-sm font-bold capitalize text-white md:text-2xl lg:text-sm">
          {t("status")}:
          <span className="font-normal"> {t(status)}</span>
        </p> */}

        <div className="mt-4 flex justify-end">
          <Link to={`/order-detail/${id}`}>
            <p className="text-right text-sm capitalize text-white hover:text-secondary md:text-2xl lg:text-sm">
              {t("view detail", { count: 2 })}
            </p>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default OrderHistoryCard;
