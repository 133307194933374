import { useTranslation } from "react-i18next";
import Avatar from "src/main/components/Avatar";
import { useBreakPoint, useTypedSelector } from "src/main/hooks";
import { displayPhoneNumber } from "src/main/utils";
import ProfileNavigation from "../ProfileNavigation";
import ProfilePointComponent from "../ProfilePointComponent";

const ProfileView = (props: ProfileViewProps) => {
  const { t } = useTranslation();
  const { isDesktop, isMobile } = useBreakPoint();

  const { self } = useTypedSelector((state) => state.auth);
  return (
    <>
      {isDesktop && (
        <div className="hidden lg:container lg:mx-auto lg:mt-40 lg:block lg:px-4">
          <div className="mb-10 mt-8 grid grid-cols-9 gap-2 text-center text-white">
            <div className="col-start-1 col-end-3">
              <div className="flex items-center">
                <div className="w-64 flex-1">
                  <div className="text-left font-bold text-white">
                    <div className="flex w-[360px] flex-col items-center gap-[18px]">
                      <div className="flex w-80 flex-col items-center justify-center gap-1">
                        <p className="m-0 text-lg leading-[18px]">{t("VIP Profile")}</p>
                      </div>
                      <Avatar />
                    </div>
                  </div>
                </div>
                {!!self?.member?.phone && (
                  <div className="w-32 flex-1 align-middle">
                    <p className="m-0 text-lg font-bold leading-[18px]">
                      ***{displayPhoneNumber(self?.member?.phone).slice(-3)}
                    </p>
                  </div>
                )}
              </div>
            </div>
            <div className="col-span-3 col-end-10 mt-4">
              <ProfilePointComponent />
            </div>
          </div>
          <div className="grid grid-cols-3 gap-4">
            <div className="">
              <ProfileNavigation />
            </div>
            <div
              className="
                        inner-frame
                        col-span-2 h-[calc(100vh-400px)] w-full overflow-auto border-2 border-[rgba(153,182,255,0.2)] bg-[rgba(0,0,0,0.5)]
                        p-4
                        text-white scrollbar scrollbar-track-gray-100 scrollbar-thumb-gray-900"
            >
              <div className="py-4 font-bold capitalize">{t(props.title)}</div>
              {props.children}
            </div>
          </div>
        </div>
      )}
      {isMobile && !props.desktopOnly && <div className="lg:hidden">{props.children}</div>}
    </>
  );
};

ProfileView.defaultProps = {};

interface ProfileViewProps {
  title: string;
  children: JSX.Element;
  desktopOnly?: boolean;
}

export default ProfileView;
