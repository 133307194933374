import { Moment } from "moment/moment";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { ClockIcon } from "src/main/components/Icon";
import { PointOrderItemType } from "src/main/store/types";
import { WalletTxSourceType, WalletTxSourceWalletType } from "src/main/types";
import { bnOrZero, convertUTCToLocal, currencySymbol, fNumber } from "src/main/utils";
import Currency from "src/main/utils/currency";

interface PointHistoryCardProps {
  amount: string;
  createdAt: Moment | undefined;
  srcWalletType?: WalletTxSourceWalletType;
  sourceType: WalletTxSourceType;
  orderItem?: PointOrderItemType;
}

function PointHistoryCard(props: PointHistoryCardProps) {
  const { createdAt, amount, srcWalletType, sourceType, orderItem } = props;
  const { t } = useTranslation();

  const brandName = orderItem?.info?.pointExchangeInfo?.brand ?? "";

  const renderPointAmount = useMemo(() => {
    if (amount) {
      const amountNumber = bnOrZero(amount);
      if (amountNumber.gte(0)) {
        return (
          <span
            className="font-normal capitalize"
            style={{ color: "#83D334" }}
          >
            +{fNumber(amountNumber, "floor")} {t("point_other")}
          </span>
        );
      }

      if (amountNumber.lt(0)) {
        return (
          <span
            className="font-normal capitalize"
            style={{ color: "#E82550" }}
          >
            {fNumber(amountNumber, "floor")} {t("point_other")}
          </span>
        );
      }
    }

    return <></>;
  }, [amount, t]);

  const renderCreditAmount = useMemo(() => {
    const totalCredit = orderItem?.info?.variant?.virtualFulfilment?.data?.totalCredit;
    if (!!totalCredit) {
      const currency = currencySymbol(orderItem?.info?.variant?.virtualFulfilment?.data?.currency ?? "");
      const amountNumber = bnOrZero(totalCredit);
      if (amountNumber.gte(0)) {
        return (
          <p className="mt-2 text-sm font-bold text-white first-letter:capitalize md:text-2xl lg:text-sm">
            {t("Credit")}:{" "}
            <span
              className="font-normal capitalize"
              style={{ color: "#83D334" }}
            >
              +{Currency(amountNumber).format(currency)}
            </span>
          </p>
        );
      }

      if (amountNumber.lt(0)) {
        return (
          <p className="mt-2 text-sm font-bold text-white first-letter:capitalize md:text-2xl lg:text-sm">
            {t("Credit")}:{" "}
            <span
              className="font-normal capitalize"
              style={{ color: "#E82550" }}
            >
              {Currency(amountNumber).format(currency)}
            </span>
          </p>
        );
      }
    }

    return <></>;
  }, [orderItem, t]);

  // The display of sourceType on UI is based on the following mapping
  const transactionSourceType = useMemo(() => {
    // Display 'manual-credit' for all above sourceType
    if (
      [
        WalletTxSourceType.REVERT,
        WalletTxSourceType.REFUND,
        WalletTxSourceType.MANUAL_CREDIT,
        WalletTxSourceType.WELCOME_BONUS,
        WalletTxSourceType.TURNOVER_BONUS,
        WalletTxSourceType.TRANSFER,
        WalletTxSourceType.SECRET_TRANSFER,
        WalletTxSourceType.OTHER_BONUS,
      ].includes(sourceType)
    ) {
      return WalletTxSourceType.MANUAL_CREDIT;
    }

    // Display 'reward-claim' for 'daily-bonus' and 'mission-reward'
    if ([WalletTxSourceType.DAILY_BONUS, WalletTxSourceType.MISSION_REWARD].includes(sourceType)) {
      return WalletTxSourceType.REWARD_CLAIM;
    }

    return sourceType;
  }, [sourceType]);

  // The display of top-right badge on UI is based on the following mapping
  const transactionSourceWalletType = useMemo(() => {
    // Only display top-right badge if it is 'reward-claim','daily-bonus','mission-reward'
    if (
      ![WalletTxSourceType.REWARD_CLAIM, WalletTxSourceType.DAILY_BONUS, WalletTxSourceType.MISSION_REWARD].includes(
        sourceType,
      )
    ) {
      return;
    }

    // Display srcWalletType as 'daily surprise' for sourceType = 'daily-bonus'
    if (sourceType === WalletTxSourceType.DAILY_BONUS) {
      return "daily surprise";
    }
    // Display srcWalletType as 'mission' for sourceType = 'mission-reward'
    if (sourceType === WalletTxSourceType.MISSION_REWARD) {
      return "mission";
    }
    return srcWalletType;
  }, [sourceType, srcWalletType]);

  return (
    <div className="mt-3 flex items-center rounded-lg border-t border-l border-[rgba(137,118,255,0.24)] bg-gradient-to-b from-[rgba(38,18,92,0.90)] to-[rgba(23,11,55,0.90)]">
      <div className="w-full p-3">
        <div className="flex items-center justify-between">
          <div className="flex items-center justify-center space-x-2 font-bold text-white">
            <ClockIcon
              width={22}
              height={22}
              className="text-secondary"
            />
            <span>{createdAt && convertUTCToLocal(createdAt as unknown as string).format("DD MMM YYYY")}</span>
          </div>
          {!!brandName && (
            <span className="inline-flex items-center rounded-md bg-purple-50 px-2 py-1 text-xs font-medium text-purple-700 ring-1 ring-inset ring-purple-700/10">
              {brandName}
            </span>
          )}
          {!!transactionSourceWalletType && (
            <span className="inline-flex items-center rounded-md bg-purple-50 px-2 py-1 text-xs font-medium text-purple-700 ring-1 ring-inset ring-purple-700/10">
              {t(transactionSourceWalletType)}
            </span>
          )}
        </div>

        <p className="mt-4 text-sm font-bold capitalize text-white md:text-2xl lg:text-sm">
          {t("Type")}: <span className="font-normal">{t(transactionSourceType)}</span>
        </p>

        <p className="mt-2 text-sm font-bold text-white first-letter:capitalize md:text-2xl lg:text-sm">
          {t("amount")}: {renderPointAmount}
        </p>
        {renderCreditAmount}
      </div>
    </div>
  );
}

export default PointHistoryCard;
