import React, { useMemo } from "react";
import { FaXmark } from "react-icons/fa6";
import { Button, ButtonSize, ButtonVariant } from "src/main/components";

interface CloseButtonProps {
  className?: string;
  onClick: () => void;
  variant?: ButtonVariant;
  height?: number;
  width?: number;
  size?: ButtonSize;
  tabIndex?: number;
}

const BUTTON_SIZE_MAP: Record<ButtonSize, number> = {
  sm: 24,
  md: 32,
  lg: 40,
};

const VARIANT_MAP: Record<ButtonVariant, string> = {
  outlined: "absolute rounded-full border-2 border-token-border opacity-30 text-xs font-bold",
  fill: "absolute rounded-full bg-token-border opacity-30 text-xs font-bold",
  text: "",
};

const CloseButton: React.FC<CloseButtonProps> = (props) => {
  const { onClick, variant = "text", className = "", height, width, size = "md" } = props;

  const dimension = useMemo(() => {
    return {
      height: height || BUTTON_SIZE_MAP[size],
      width: width || BUTTON_SIZE_MAP[size],
    };
  }, [height, size, width]);

  const closeXButtonStyle: React.CSSProperties = {
    height: dimension.height * 0.6,
    width: dimension.width * 0.6,
  };

  return (
    <>
      <Button
        variant="bare"
        size="sm"
        className={className}
        onClick={onClick}
        icon={
          <FaXmark
            className="transition-opacity group-hover:opacity-30"
            style={closeXButtonStyle}
          />
        }
      >
        {variant !== "text" && (
          <div
            className={VARIANT_MAP[variant]}
          ></div>
        )}
      </Button>
    </>
  );
};

export default CloseButton;
