import { clsx } from "clsx";
import { PropsWithChildren } from "react";
import { PropsWithClassName } from "src/main/components";

interface ModalFooterProps extends PropsWithChildren, PropsWithClassName {}

function ModalFooter({ children, className }: ModalFooterProps) {
  return <div className={clsx("", className)}>{children}</div>;
}

export default ModalFooter;
