import { useEffect } from "react";
import { useSelector } from "react-redux";
import { Outlet, useNavigate } from "react-router-dom";
import { MainBody } from "src/main/components";
import { PUSH_NOTIFICATION_MEMBER_STORAGE_KEY } from "src/main/contants";
import { RootState } from "src/main/store";
import { Paths, updatePushSubscription } from "src/main/utils";
import { isPasswordExpired } from "src/main/utils/profile";
import { AuthState } from "../store/auth/slices";
import logger from "./logger";

const ProtectedRoutes = () => {
  const navigate = useNavigate();
  const { self, oauth } = useSelector<RootState, AuthState>((state) => state.auth);

  useEffect(() => {
    if (oauth === undefined) {
      logger._console.log("undefined, returning");
      return;
    }
    logger._console.log("not undefined");
    if (!self || !oauth) {
      logger._console.log("has no profile or token", { self }, { oauth });
      navigate("/login");
    }
    if (!!self?.member && isPasswordExpired(self.member)) {
      navigate(Paths.Public.ForceUpdatePassword);
    }
    if (!!self && !!oauth && !!self.member?.id) {
      const registeredMemberId = sessionStorage.getItem(PUSH_NOTIFICATION_MEMBER_STORAGE_KEY);
      // if member id is not registered or different, update notification subscription
      if (!registeredMemberId || registeredMemberId !== self.member.id) {
        updatePushSubscription(self.member.id);
      }
    }
  }, [navigate, self, oauth]);

  // logger._console.log("main auth", self, oauth);

  if (oauth === undefined) return null;

  return (
    <MainBody>
      <Outlet />
    </MainBody>
  );
};

export default ProtectedRoutes;
