import { getLangId } from "../services/auth-header";
import {
  BrandType,
  ChangePasswordType,
  LangType,
  logisticsType,
  OrderDetailType,
  OrderType,
  PaginationType,
  ProductGrandTotalType,
  ProfileType,
  ShippingDetailType,
  TokenType,
} from "./types";
import {
  closeFlagType,
  PopupBannerType,
  ProductType,
  ShowDailyBonusType,
  ShowPopupBannerType,
  UserCartItemType,
  UserDailyBonusType,
  VipStatusTokenType,
  VipStatusType,
} from "./types/types";

export const productDetailInitialState: ProductType = {
  id: -1,
  name: "",
  images: [],
  price: 0,
  category: {
    id: -1,
    name: "",
    is_virtual: false,
  },
  variants: {
    Colour: [
      {
        id: 0,
        value: "",
      },
    ],
  },
  quantity: 0,
  cartId: "",
  selected: false,
  short_description: "",
};

export const productGrandTotalInitialState: ProductGrandTotalType = {
  grand_total: 0,
};

export const shippingDetailInitialState: ShippingDetailType = {
  full_name: "",
  mobile_no: "",
  shipping_address1: "",
  shipping_address2: "",
  shipping_city_code: "",
  shipping_country_code: "",
  shipping_postcode: "",
  shipping_state_code: "",
};

export const brandInitialState: BrandType = {
  footer_logo_url: "",
  footer_site_url: "",
  id: -1,
  logo_url: "",
  main_site_url: "",
  name: "",
  promotion_banners: {
    data: [],
  },
};

export const tokenInitialState: TokenType = {
  token: "",
};

export const langInitialState: LangType = {
  lang: getLangId(),
};

export const profileInitialState: ProfileType = {
  user_info: {
    currency: "",
    balance_amt: 0,
    email: "",
    full_name: "",
    mobile: "",
    user_name: "",
    game_credit: 0,
    first_time_login: false,
    member_address: shippingDetailInitialState,
    member_address_default: shippingDetailInitialState,
  },
  has_view_pop_up_banner: true,
  is_redeemed_daily_bonus: true,
  last_password_changed_datetime: "",
};

export const changePasswordInitialState: ChangePasswordType = {
  status: "",
};

export const logisticsTypeInitialState: logisticsType = {
  name: "",
  tracking_url: "",
};

export const OrderInitialState: OrderType = {
  id: -1,
  balance_amt: "",
  created_at: "",
  grand_total: "",
  member_remarks: "",
  mobile_no: "",
  recipient: "",
  reference_no: "",
  shipping_address: "",
  status: "",
  tracking_number_id: "",
  user_name: "",
  logistics: logisticsTypeInitialState,
};

export const OrderDetailInitialState: OrderDetailType = {
  order_details: [],
  id: -1,
  balance_amt: "",
  created_at: "",
  grand_total: "",
  member_remarks: "",
  mobile_no: "",
  recipient: "",
  reference_no: "",
  shipping_address: "",
  tracking_number_id: "",
  user_name: "",
  status: "",
  logistics: logisticsTypeInitialState,
};

export const PaginationInitialState: PaginationType = {
  count: 0,
  current_page: 0,
  per_page: 0,
  total: 0,
  total_pages: 0,
};

export const UserCartInitialState: UserCartItemType = {
  cartId: "",
  productId: -1,
  quantity: 0,
  selected: false,
  sub_total: 0,
  product: productDetailInitialState,
};

export const UserDailyBonusInitialState: UserDailyBonusType = {
  data: 0,
};

export const ShowDailyBonusInitialState: ShowDailyBonusType = {
  show: true,
};

export const ShowPopupBonusInitialState: ShowPopupBannerType = {
  show: false,
};

export const CloseFlagInitialState: closeFlagType = {
  close: false,
};

export const FetchBannerFlagInitialState: Boolean = false;

export const PopupBannerInitialState: PopupBannerType = {
  file_url: "",
  id: -1,
  name: "",
  site_url: "",
};

export const VipStatusInitialState: VipStatusType = {
  data: {
    last_login: false,
    offer_points: 0,
    points: "",
    username: null,
    valid: null,
    logo_url: null,
    main_site_url: null,
  },
};

export const VipStatusTokenInitialState: VipStatusTokenType = {
  data: {
    username: null,
    mobile: null,
    token: null,
    valid: null,
    main_site_url: null,
  },
};
