import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { CopyText } from "src/main/components";
import { ClockIcon } from "src/main/components/Icon";
import { ShipmentInfo } from "src/main/types";
import { getFullAddress, truncate } from "src/main/utils";
import { convertUTCToLocal } from "src/main/utils/misc";

function RedeemedSuccessfulOrderInfo(props: RedeemedSuccessfulOrderInfoProps) {
  const {
    referenceNo,
    status,
    shipmentInfo,
    createdAt,
    isExchangeGameCredit = false,
    brand,
    showSuccessBanner = true,
  } = props;
  const { t } = useTranslation();

  const fullAddress = useMemo(() => {
    return shipmentInfo ? getFullAddress(shipmentInfo) : "";
  }, [shipmentInfo]);

  const shortId = useMemo(() => truncate(referenceNo, 0, 8), [referenceNo]);

  return (
    <div className="w-full">
      {showSuccessBanner && (
        <img
          src="/images/body-bg/ribbon-header.png"
          alt="success banner"
          className="absolute left-1/2 w-60 -translate-x-1/2 transform"
        />
      )}

      <div
        className={`
            w-full
            ${showSuccessBanner ? "mt-[41px]" : ""}
            rounded-md
            bg-[rgba(0,0,0,0.3)] p-5 text-sm font-extrabold
            text-white
            [box-shadow-width:2px]
            [box-shadow:0px_0px_0px_1px_rgba(255,_255,_255,_0.2)_inset]
            `}
      >
        <div className="flex flex-wrap items-center justify-between">
          <div className="flex items-center justify-center space-x-2 font-extrabold">
            <ClockIcon
              width={22}
              height={22}
              className="text-secondary"
            />
            <span>{createdAt && convertUTCToLocal(createdAt).format("DD MMM YYYY")}</span>
          </div>
          {referenceNo && (
            <div className="text-right text-sm text-secondary first-letter:capitalize">
              {t("order id")}: <CopyText value={shortId?.toUpperCase()} />
            </div>
          )}
        </div>

        <div className="mt-5 flex max-w-full flex-col space-y-2">
          {status && (
            <p className="pb-2 capitalize">
              {t("status")} : <span className="font-normal">{t(status)}</span>{" "}
            </p>
          )}
          {brand && (
            <p className="pt-2 capitalize">
              {t("Brand")}:<span className="font-normal"> {brand}</span>
            </p>
          )}
          {!isExchangeGameCredit && (
            <>
              <p className="break-words capitalize">
                {t("recipient")} : <span className="font-normal">{shipmentInfo?.recipientName}</span>
              </p>
              <p className="break-words capitalize">
                {t("mobile")} : <span className="font-normal">{shipmentInfo?.recipientMobile}</span>
              </p>
              {fullAddress && (
                <p className="mt-5 break-words capitalize">
                  {t("address")} : <span className="font-normal">{fullAddress}</span>
                </p>
              )}
              <p className="break-words pt-2 capitalize">
                {t("remark")}:<span className="font-normal"> {shipmentInfo?.remarks}</span>
              </p>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

RedeemedSuccessfulOrderInfo.defaultProps = {
  isExchangeGameCredit: false,
};

interface RedeemedSuccessfulOrderInfoProps {
  referenceNo?: string;
  status?: string;
  createdAt?: string;
  isExchangeGameCredit?: boolean;
  shipmentInfo?: ShipmentInfo;
  brand?: string;
  showSuccessBanner?: boolean;
}

export default RedeemedSuccessfulOrderInfo;
