import i18next from "i18next";
import { initReactI18next } from "react-i18next";

//import locale files
import englishTranslation from "./locales/en.json";
import thailandTranslation from "./locales/th.json";

const resources = {
  en: {
    translation: englishTranslation,
  },
  th: {
    translation: thailandTranslation,
  },
};

i18next.use(initReactI18next);

export const initI18n = (lng: string) => {
  i18next.init({
    resources,
    lng,
    debug: false,
  });
};

export default i18next;
