import BigNumber from "bignumber.js";
import { clsx } from "clsx";
import { PropsWithChildren } from "react";
import { useTranslation } from "react-i18next";
import Currency from "src/main/utils/currency";

interface BonusDetailProps extends PropsWithChildren {
  // after plus bonus
  exchangeCash: BigNumber;
  // before plus bonus
  originalExchangeCash: BigNumber;
  bonusPercentage?: number;
  currency: string;
  className?: string;
}

const BonusDetail = ({
  className,
  exchangeCash,
  originalExchangeCash,
  bonusPercentage = 0,
  currency,
}: BonusDetailProps) => {
  const { t } = useTranslation();

  return (
    <div className={clsx(className)}>
      <span className="text-xs text-token-secondary-2 line-through">
        {Currency(originalExchangeCash).format(currency)}
      </span>{" "}
      <span className="text-xs font-bold text-lime-400">{Currency(exchangeCash).format(currency)}</span>{" "}
      <span className="text-xs text-lime-400">{`(${t("Bonus")} +${bonusPercentage * 100}%)`}</span>
    </div>
  );
};

export default BonusDetail;
