import React, { ComponentProps, ElementType } from "react";
import { RevealProps } from "react-awesome-reveal";

type RevealOnScrollProps<AnimationComponent extends ElementType> = {
  animation: AnimationComponent;
  children: React.ReactNode;
  group?: boolean;
} & RevealProps &
  ComponentProps<AnimationComponent>;

/**
 * Wrapper for react-awesome-reveal
 * https://www.npmjs.com/package/react-awesome-reveal
 */
const RevealOnScroll = <Component extends ElementType>({
  animation: Animation,
  children,
  group = false,
  ...props
}: RevealOnScrollProps<Component>) => {
  return <Animation {...props}>{group ? <div>{children}</div> : children}</Animation>;
};

RevealOnScroll.DAMPLING = {
  FASTEST: 0.1,
  FAST: 0.3,
  MEDIUM: 0.5,
  SLOW: 0.7,
  SLOWEST: 1,
};

export default RevealOnScroll;
