import { useAutoAnimate } from "@formkit/auto-animate/react";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { siteApi } from "src/main/api";
import { Page } from "src/main/components";
import { useSharedTimerProvider, useTypedSelector } from "src/main/hooks";
import { RootState } from "src/main/store";
import { MissionModel } from "src/main/types";
import MissionBackground from "src/main/views/MissionsPage/components/MissionBackground";
import { getMissionUIStatus } from "src/main/views/MissionsPage/utils/defaults";
import { MissionCard, MissionDetailDrawer, MissionEmptyState, MissionTopSection, SkeletonLoader } from "./components";
import { MissionUIStatus, MISSION_TIMER } from "./types";

const MissionsPage = () => {
  const timezoneOffset = useTypedSelector((state: RootState) => state.auth.utcOffset);
  const { t } = useTranslation();
  const { data: missionsResponse, isLoading } = siteApi.useListMissionQuery(undefined, {
    refetchOnMountOrArgChange: true,
  });
  useSharedTimerProvider({ eventName: MISSION_TIMER, stepInMs: 1000 });
  const [missionListTopParent] = useAutoAnimate();
  const [missionListExpiredParent] = useAutoAnimate();
  const [missionListClaimedParent] = useAutoAnimate();

  const missions = useMemo(() => {
    return missionsResponse?.entries ?? [];
  }, [missionsResponse]);

  const [firstLoad, setFirstLoad] = useState(true);

  const [missionClaimedList, setMissionClaimedList] = useState<MissionModel[]>([]);
  const [missionExpiredList, setMissionExpiredList] = useState<MissionModel[]>([]);
  const [missionRestList, setMissionRestList] = useState<MissionModel[]>([]);

  const groupMissionByStatus = useCallback(() => {
    const claimed: MissionModel[] = [];
    const expired: MissionModel[] = [];
    const rest: MissionModel[] = [];
    for (const mission of missions) {
      const missionUIStatus = getMissionUIStatus(mission, timezoneOffset ?? 0);
      if (missionUIStatus === MissionUIStatus.CLAIMED) claimed.push(mission);
      else if (missionUIStatus === MissionUIStatus.EXPIRED) expired.push(mission);
      else rest.push(mission);
    }
    setMissionClaimedList(claimed);
    setMissionExpiredList(expired);
    
    if (rest.length > 0) {
      rest.sort((first, second) => {
        const statusOrder = [MissionUIStatus.CLAIMABLE, MissionUIStatus.IN_PROGRESS];
        const firstStatusIndex = statusOrder.indexOf(getMissionUIStatus(first, timezoneOffset ?? 0));
        const secondStatusIndex = statusOrder.indexOf(getMissionUIStatus(second, timezoneOffset ?? 0));
       return firstStatusIndex - secondStatusIndex;
      });
    }
    setMissionRestList(rest);
  }, [missions, timezoneOffset]);

  useEffect(() => {
    groupMissionByStatus();
  }, [groupMissionByStatus, missions, timezoneOffset]);

  const handleOnMissionTimeout = useCallback(() => {
    groupMissionByStatus();
  }, [groupMissionByStatus]);

  useEffect(() => {
    if (firstLoad && !!missions) {
      setTimeout(() => {
        setFirstLoad(false);
      }, 1500);
    }
  }, [missions, firstLoad]);

  const isEmptyState = useMemo(() => {
    return !isLoading && missions.length === 0;
  }, [isLoading, missions.length]);

  return (
    <Page
      footer={false}
      className="relative -my-20 min-h-screen bg-midnight-900 lg:!m-0 lg:!w-full lg:!max-w-full lg:!px-0 lg:!pt-0"
    >
      <MissionBackground />
      <MissionDetailDrawer />

      {/*MISSION LIST*/}
      <div className="scrollbar-primary absolute top-0 left-0 z-[15] h-full w-full overflow-auto">
        <MissionTopSection />
        <div className="m-auto flex min-h-full w-full flex-col items-center gap-7 pt-3">
          {isLoading && <SkeletonLoader />}

          {/*MISSION GROUP CLAIMABLE & IN-PROGRESS*/}
          {!isLoading && missionRestList.length > 0 && (
            <div
              className="flex w-full flex-col items-center space-y-8 px-4"
              ref={missionListTopParent}
            >
              {missionRestList.map((mission, index) => (
                <MissionCard
                  data={mission}
                  key={mission.id}
                  style={{
                    animationDelay: getDelay(index),
                  }}
                  onTimeout={handleOnMissionTimeout}
                />
              ))}
            </div>
          )}

          {/*MISSION GROUP CLAIMED*/}
          {!isLoading && missionClaimedList.length > 0 && (
            <div
              className="flex w-full flex-col items-center"
              ref={missionListClaimedParent}
            >
              <div className="mb-6 w-full max-w-lg px-4 text-xs font-bold uppercase leading-3 text-white sm:px-0">
                {t("claimed")}
              </div>
              <div className="flex w-full flex-col items-center space-y-8 px-4">
                {missionClaimedList.map((mission, i) => (
                  <MissionCard
                    data={mission}
                    key={mission.id}
                    style={{
                      animationDelay: firstLoad ? getDelay(i, missionRestList.length) : "0ms",
                    }}
                    onTimeout={handleOnMissionTimeout}
                  />
                ))}
              </div>
            </div>
          )}

          {/*MISSION GROUP EXPIRED*/}
          {!isLoading && missionExpiredList.length > 0 && (
            <div
              className="flex w-full flex-col items-center"
              ref={missionListExpiredParent}
            >
              <div className="mb-6 w-full max-w-lg px-4 text-xs font-bold uppercase leading-3 text-white sm:px-0">
                {t("expired")}
              </div>
              <div className="flex w-full flex-col items-center space-y-8 px-4">
                {missionExpiredList.map((mission, index) => (
                  <MissionCard
                    data={mission}
                    key={mission.id}
                    style={{
                      animationDelay: firstLoad
                        ? getDelay(index, missionRestList.length + missionClaimedList.length)
                        : "0ms",
                    }}
                    onTimeout={handleOnMissionTimeout}
                  />
                ))}
              </div>
            </div>
          )}

          {/*EMPTY STATE*/}
          {isEmptyState && <MissionEmptyState />}

          {/* PLACEHOLDER */}
          <div className="h-20 w-full"></div>
        </div>
      </div>
    </Page>
  );
};

const getDelay = (index: number, startIndex = 0, threshold = 5, delayInMs = 200) => {
  const indexInList = index + startIndex;
  // all items after threshold have the same delay (1s)
  return indexInList < threshold ? `${indexInList * delayInMs}ms` : `${delayInMs * threshold}ms`;
};

export default MissionsPage;
