import { getDeployEnv } from "./deployEnv";

/**
 * Standardize phone number by:
 * - Removing all non-digit characters
 * - Removing leading 0
 * - Adding country code if not present
 * @example
 * standardizePhoneNumber('0812345678') // 62812345678
 * standardizePhoneNumber('62812345678') // 62812345678
 */
export const standardizePhoneNumber = (phoneNumber: string, countryDialingCode?: string | number | null): string => {
  // Remove all non-digit characters
  let cleanedPhoneNumber = phoneNumber.replace(/\D/g, "");
  // Get default country dialing code if not provided
  if (countryDialingCode === undefined) {
    countryDialingCode = getDeployEnv().country_code;
  }
  // Add country dialing code if not present
  if (cleanedPhoneNumber[0] === "0" && !!countryDialingCode) {
    cleanedPhoneNumber = String(countryDialingCode) + cleanedPhoneNumber.slice(1);
  }
  // Add '+' if not present
  if (cleanedPhoneNumber[0] !== "+") {
    cleanedPhoneNumber = "+" + cleanedPhoneNumber;
  }
  return cleanedPhoneNumber;
};

/**
 * Display phone number by removing country dialing code if present
 * @example
 * displayPhoneNumber('+62812345678') // 0812345678
 * @param standardizedPhoneNumber
 * @param countryDialingCode
 */
export const displayPhoneNumber = (
  standardizedPhoneNumber: string,
  countryDialingCode?: string | number | null,
): string => {
  if (countryDialingCode === undefined) {
    countryDialingCode = getDeployEnv().country_code;
  }
  // Remove country dialing code if present
  let cleanedPhoneNumber = standardizedPhoneNumber;
  if (cleanedPhoneNumber[0] === "+") {
    cleanedPhoneNumber = cleanedPhoneNumber.replace("+" + countryDialingCode, "0");
  }
  return cleanedPhoneNumber;
};
