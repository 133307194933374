import { clsx } from "clsx";
import { DetailedHTMLProps, ImgHTMLAttributes, useEffect, useState } from "react";

interface LazyImageProps extends Omit<DetailedHTMLProps<ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>, "src"> {
  fallbackSrc?: string;
  fallbackClassName?: string;
  fadeOnShow?: boolean;
  src: string | undefined | null;
}

/**
 * Lazy load an image
 */
function LazyImage({ fallbackSrc = "", src, alt, fallbackClassName, fadeOnShow, ...imgProps }: LazyImageProps) {
  const [imgSrc, setImgSrc] = useState(fallbackSrc);

  useEffect(() => {
    if (!src) return;
    const img = new Image();
    img.onload = () => {
      setImgSrc(src);
    };
    img.src = src;
  }, [src]);

  return (
    <img
      {...imgProps}
      src={imgSrc}
      alt={alt || ""}
      className={clsx(
        "leading-[0]",
        imgProps.className,
        imgSrc === fallbackSrc && fallbackClassName,
        imgSrc !== fallbackSrc && fadeOnShow && "animate-fade-in",
      )}
    />
  );
}

export default LazyImage;
