import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import IcoCart from "src/assets/IcoCart";
import { RootState } from "src/main/store";
import { ProductState } from "src/main/store/product/slices";

interface CartIconProps {}

const CartIcon: React.FC<CartIconProps> = () => {
  const { t } = useTranslation();
  const { cart } = useSelector<RootState, ProductState>((state) => state.products);
  return (
    <div className="group relative flex h-9 w-9 flex-col items-center justify-center text-center text-shadow-lg lg:h-full lg:w-[30px] lg:pt-2">
      <IcoCart className="h-5 w-5 group-active:drop-shadow-[0px_0px_6px_#FFF0D1] lg:h-6 lg:w-6 lg:group-hover:drop-shadow-[0px_0px_6px_#FFF0D1]" />
      {!!cart?.data.items?.length && (
        <div className="absolute right-0 top-0 inline-flex h-4 w-4 items-center justify-center rounded-full bg-tertiary-600 font-bold lg:-right-1 lg:top-2">
          <p className="pb-[1px] text-xxs font-normal"> {cart.data.items.length}</p>
        </div>
      )}

      <p className="hidden text-xxs font-normal uppercase lg:mt-1 lg:block">{t("cart")}</p>
    </div>
  );
};

CartIcon.defaultProps = {};

export default CartIcon;
