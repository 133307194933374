import { clsx } from "clsx";
import { PropsWithChildren } from "react";
import { LoadingContainer, Skeleton } from "src/main/components";

interface RewardCardProps extends PropsWithChildren {
  title?: string | null;
  loading?: boolean;
  borderEffect: boolean;
  className?: string;
}

export default function RewardCard({ children, title, loading = false, borderEffect, className }: RewardCardProps) {
  return (
    <LoadingContainer
      loading={loading}
      loader={
        <Skeleton
          component={Skeleton.Box}
          className={clsx("relative min-w-[120px] max-w-[272px] pb-[calc(165/222*100%)]")}
          componentClassName="absolute top-0 left-0"
        />
      }
      containerClassName={clsx("relative aspect-[165/222] min-h-[230px] min-w-[120px] max-w-[272px] grow", className)}
    >
      <div className={clsx("relative aspect-[165/222] min-h-[230px] w-full max-w-[272px]", className)}>
        {/* layer border */}
        <div className="absolute top-0 left-0 z-[1] h-full w-full overflow-hidden rounded-lg bg-[#34256A]">
          <div className="relative h-full w-full">
            {borderEffect && (
              <div
                className="absolute top-[-100%] left-0 right-0 h-full w-full animate-[moving-shine_9s_linear_infinite]"
                style={{
                  background:
                    "linear-gradient(162deg, rgba(255,244,205,0) 40%, rgba(255,228,131,0.72) 50%, rgba(255,244,205,0) 60%)",
                }}
              ></div>
            )}
          </div>
        </div>

        {/* layer content */}
        <div className="absolute top-0 left-0 z-[2] flex h-full w-full items-center justify-center">
          <div className=" h-[calc(100%-5px)] w-[calc(100%-5px)] overflow-hidden rounded-lg bg-[#1D104A]">
            <div className="relative h-full w-full">
              <div
                className="absolute left-[50%] bottom-[8%] z-[3] aspect-[429/287] min-h-[450px] w-[400%] translate-x-[-50%] opacity-30"
                style={{
                  maskImage: "url(/images/icon/card-shine.svg)",
                  maskSize: "cover",
                  WebkitMaskImage: "url(/images/icon/card-shine.svg)",
                  WebkitMaskSize: "cover",
                }}
              >
                <div className="absolute h-full w-full bg-[#D9D9D9]"></div>
              </div>
              <div className="absolute top-0 left-0 bottom-0 right-0 z-[4] h-full w-full">
                {title && (
                  <div className="absolute top-3 left-0 z-[3] w-full text-center text-sm font-bold uppercase leading-[22px] text-[#CCC2ED] ">
                    {title}
                  </div>
                )}
                {children}
              </div>
              {borderEffect && (
                <div
                  className="pointer-events-none absolute top-[-100%] left-0 right-0 z-[5] h-full w-full animate-[moving-shine_9s_linear_infinite]"
                  style={{
                    background:
                      "linear-gradient(162deg, rgba(255,244,205,0) 40%, rgba(255,244,205,0.15) 50%, rgba(255,244,205,0) 60%)",
                  }}
                ></div>
              )}
            </div>
          </div>
        </div>
      </div>
    </LoadingContainer>
  );
}
