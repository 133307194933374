import React, { useEffect } from "react";
import useActions from "../../hooks/useActions";

type Props = {};

const Logout: React.FC<Props> = () => {
  const actions = useActions();
  useEffect(() => {
    actions.logout();
    // eslint-disable-next-line
  }, []);

  return <></>;
};

export default Logout;
