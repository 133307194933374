import { Form, Formik, FormikProps } from "formik";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { IcoPhone } from "src/assets";
import { authApi } from "src/main/api";
import { CheckOnboardResponse } from "src/main/api/auth/endpoints";
import { AuthBody, Button, InputField } from "src/main/components";
import { phoneSchemas } from "src/main/components/validation/loginSchema";
import { useAsyncTask } from "src/main/hooks";
import useActions from "src/main/hooks/useActions";
import { RootState } from "src/main/store";
import auth from "src/main/store/auth";
import { AuthState } from "src/main/store/auth/slices";
import { getDeployEnv, handleApiResponse, logger } from "src/main/utils";
import { LoginFormBox } from "src/main/views/login/components";

type Props = {};

type FormType = {
  phone: string;
};

const currentEnv = getDeployEnv();

const VipCheck: React.FC<Props> = () => {
  const { t } = useTranslation();
  const actions = useActions();
  const { mobile } = useSelector<RootState, AuthState>((state) => state.auth);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const phoneFormRef = React.useRef<FormikProps<FormType>>(null);

  const [initialFormValues] = useState<FormType>({
    phone: mobile ?? "",
  });

  const [runPhoneNumberCheck, isLoginLoading] = useAsyncTask("check/phone/number");

  const [checkOnboardStatus] = authApi.useCheckOnboardMutation();

  const handleSubmit = useCallback(
    (props: FormType) => {
      runPhoneNumberCheck(async () => {
        const { phone: rawPhone } = props;
        const phone = rawPhone.trim();
        // const phone = currentEnv.country_code + props.phone.substring(1);
        const result = await checkOnboardStatus({ phone });

        const { data, error } = handleApiResponse<CheckOnboardResponse>(result);

        if (!!error) {
          logger._console.log(error);
          toast.error(t(error?.data?.error?.message ?? "Something went wrong"));
          return;
        }

        dispatch(auth.slice.actions.setMobile(phone));
        if (!!data?.member) {
          dispatch(auth.slice.actions.updateMemberOnboardStatus(data.member));
          if (data.member.phoneVerified) {
            navigate(`/mobile-login`);
          } else {
            navigate(`/mobile-setup-password`);
          }
        } else {
          navigate(`/vipstatus/signup`);
        }
      });
    },
    [t, runPhoneNumberCheck, checkOnboardStatus, dispatch, navigate],
  );

  useEffect(() => {
    actions.clearVipStatusData();
  }, [actions]);

  useEffect(() => {
    if (!!currentEnv && !currentEnv.isMain) {
      logger._console.log("nav to brand site");
      navigate("/vipstatus");
    }
  }, [navigate]);

  const phoneSchema = useMemo(() => {
    return currentEnv.deployCountry ? phoneSchemas[currentEnv.deployCountry] : undefined;
  }, []);

  const handleOnClickLogin = (
    event: React.MouseEvent<HTMLButtonElement>,
    error: string | undefined,
    isFormValid: boolean,
  ) => {
    if (!isFormValid) {
      event.preventDefault();
      toast.error(t(error || "Something went wrong"));
    }
  };

  return (
    <AuthBody>
      <LoginFormBox>
        <div className="flex flex-col items-center text-center">
          <img
            className="h-[99px]"
            src="/images/login/ilv_slots_animated.gif"
            alt="Frame 99"
          />

          <div className="mt-2 text-xl font-bold text-white">{t("Log In to ILOVEVIP")}</div>
          <div className="text-[12px] text-secondary">{t("and get numerous perks!")}</div>
        </div>
        <div className="login-form">
          <Formik
            innerRef={phoneFormRef}
            initialValues={initialFormValues}
            enableReinitialize
            validationSchema={phoneSchema}
            onSubmit={handleSubmit}
          >
            {({ errors, touched, isValid, values, handleChange }) => (
              <Form>
                <div className="flex flex-col gap-4">
                  <div className="items-center">
                    <InputField
                      value={values.phone.trim()}
                      onChange={(e) => {
                        e.target.value = e.target.value.trim();
                        handleChange(e);
                      }}
                      name="phone"
                      label="phone"
                      error={!!errors.phone && !!touched.phone}
                      helperText={touched.phone ? errors.phone : errors.phone}
                      helperTextClassName="min-h-[2rem]"
                      startIcon={
                        <IcoPhone
                          width="19"
                          height="19"
                        />
                      }
                      inputMode="tel"
                    />
                  </div>

                  <Button
                    onClick={(event) => handleOnClickLogin(event, errors.phone, isValid)}
                    loading={isLoginLoading}
                    variant="blue-violet"
                    size="xl"
                  >
                    {t("Login")}
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </LoginFormBox>
    </AuthBody>
  );
};

export default VipCheck;
