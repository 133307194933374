import { Transition } from "@headlessui/react";
import { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Page } from "src/main/components";
import { TRANSITION_IN_FADE, TRANSITION_OUT_FADE } from "src/main/contants";
import { AccountSelection, CashbackSuccess, PointSelection, SignUpInstruction } from "./components";
import { AccountSelectionPayload, CashbackStep, PointSelectionPayload, SignUpBrandSelectionPayload } from "./types";

interface CashbackProps {}

function CashbackPage(props: CashbackProps) {
  const [step, setStep] = useState<CashbackStep>(CashbackStep.PointSelection);
  const [selectedAccount, setSelectedAccount] = useState<AccountSelectionPayload>();
  const [selectedSignUpBrand, setSelectedSignUpBrand] = useState<SignUpBrandSelectionPayload>();
  const [selectedPointPayload, setSelectedPointPayload] = useState<PointSelectionPayload>();
  const navigate = useNavigate();

  const handleOnSubmitPointSelection = useCallback((payload: PointSelectionPayload) => {
    setSelectedPointPayload(payload);
    setStep(CashbackStep.AccountSelection);
  }, []);

  const handleOnSubmitAccountSelection = useCallback((payload: AccountSelectionPayload) => {
    setSelectedAccount(payload);
    setStep(CashbackStep.CashbackSuccess);
  }, []);

  const handleOnSubmitSignUpInstruction = useCallback((payload: SignUpBrandSelectionPayload) => {
    setSelectedSignUpBrand(payload);
    setStep(CashbackStep.SignUpInstruction);
  }, []);

  const onBack = useCallback(() => {
    switch (step) {
      case CashbackStep.PointSelection:
        navigate(-1);
        break;
      case CashbackStep.AccountSelection:
        setStep(CashbackStep.PointSelection);
        break;
      case CashbackStep.CashbackSuccess:
        setSelectedPointPayload(undefined);
        setSelectedAccount(undefined);
        setStep(CashbackStep.PointSelection);
        break;
      case CashbackStep.SignUpInstruction:
        setSelectedSignUpBrand(undefined);
        setSelectedAccount(undefined);
        setStep(CashbackStep.AccountSelection);
    }
  }, [navigate, step]);

  return (
    <Page
      className="mt-4 h-full flex-1"
      paddingTop
    >
      <div
        className="fixed top-0 left-0 z-[100] h-16 w-16 lg:hidden"
        onClick={onBack}
      />
      <Transition
        show={step === CashbackStep.PointSelection}
        className="duration-500"
        {...TRANSITION_IN_FADE}
        {...TRANSITION_OUT_FADE}
      >
        {step === CashbackStep.PointSelection && (
          <PointSelection
            onSubmit={handleOnSubmitPointSelection}
            defaultValue={selectedPointPayload}
          />
        )}
      </Transition>
      <Transition
        show={step === CashbackStep.AccountSelection}
        className="duration-500"
        {...TRANSITION_IN_FADE}
        {...TRANSITION_OUT_FADE}
      >
        {step === CashbackStep.AccountSelection && !!selectedPointPayload && (
          <AccountSelection
            onSubmit={handleOnSubmitAccountSelection}
            selectedPointPayload={selectedPointPayload}
            onSignUpInstruction={handleOnSubmitSignUpInstruction}
            onBack={onBack}
          />
        )}
      </Transition>
      <Transition
        show={step === CashbackStep.CashbackSuccess}
        className="duration-500"
        {...TRANSITION_IN_FADE}
        {...TRANSITION_OUT_FADE}
      >
        {step === CashbackStep.CashbackSuccess && !!selectedAccount && !!selectedPointPayload && (
          <CashbackSuccess
            selectedAccountPayload={selectedAccount}
            selectedPointPayload={selectedPointPayload}
          />
        )}
      </Transition>
      <Transition
        show={step === CashbackStep.SignUpInstruction}
        className="duration-500"
        {...TRANSITION_IN_FADE}
        {...TRANSITION_OUT_FADE}
      >
        {step === CashbackStep.SignUpInstruction && !!selectedSignUpBrand && !!selectedPointPayload && (
          <SignUpInstruction
            selectedPointPayload={selectedPointPayload}
            selectedSignUpBrandPayload={selectedSignUpBrand}
          />
        )}
      </Transition>
    </Page>
  );
}

export default CashbackPage;
