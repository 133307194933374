import { ProfileView } from "../component";
import { AddressListContent } from "./components";

interface Props {
  isManage?: boolean;
}

export default function AddressListPage({ isManage = false }: Props) {
  if (!isManage) return <AddressListContent />;

  return (

      <ProfileView title="manage address">
        <AddressListContent isManage={isManage} />
      </ProfileView>
  );
}
