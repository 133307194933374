import { StarFieldBackground } from "./components";

interface MissionBackgroundProps {}

const MissionBackground = (props: MissionBackgroundProps) => {
  return (
    <div className="relative m-auto h-[360px] w-full min-w-[320px]">
      {/* bg */}
      <div className="absolute top-0 left-0 right-0 bottom-0 z-[11] h-full w-full bg-[url(../public/images/body-bg/milky-way-missions-page_sm.png)] !bg-cover !bg-center !bg-no-repeat opacity-80 md:bg-[url(../public/images/body-bg/milky-way-missions-page.png)]"></div>
      {/* moving stars */}
      <div className="absolute top-0 left-0 right-0 bottom-0 z-[12] h-full w-full !bg-cover !bg-center !bg-no-repeat">
        <StarFieldBackground />
      </div>

      {/* gradient layer 1 */}
      <div className="absolute top-0 left-0 right-0 bottom-0 z-[13] h-[40%] w-full bg-[linear-gradient(180deg,rgba(158,0,255,0.5)_0%,rgba(158,0,255,0)_100%)]"></div>

      {/* gradient layer 2 */}
      <div className="absolute top-0 left-0 right-0 bottom-0 z-[14] h-[calc(100%+1px)] w-full bg-[linear-gradient(180deg,rgba(27,14,58,0.05)_0%,#1B0E2C_100%)]"></div>
    </div>
  );
};

export default MissionBackground;
