import BigNumber from "bignumber.js";
import getSymbolFromCurrency from "currency-symbol-map";
import numeral from "numeral";
import { BN_ZERO } from "../contants";

type InputValue = string | number | null | undefined;
type InputRound = "floor" | "ceil" | null | undefined;

export function fNumber(number: InputValue | BigNumber, round?: InputRound, separator = ",") {
  if (number instanceof BigNumber) {
    const formatter = {
      groupSize: 3,
      groupSeparator: separator,
      decimalSeparator: ".",
    };
    if (round === "floor") return number.integerValue(BigNumber.ROUND_FLOOR).toFormat(formatter);
    if (round === "ceil") return number.integerValue(BigNumber.ROUND_CEIL).toFormat(formatter);
    return number.toFormat(formatter);
  }

  let roundFunc = undefined;
  if (round === "ceil") roundFunc = Math.ceil;
  if (round === "floor") roundFunc = Math.floor;

  return numeral(number).format(`0${separator}0`, roundFunc);
}

export const numberOrZero = (input: any): number => {
  switch (typeof input) {
    case "number":
      return input;
    default:
      return isNumber(input) ? Number(input) : 0;
  }
};

export const isNumber = (input: any): boolean => {
  return !isNaN(input) && isFinite(input);
};

export const parseBN = (input?: string | BigNumber | number | null, defaultValue?: BigNumber) => {
  if (!input && input !== 0) return defaultValue;
  if (typeof input === "string") input = input.trim().replace(/,/g, "");
  const result = BigNumber.isBigNumber(input) ? input : new BigNumber(input);
  if (!result.isFinite() || result.isNaN()) return defaultValue;

  return result;
};

export const bnOrZero = (input?: string | BigNumber | number | null, defaultValue: BigNumber = BN_ZERO) => {
  return parseBN(input, defaultValue)!;
};

export const charactersStr = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";

export const uuidv4 = () => {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, (c) => {
    const r = (Math.random() * 16) | 0;
    const v = c === "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
};

/**
 * Get currency symbol
 * @param currency 3-letter currency code
 */
export const currencySymbol = (currency: string) => {
  const upperCaseCurrency = currency.toUpperCase();
  let symbol = getSymbolFromCurrency(upperCaseCurrency);
  if (!symbol) {
    symbol = upperCaseCurrency;
  }
  return symbol;
};

export const randomInt = (min: number, max: number) => {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
};
