import BigNumber from "bignumber.js";
import { clsx } from "clsx";
import { Formik, FormikProps } from "formik";
import { ChangeEvent, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { IcoCash, IcoPoint } from "src/assets";
import { siteApi } from "src/main/api";
import { Button, Card, CardLogo, CardTitle, InputField, LoadingContainer, Slider } from "src/main/components";
import { BN_ZERO } from "src/main/contants";
import { useActions, useTypedSelector } from "src/main/hooks";
import { ExchangeTargetModel, ExchangeTargetStatus } from "src/main/models";
import { bnOrZero, fNumber } from "src/main/utils";
import Currency from "src/main/utils/currency";
import { PointSelectionPayload } from "src/main/views/CashbackPage/types";
import * as Yup from "yup";
import { SkeletonLoader } from "./components";

interface PointSelectionProps {
  onSubmit?: (payload: PointSelectionPayload) => void;
  defaultValue?: PointSelectionPayload;
}

type PointFormType = {
  points: string;
};

function StepSelectPoint({ onSubmit, defaultValue }: PointSelectionProps) {
  const { self } = useTypedSelector((state) => state.auth);
  const [exchangePoints, setExchangePoints] = useState(BN_ZERO);
  const [exchangeAmount, setExchangeAmount] = useState<string>("0");
  const [exchangePercent, setExchangePercent] = useState(0);
  const currentBalance = useMemo(
    () => bnOrZero(self?.member?.primaryWallet?.balance).integerValue(BigNumber.ROUND_FLOOR),
    [self],
  );
  const actions = useActions();
  const { t } = useTranslation();
  const formRef = useRef<FormikProps<PointFormType>>(null);

  const { data: exchangeVariantsGroup, isFetching: isFetchingConfig } = siteApi.useListExchangePointVariantsQuery({
    meta: {
      limit: 1,
      offset: 0,
      sort: "sortPriority:DESC",
      status: ExchangeTargetStatus.Active,
    },
  });

  const exchangeTarget = useMemo(() => {
    if (exchangeVariantsGroup?.targets) {
      const exchangeTargets = Object.entries(exchangeVariantsGroup.targets).map(([targetId, targetModel]) => {
        return {
          ...targetModel,
          id: targetId,
        } as ExchangeTargetModel;
      });
      // get the first target
      return exchangeTargets[0];
    }
  }, [exchangeVariantsGroup]);

  const exchangeVariant = useMemo(() => {
    if (exchangeTarget) {
      // get the first variant
      return exchangeTarget.variants[0];
    }
  }, [exchangeTarget]);

  const minExchangePoint = useMemo(() => bnOrZero(exchangeVariant?.product?.minQuantity), [exchangeVariant]);

  const redeemable = useMemo(() => currentBalance.gte(minExchangePoint), [currentBalance, minExchangePoint]);

  const amountPerOnePoint = useMemo(() => {
    return exchangeVariant?.properties?.["Value"] ?? 0;
  }, [exchangeVariant]);

  const currency = useMemo(() => {
    return exchangeTarget?.currency ?? "";
  }, [exchangeTarget]);

  useEffect(() => {
    let percent;
    let exchangePoint;

    if (!!defaultValue) {
      exchangePoint = defaultValue.points;
    } else {
      exchangePoint = currentBalance;
    }

    percent = exchangePoint.div(currentBalance).times(100).toNumber();
    setExchangePoints(exchangePoint);
    setExchangePercent(percent);
    setExchangeAmount(exchangePoint.times(amountPerOnePoint).toFixed(2));
  }, [currentBalance, minExchangePoint, defaultValue, amountPerOnePoint]);

  useEffect(() => {
    formRef.current?.handleChange({
      target: {
        name: "points",
        value: exchangePoints,
      },
    });
  }, [exchangePoints, formRef]);

  const updateAmountFromPoints = useCallback(
    (points: BigNumber) => {
      setExchangeAmount(points.times(amountPerOnePoint).decimalPlaces(2, BigNumber.ROUND_DOWN).toFixed(2));
    },
    [amountPerOnePoint],
  );

  const handleOnInputChangeExchangePoints = (evt: ChangeEvent<HTMLInputElement>) => {
    const points = evt.currentTarget.value;
    const newExchangePoints = bnOrZero(points);
    const percent = newExchangePoints.div(currentBalance).times(100).toNumber();

    setExchangePoints(newExchangePoints);
    setExchangePercent(Math.min(percent, 100));
    updateAmountFromPoints(newExchangePoints);
  };

  const handleOnChangePercentSlider = (e: ChangeEvent<HTMLInputElement>) => {
    const percent = e.currentTarget.value;
    const points = currentBalance.times(Number(percent) / 100).decimalPlaces(0, BigNumber.ROUND_FLOOR);

    setExchangePoints(points);
    setExchangePercent(Number(percent));
    updateAmountFromPoints(points);
  };

  const handleOnChangeCash = (evt: ChangeEvent<HTMLInputElement>) => {
    const cash = evt.currentTarget.value;

    const cashBn = bnOrZero(cash);
    const points = cashBn.div(amountPerOnePoint).decimalPlaces(0, BigNumber.ROUND_FLOOR);
    const percent = points.div(currentBalance).times(100).toNumber();

    setExchangePoints(points);
    setExchangeAmount(cash);
    setExchangePercent(Math.min(percent, 100));
  };

  const correctAmountOnBlur = () => {
    const percent = exchangePoints.div(currentBalance).times(100).toNumber();
    setExchangePoints(exchangePoints);
    setExchangePercent(Math.min(percent, 100));
    updateAmountFromPoints(exchangePoints);
  };

  const handleOnGetMorePoints = useCallback(() => {
    actions.launchPlaynow({ launch: true });
  }, [actions]);

  const handleOnClickNext = useCallback(() => {
    formRef.current?.submitForm();
  }, [formRef]);

  const pointValidation = useMemo(() => {
    return Yup.object().shape({
      points: Yup.string()
        .test("min-exchange", t("Minimum points", { points: fNumber(minExchangePoint) }) ?? "", (value) => {
          return bnOrZero(value).gte(minExchangePoint);
        })
        .test("max-exchange", t("Maximum points", { points: fNumber(currentBalance) }) ?? "", (value) => {
          return bnOrZero(value).lte(currentBalance);
        }),
    });
  }, [t, minExchangePoint, currentBalance]);

  const handleFormSubmit = () => {
    if (onSubmit) {
      onSubmit({
        points: bnOrZero(exchangePoints),
        amount: bnOrZero(exchangeAmount),
        exchangeVariant: exchangeVariant!,
        exchangeTarget: exchangeTarget!,
      });
    }
  };

  const pointFormInitialValues = useMemo(
    () => ({
      points: fNumber(minExchangePoint.gte(currentBalance) ? currentBalance : minExchangePoint),
    }),
    [currentBalance, minExchangePoint],
  );

  const displayedExchangeRate = useMemo(() => {
    const bnAmountPerOnePoint = bnOrZero(amountPerOnePoint);
    let minimumAmount = bnAmountPerOnePoint.toNumber();
    let exchangePointsPerOneAmount = 1;
    if (bnAmountPerOnePoint.lt(1) && bnAmountPerOnePoint.gt(0)) {
      // show how many points for 1 unit of currency
      const times = bnOrZero(1).dividedBy(bnAmountPerOnePoint);
      minimumAmount = 1;
      exchangePointsPerOneAmount = times.decimalPlaces(0, BigNumber.ROUND_DOWN).toNumber();
    }
    return (
      <span>
        {fNumber(exchangePointsPerOneAmount)} {t("point_other")}
        {" = "}
        <span className="tracking-widest">
          {Currency(minimumAmount).format(currency, {
            maximumFractionDigits: 0,
          })}
        </span>
      </span>
    );
  }, [amountPerOnePoint, t, currency]);

  return (
    <LoadingContainer
      loading={isFetchingConfig}
      loader={<SkeletonLoader />}
    >
      <div className="px-4 pt-8 lg:mt-24">
        {exchangeVariant && (
          <Card
            variant="gradient-l"
            className="mx-auto animate-fade-in pt-4 lg:max-w-lg"
          >
            <CardLogo
              float
              src="/images/icon/cash-back-logo.gif"
              className="h-auto w-20"
            />
            <CardTitle className="capitalize">{t("cashBack")}</CardTitle>
            <Card
              variant="container-light"
              border={false}
              className="mt-2 gap-y-1 shadow-xl"
            >
              <div className="mb-1 flex items-baseline justify-between">
                <h2 className="text-sm text-white">{t("vip points")}</h2>
                <h2 className="text-xs font-normal text-token-secondary-2">
                  {t("balance point")}: {fNumber(currentBalance)}
                </h2>
              </div>
              <div>
                <Formik
                  innerRef={formRef}
                  validationSchema={pointValidation}
                  initialValues={pointFormInitialValues}
                  onSubmit={handleFormSubmit}
                >
                  {({ errors, touched }) => (
                    <div className="relative">
                      <InputField
                        name="points"
                        label=""
                        value={exchangePoints.toString()}
                        onChange={handleOnInputChangeExchangePoints}
                        inputClassName={clsx("!py-2.5 text-right text-xl font-bold", {
                          "!border-error-500": !!errors.points,
                          "!border-token-border": !errors.points,
                        })}
                        startIconClassName="!translate-x-0 top-3 left-2 !translate-y-0"
                        inputMode={"numeric"}
                        startIcon={
                          <IcoPoint
                            width={26}
                            height={26}
                          />
                        }
                        error={!!errors.points && !!touched.points}
                        helperText={errors.points ?? t("Minimum points", { points: fNumber(minExchangePoint) })}
                        helperTextClassName={clsx("-bottom-5.5 absolute right-0 text-right", {
                          "text-error-500": !!errors.points,
                          "text-token-secondary-2": !errors.points,
                        })}
                        autoComplete="off"
                        type="currency"
                        currencyProps={{
                          allowNegativeValue: false,
                          allowDecimals: false,
                          inputMode: "numeric",
                        }}
                      />
                    </div>
                  )}
                </Formik>
              </div>
              <div className="mt-10 mb-3 px-3">
                <Slider
                  name="slider"
                  value={exchangePercent}
                  onChange={handleOnChangePercentSlider}
                  min={0}
                  max={100}
                  quickPoints={[0, 25, 50, 75, 100]}
                  skipEventOnQuickPoints={[0, 100]}
                />
              </div>
            </Card>

            {redeemable && (
              <div className="z-[2] mx-auto -mt-2 -mb-2 rounded-full bg-[#2F1B66] p-2 drop-shadow-xl">
                <img
                  src="/images/icon/arrow-flush-down.gif"
                  alt="flush-all-icon"
                  className="translate-y-0.5"
                  height={20}
                  width={20}
                />
              </div>
            )}

            {redeemable && (
              <Card
                variant="container-light"
                border={false}
                className="z-[1] gap-y-1 shadow-xl"
              >
                <div className="mb-1 flex items-baseline justify-between">
                  <h2 className="text-sm text-white">{t("cash")}</h2>
                  <h2 className="text-xs font-normal text-token-secondary-2">{displayedExchangeRate}</h2>
                </div>
                <div>
                  <InputField
                    name="exchange-amount"
                    label=""
                    value={exchangeAmount}
                    onChange={handleOnChangeCash}
                    inputClassName="text-xl !py-2.5 font-bold text-right"
                    startIconClassName="!translate-x-0 top-3 left-2 !translate-y-0"
                    startIcon={
                      <IcoCash
                        height={26}
                        width={26}
                      />
                    }
                    autoComplete="off"
                    type="currency"
                    currencyProps={{
                      decimalScale: 2,
                      fixedDecimalLength: 2,
                      allowNegativeValue: false,
                      inputMode: "numeric",
                    }}
                    onBlur={correctAmountOnBlur}
                  />
                </div>
              </Card>
            )}
            <div className="mt-3 w-full">
              {redeemable && (
                <Button
                  variant="blue-violet"
                  onClick={handleOnClickNext}
                  size="xl"
                >
                  {t("Next")}
                </Button>
              )}

              {!redeemable && (
                <Button
                  variant="orange-yellow"
                  onClick={handleOnGetMorePoints}
                  size="xl"
                >
                  {t("Get More Points")}
                </Button>
              )}
            </div>
          </Card>
        )}
      </div>
    </LoadingContainer>
  );
}

export default StepSelectPoint;
