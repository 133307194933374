import { PropsWithChildren, useCallback, useEffect, useMemo, useState } from "react";
import CountUp from "react-countup";
import { useTranslation } from "react-i18next";
import { FaClipboardCheck } from "react-icons/fa6";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { IcoGift, IcoProfile } from "src/assets";
import IcoChat from "src/assets/IcoChat";
import { ContactModal } from "src/main/components";
import { CartIcon } from "src/main/components/_layouts/TopBar/components";
import { ID_TOP_BAR_COIN_ICON } from "src/main/contants";
import { useProfileBalance } from "src/main/hooks";
import { getDeployEnv, Paths } from "src/main/utils";
import { PlayNowIcon } from "src/main/views/component";
import { TopNavItem } from "./components";
import { useNavIndicator } from "../hooks";

interface TopNavigationProps extends PropsWithChildren {}

const currentEnv = getDeployEnv();

const TopBarNavigation = (props: TopNavigationProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  // const actions = useActions();
  const { nextBalance, prevBalance } = useProfileBalance();
  const [isShowChat, setIsShowChat] = useState(false);
  const countUpAnimationDuration = useMemo(() => {
    // only show animation when the balance is increased
    return prevBalance.toNumber() < nextBalance.toNumber() ? 1.5 : 0.01;
  }, [prevBalance, nextBalance]);
  const { claimableMissionState } = useNavIndicator();

  const toggleChat = useCallback(() => {
    const networks = currentEnv?.contactPlatforms;
    if (networks.length === 1) {
      return window.open(networks[0].url, "_blank");
    }

    setIsShowChat((status) => !status);
  }, []);

  // close chat when navigate to another page
  useEffect(() => {
    setIsShowChat(false);
  }, [location.pathname]);

  return (
    <div className="ml-auto flex items-center">
      {/*MOBILE*/}
      <div
        className="flex items-center gap-x-1 lg:hidden"
        onClick={() => {
          navigate(Paths.Private.CashBack);
        }}
      >
        <img
          src="/images/icon/heart_icon_yellow.svg"
          alt="points-icon"
          className="h-5 w-5"
          id={ID_TOP_BAR_COIN_ICON.MOBILE}
        />
        <div className="text-sm font-bold text-white">
          <CountUp
            start={prevBalance.toNumber()}
            end={nextBalance.toNumber()}
            duration={countUpAnimationDuration}
            separator=","
          />
        </div>
      </div>

      {/*DESKTOP*/}
      <div className="z-10 flex justify-end gap-4 lg:pt-0.5">
        {/*POINTS*/}
        <TopNavItem
          linkTo={Paths.Private.CashBack}
          title={
            <CountUp
              start={prevBalance.toNumber()}
              end={nextBalance.toNumber()}
              duration={countUpAnimationDuration}
              separator=","
            />
          }
          icon={
            <img
              src="/images/icon/heart_icon_yellow.svg"
              height="32"
              width="32"
              className="mx-auto mt-1"
              alt="points-icon"
              id={ID_TOP_BAR_COIN_ICON.DESKTOP}
            />
          }
        />

        {/*REWARDS*/}
        <TopNavItem
          title={t("rewards")}
          linkTo={Paths.Private.Home}
          icon={<IcoGift className="h-full w-full" />}
        />

        {/*MISSION*/}
        <TopNavItem
          indicator={claimableMissionState ? "claimable-indicator" : undefined}
          title={t("Missions")}
          linkTo={Paths.Private.Missions}
          icon={
            <FaClipboardCheck
              // color="#B9A3F1"
              className="h-full w-full py-0.5"
            />
          }
        />

        {/*PLAY NOW*/}
        <div className="relative hidden cursor-pointer lg:block">
          <PlayNowIcon />
        </div>

        {/*PROFILE*/}
        <TopNavItem
          title={t("profile")}
          linkTo={Paths.Private.Profile}
          icon={<IcoProfile className="h-full w-full" />}
        />

        {/*CHAT*/}
        <TopNavItem
          title={t("chat")}
          icon={<IcoChat className="h-full w-full pt-0.5" />}
          indicator="chat-indicator"
          onClick={toggleChat}
        />

        <div className="no-tap-highlight cursor-pointer">
          <div className="ml-2 mr-3 lg:hidden">
            {location.pathname !== Paths.Private.CashBack && (
              <Link
                to={Paths.Private.Cart}
                relative="path"
              >
                <div className="relative flex flex-col">
                  <CartIcon />
                </div>
              </Link>
            )}
          </div>

          <div className="hidden lg:inline-flex">
            <button
              className="rounded text-xs font-medium leading-tight shadow-md ease-in-out lg:inline-flex"
              type="button"
              data-bs-toggle="offcanvas"
              data-bs-target="#offcanvasRight"
              aria-controls="offcanvasRight"
            >
              <div className="relative flex flex-col">
                <CartIcon />
              </div>
            </button>
          </div>
        </div>
      </div>

      {/*CONTACT MODAL*/}

      <ContactModal
        show={isShowChat}
        onClose={toggleChat}
      />
    </div>
  );
};

export default TopBarNavigation;
