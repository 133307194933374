interface TransitionProps {
  enter?: string;
  enterFrom?: string;
  enterTo?: string;
  leave?: string;
  leaveFrom?: string;
  leaveTo?: string;
}

export const TRANSITION_IN_SLIDE_UP: TransitionProps = {
  enter: "transition-all duration-300",
  enterFrom: "opacity-0 translate-y-48",
  enterTo: "opacity-100 translate-y-0",
};

export const TRANSITION_IN_SLIDE_DOWN: TransitionProps = {
  enter: "transition-all duration-300",
  enterFrom: "opacity-0 -translate-y-48",
  enterTo: "opacity-100 translate-y-0",
};

export const TRANSITION_OUT_SLIDE_DOWN: TransitionProps = {
  leave: "transition-all duration-300",
  leaveFrom: "opacity-100 translate-y-0",
  leaveTo: "opacity-0 translate-y-48",
};

export const TRANSITION_OUT_SLIDE_UP: TransitionProps = {
  leave: "transition-all duration-300",
  leaveFrom: "opacity-100 translate-y-0",
  leaveTo: "opacity-0 -translate-y-48",
};

export const TRANSITION_IN_SLIDE_LEFT: TransitionProps = {
  enter: "transition-all duration-300",
  enterFrom: "opacity-0 translate-x-48",
  enterTo: "opacity-100 translate-x-0",
};

export const TRANSITION_OUT_SLIDE_RIGHT: TransitionProps = {
  leave: "transition-all duration-300",
  leaveFrom: "opacity-100 translate-x-0",
  leaveTo: "opacity-0 translate-x-48",
};

export const TRANSITION_IN_SLIDE_RIGHT: TransitionProps = {
  enter: "transition-all duration-300",
  enterFrom: "opacity-0 -translate-x-48",
  enterTo: "opacity-100 translate-x-0",
};

export const TRANSITION_OUT_SLIDE_LEFT: TransitionProps = {
  leave: "transition-all duration-300",
  leaveFrom: "opacity-100 translate-x-0",
  leaveTo: "opacity-0 -translate-x-48",
};

export const TRANSITION_IN_FADE: TransitionProps = {
  enter: "transition-all duration-300",
  enterFrom: "opacity-0",
  enterTo: "opacity-100",
};

export const TRANSITION_OUT_FADE: TransitionProps = {
  leave: "transition-all duration-300",
  leaveFrom: "opacity-100",
  leaveTo: "opacity-0",
};
