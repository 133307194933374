import { clsx } from "clsx";
import { PropsWithChildren } from "react";
import { bnOrZero } from "src/main/utils";
import "./BonusBadge.css";

interface BonusBadgeProps extends PropsWithChildren {
  // Value from 0 to 1
  percent: number;
  className?: string;
}

const BonusBadge = ({ percent, className }: BonusBadgeProps) => {
  return (
    <div
      className={clsx(
        "relative flex h-fit items-center overflow-hidden rounded-md bg-[linear-gradient(286.87deg,#7230FF_0%,#EA3E93_100%)] py-0.5 px-1",
        className,
      )}
    >
      <div className="absolute top-0 left-0 h-[80%] w-[140%] animate-[badgeShine_1.2s_linear_infinite] bg-[linear-gradient(180deg,rgba(255,255,255,0)_0%,rgba(255,255,255,0.3)_47%,rgba(255,255,255,0.3)_100%)]" />
      <span className="z-[10] mx-auto inline-block text-xs font-bold tracking-wide text-white text-shadow-md">
        +{bnOrZero(percent).times(100).toNumber()}%
      </span>
    </div>
  );
};

export default BonusBadge;
