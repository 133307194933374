import { useTranslation } from "react-i18next";
import { SpreadShadow, TopSectionRibbon } from "./components";
import "./MissionTopSection.css";

export default function MissionTopSection() {
  const { t } = useTranslation();

  return (
    <div className="relative m-auto mb-5 flex w-80 flex-col items-center pt-[60px]">
      <SpreadShadow />
      <TopSectionRibbon />
      <div className="left-50% missions-page-title absolute bottom-[9px] z-[16] text-center text-[28px] font-bold uppercase leading-8 text-shadow-[1px_1px_0px_rgba(0,0,0,0.25)]">
        {t("Missions")}
      </div>
    </div>
  );
}
