import moment from "moment";
import { appConfig } from "src/config";
import { DeployCountry } from "src/main/types";

const CLICK_GENERATOR_URL = "https://a.vtrk1.co/d/:campaignId.js?oref=&ourl=:siteUrl&opt=&vtm=:timestamp";

export const retrieveNewVoluumCid = async (deployCountry: DeployCountry) => {
  const config = appConfig.countries[deployCountry].postback;
  if (!config) throw new Error("invalid deploy country:" + deployCountry);

  const cidUrl = CLICK_GENERATOR_URL.replace(":campaignId", config.campaignId)
    .replace(":siteUrl", config.siteUrl)
    .replace(":timestamp", moment().unix().toString());

  const response = await fetch(cidUrl);
  const result = await response.text();
  const cid = result.match(/"cid":"([a-z0-9]+)"/i)?.[1];
  if (!cid) throw new Error("unable to retrieve voluum cid");

  return cid;
};
