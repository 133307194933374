export default function TopSectionRibbon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="320"
      height="30"
      viewBox="0 0 343 30"
      fill="none"
      className="-mt-[33px]"
    >
      <path
        d="M342.855 1.36719H289.483C285.239 1.36719 281.17 3.0529 278.169 6.05348L260.042 24.1809C257.041 27.1815 252.972 28.8672 248.728 28.8672H96.4829C92.2394 28.8672 88.1698 27.1815 85.1692 24.1809L67.0418 6.05348C64.0412 3.0529 59.9715 1.36719 55.7281 1.36719H0.855469"
        stroke="url(#paint0_linear_1588_15410)"
        strokeWidth="2"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1588_15410"
          x1="0.855469"
          y1="15.1172"
          x2="342.855"
          y2="15.1172"
          gradientUnits="userSpaceOnUse"
        >
          <stop
            stopColor="#B02DBF"
            stopOpacity="0"
          />
          <stop
            offset="0.503267"
            stopColor="#B02DBF"
          />
          <stop
            offset="1"
            stopColor="#B02DBF"
            stopOpacity="0"
          />
        </linearGradient>
      </defs>
    </svg>
  );
}
