import { clsx } from "clsx";
import { useNavigate } from "react-router-dom";

// export default function IcoArrowleft(props: IcoArrowleftProps) {

function IcoArrowleft(props: IcoArrowleftProps) {
  const { pathUrl } = props;

  const navigate = useNavigate();
  const goBack = () => {
    if (pathUrl) {
      navigate(pathUrl);
      return;
    }
    navigate(-1);
  };

  return (
    <div
      className={clsx("h-7 w-[30px]", props.className)}
      onClick={goBack}
    >
      <svg
        width="100%"
        height="100%"
        preserveAspectRatio="none"
        viewBox="0 0 30 28"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M 21.158 2.612 C 22.024 3.429 22.024 4.753 21.158 5.569 L 12.744 13.5 L 21.158 21.431 C 22.024 22.247 22.024 23.571 21.158 24.388 C 20.292 25.204 18.887 25.204 18.021 24.388 L 9.607 16.457 C 7.874 14.824 7.874 12.176 9.607 10.543 L 18.021 2.612 C 18.887 1.796 20.292 1.796 21.158 2.612 Z"
          fill="white"
        />
      </svg>
    </div>
  );
}

export default IcoArrowleft;

IcoArrowleft.defaultProps = {
  pathUrl: "",
};

interface IcoArrowleftProps {
  pathUrl?: string;
  className?: string;
}
