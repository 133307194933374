import React from "react";

const PageNotFound: React.FC = () => {
  return (
    <div className="flex h-screen">
      <div className="m-auto text-white">
        <h1>Page Not Found</h1>
      </div>
    </div>
  );
};

export default PageNotFound;
