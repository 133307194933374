import clsx from "clsx";
import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { FaCirclePlusGradient } from "src/assets";
import { siteApi } from "src/main/api";
import { Button, Card, Page, Radio } from "src/main/components";
import { RootState } from "src/main/store";
import productSlice, { ProductState } from "src/main/store/product/slices";
import { ShippingAddressModel } from "src/main/types";
import { Paths } from "src/main/utils";
import { SkeletonLoader } from "src/main/views/AddressListPage/components";
import AddressItem from "../AddressItem";

interface Props {
  isManage?: boolean;
}

export default function AddressListContent({ isManage = false }: Props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { checkoutShippingInfo } = useSelector<RootState, ProductState>((state) => state.products);
  const addressRes = siteApi.useListShippingAddressQuery({
    meta: { limit: 100 },
  });

  const addresses = useMemo(() => addressRes.data?.entries ?? [], [addressRes]);

  const handleChangeCheckoutAddress = (address: ShippingAddressModel) => {
    if (!!isManage) return;

    dispatch(productSlice.actions.updateShippingInfo(address));
    navigate(Paths.Private.Checkout);
  };

  const handleAddNewAddress = useCallback(() => {
    navigate(isManage ? Paths.Private.ManageAddressNew : Paths.Private.AddressNew);
  }, [navigate, isManage]);

  const isEmpty = useMemo(() => !addresses.length, [addresses]);

  return (
    <>
      <Page
        className={clsx(
          {
            "lg:mt-20": isManage,
          },
          "text-white",
        )}
        loading={addressRes.isFetching}
        loader={<SkeletonLoader />}
        paddingX
        title={
          <>
            {!isManage && <p className="text-center font-bold">{t(isEmpty ? "New Address" : "Address Selection")}</p>}

            {!!isManage && <p className="text-center font-bold lg:hidden">{t("manage address")}</p>}
          </>
        }
        footer={false}
        mobileView
      >
        <Card
          className="flex flex-col items-center gap-6 !rounded-lg px-4 pt-3"
          variant="container-gradient"
        >
          {/*Address list*/}
          {!isEmpty && (
            <div className="w-full pb-4">
              {addresses.map((address, index) => (
                <div
                  className="mb-3.5 flex max-w-full border-b border-[#6B7280A3] p-2 pb-[22px] last:mb-0 last:border-none last:pb-2"
                  key={address.id ?? index}
                >
                  {!isManage && (
                    <Radio
                      className="mr-4 mt-1.5 h-5 w-5"
                      checked={checkoutShippingInfo?.id === address.id}
                      onClick={() => handleChangeCheckoutAddress(address)}
                      variant="filled"
                    />
                  )}

                  <AddressItem
                    handleClick={() => handleChangeCheckoutAddress(address)}
                    address={address}
                    isManage={isManage}
                  />
                </div>
              ))}
            </div>
          )}

          {/*Empty State*/}
          {isEmpty && (
            <p className="pt-2 text-center text-xs text-content-secondary">
              {t("Lets start by adding your first shipping address")}
            </p>
          )}

          <Button
            className={clsx("uppercase h-12", {
              "mb-12": isEmpty,
            })}
            variant="secondary"
            size="md"
            icon={<FaCirclePlusGradient className="h-5 w-5" />}
            onClick={handleAddNewAddress}
          >
            {t("Add New Address")}
          </Button>
        </Card>
      </Page>
    </>
  );
}
