import React from "react";

import { Trans, useTranslation } from "react-i18next";

import "./FAQsContent.css";

import ExpandableCard from "src/main/views/component/ExpandableCard";

const FAQs = [
  {
    title: "Point_1",
    content: "Point_1_1",
  },
  {
    title: "Point_2",
    content: "Point_2_1",
  },
  {
    title: "Point_3",
    content: "Point_3_1",
  },
  {
    title: "Point_4",
    content: "Point_4_1",
  },
  {
    title: "Ordering_2",
    content: "Ordering_2_1",
  },
];
const FAQsContent: React.FC = () => {
  const { t } = useTranslation();
  return (
    <div>
      <div className={`w-full px-3 lg:px-0`}>
        <div className="">
          <div className="flex items-center justify-center pb-[19px] pt-[7px] text-center text-white lg:hidden">
            <p className="text-xl font-bold leading-6">{t("FAQ")}</p>
          </div>
          {FAQs.map((faq, index) => (
            <ExpandableCard
              key={index}
              title={faq.title}
              content={t(faq.content)}
              index={index}
            />
          ))}
        </div>
        {/* <div className="mt-4 hidden w-full text-center text-[12px] lg:block">
          <p className=" inline leading-[18px] leading-[normal] text-white">
            <span className="m-0 inline font-bold  leading-[normal]">Need help? </span>
            Contact our support team
          </p>
        </div> */}
        <div className="">
          <div className="flex h-20 w-full items-center justify-center text-center text-white lg:justify-start">
            <p className="mt-5 font-bold leading-6 lg:font-bold">{t("Tnc")}</p>
          </div>
          <div className="text-[14px] font-normal">
            <div className="mt-1 flex w-full lg:mt-8">
              <div
                className={`inline-flex h-6 w-6 flex-col items-center justify-center rounded-[50px] bg-[rgba(210,193,255,0.15)] text-center font-bold text-white `}
              >
                <p className="m-0 text-sm leading-[10px]">1</p>
              </div>
              <p className="m-0 ml-3 flex-1 leading-4 text-white">{t("Orders_1")}</p>
            </div>
            <div className="mt-4 flex w-full">
              <div
                className={`inline-flex h-6 w-6 flex-col items-center justify-center rounded-[50px] bg-[rgba(210,193,255,0.15)] text-center font-bold text-white `}
              >
                <p className="m-0 text-sm leading-[10px]">2</p>
              </div>
              <p className="m-0 ml-3 flex-1 leading-4 text-white">{t("Orders_2")}</p>
            </div>
            <div className="mt-4 flex w-full">
              <div
                className={`inline-flex h-6 w-6 flex-col items-center justify-center rounded-[50px] bg-[rgba(210,193,255,0.15)] text-center font-bold text-white `}
              >
                <p className="m-0 text-sm leading-[10px]">3</p>
              </div>
              <p className="m-0 ml-3 flex-1 leading-4 text-white">{t("Orders_3")}</p>
            </div>
            <div className="mt-4 flex w-full">
              <div
                className={`inline-flex h-6 w-6 flex-col items-center justify-center rounded-[50px] bg-[rgba(210,193,255,0.15)] text-center font-bold text-white `}
              >
                <p className="m-0 text-sm leading-[10px]">4</p>
              </div>
              <p className="m-0 ml-3 flex-1 leading-4 text-white">
                {/* {t("Orders_4")} */}
                <Trans
                  i18nKey="Orders_4"
                  components={{
                    1: <span className="font-bold" />,
                  }}
                />
              </p>
            </div>
            <div className="mt-4 flex w-full">
              <div
                className={`inline-flex h-6 w-6 flex-col items-center justify-center rounded-[50px] bg-[rgba(210,193,255,0.15)] text-center font-bold text-white `}
              >
                <p className="m-0 text-sm leading-[10px]">5</p>
              </div>
              <p className="m-0 ml-3 flex-1 leading-4 text-white">{t("Orders_5")}</p>
            </div>
          </div>

          {/* <div className="mt-4 flex w-full text-center text-[12px] lg:hidden">
            <p className="inline flex-1 leading-[18px] leading-[normal] text-white">
              <span className="m-0 inline font-bold  leading-[normal]">Need help? </span>
              Contact our support team or go through our FAQs.
            </p>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default FAQsContent;
