import { Route, Routes } from "react-router-dom";
import {
  AddressListPage,
  AddressUpdatePage,
  Authorize,
  CartPage,
  CashbackPage,
  ChangeLanguage,
  ChangePassword,
  CheckoutPage,
  FAQs,
  GiftsPage,
  LoginPage,
  Logout,
  Maintenance,
  MissionsPage,
  OrderDetail,
  OrderHistory,
  PageNotFound,
  ProductPage,
  Profile,
  RedeemFailedPage,
  RedeemSuccessfulPage,
  RewardsPage,
  TermsAndCondition,
} from "src/main/views";

import * as Sentry from "@sentry/react";
import ReactPixel from "react-facebook-pixel";
import { PageTransitionOverlay } from "src/main/components";
import { DeployCountry, DeployEnv } from "src/main/types";
import { Navigate } from "./main/components";
import { getDeployEnv, logger, Paths } from "./main/utils";
import PermissionDenied from "./main/utils/PermissionDenied";
import ProtectedRoute from "./main/utils/ProtectedRoute";
import PublicRoutes from "./main/utils/PublicRoutes";
import SemiProtectedRoute from "./main/utils/SemiProtectedRoute";
import AddToHomeScreen from "./main/views/component/AddToHomeScreen/AddToHomeScreen";
import { VipCheck, VipStatus, VipStatusInfo, VipStatusSignup } from "./main/views/LandingPage";
import { SessionCheck } from "./main/views/RemoteLogin";
import { ForceUpdatePassword, MobileResetPassword } from "./main/views/ResetPassword";

const currentEnv = getDeployEnv();

const SENTRY_DSN = process.env.REACT_APP_SENTRY_DSN;
const appVersion = process.env.REACT_APP_VERSION;

if (!!SENTRY_DSN && currentEnv.deploymentType === DeployEnv.Production) {
  try {
    // Sentry.configureScope((scope) => {
    //   scope.setExtra("version", appVersion);
    // });

    Sentry.setTag("version", appVersion);

    Sentry.init({
      dsn: SENTRY_DSN,
      environment: currentEnv.deployCountry === DeployCountry.PH ? "PH" : undefined,
      integrations: [new Sentry.BrowserTracing()],
      // Performance Monitoring
      tracesSampleRate: 0.5, // Capture % of the transactions
    });
  } catch (error) {
    logger._console.log("sentry init failed");
  }
}

const options = {
  autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
  debug: false, // enable logs
};

if (currentEnv.envKey) {
  const pixelEnvKey = `REACT_APP_${currentEnv.envKey}_FB_PIXEL_ID`;
  let pixelIdString = "";
  if (currentEnv.deploymentType === DeployEnv.Production) {
    pixelIdString = process.env[pixelEnvKey] ?? "";
  } else {
    pixelIdString = process.env.REACT_APP_FB_PIXEL_ID ?? "";
  }

  if (pixelIdString) {
    const pixelIds = pixelIdString.split(",");
    pixelIds.forEach((pi) => {
      const pixelId = pi.trim();
      if (pixelId) ReactPixel.init(pixelId, undefined, options);
    });

    ReactPixel.pageView();
  }
}

function AppRoutes() {
  return (
    <>
      <PageTransitionOverlay />
      <Routes>
        {/** Protected Routes */}
        <Route element={<ProtectedRoute />}>
          <Route
            path="/"
            element={
              <Navigate
                replace
                to={Paths.Private.Home}
              />
            }
          />
          <Route
            path={Paths.Private.Missions}
            element={<MissionsPage />}
          />
          <Route
            path={Paths.Private.Home}
            element={<RewardsPage />}
          />
          <Route
            path={Paths.Private.Gifts}
            element={<GiftsPage />}
          />
          <Route
            path={Paths.Private.Product}
            element={<ProductPage />}
          />
          <Route
            path={Paths.Private.Profile}
            element={<Profile />}
          />
          <Route
            path={Paths.Private.RedeemSuccessful}
            element={<RedeemSuccessfulPage />}
          />
          <Route
            path={Paths.Private.Cart}
            element={<CartPage />}
          />
          <Route
            path={Paths.Private.ChangePassword}
            element={<ChangePassword />}
          />
          <Route
            path={Paths.Private.OrderHistory}
            element={<OrderHistory />}
          />
          <Route
            path={Paths.Private.Checkout}
            element={<CheckoutPage />}
          />
          <Route
            path={Paths.Private.TermsAndCondition}
            element={<TermsAndCondition />}
          />
          <Route
            path={Paths.Private.FAQs}
            element={<FAQs />}
          />
          <Route
            path={Paths.Private.ChangeLanguage}
            element={<ChangeLanguage />}
          />
          <Route
            path={Paths.Private.OrderDetail}
            element={<OrderDetail />}
          />
          <Route
            path={Paths.Private.RedeemFailed}
            element={<RedeemFailedPage />}
          />
          <Route
            path={Paths.Private.PointExchange}
            element={<CashbackPage />}
          />
          <Route
            path={Paths.Private.AddressList}
            element={<AddressListPage />}
          />
          <Route
            path={Paths.Private.AddressNew}
            element={<AddressUpdatePage />}
          />
          <Route
            path={Paths.Private.AddressDetail}
            element={<AddressUpdatePage />}
          />
          <Route
            path={Paths.Private.ManageAddress}
            element={<AddressListPage isManage />}
          />
          <Route
            path={Paths.Private.ManageAddressNew}
            element={<AddressUpdatePage isManage />}
          />
          <Route
            path={Paths.Private.ManageAddressDetail}
            element={<AddressUpdatePage isManage />}
          />
          <Route
            path={Paths.Private.CashBack}
            element={<CashbackPage />}
          />
          <Route
            path="/*"
            element={<PageNotFound />}
          />
        </Route>

        {/** Public Routes */}
        <Route element={<PublicRoutes />}>
          <Route
            element={
              <Navigate
                replace
                to={Paths.Public.Landing}
              />
            }
            path={Paths.Public.Login}
          />
          <Route
            element={<LoginPage />}
            path={Paths.Public.MobileLogin}
          />
          <Route
            element={<MobileResetPassword page="reset" />}
            path={Paths.Public.MobileResetPassword}
          />
          <Route
            element={<MobileResetPassword page="setup" />}
            path={Paths.Public.MobileSetupPassword}
          />
          <Route
            path={Paths.Public.ForceUpdatePassword}
            element={<ForceUpdatePassword />}
          />

          <Route
            element={
              <Navigate
                replace
                to={currentEnv.isMain ? Paths.Public.VipCheck : Paths.Public.VipStatus}
              />
            }
            path={Paths.Public.Landing}
          />

          <Route
            path={Paths.Public.VipStatus}
            element={<VipStatus />}
          />
          <Route
            path={Paths.Public.VipStatusUsername}
            element={<VipStatusInfo />}
          />
          <Route
            path={Paths.Public.VipStatusSignup}
            element={<VipStatusSignup />}
          />
          <Route
            path={Paths.Public.VipCheck}
            element={<VipCheck />}
          />
        </Route>

        <Route element={<SemiProtectedRoute />} />
        <Route
          path={Paths.Public.Authorize}
          element={<Authorize />}
        />
        <Route
          path={Paths.Public.SessionCheck}
          element={<SessionCheck />}
        />
        <Route
          path={Paths.Public.Logout}
          element={<Logout />}
        />
        <Route
          path={Paths.Public.Denied}
          element={<PermissionDenied />}
        />
        <Route
          path={Paths.Public.Maintenance}
          element={<Maintenance />}
        />
      </Routes>
      <AddToHomeScreen />
    </>
  );
}

export default AppRoutes;
