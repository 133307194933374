import moment from "moment";
import { useMemo } from "react";
import { useDispatch } from "react-redux";
import { authApi, siteApi } from "src/main/api";
import { useTypedSelector } from "src/main/hooks";
import { RootState } from "src/main/store";
import authSlice from "src/main/store/auth/slices";
import { RewardInfo, RewardType } from "src/main/types";
import { RewardBoxStatus, RewardInfoData } from "../types";

/**
 * rule: [current <  now  < next]
 * did claim ?
 *  - no  -> claimable
 *  - yes -> claimedAt in range (current, next) ?
 *        - no  -> claimable
 *        - yes -> show count down
 */
const getDiff = (rewardInfo: RewardInfo | undefined, offset: number) => {
  const lastClaimedAt = rewardInfo?.lastClaimedAt ?? null;
  const currentPeriodResetDate = rewardInfo?.currentPeriodResetDate ?? null;
  const nextPeriodResetDate = rewardInfo?.nextPeriodResetDate ?? null;

  if (lastClaimedAt === null) {
    return 0;
  }
  if (moment(lastClaimedAt).isBefore(moment(currentPeriodResetDate))) {
    return 0;
  }

  const now = moment();
  const target = moment.utc(nextPeriodResetDate).utcOffset(offset);

  return Math.ceil(target.diff(now, "seconds"));
};

const QUERY_CACHE_IN_SECONDS = 2;
const ONE_DAY_IN_SECONDS = 24 * 60 * 60;

export default function useGetRewardsInfo(type?: RewardType) {
  const result = siteApi.useGetRewardStatusQuery(undefined, {
    // If there is an existing query, but the amount of time specified since the last query has not elapsed, it will serve the existing cached data.
    refetchOnMountOrArgChange: QUERY_CACHE_IN_SECONDS,
  });
  const profileQuery = authApi.useProfileQuery();
  const offset = useTypedSelector((state: RootState) => state.auth.utcOffset);
  const dispatch = useDispatch();

  const data: Record<RewardType, RewardInfoData> | null = useMemo(() => {
    if (!result.data) return null;
    const { daily, monthly, weekly, vip } = result.data.result;

    return {
      [RewardType.DAILY]: {
        remainingSeconds: getDiff(daily, offset ?? 0),
        periodInSeconds: ONE_DAY_IN_SECONDS,
      },
      [RewardType.VIP]: {
        remainingSeconds: getDiff(vip, offset ?? 0),
        claimPoints: Number(vip?.balance ?? 0),
        periodInSeconds: ONE_DAY_IN_SECONDS,
      },
      [RewardType.WEEKLY]: {
        remainingSeconds: getDiff(weekly, offset ?? 0),
        claimPoints: Number(weekly?.balance ?? 0),
        periodInSeconds: weekly
          ? moment.duration(moment(weekly.nextPeriodResetDate).diff(moment(weekly.currentPeriodResetDate))).asSeconds()
          : moment.duration(1, "week").asSeconds(),
      },
      [RewardType.MONTHLY]: {
        remainingSeconds: getDiff(monthly, offset ?? 0),
        claimPoints: Number(monthly?.balance ?? 0),
        periodInSeconds: monthly
          ? moment
              .duration(moment(monthly.nextPeriodResetDate).diff(moment(monthly.currentPeriodResetDate)))
              .asSeconds()
          : moment.duration(1, "month").asSeconds(),
      },
    };
  }, [offset, result.data]);

  const refetch = async () => {
    if (result.isUninitialized) return;
    result.refetch();
    const profileResult = await profileQuery.refetch();
    if (profileResult?.data) {
      dispatch(authSlice?.actions?.updateProfile(profileResult.data));
    }
  };

  const isGetMorePoints = useMemo(() => {
    if (!!type && !!data) {
      return data?.[type].claimPoints === 0;
    }
    return false;
  }, [data, type]);

  const initialRewardStatus = useMemo(() => {
    if (!!type && !!data) {
      const { remainingSeconds } = data[type];
      if (remainingSeconds !== 0) {
        return RewardBoxStatus.LOCKED;
      } else {
        return RewardBoxStatus.CLAIMABLE;
      }
    }
  }, [data, type]);

  return {
    data,
    isLoading: result.isLoading,
    refetch: refetch,
    isGetMorePoints,
    initialRewardStatus,
  };
}
