import { useMemo } from "react";
import { Fade } from "react-awesome-reveal";
import { useTranslation } from "react-i18next";
import { Card, LazyImage, PointDisplay } from "src/main/components";
import { DEFAULT_PRODUCT_IMAGE_URL } from "src/main/contants";
import { OrderItem } from "src/main/types";
import { getItemOrderPrice } from "src/main/utils";
import LocalizationDisplay from "../../LocalizationDisplay";

interface OrderItemCardProps {
  item: OrderItem;
}

const OrderItemCard = ({ item }: OrderItemCardProps) => {
  const { info, quantity, product, productVariant } = item;

  const { t } = useTranslation();

  const unitPrice = useMemo(() => {
    return getItemOrderPrice(item);
  }, [item]);

  const imageUrl = info?.variant?.imageUrl || info?.product?.imageUrl;

  console.log(item);

  return (
    <Card
      className="!flex-row space-x-2 px-4 pb-4 pt-2.5"
      variant="solid-inverted-surface"
    >
      <div className="relative h-[70px] w-[70px] flex-shrink-0">
        <Fade triggerOnce>
          <LazyImage
            src={imageUrl}
            className="absolute top-0 left-0 z-10 h-full w-full rounded-lg object-contain"
            alt="card"
            fallbackSrc={DEFAULT_PRODUCT_IMAGE_URL}
          />
        </Fade>
      </div>

      <div className="flex w-full flex-grow flex-col justify-between space-y-2 self-stretch pt-1.5 overflow-x-hidden">
        <LocalizationDisplay
          field="name"
          localisations={product?.localisations}
          defaultValue={product?.name}
          className="line-clamp-1 text-xs font-bold text-gray-700"
        />

        <div className="h-4.5">
          {!!productVariant && (
            <LocalizationDisplay
              field="name"
              localisations={productVariant?.localisations}
              defaultValue={productVariant?.name}
              className="line-clamp-1 text-xs text-gray-700"
            />
          )}
        </div>

        <div className="flex items-center justify-between">
          <PointDisplay points={unitPrice} />
          <span className="text-sm text-content-inverted-primary">{`${t("Qty")}: ${quantity}`}</span>
        </div>
      </div>
    </Card>
  );
};

export default OrderItemCard;
