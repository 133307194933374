import { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import layoutSlice from "src/main/store/layout/slices";
import { RootState } from "../store";
import { LoadingTasks } from "../store/layout/types";
import { uuidv4 } from "../utils";

export type ErrorHandler = (error: any) => () => void;

export type AsyncTaskTask<T> = () => Promise<T>;
export type AsyncTaskErrorHandler = (error: Error) => Promise<boolean | undefined | void>;
export type FinalHandler = (() => void) | null;

export type AsyncTaskRunner<T> = (
  task: AsyncTaskTask<T>,
  errorHandler?: AsyncTaskErrorHandler,
  finalHandler?: FinalHandler,
) => Promise<void>;
export type AsyncTaskLoading = boolean;
export type AsyncTaskError = Error | null;
export type AsyncTaskClearError = () => void;

export type AsyncTaskOutput<T> = [AsyncTaskRunner<T>, AsyncTaskLoading, AsyncTaskError, AsyncTaskClearError];

const parseError = (original: unknown): Error => {
  const error = original as Error;
  return error;
};

const useAsyncTask = <T>(taskname: string, onError?: (e: Error) => void): AsyncTaskOutput<T> => {
  const [error, setError] = useState<Error | null>(null);
  const dispatch = useDispatch();
  const loadingTasks = useSelector<RootState, LoadingTasks>((store) => store.layout.loadingTasks);

  const asyncTaskRunner = useCallback(
    async (
      task: AsyncTaskTask<T>,
      errorHandler?: AsyncTaskErrorHandler,
      finalHandler?: FinalHandler,
    ): Promise<void> => {
      if (typeof task !== "function") {
        throw new Error("async task not a function");
      }

      setError(null);

      const taskUuid = uuidv4();

      dispatch(layoutSlice.actions.addLoadingTask({ name: taskname, uuid: taskUuid }));
      try {
        await task();
      } catch (rawError: unknown) {
        let error = parseError(rawError);

        if (errorHandler) {
          const handleErrorResult = await errorHandler(error);
          if (handleErrorResult === false) {
            return;
          }
        }

        if (onError) {
          onError(error);
          return;
        }

        if (error?.message) toast.error(error.message);

        setError(error);
      } finally {
        dispatch(layoutSlice.actions.removeLoadingTask(taskUuid));
        if (finalHandler) {
          finalHandler();
        }
      }
    },
    [dispatch, taskname, onError],
  );

  const clearError = useCallback(() => setError(null), []);

  const loadingState = !!loadingTasks[taskname];
  return [asyncTaskRunner, loadingState, error, clearError];
};

export default useAsyncTask;
