import { clsx } from "clsx";
import { LazyImage } from "src/main/components";

interface ProductImageProps {
  imageUrl: string;
  className?: string;
  variant?: "thumbnail" | "full";
}

const PLACEHOLDER_IMAGE = "/images/products/placeholder.svg";

const ProductImage = (props: ProductImageProps) => {
  const { imageUrl, variant = "thumbnail" } = props;

  return (
    <div className={clsx("h-full w-full", props.className)}>
      <LazyImage
        src={imageUrl}
        fadeOnShow
        fallbackSrc={PLACEHOLDER_IMAGE}
        alt={`product`}
        className={clsx("aspect-square object-contain lg:h-[100px] lg:w-[100px]", {
          "h-20 w-20": variant === "thumbnail",
          "h-full w-full": variant === "full",
        })}
      />
    </div>
  );
};

export default ProductImage;
