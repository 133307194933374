import { clsx } from "clsx";
import { useMemo } from "react";
import { PropsWithClassName, PropsWithThemeConfig, ThemeSize } from "src/main/components";
import { styledClassBuilder } from "src/main/utils";

type CircularProgressVariant = "filled" | "outlined";

interface PieProgressProps extends PropsWithClassName, PropsWithThemeConfig<ThemeSize, CircularProgressVariant> {
  percentage: number; // 0 - 0.1
}

const CircularProgress = ({
  size = "md",
  variant = "filled",
  percentage,
  className,
  color = "#FFFFFF",
}: PieProgressProps) => {
  const bgColors = useMemo(() => {
    // UI can't display small percentage well, need to set a minimum value is 3%
    const roundedPercentage = percentage > 0 ? Math.min(Math.max(percentage, 0.03), 0.97) : 0;
    const transitionDeg = Math.round((1 - roundedPercentage) * 360);

    // Separate the circle into 2 parts: empty part and filled part, the middle point is the transitionDeg
    const emptyPart = {
      start: createColorPoint(color, transitionDeg > 0 ? 25 : 100, 0),
      end: createColorPoint(color, 25, transitionDeg),
    };
    const filledPart = {
      start: createColorPoint(color, 100, Math.min(transitionDeg + 1, 360)),
      end: createColorPoint(color, transitionDeg === 360 ? 25 : 100, 360),
    };

    return [emptyPart.start, emptyPart.end, filledPart.start, filledPart.end].join(", ");
  }, [color, percentage]);

  return (
    <div
      className={clsx("rounded-full", buildStyledClass({ size, variant }), className)}
      style={{
        background: `conic-gradient(from 0deg at 50% 50%, ${bgColors})`,
      }}
    />
  );
};

const buildStyledClass = styledClassBuilder<ThemeSize, CircularProgressVariant>({
  size: {
    xs: "w-3 h-3",
    sm: "w-3 h-3",
    md: "w-4 h-4",
    lg: "w-6 h-6",
    xl: "w-6 h-6",
  },
  variant: {
    filled: "",
    outlined: "",
  },
});

const createColorPoint = (color: string, opacity: number, deg: number) => {
  const opacityIn256 = Math.round((opacity / 100) * 255);
  const opacityHex = opacityIn256.toString(16).toUpperCase();
  return `${color}${opacityHex} ${deg}deg`;
};

export default CircularProgress;
