import { useTranslation } from "react-i18next";

const Slogan: React.FC<SloganProps> = ({ style }) => {
  const { t } = useTranslation();
  // export default function Slogan(props: SloganProps) {
  return <div className={style}>{t("ilove slogan")}</div>;
};

export default Slogan;

Slogan.defaultProps = {};

interface SloganProps {
  style: string;
}
