import { clsx } from "clsx";
import { ReactNode, useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { FaChevronRight } from "react-icons/fa6";
import { Button, Modal } from "src/main/components";
import { SOCIAL_MEDIA_TYPE } from "src/main/contants";
import { ContactType } from "src/main/types";
import { getDeployEnv, waitForTransitionEnd } from "src/main/utils";

export type ContactScreen = "greeting" | "contact";

interface ContactModalProps {
  show: boolean;
  onClose: () => void;
  greeting?: ReactNode;
  greetingButtonText?: string | null;
  greetingButtonIcon?: ReactNode | null;
  onGreetingButtonClick?: (
    setScreen: (screen: ContactScreen) => void,
    hasMultiplePlatforms: boolean,
    platforms: ContactType[],
  ) => void;
  displayTitle?: boolean;
  displayStatus?: boolean;
  title?: string | null;
  onScreenChange?: (screen: ContactScreen) => void;
}

const currentEnv = getDeployEnv();

const SOCIAL_ICON_SRC_MAP = {
  [SOCIAL_MEDIA_TYPE.LINE]: "/images/icon/fab-line.svg",
  [SOCIAL_MEDIA_TYPE.Telegram]: "/images/icon/fab-telegram.svg",
  [SOCIAL_MEDIA_TYPE.Messenger]: "/images/icon/fab-messenger.svg",
};

const ContactModal = ({
  show,
  onClose,
  greeting,
  onGreetingButtonClick,
  greetingButtonIcon,
  greetingButtonText,
  displayTitle = true,
  displayStatus = true,
  title,
  onScreenChange,
}: ContactModalProps) => {
  const { t } = useTranslation();
  const [screen, setScreen] = useState<ContactScreen>(!!greeting ? "greeting" : "contact");

  useEffect(() => {
    if (!!greeting && !show) {
      // reset screen
      waitForTransitionEnd(() => {
        setScreen("greeting");
      });
    }
  }, [show, greeting]);

  useEffect(() => {
    if (show) {
      onScreenChange?.(screen);
    }
  }, [show, screen, onScreenChange]);

  const platforms = useMemo(() => {
    return currentEnv?.contactPlatforms ?? [];
  }, []);

  const hasMultiplePlatforms = useMemo(() => {
    return platforms.length > 1;
  }, [platforms]);

  const handleOnClickContact = (url: string) => {
    window.open(url, "_blank");
  };

  const handleOnClickGreeting = useCallback(() => {
    onGreetingButtonClick?.(setScreen, hasMultiplePlatforms, platforms);
  }, [hasMultiplePlatforms, onGreetingButtonClick, platforms]);

  return (
    <Modal
      show={show}
      onClose={onClose}
      backdropClassName="z-[60]"
      panelClassName="lg:!max-w-sm w-full lg:!min-w-[unset]"
    >
      <Modal.Header className="-mt-3 flex items-center space-x-3">
        {
          <h2
            className={clsx("text-lg lg:text-2xl", {
              "opacity-0": !displayTitle,
            })}
          >
            {title ?? t("Chat with us")}
          </h2>
        }
        {displayStatus && (
          <span className="inline-flex items-center rounded bg-onOverlay px-1 py-0.5 text-xxs leading-[10px] text-content-secondary">
            <span className="bg mr-1 inline-block h-2 w-2 rounded-full bg-lime-400" />
            {t("Online")}
          </span>
        )}
      </Modal.Header>
      <Modal.Content className="pt-3">
        {screen === "greeting" && (
          <div className="flex animate-fade-in flex-col space-y-4">
            {greeting}
            <Button
              onClick={handleOnClickGreeting}
              variant="green"
              className="uppercase"
              icon={greetingButtonIcon}
            >
              {greetingButtonText}
            </Button>
          </div>
        )}
        {screen === "contact" && (
          <div className="flex animate-fade-in flex-col space-y-1">
            {platforms.map((socialNetwork, index) => (
              <button
                key={socialNetwork.url}
                onClick={() => handleOnClickContact(socialNetwork.url)}
                className="flex w-full cursor-pointer items-center justify-around space-x-3 rounded-lg bg-onOverlay px-4 py-2.5 pl-2 transition-opacity duration-200 ease-in-out hover:opacity-80"
              >
                <img
                  className="h-[30px] w-[30px] flex-shrink-0"
                  src={SOCIAL_ICON_SRC_MAP[socialNetwork.type]}
                  alt={socialNetwork.title}
                />
                <span className="flex-grow text-sm font-bold text-content-primary">{socialNetwork.title}</span>
                <FaChevronRight className="h-3.5 w-3.5 flex-shrink-0 text-content-secondary" />
              </button>
            ))}
          </div>
        )}
      </Modal.Content>
    </Modal>
  );
};

export default ContactModal;
