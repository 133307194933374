import { PropsWithChildren } from "react";
import { TopBar } from "src/main/components";

interface TopBarProps extends PropsWithChildren {}

const MainTopBar = (props: TopBarProps) => {
  return (
    <>
      <header className="fixed top-[-1px] z-50 w-full">
        <TopBar />
      </header>
    </>
  );
};

export default MainTopBar;
