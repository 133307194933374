export default function IcoGreaterThan(props: IcoGreaterThanProps) {
  return (
    <svg
      width="7"
      height="11"
      viewBox="0 0 7 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.1967 0.825451C0.839179 1.1754 0.839179 1.74278 1.1967 2.09273L4.66902 5.49156L1.1967 8.89039C0.839178 9.24034 0.839178 9.80772 1.1967 10.1577C1.55421 10.5076 2.13387 10.5076 2.49138 10.1577L5.96371 6.75884C6.67874 6.05894 6.67875 4.92418 5.96371 4.22428L2.49138 0.825451C2.13387 0.475501 1.55421 0.475501 1.1967 0.825451Z"
        fill={props.color}
      />
    </svg>
  );
}

IcoGreaterThan.defaultProps = {
  color: "white",
};

interface IcoGreaterThanProps {
  color?: string;
}
