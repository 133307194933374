import React from "react";

const Spinner: React.FC = () => {
  return (
    <div className="rounded-lg sm:m-1">
      <div className="rounded-t-lg">
        <div className="w-100 h-56 rounded-md border border-blue-300 p-4 shadow">
          <div className="flex animate-pulse space-x-4">
            {/* <div className="rounded-full bg-slate-200 h-10 w-10" ></div> */}
            <div className="flex-1 space-y-6 py-1">
              <div className="h-20 rounded bg-slate-200"></div>
              <div className="space-y-3">
                <div className="grid grid-cols-4 gap-4">
                  <div className="col-span-3 h-4 rounded bg-slate-200"></div>
                  {/* <div className="h-10 bg-slate-200 rounded col-span-1" ></div> */}
                  {/* <div className="h-2 bg-slate-200 rounded col-span-1" ></div> */}
                </div>
                <div className="grid grid-cols-4 gap-4">
                  <div className="col-span-2 h-4 rounded bg-slate-200"></div>
                  {/* <div className="h-10 bg-slate-200 rounded col-span-1" ></div> */}
                  {/* <div className="h-2 bg-slate-200 rounded col-span-1" ></div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <img className="mx-auto rounded-t-lg object-fill h-48 w-96" src={images[0].url}></img> */}
      </div>

      <div className="bottom-product-card p-5 text-xs sm:text-sm md:text-xl">
        <div className="flex">
          <div className="col-span-1 h-2 rounded bg-slate-200"></div>
          {/* <img src="/images/icon/heart_icon_yellow.svg" height="5" width="20" alt="points-icon" />
                    <p className="px-1 md:px-2 font-normal text-white  font-extrabold">price</p> */}
        </div>
      </div>
    </div>
  );
};

export default Spinner;
