import { SVGProps } from "react";

export default function IcoLock(props: IcoLockProps) {
  return (
    <svg
      width="16"
      height="18"
      viewBox="0 0 12 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M9.54492 7.36215V4.04557C9.54492 2.12222 7.99318 0.5625 6.07812 0.5625C4.16237 0.5625 2.61133 2.12222 2.61133 4.04557V7.36215C1.34456 8.38339 0.53125 9.94938 0.53125 11.7083C0.53125 14.786 3.01486 17.2812 6.07812 17.2812C9.14139 17.2812 11.625 14.786 11.625 11.7083C11.625 9.94938 10.8117 8.38339 9.54492 7.36215ZM3.99805 4.04557C3.99805 2.89337 4.93131 1.95573 6.07812 1.95573C7.22494 1.95573 8.1582 2.89337 8.1582 4.04557V6.54433C7.51546 6.2824 6.81447 6.13542 6.07812 6.13542C5.34178 6.13542 4.64079 6.2824 3.99805 6.54433V4.04557ZM6.77148 12.212V13.7982H5.38477V12.212C4.97152 11.9703 4.69141 11.5265 4.69141 11.0117C4.69141 10.2427 5.31266 9.61849 6.07812 9.61849C6.84359 9.61849 7.46484 10.2427 7.46484 11.0117C7.46484 11.5258 7.18473 11.9703 6.77148 12.212Z"
        fill="currentColor"
      />
    </svg>
  );
}

IcoLock.defaultProps = {};

interface IcoLockProps extends SVGProps<SVGSVGElement> {}
