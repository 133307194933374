import { BaseQueryFn, FetchArgs, FetchBaseQueryError, FetchBaseQueryMeta } from "@reduxjs/toolkit/dist/query";
import { EndpointBuilder } from "@reduxjs/toolkit/dist/query/endpointDefinitions";
import { createApi } from "@reduxjs/toolkit/dist/query/react";
import { queryWithRefresh } from "../base";
import { DeployCountry, DeployEnv, QueryResult } from "src/main/types";
import { BrandModel } from "src/main/models";
import { getDeployEnv, SimpleMap } from "src/main/utils";

const deployEnv = getDeployEnv();
const accountId = deployEnv.accountId

export const require200Status = (response: Response) => response.status === 200;

export type PublicTagType = "public";
export type BuilderProp = EndpointBuilder<
  BaseQueryFn<
    string | FetchArgs,
    unknown,
    FetchBaseQueryError,
    {},
    FetchBaseQueryMeta
  >,
  PublicTagType,
  "publicApi"
>;

export const publicTags: PublicTagType[] = ["public"];

export interface GetBrandResponse {
  model: Partial<BrandModel>;
}

export interface GetBrandRequest {
  brand: string;
}

interface GetPublicInfoResponse {
  status: string;
  utcOffset: number;
  creditBonus?: SimpleMap<number>;
}

interface GetCentralServerResponse {
  underMaintenance: boolean;
}

const publicApi = createApi({
  reducerPath: "publicApi",
  baseQuery: queryWithRefresh,
  tagTypes: publicTags,
  endpoints: (builder) => ({
    getBrand: builder.query<GetBrandResponse, GetBrandRequest>({
      providesTags: ["public"],
      query: ({ brand }) => ({
        url: `/brand?name=${brand}`,
        validateStatus: require200Status,
      }),
      transformResponse: (response: QueryResult<GetBrandResponse>) => {
        return response.result;
      },
    }),
    getPublicInfo: builder.query<GetPublicInfoResponse, void>({
      providesTags: ["public"],
      query: () => ({
        url: `/info?account=${accountId}`,
        validateStatus: require200Status,
      }),
      transformResponse: (response: QueryResult<GetPublicInfoResponse>) => {
        return response.result;
      },
    }),
    getCentralServerConfig: builder.query<GetCentralServerResponse, void>({
      queryFn: async (arg, api, extraOptions, baseQuery) => {
        try {
          const deploymentConfig = getDeployEnv();
          const queryToken = new Date().getTime();
          if (
            deploymentConfig.deploymentType === DeployEnv.Production &&
            deploymentConfig.deployCountry === DeployCountry.PH
          ) {
            const response = await fetch(
              "https://raw.githubusercontent.com/99techteam/ilovevip-central-config/main/server-configs-ph.json?token=" +
              queryToken,
              {
                cache: "no-store",
              },
            ).then((res) => res.json());
            return {
              data: response,
            };
          }

          if (
            deploymentConfig.deploymentType === DeployEnv.Production &&
            deploymentConfig.deployCountry === DeployCountry.TH
          ) {
            const response = await fetch(
              "https://raw.githubusercontent.com/99techteam/ilovevip-central-config/main/server-configs-th.json?token=" +
              queryToken,
              {
                cache: "no-store",
              },
            ).then((res) => res.json());
            return {
              data: response,
            };
          }

          if (deploymentConfig.deploymentType === DeployEnv.Staging) {
            const response = await fetch(
              "https://raw.githubusercontent.com/99techteam/ilovevip-central-config/main/server-configs.staging.json?token=" +
              queryToken,
              {
                cache: "no-store",
              },
            ).then((res) => res.json());
            return {
              data: response,
            };
          }
          // ignore maintenance page in dev
          return {
            data: { underMaintenance: false },
          };
        } catch (e) {
          return {
            data: { underMaintenance: false },
          };
        }
      },
    }),
  }),
});

export default publicApi;
