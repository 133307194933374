export default function IcoLogout(props: IcoLogoutProps) {
  return (
    <div className="h-7 w-7">
      <svg
        width="100%"
        height="100%"
        preserveAspectRatio="none"
        viewBox="0 0 28 28"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M 17.5 11.375 V 7.875 L 24.5 14 L 17.5 20.125 V 16.625 H 10.5 V 11.375 H 17.5 Z M 15.75 20.125 V 20.052 C 14.719 20.652 13.526 21 12.25 21 C 8.39 21 5.25 17.86 5.25 14 C 5.25 10.14 8.39 7 12.25 7 C 13.526 7 14.719 7.348 15.75 7.948 V 5.983 C 14.677 5.514 13.495 5.25 12.25 5.25 C 7.418 5.25 3.5 9.167 3.5 14 C 3.5 18.833 7.418 22.75 12.25 22.75 C 13.495 22.75 14.677 22.486 15.75 22.017 V 20.125 Z"
          fill="white"
        />
      </svg>
    </div>
  );
}

IcoLogout.defaultProps = {};

interface IcoLogoutProps {}
