import { createAction } from "@reduxjs/toolkit";

import * as payloadType from "./types/payload";

export const logout = createAction("logout");

export const getVipStatus = createAction<payloadType.GetVipStatusPayload>("getVipStatus");

export const generateVipStatusToken = createAction<payloadType.GenerateVipStatusTokenPayload>("generateVipStatusToken");

export const clearVipStatusData = createAction("clearVipStatusData");
