import { Moment } from "moment";
import { PointOrderItemType } from "../store/types";
import { SimpleMap } from "../utils";
import { AccountModel, MemberModel, WalletTxSourceWalletType } from "./identity";
import { AssetModel, BaseModel, LocalisationListItem } from "./models";

export interface CartType {
  quantity: number;
  colour: string;
  id: string;
  name: string;
  selected: boolean;
}

export interface CartItemModel {
  variantId: string;
  quantity: number;
  variant?: ProductVariantModel;
}

export interface ProductInfo {
  id: string;
  content: string;
  localisations?: LocalisationListItem[];
}

export enum ProductFulfilmentType {
  DELIVERY = "delivery",
  SELF_COLLECT = "self-collect",
  VIRTUAL = "virtual",
}

export interface ProductModel extends BaseModel {
  image: AssetModel;
  extraImages: AssetModel[];
  accountId: string;
  status: string;
  fulfilmentType: ProductFulfilmentType;
  publishedAt: Moment | null;
  name: string;
  description: string | null;
  memo: string | null;
  minQuantity: number | null;
  maxQuantity: number | null;
  basePrice: string;
  sortPriority: number | null;
  slug: string;

  account?: AccountModel | null;
  productVariants?: ProductVariantModel[] | null;
  localisations?: LocalisationListItem[];
  categories?: CategoryModel[];
  properties?: ProductPropertyModel[];
  info?: ProductInfo;
}

export interface ProductPropertyDisplayConfig {
  type: "generic" | "color" | "measurement" | "size";
  unit?: "mm" | "cm" | "m" | "inch" | "cm2" | "m2" | "cm3" | "m3" | "l";
  color?: string;
}

export interface ProductPropertyModel {
  accountId: string;
  productId: string; // non-essential, for query convenience

  status: string;
  key: string;
  name: string;
  // configurations when displaying property on product page
  displayConfig: ProductPropertyDisplayConfig;
  defaultValue: string | null;
  account: AccountModel | null;
}

interface subInformation {
  color: {
    id: number;
    value: string;
  };
}

export interface ProductInventoryModel extends BaseModel {
  quantity: number;
}

export enum VirtualProductFulfilmentType {
  POINT_EXCHANGE = "point-exchange",
}

export interface ProductVariantVirtualFulfilment {
  type: VirtualProductFulfilmentType;
  data: {
    currency?: string;
    iconUrl?: string;
    name?: string;
    totalCredit?: string;
  } & {
    [key: string]: string | number | boolean;
  };
}

export interface ProductVariantModel extends BaseModel {
  accountId: string;
  productId: string;
  productInventory?: ProductInventoryModel;
  productLocalisationId: string | null;
  status?: string;
  name: string;
  description?: string | null;
  sku?: string | null;
  price?: string;
  properties?: SimpleMap<string | number>;
  account?: AccountModel;
  image: AssetModel;
  localisations?: LocalisationListItem[];
  product?: Partial<ProductModel>;
  virtualFulfilment?: ProductVariantVirtualFulfilment;
}

export interface colorVariants {
  id: number;
  value: string;
  product_image_id?: number;
}

export interface localStorageProductType {
  productId: number;
  cartId: string;
  name: string;
  quantity: number;
  selected: boolean;
  subInformation: subInformation;
}

export interface AddToCartFormProps {
  onChange?: (event: React.ChangeEvent<HTMLSelectElement>) => void;
  variantRef: string | null;
  styles: {
    label: string;
    field: string;
    button: string;
    errorMsg: string;
  };
  item: {
    id: number;
    price: number;
    name: string;
    category: {
      id: number;
      name: string;
    };
    short_description: string;
    variants: {
      Colour: colorVariants[];
    };
  };
}

export enum OrderStatus {
  PLACED = "placed",
  PROCESSING = "processing",
  IN_DELIVERY = "in-delivery",
  COMPLETED = "completed",
  CANCELLED = "cancelled",
  VOID = "void",
}

export interface CartModel extends BaseModel {
  memberId: string;
  status: OrderStatus;
  data: {
    items?: CartItemModel[];
    version?: number;
    promocode?: string | null;
    shippingInfo?: ShipmentInfo;
  };
}

export interface OrderItem {
  variantId: string;
  quantity: number;
  info?: {
    pointExchangeInfo?: {
      brand: string;
      identifier: string;
    };
    product: {
      basePrice: string;
      description: string;
      imageUrl: string;
      name: string;
      fulfillmentType?: ProductFulfilmentType;
    };
    properties: SimpleMap;
    variant?: {
      description?: string | null;
      name: string;
      price: string;
      sku: string;
      imageUrl?: string;
      virtualFulfilment?: ProductVariantVirtualFulfilment;
    };
  };
  subtotal?: string;
  product?: ProductModel;
  productVariant?: ProductVariantModel;
  productVariantId?: string;
}

export interface ShipmentInfo {
  recipientName: string;
  recipientMobile: string;
  city: string;
  state: string;
  address: string;
  address2?: string;
  country: string;
  remarks?: string;
  postalCode: string;
}

export interface UpdateCartProps {
  version: string;
  items: OrderItem[];
  shippingInfo?: Partial<ShippingAddressModel>;
  promocode?: string;
}

export interface CheckoutCartProps {
  version: string;
  items: OrderItem[];
  shippingInfo?: ShipmentInfo;
  promocode?: string;
}

export interface CategoryModel extends BaseModel {
  name: string;
  parentId: string;
  slug?: string;
  localisations?: LocalisationListItem[];
  logo?: AssetModel;
  sortPriority?: number;
}

export interface ShippingAddressModel extends BaseModel {
  recipientName: string;
  recipientMobile: string;
  city: string;
  state: string;
  address: string;
  address2?: string;
  country: string;
  remarks?: string;
  postalCode: string;
  isDefault?: boolean;
  member?: MemberModel;
}

export interface FulfilmentInfo {
  trackingLink?: string;

  shipmentProviderInfo?: string;
  shipmentProviderLink?: string;

  /** unix timestamp in seconds */
  estimatedDeliveryAt: number;

  /** remarks to show to member */
  remarks?: string;
}

export interface OrderHistoryModel extends BaseModel {
  fulfilment?: FulfilmentInfo;
  memberId: string;
  accountId: string;
  processorId?: string;
  shipment?: ShipmentInfo;
  status: OrderStatus;
  total: string;
  orderItems: OrderItem[];
  isExchangeOrder?: boolean;
}

export enum TransactionType {
  IN = "in",
  OUT = "out",
}

export enum WalletTxSourceType {
  CREDIT_REDEEM = "credit-redeem",
  DAILY_BONUS = "daily-bonus",
  SECRET_BONUS = "secret-bonus",
  MANUAL_CREDIT = "manual-credit",
  REVERT = "revert",
  REFUND = "refund",
  TRANSFER = "transfer",
  SECRET_TRANSFER = "secret-transfer",
  TURNOVER_BONUS = "turnover-bonus",
  WELCOME_BONUS = "welcome-bonus",
  OTHER_BONUS = "other-bonus",
  POINT_EXCHANGE = "point-exchange",
  REWARD_ACCRUE = "reward-accrue",
  REWARD_CLAIM = "reward-claim",
  REWARD_FORWARD = "reward-forward",
  MISSION_REWARD = "mission-reward",
}
export interface PrimaryWalletPointHistoryModel extends BaseModel {
  amount: string;
  srcWalletType: WalletTxSourceWalletType;
  sourceType: WalletTxSourceType;
  remark: string | null;
  orderItem?: PointOrderItemType;
}
