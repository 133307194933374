import { clsx } from "clsx";
import { PropsWithChildren, useCallback, useMemo } from "react";
import { FaChevronLeft } from "react-icons/fa6";
import { useLocation, useNavigate } from "react-router-dom";
import { useTypedSelector } from "src/main/hooks";
import { Paths } from "src/main/utils";
import { Slogan } from "src/main/views/component";
import InfiniteRotate from "src/main/views/component/AnimatedIcon/AnimationIcon";

interface TopBarLogoProps extends PropsWithChildren {}

const TopBarLogo = (props: TopBarLogoProps) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { language } = useTypedSelector((state) => state);

  const isBackHome = useMemo(() => {
    return location.pathname === Paths.Private.RedeemSuccessful || location.pathname === Paths.Private.RedeemFailed;
  }, [location.pathname]);

  const isDisplayBackButton = useMemo(() => {
    return location.pathname !== Paths.Private.Home;
  }, [location.pathname]);

  const onClickBack = useCallback(() => {
    if (isBackHome) {
      navigate(Paths.Private.Home);
    } else {
      navigate(-1);
    }
  }, [isBackHome, navigate]);

  return (
    <div>
      <div className="flex justify-start">
        {/*DESKTOP*/}
        <div className="hidden items-center lg:inline-flex">
          <div>
            <InfiniteRotate />
          </div>
          <div>
            <Slogan style={`text-xs px-2 hidden lg:block ${language.lang === 1 ? "w-[140px]" : ""}`} />
          </div>
        </div>

        {/*MOBILE*/}
        <div className="flex items-center gap-x-2 lg:hidden">
          {isDisplayBackButton && (
            <button
              className="no-tap-highlight flex h-9 w-9 animate-fade-in items-center justify-center active:drop-shadow-[0px_0px_6px_#FFF0D1]"
              onClick={onClickBack}
            >
              <FaChevronLeft className="inline-block h-3 w-3 text-primary-200" />
            </button>
          )}
          <div
            className={clsx("flex justify-center", {
              "ml-4": !isDisplayBackButton,
            })}
          >
            <div className="lg:hidden">
              <InfiniteRotate />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TopBarLogo;
