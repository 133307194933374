import { clsx } from "clsx";
import { PropsWithChildren } from "react";

interface CardTitleProps extends PropsWithChildren {
  className?: string;
}

function CardTitle({ children, className }: CardTitleProps) {
  return <h2 className={clsx("text-center text-xl font-bold text-white", className)}>{children}</h2>;
}

export default CardTitle;
