import { clsx } from "clsx";
import { DetailedHTMLProps, HTMLAttributes, PropsWithChildren } from "react";
import { PropsWithThemeConfig, ThemeSize } from "src/main/components";
import { styledClassBuilder } from "src/main/utils";

type CardVariant =
  | "gradient-b"
  | "gradient-r"
  | "gradient-l"
  | "gradient-t"
  | "container-dark"
  | "container"
  | "container-gradient"
  | "container-light"
  | "solid-inverted-surface"
  | "solid-overlay"
  | "solid-onOverlay";

interface CardProps
  extends PropsWithChildren,
    DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>,
    PropsWithThemeConfig<ThemeSize, CardVariant> {
  className?: string;
  transparent?: boolean;
  border?: boolean;
  header?: React.ReactNode;
}

function Card({
  children,
  className,
  transparent,
  size = "md",
  rounded = "md",
  variant = "gradient-b",
  border = true,
  header,
  ...props
}: CardProps) {
  return (
    <div
      className={clsx(
        "flex flex-col",
        {
          "border-l border-t border-transparent-primary": border,
          "from-container-3 to-container-1": variant?.includes("gradient"),
        },
        buildStyledClass({
          size,
          variant,
          rounded,
        }),
        className,
      )}
      {...props}
    >
      {!!header && (
        <div
          className={clsx(
            "",
            buildStyledHeaderClass({
              rounded,
              variant,
              size,
            }),
          )}
        >
          {header}
        </div>
      )}
      {children}
    </div>
  );
}

const buildStyledClass = styledClassBuilder<ThemeSize, CardVariant>({
  variant: {
    "container-dark": "bg-container-1",
    container: "bg-container-2",
    "container-gradient": "bg-container-base-gradient shadow-[1px_1px_0px_0px_#FFFFFF0A_inset]",
    "container-light": "bg-container-3",
    "gradient-b": "bg-gradient-to-b",
    "gradient-r": "bg-gradient-to-r",
    "gradient-l": "bg-gradient-to-l",
    "gradient-t": "bg-gradient-to-t",
    "solid-inverted-surface": "bg-inverted-surface",
    "solid-overlay": "bg-overlay",
    "solid-onOverlay": "bg-onOverlay",
  },
  size: {
    xs: "p-1",
    sm: "p-2",
    md: "p-4",
    lg: "p-6",
    xl: ""
  },
  rounded: {
    xs: "rounded-sx",
    sm: "rounded-sm",
    md: "rounded",
    lg: "rounded-lg",
    xl: "rounded-lg",
  },
});

const buildStyledHeaderClass = styledClassBuilder<ThemeSize, CardVariant>({
  size: {
    xs: "-mx-1 -mt-1 px-1 py-1",
    sm: "-mx-2 -mt-2 px-2 py-1",
    md: "-mx-4 -mt-4 px-4 py-1",
    lg: "-mx-6 -mt-6 px-6 py-3",
    xl: "px-3 py-6"
  },
  variant: {
    "container-dark": "",
    container: "",
    "container-gradient": "",
    "container-light": "",
    "gradient-b": "",
    "gradient-r": "",
    "gradient-l": "",
    "gradient-t": "",
    "solid-inverted-surface": "",
    "solid-overlay": "bg-onOverlay",
    "solid-onOverlay": "bg-onOverlay",
  },
  rounded: {
    xs: "rounded-t-xs",
    sm: "rounded-t-sm",
    md: "rounded-t",
    lg: "rounded-t-lg",
    xl: "rounded-lg",
  },
});

export default Card;
