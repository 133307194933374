import { BuilderProp, require200Status } from "src/main/api/auth";
import { QueryResult } from "src/main/types";

export interface VolummUpdateResponse {}

export const updateVolumm = (builder: BuilderProp) => {
  return builder.mutation<VolummUpdateResponse, string>({
    invalidatesTags: ["self"],
    query: (voluumId: string) => ({
      url: "/member/voluum",
      method: "PUT",
      body: {
        voluumId,
      },
      validateStatus: require200Status,
    }),
    transformResponse: (response: QueryResult<VolummUpdateResponse>) => {
      return response.result;
    },
  });
};
