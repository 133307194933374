import clsx from "clsx";
import { useTranslation } from "react-i18next";
import {
  FaChevronRight,
  FaCircleInfo,
  FaClockRotateLeft,
  FaCoins,
  FaEarthAsia,
  FaHouse,
  FaLock,
} from "react-icons/fa6";

const UserMenuList = ({ type, selected, text }: UserMenu_listProps) => {
  const { t } = useTranslation();

  return (
    <>
      <div
        className={clsx(
          "inline-flex w-full cursor-pointer items-center justify-between self-stretch rounded-lg bg-[radial-gradient(84.58%_106.92%_at_83.69%_0%,#26125C_0%,#170B37_100%)] px-4 py-[14px] text-left font-bold text-white [box-shadow:1px_1px_0px_0px_rgba(137,118,255,0.24)_inset]",
          {
            "bg-[linear-gradient(0deg,rgba(104,64,213,0.2)_0%,rgba(104,64,213,0.2)_100%),radial-gradient(84.58%_106.92%_at_83.69%_0%,#26125C_0%,#170B37_100%)] pt-4 pb-3":
              selected,
          },
        )}
      >
        <div className="basis-5/6">
          <div className={`flex items-center ${selected ? "text-[rgba(240, 63, 150, 1)]" : "text-white"} `}>
            {type === "TYPE" && (
              <FaClockRotateLeft
                color="#B9A3F1"
                className="h-5 w-5"
              />
            )}
            {type === "TYPE1" && (
              <FaLock
                color="#B9A3F1"
                className="h-5 w-5"
              />
            )}
            {type === "TYPE2" && (
              <FaEarthAsia
                color="#B9A3F1"
                className="h-5 w-5"
              />
            )}
            {type === "TYPE3" && (
              <FaCircleInfo
                color="#B9A3F1"
                className="h-5 w-5"
              />
            )}
            {type === "TYPE4" && (
              <FaCoins
                color="#B9A3F1"
                className="h-5 w-5"
              />
            )}
            {type === "TYPE5" && (
              <FaHouse
                color="#B9A3F1"
                className="h-5 w-5"
              />
            )}

            <p className="px-[10px] text-sm font-bold capitalize not-italic leading-4">{t(text)}</p>
          </div>
        </div>
        <div className="basis-1/6">
          <div className="flex flex-row-reverse">
            <FaChevronRight className="h-[10px] w-[10px]" />
          </div>
        </div>
      </div>
    </>
  );
};

UserMenuList.defaultProps = {
  type: "TYPE",
  text: "Order History",
  selected: false,
};

interface UserMenu_listProps {
  type: "TYPE" | "TYPE1" | "TYPE2" | "TYPE3" | "TYPE4" | "TYPE5";
  text: string;
  selected: boolean;
}

export default UserMenuList;
