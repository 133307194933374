import { SVGProps } from "react";

export default function IcoPhone(props: IcoPhoneProps) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g id="icon/phone">
        <path
          id="Vector"
          d="M18.25 4.1875C18.25 3.32422 17.55 2.625 16.6875 2.625H7.3125C6.44922 2.625 5.75 3.325 5.75 4.1875V19.8125C5.75 20.675 6.44922 21.375 7.3125 21.375H16.6875C17.55 21.375 18.25 20.675 18.25 19.8125V4.1875ZM10.8273 4.1875H13.1719C13.3875 4.1875 13.5625 4.3625 13.5625 4.57812C13.5625 4.79375 13.3875 4.96875 13.1719 4.96875H10.8273C10.6125 4.96875 10.4375 4.79375 10.4375 4.57812C10.4375 4.3625 10.6125 4.1875 10.8273 4.1875ZM12 19.8125C11.568 19.8125 11.2187 19.4625 11.2187 19.0312C11.2187 18.6 11.568 18.25 12 18.25C12.4312 18.25 12.7805 18.6 12.7805 19.0312C12.7805 19.4625 12.4312 19.8125 12 19.8125ZM16.6875 17.4688H7.3125V6.5125H16.6875V17.4688Z"
          fill="currentColor"
        />
      </g>
    </svg>
  );
}

IcoPhone.defaultProps = {};

interface IcoPhoneProps extends SVGProps<SVGSVGElement> {}
