import { clsx } from "clsx";
import { useTranslation } from "react-i18next";
import { FaCheck, FaPenToSquare } from "react-icons/fa6";
import { generatePath, Link } from "react-router-dom";
import { ShippingAddressModel } from "src/main/types";
import { Paths } from "src/main/utils";

interface Props {
  address: ShippingAddressModel;
  shortMode?: boolean;
  handleClick?: () => void;
  isManage?: boolean;
  editable?: boolean;
  displayDefaultMark?: boolean;
  backTo?: string;
}

export default function AddressItem({
  handleClick,
  address,
  shortMode = false,
  isManage = false,
  editable = true,
  displayDefaultMark = true,
  backTo,
}: Props) {
  const { t } = useTranslation();

  return (
    <div
      className={clsx("flex flex-1 flex-col gap-1", {
        "max-w-[calc(100%-36px)]": !isManage,
        "!max-w-full": isManage || shortMode,
      })}
    >
      <div
        onClick={() => handleClick?.()}
        className="break-wordsfont-bold flex items-center"
      >
        <span className="line-clamp-1 text-xs font-bold">{address.recipientName}</span>
        <span className="mx-2 inline-block h-1 w-1 flex-shrink-0 rounded-full bg-[#6B7280A3]"></span>
        <span className="flex-shrink-0 text-xs">{address.recipientMobile}</span>
        {editable && (
          <Link
            to={generatePath(
              isManage
                ? Paths.Private.ManageAddressDetail
                : Paths.Private.AddressDetail + (backTo ? `?backTo=${backTo}` : ""),
              {
                id: address.id,
              },
            )}
            className="ml-auto  flex-shrink-0"
            onClick={(e) => e.stopPropagation()}
          >
            <button className=" flex h-7 w-7 items-center justify-center">
              <FaPenToSquare className="h-3.5 w-3.5" />
            </button>
          </Link>
        )}
      </div>
      <div
        onClick={() => handleClick?.()}
        className="break-words text-xs text-content-secondary"
      >
        {address.address} {address.address2}
        <br />
        {address.state}, {address.city}
        <br />
        {address.postalCode} {address.country}
      </div>
      {address.isDefault && displayDefaultMark && (
        <div className="text-xxs text-content-positive-primary">
          <FaCheck className="w-6.5 h-6.5 inline-block text-content-positive-primary" /> {t("Default Address")}
        </div>
      )}
    </div>
  );
}
