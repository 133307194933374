import { Form, Formik, FormikProps } from "formik";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { IcoPhone } from "src/assets";
import { authApi } from "src/main/api";
import { Button, ContactSupportButton } from "src/main/components";
import { phPhoneSchema, thPhoneSchema } from "src/main/components/validation/loginSchema";
import useTypedSelector from "src/main/hooks/useTypedSelector";
import { DeployCountry } from "src/main/types";
import { formatPhoneNumber, getDeployEnv, getErrorMessage, logger } from "src/main/utils";
import OtpForm from "./OtpForm";

type LoginFormProps = {
  page: "reset" | "setup";
};

interface OtpFormType {
  phone: string;
}

const MobileOtpRequestForm = ({ page }: LoginFormProps) => {
  const { t } = useTranslation();
  const state = useTypedSelector((state) => state);
  const navigate = useNavigate();
  //
  const mobile = state.auth.mobile;
  //
  //
  const initialValues: OtpFormType = useMemo(
    () => ({
      phone: mobile,
    }),
    [mobile],
  );
  const currentEnv = getDeployEnv();
  const systemPhoneNumber = useMemo(
    () => currentEnv.country_code + mobile?.substring(1),
    [currentEnv.country_code, mobile],
  );
  //
  const [count, setCount] = useState(0);

  // new process
  const [requestOnboard, { isLoading }] = authApi.useRequestOnboardMutation();
  const [process, setProcess] = useState({
    sentOtp: false,
  });

  const handleOtp = useCallback(
    async (formValue: OtpFormType) => {
      const { phone } = formValue;
      //   const phoneCode = currentEnv.country_code + phone.substring(1);
      //
      try {
        const result = await requestOnboard({
          phone,
          resetPassword: page === "reset",
        }).unwrap();
        setCount(60); // counting down after success
        logger._console.log(result);
        setProcess((prev) => ({ sentOtp: true }));
        toast.success(t("OTP has been sent!"));
      } catch (error: any) {
        logger._console.log(error);
        toast.error(getErrorMessage(error, t));
        setProcess((prev) => ({ sentOtp: false }));
      }
    },
    [page, requestOnboard, t],
  );

  useEffect(() => {
    if (count === 60) {
      const timer = setInterval(() => {
        setCount((prevCount) => {
          if (prevCount > 0) {
            return prevCount - 1;
          } else {
            clearInterval(timer);
            return 0;
          }
        });
      }, 1000);
    }
  }, [count]);

  const myRef = React.useRef<FormikProps<OtpFormType>>(null);

  const handleRequestAgain = useCallback(() => {
    myRef.current?.submitForm();
  }, []);

  return (
    <>
      <div
        className="login-form"
        style={{ display: !process.sentOtp ? "block" : "none" }}
      >
        <Formik
          innerRef={myRef}
          initialValues={initialValues}
          validationSchema={currentEnv.deployCountry === DeployCountry.PH ? phPhoneSchema : thPhoneSchema}
          onSubmit={(values) => {
            handleOtp(values);
          }}
        >
          {() => (
            <Form>
              <div className="flex flex-col gap-4 text-center text-white">
                <div className="w-full justify-center bg-container-3 py-2 text-center text-2xl font-bold">
                  <div className="flex w-full items-center justify-center text-primary-200">
                    <IcoPhone
                      width="24"
                      height="24"
                    />
                    <div className="text-white">
                      {formatPhoneNumber(mobile ?? "INVALID", currentEnv.country_code ?? "")}
                    </div>
                  </div>
                </div>

                <Button
                  loading={isLoading}
                  variant="blue-violet"
                >
                  {t("request OTP")}
                </Button>

                <ContactSupportButton />
                <div className="text-center text-[12px] text-secondary">
                  <div
                    className="px-4 py-2 text-[13px] font-semibold text-white"
                    onClick={() => navigate(-1)}
                  >
                    {t("Back to login")}
                  </div>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
      <div className="login-form">
        {process.sentOtp && (
          <OtpForm
            count={count}
            handleRequestAgain={handleRequestAgain}
            phone={systemPhoneNumber ?? ""}
          />
        )}
      </div>
    </>
  );
};

export default MobileOtpRequestForm;
