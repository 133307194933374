import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { FaClone } from "react-icons/fa6";
import { toast } from "react-toastify";
import { Button, ContactModal, Drawer, LocalizationDisplay, localizationValue, ScrollBox } from "src/main/components";
import { ContactScreen } from "src/main/components/_displays/ContactModal/ContactModal";
import { ContactType, MissionModel } from "src/main/types";
import { SEND_MISSION_DETAIL } from ".";

export default function MissionDetailDrawer() {
  const [missionData, setMissionData] = useState<MissionModel | null>(null);
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [isOpenContactPopUp, setIsOpenContactPopUp] = useState(false);
  const [contactScreen, setContactScreen] = useState<ContactScreen>("greeting");

  useEffect(() => {
    const handleSendMissionDetail = (
      v: CustomEventInit<{
        data: MissionModel;
      }>,
    ) => {
      setMissionData(v.detail?.data ?? null);
      setOpen(true);
    };

    window.addEventListener(SEND_MISSION_DETAIL, handleSendMissionDetail);

    return () => {
      window.removeEventListener(SEND_MISSION_DETAIL, handleSendMissionDetail);
    };
  }, []);

  const contactCSGreeting = useMemo(() => {
    const missionName = localizationValue("name", undefined, missionData?.title);
    return `${t("Hi, I want to ask about ILOVEVIP Mission")} ${missionName}`;
  }, [missionData?.title, t]);

  const handleOnClickCopyAndContact = useCallback(
    async (setScreen: (screen: ContactScreen) => void, hasMultiplePlatforms: boolean, platforms: ContactType[]) => {
      // Copy to clipboard
      try {
        await navigator.clipboard.writeText(contactCSGreeting);
        toast.success(t("Copied") + "!", {
          style: {
            top: "-50px",
          },
        });
      } catch (e) {
        console.error(e);
      }
      if (hasMultiplePlatforms) {
        setScreen("contact");
      } else {
        const url = platforms[0]?.url;
        if (url) {
          window.open(url, "_blank");
        }
      }
    },
    [contactCSGreeting, t],
  );

  const handleOnContactScreenChange = useCallback((screen: ContactScreen) => {
    setContactScreen(screen);
  }, []);

  return (
    <>
      <Drawer
        show={open}
        onClose={() => setOpen(false)}
        placement="bottom"
        contentClassName="fixed lg:bottom-0 left-0 bottom-[60px] w-full rounded-t-2xl !bg-overlay border border-subtle"
        title={localizationValue("title", missionData?.localisations, missionData?.title)}
        titleClassName="text-lg text-left pr-8 flex-grow !mb-0"
        backdropClassName="bg-dimmer"
        preload
      >
        {missionData?.isInstructionVisible && (
          <ScrollBox
            className="mb-4 flex flex-1 flex-col overflow-y-hidden"
            contentClassName="px-4 max-h-[50vh]"
          >
            <LocalizationDisplay
              field={"instructions"}
              localisations={missionData?.localisations}
              defaultValue={missionData?.instructions}
              displayHtml
              className="text-white"
            />
          </ScrollBox>
        )}

        {missionData?.isCsButtonEnabled && (
          <div className="mb-7 mt-3 w-full px-4">
            {" "}
            <Button
              size="md"
              className="uppercase"
              variant="green"
              onClick={() => setIsOpenContactPopUp(true)}
              disabled={isOpenContactPopUp}
            >
              {t("Contact CS")}
            </Button>
          </div>
        )}
        {!missionData?.isCsButtonEnabled && <div className="mb-7"></div>}
      </Drawer>

      <ContactModal
        show={isOpenContactPopUp}
        onClose={() => setIsOpenContactPopUp(false)}
        greeting={<p className="pb-2 text-center text-sm italic text-content-primary">"{contactCSGreeting}"</p>}
        greetingButtonText={t("Copy and open cs chat")}
        greetingButtonIcon={<FaClone className="text-base" />}
        onGreetingButtonClick={handleOnClickCopyAndContact}
        displayStatus={false}
        displayTitle={contactScreen === "contact"}
        title={t("Open CS Chat")}
        onScreenChange={handleOnContactScreenChange}
      />
    </>
  );
}
