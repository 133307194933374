import { PUSH_NOTIFICATION_MEMBER_STORAGE_KEY } from "src/main/contants";
import { getDeployEnv } from "src/main/utils";

const PUSH_NOTIFICATION_ENDPOINT = process.env.REACT_APP_PUSH_NOTIFICATION_ENDPOINT;
const TEST_NOTIFICATION_PARAM = process.env.REACT_APP_TEST_NOTIFICATION_PARAM;

const deployEnv = getDeployEnv();
const accountId = deployEnv.accountId;
/**
 * ## this will be used in /home and logout
 */
export const updatePushSubscription = async (memberId: string) => {
  try {
    const sw = await navigator.serviceWorker.getRegistration(`${process.env.PUBLIC_URL}/service-worker.js`);
    if (!sw) return;

    const registration = await navigator.serviceWorker.ready;
    const pushReg = await registration.pushManager.getSubscription();
    if (!pushReg) return;

    const endpoint = process.env.REACT_APP_PUSH_NOTIFICATION_ENDPOINT;
    if (!endpoint) return;

    const params = new URLSearchParams(window.location.search);
    const notifClient = params.get(TEST_NOTIFICATION_PARAM ?? "");

    const notificationInfoResponse = await fetch(endpoint + "/notification/info", { method: "GET" });
    const {
      result: { subscribeEndpoint, vapidKey },
    } = await notificationInfoResponse.json();

    if (!!subscribeEndpoint) {
      let subscription = await sw.pushManager.getSubscription();
      if (!subscription && !!vapidKey) {
        let unsub = false;
        try {
          subscription = await sw.pushManager.subscribe({
            userVisibleOnly: true,
            applicationServerKey: vapidKey,
          });
        } catch (error: any) {
          if ("message" in error) {
            if (
              error.message.startsWith("Registration failed - A subscription with a different applicationServerKey")
            ) {
              unsub = true;
            }
          }
        }

        if (unsub && !!subscription) {
          await subscription.unsubscribe();
        }
      }
      if (!!subscription) {
        const domain = window.location.hostname ?? "";
        try {
          await fetch(PUSH_NOTIFICATION_ENDPOINT + subscribeEndpoint, {
            method: "POST",
            body: JSON.stringify({
              subscription,
              accountId,
              client: notifClient ?? domain,
              memberId,
            }),
            headers: {
              "Content-Type": "application/json",
            },
          });
          sessionStorage.setItem(PUSH_NOTIFICATION_MEMBER_STORAGE_KEY, memberId);
        } catch (error) {
          console.error(error);
        }
      }
    }
  } catch (e) {
    console.error("updatePushSubscription error ", e);
  }
};
