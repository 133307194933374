import React, { PropsWithChildren, useEffect, useState } from "react";

interface Props extends PropsWithChildren {
  flexibleHeight?: boolean;
}

const AuthBody: React.FC<Props> = ({ flexibleHeight = false, children }) => {
  const [backgroundImage, setBackgroundImage] = useState<string>("");
  useEffect(() => {
    setBackgroundImage("/images/body-bg/bg_lg_wavy_desktop.jpeg");
  }, []);

  return (
    <div
      className="relative flex grid h-full flex-col items-center justify-center"
      style={{
        backgroundImage: `url(${backgroundImage})`,
        backgroundPosition: "center",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        width: "100vw",
        ...(flexibleHeight ? { minHeight: "100vh" } : { height: "100vh" }),
        zIndex: 2,
      }}
    >
      {children}
      <footer
        className="page-footer fixed bottom-0 h-[74px] bg-[url('../public/images/login/caishen-coinpile-lg.png')] lg:h-[158px] lg:bg-[url('../public/images/login/coinstack-repeatx.png')]"
        style={{
          width: "100vw",
          zIndex: 1,
        }}
      ></footer>
    </div>
  );
};

export default AuthBody;
