import { SVGProps } from "react";

interface IcoClockProps extends SVGProps<SVGSVGElement> {}

function IcoClock(props: IcoClockProps) {
  return (
    <svg
      width="14"
      height="15"
      viewBox="0 0 14 15"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g id="Icons/clock">
        <path
          id="Vector"
          d="M7 2.72449C4.16752 2.72449 1.85938 5.03263 1.85938 7.86511C1.85938 10.6976 4.16752 13.0057 7 13.0057C9.83248 13.0057 12.1406 10.6976 12.1406 7.86511C12.1406 5.03263 9.83248 2.72449 7 2.72449ZM10.5419 7.94736C9.56003 8.13242 7.43695 8.51797 7.17478 8.51797C6.80466 8.51797 6.5065 8.21982 6.5065 7.84969C6.5065 7.58752 6.90233 5.04805 7.08225 4.01992C7.09767 3.92225 7.24161 3.92739 7.25703 4.02507L7.76081 7.23282L10.5316 7.75202C10.6447 7.77772 10.6447 7.93194 10.5419 7.94736Z"
          fill="currentColor"
        />
      </g>
    </svg>
  );
}

export default IcoClock;
