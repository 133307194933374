import { forwardRef, PropsWithChildren } from "react";
import { BottomNavigation, BottomNavigationRef } from "src/main/components";

interface BottomBarProps extends PropsWithChildren {}

const MainBottomBar = forwardRef<BottomNavigationRef, BottomBarProps>((props, ref) => {
  return (
    <footer className="fixed bottom-[-1px] z-[70] w-full bg-[rgba(0,0,0,0.6)] text-center [backdrop-filter:blur(40px)] lg:hidden">
      <BottomNavigation ref={ref} />
    </footer>
  );
});

export default MainBottomBar;
