import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { ImagePlaceholder } from "src/main/components";
import useTypedSelector from "src/main/hooks/useTypedSelector";

type Props = {};

const TopBar: React.FC<Props> = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const state = useTypedSelector((state) => state);

  return (
    <div className="flex justify-between p-4">
      <a href="/vipstatus">
        {state.vipStatus.data.logo_url && (
          <div className="w-[120px]">
            <ImagePlaceholder
              path={state.vipStatus.data.logo_url}
              aspectRatio={1.2}
            />
          </div>
        )}
      </a>
      <div className="flex flex-col items-center justify-center">
        <div className="mb-2 text-[12px] font-normal leading-3">{t("Already a VIP?")}</div>
        <button
          className="flex items-center rounded-md bg-black bg-opacity-20 px-2 py-1"
          onClick={() => {
            navigate("/login", { replace: true });
          }}
        >
          <div className="text-[13px] font-semibold uppercase leading-4">{t("login now")}</div>
          <img
            className="h-[14px] w-[14px]"
            src="/images/icon/ico-right-arrow.svg"
            alt="VIP badge"
          />
        </button>
      </div>
    </div>
  );
};

export default TopBar;
