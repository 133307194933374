export default function IcoCart(props: IcoCartProps) {
  return (
    <div>
      <div className={`${props.className}`}>
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 18 17"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M5.83765 16.9727C5.37931 16.9727 4.98695 16.8095 4.66056 16.4831C4.33417 16.1567 4.17098 15.7643 4.17098 15.306C4.17098 14.8477 4.33417 14.4553 4.66056 14.1289C4.98695 13.8025 5.37931 13.6393 5.83765 13.6393C6.29598 13.6393 6.68834 13.8025 7.01473 14.1289C7.34112 14.4553 7.50431 14.8477 7.50431 15.306C7.50431 15.7643 7.34112 16.1567 7.01473 16.4831C6.68834 16.8095 6.29598 16.9727 5.83765 16.9727ZM14.171 16.9727C13.7126 16.9727 13.3203 16.8095 12.9939 16.4831C12.6675 16.1567 12.5043 15.7643 12.5043 15.306C12.5043 14.8477 12.6675 14.4553 12.9939 14.1289C13.3203 13.8025 13.7126 13.6393 14.171 13.6393C14.6293 13.6393 15.0217 13.8025 15.3481 14.1289C15.6745 14.4553 15.8376 14.8477 15.8376 15.306C15.8376 15.7643 15.6745 16.1567 15.3481 16.4831C15.0217 16.8095 14.6293 16.9727 14.171 16.9727ZM4.33765 1.97268H16.6293C16.9488 1.97268 17.1918 2.11504 17.3585 2.39976C17.5251 2.68448 17.5321 2.97268 17.3793 3.26434L14.421 8.59768C14.2682 8.87546 14.0633 9.09073 13.8064 9.24351C13.5495 9.39629 13.2682 9.47268 12.9626 9.47268H6.75431L5.83765 11.1393H15.0043C15.2404 11.1393 15.4383 11.2192 15.5981 11.3789C15.7578 11.5387 15.8376 11.7366 15.8376 11.9727C15.8376 12.2088 15.7578 12.4067 15.5981 12.5664C15.4383 12.7262 15.2404 12.806 15.0043 12.806H5.83765C5.21265 12.806 4.74042 12.5317 4.42098 11.9831C4.10154 11.4345 4.08765 10.8893 4.37931 10.3477L5.50431 8.30601L2.50431 1.97268H1.67098C1.43487 1.97268 1.23695 1.89282 1.07723 1.73309C0.917508 1.57337 0.837646 1.37545 0.837646 1.13934C0.837646 0.903233 0.917508 0.705316 1.07723 0.545594C1.23695 0.385871 1.43487 0.30601 1.67098 0.30601H3.02515C3.17792 0.30601 3.32376 0.347677 3.46265 0.43101C3.60154 0.514344 3.7057 0.632399 3.77515 0.785177L4.33765 1.97268Z"
            fill="white"
          />
        </svg>
      </div>
    </div>
  );
}

IcoCart.defaultProps = {};

interface IcoCartProps {
  className?: string;
}
