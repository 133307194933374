import React, { useEffect, useState } from "react";

interface Props {}

interface TimeType {
  days: string;
  hours: string;
  minutes: string;
  seconds: string;
}

const CountdownTimer: React.FC<Props> = () => {
  const [timing, setTiming] = useState<TimeType>({
    days: "00",
    hours: "00",
    minutes: "00",
    seconds: "00",
  });

  const [timeout, setTimeout] = useState<number>(0);

  const format = (num: number): string => {
    return num < 10 ? "0" + num : num.toString();
  };

  const getTimestampTwoHoursAhead = () => {
    const currentTime = new Date();
    const twoHoursAhead = new Date(currentTime.getTime() + 2 * 60 * 60 * 1000);
    return twoHoursAhead.getTime();
  };

  useEffect(() => {
    const twoHoursAhead = getTimestampTwoHoursAhead();
    setTimeout(twoHoursAhead);
  }, []);

  useEffect(() => {
    const intervalId = setInterval(() => {
      const time = Math.max(0, Math.floor((timeout - Date.now()) / 1000));
      const calculatedTime = {
        days: format(Math.floor(time / (3600 * 24))),
        hours: format(Math.floor((time / 3600) % 24)),
        minutes: format(Math.floor((time / 60) % 60)),
        seconds: format(time % 60),
      };
      setTiming(calculatedTime);
    }, 1000);
    return () => clearInterval(intervalId);
  }, [timeout]);
  return (
    <div className="flex">
      <div className="flex h-6 w-7 items-center justify-center rounded bg-white text-black">{timing.hours}</div>
      <div className="px-1 font-[14px] font-bold">:</div>
      <div className="flex h-6 w-7 items-center justify-center rounded bg-white text-black">{timing.minutes}</div>
      <div className="px-1 font-[14px] font-bold">:</div>
      <div className="flex h-6 w-7 items-center justify-center rounded bg-white text-black">{timing.seconds}</div>
    </div>
  );
};

export default CountdownTimer;
