import { useTranslation } from "react-i18next";
import IcoCross from "src/assets/IcoCross";
import CartPage from "../CartPage";

const CartInDrawer: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div
      className="offcanvas-end offcanvas invisible fixed bottom-0 top-0 right-0 z-[75] flex w-96 max-w-full flex-col border-none bg-white bg-[rgba(20,16,51,1)] bg-clip-padding text-gray-700 shadow-sm outline-none transition duration-300  ease-in-out"
      id="offcanvasRight"
      aria-labelledby="offcanvasRightLabel"
    >
      <div className="offcanvas-header flex items-center justify-between p-4">
        <p
          className="text-2xl font-bold capitalize text-white"
          id="offcanvasRightLabel"
        >
          {t("cart")}
        </p>
        <button
          type="button"
          className="-my-5 -mr-2 box-content h-4 w-4 rounded-none
                border-none p-2 text-red-500 transition-opacity
                focus:shadow-none focus:outline-none hover:text-red-700 hover:no-underline hover:opacity-80"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
        >
          <IcoCross />
        </button>
      </div>
      <div className="scrollbar-primary offcanvas-body flex-grow overflow-y-auto py-3">
        <CartPage container="CartInDrawer" />
      </div>
    </div>
  );
};

export default CartInDrawer;
