import BigNumber from "bignumber.js";
import { clsx } from "clsx";
import { IcoPointV4 } from "src/assets";
import { PropsWithClassName, PropsWithThemeConfig, ThemeSize } from "src/main/components";
import { fNumber, styledClassBuilder } from "src/main/utils";

type PointDisplayVariant = "normal" | "strong";

interface PointDisplayProps extends PropsWithThemeConfig, PropsWithClassName {
  withIcon?: boolean;
  points: number | BigNumber;
  iconClassName?: string;
  textClassName?: string;
}

const buildTextStyledClass = styledClassBuilder<ThemeSize, PointDisplayVariant>({
  size: {
    xs: "text-xs",
    sm: "text-xs",
    md: "text-sm",
    lg: "text-lg",
    xl: "text-base",
  },
  variant: {
    strong: "font-bold",
    normal: "",
  },
});

const buildIconStyledClass = styledClassBuilder<ThemeSize>({
  size: {
    xs: "w-3 h-3",
    sm: "w-4 h-4",
    md: "w-4.5 h-4.5",
    lg: "w-5 h-5",
    xl: "w-5 h-5",
  },
});

const PointDisplay = ({
  withIcon = true,
  iconClassName,
  textClassName,
  className,
  points,
  size = "md",
  variant = "normal",
}: PointDisplayProps) => {
  return (
    <span className={clsx("inline-flex items-center gap-x-1", className)}>
      {withIcon && (
        <IcoPointV4
          className={clsx(iconClassName, buildIconStyledClass({ size }))}
          name="point"
        />
      )}
      {
        <span className={clsx("text-content-inverted-primary", textClassName, buildTextStyledClass({ size, variant }))}>
          {fNumber(points)}
        </span>
      }
    </span>
  );
};

export default PointDisplay;
