export default function Knob() {
  return (
    <svg
      width="33"
      height="33"
      viewBox="0 0 33 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_dd_2038_5190)">
        <circle
          cx="16.6001"
          cy="16.9629"
          r="8"
          fill="#4ADE80"
        />
        <circle
          cx="16.6001"
          cy="16.9629"
          r="7"
          stroke="#BBF7D0"
          strokeWidth="2"
        />
      </g>
      <g clipPath="url(#clip0_2038_5190)">
        <path
          d="M13.6152 17.526L16.2034 19.9423C16.3108 20.0426 16.4526 20.0984 16.6001 20.0984C16.7476 20.0984 16.8894 20.0426 16.9969 19.9423L19.585 17.526C20.0204 17.1207 20.2668 16.5521 20.2668 15.9577V15.8746C20.2668 14.8734 19.5435 14.0198 18.5566 13.8551C17.9035 13.7462 17.2389 13.9596 16.772 14.4265L16.6001 14.5984L16.4282 14.4265C15.9613 13.9596 15.2967 13.7462 14.6436 13.8551C13.6567 14.0198 12.9334 14.8734 12.9334 15.8746V15.9577C12.9334 16.5521 13.1798 17.1207 13.6152 17.526Z"
          fill="black"
          fillOpacity="0.48"
        />
      </g>
      <defs>
        <filter
          id="filter0_dd_2038_5190"
          x="0.600098"
          y="0.962891"
          width="32"
          height="32"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood
            floodOpacity="0"
            result="BackgroundImageFix"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite
            in2="hardAlpha"
            operator="out"
          />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_2038_5190"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="4" />
          <feComposite
            in2="hardAlpha"
            operator="out"
          />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.52549 0 0 0 0 0.937255 0 0 0 0 0.67451 0 0 0 1 0"
          />
          <feBlend
            mode="normal"
            in2="effect1_dropShadow_2038_5190"
            result="effect2_dropShadow_2038_5190"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect2_dropShadow_2038_5190"
            result="shape"
          />
        </filter>
        <clipPath id="clip0_2038_5190">
          <rect
            width="8"
            height="8"
            fill="white"
            transform="translate(12.6001 12.9629)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
