import BigNumber from "bignumber.js";
import { clsx } from "clsx";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { IcoCash, IcoPoint } from "src/assets";
import { publicApi, siteApi } from "src/main/api";
import { SubmitExchangePointRequest, SubmitExchangePointResponse } from "src/main/api/site/ExchangePoint";
import { BonusBadge, Button, Card, CardTitle, Modal } from "src/main/components";
import { useAsyncTask, useProfileBalance } from "src/main/hooks";
import { ExchangeTargetStatus } from "src/main/models";
import { RootState } from "src/main/store";
import { AuthState } from "src/main/store/auth/slices";
import { UserState } from "src/main/store/user/slices";
import { bnOrZero, fNumber, getErrorMessage, handleApiResponse, logger } from "src/main/utils";
import Currency from "src/main/utils/currency";
import { BonusDetail } from "src/main/views/CashbackPage/components";
import { AccountSelectionPayload, MemberBrand, PointSelectionPayload, SignUpBrandSelectionPayload } from "../../types";

interface AccountSelectionProps {
  onSubmit: (payload: AccountSelectionPayload) => void;
  selectedPointPayload: PointSelectionPayload;
  onBack: () => void;
  onSignUpInstruction: (payload: SignUpBrandSelectionPayload) => void;
}

interface MemberBrandWithBonus extends MemberBrand {
  bonusPercent: number;
}

function StepSelectAccount({ onSubmit, selectedPointPayload, onBack, onSignUpInstruction }: AccountSelectionProps) {
  const { self } = useSelector<RootState, AuthState>((state) => state.auth);
  const [selectedAccount, setSelectedAccount] = useState<MemberBrand>();
  const [isShowConfirmModal, setIsShowConfirmModal] = useState(false);
  const { t } = useTranslation();
  const [runExchangePoint, isExchanging] = useAsyncTask("exchange/point");
  const [submitExchangePoint] = siteApi.useSubmitExchangePointMutation();
  const { refetch: refetchBalance } = useProfileBalance();
  const exchangeVariantQuery = siteApi.useListExchangePointVariantsQuery({
    meta: {
      limit: 1,
      offset: 0,
      sort: "sortPriority:DESC",
      status: ExchangeTargetStatus.Active,
    },
  });
  const { data: creditBonusInfo, isFetching: isFetchingBonusBrands } = publicApi.useGetPublicInfoQuery();
  const { brands: allBrands } = useSelector<RootState, UserState>((state) => state.user);

  const bonusMap: Record<string, number> = useMemo(() => {
    return creditBonusInfo?.creditBonus ?? {};
  }, [creditBonusInfo]);

  const brandSortPriorityMap = useMemo(() => {
    let priorityMap: Record<string, number> = {};
    allBrands?.forEach((brand) => {
      priorityMap[brand.id] = brand.sortPriority ?? 0;
    });
    return priorityMap;
  }, [allBrands]);

  const bonusBrandIds = useMemo(() => {
    // sort bonus brand by sort priority ASC
    const bonusBrandWithSortPriority = Object.keys(bonusMap).map((brandId) => ({
      brandId,
      sortPriority: brandSortPriorityMap[brandId] ?? 0,
    }));

    return bonusBrandWithSortPriority.sort((a, b) => a.sortPriority - b.sortPriority).map((brand) => brand.brandId);
  }, [brandSortPriorityMap, bonusMap]);

  const refreshExchangeVariant = useCallback(async () => {
    await exchangeVariantQuery.refetch();
  }, [exchangeVariantQuery]);

  const { exchangeCash, originalExchangeCash, bonusPercentage, isSelectedHasBonus, exchangePoint, currency } =
    useMemo(() => {
      let exchangeCash = selectedPointPayload.amount;
      const hasBonus = !!selectedAccount && !!bonusMap[selectedAccount.brandId];
      if (hasBonus) {
        const bonusPercent = bnOrZero(bonusMap[selectedAccount.brandId]);
        exchangeCash = selectedPointPayload.amount.times(bonusPercent.plus(1)).dp(2, BigNumber.ROUND_FLOOR);
      }

      return {
        exchangePoint: selectedPointPayload.points,
        exchangeCash: exchangeCash,
        originalExchangeCash: selectedPointPayload.amount,
        currency: selectedPointPayload.exchangeTarget.currency,
        isSelectedHasBonus: hasBonus,
        bonusPercentage: !!selectedAccount ? bonusMap[selectedAccount.brandId] : undefined,
      };
    }, [selectedPointPayload, selectedAccount, bonusMap]);

  const availableBrands: MemberBrand[] = useMemo(() => {
    if (isFetchingBonusBrands) return [];
    // contains bonus brands that are in member brands
    let bonusMemberBrandList: MemberBrandWithBonus[] = [];
    // contains brands that are in member brands and has no bonus
    let nonBonusMemberBrandList: MemberBrand[] = [];

    const memberBrands = self?.member?.memberBrands ?? [];
    const exchangeBrandIds = selectedPointPayload.exchangeTarget?.brands?.map((brand) => brand.id) ?? [];

    // exchangeable brands are brands that are in member brands and in exchange target config
    const exchangeableBrands = memberBrands.filter((memberBrand) => exchangeBrandIds.includes(memberBrand.brandId));

    const memberBrandsWithSortPriority = exchangeableBrands.map((memberBrand) => ({
      ...memberBrand,
      sortPriority: brandSortPriorityMap[memberBrand.brandId] ?? 0,
    }));

    // sort member brands by sort priority ASC
    const sortedExchangeableBrands = memberBrandsWithSortPriority.sort((a, b) => a.sortPriority - b.sortPriority);

    // split member brands into bonus and non-bonus list
    sortedExchangeableBrands.forEach((memberBrand) => {
      let targetBrand = allBrands?.find((brand) => brand.id === memberBrand.brandId);

      if (!!targetBrand) {
        let target = {
          brandId: memberBrand.brandId,
          identifier: memberBrand.identifier,
          brand: targetBrand,
        };
        if (bonusBrandIds.includes(memberBrand.brandId)) {
          bonusMemberBrandList.push({ ...target, bonusPercent: bonusMap[memberBrand.brandId] });
        } else {
          nonBonusMemberBrandList.push(target);
        }
      }
    });

    // Recommended bonus brand list is a list of bonus brands but that are not in member brands
    let recommendedBonusBrandList: MemberBrandWithBonus[] = [];
    bonusBrandIds.forEach((bonusBrandId) => {
      const bonusBrandInTargetList = sortedExchangeableBrands.find((brand) => brand.brandId === bonusBrandId);
      if (!bonusBrandInTargetList) {
        const bonusBrand = allBrands?.find((brand) => brand.id === bonusBrandId);
        if (!!bonusBrand) {
          recommendedBonusBrandList.push({
            brandId: bonusBrandId,
            identifier: "",
            brand: bonusBrand,
            bonusPercent: bonusMap[bonusBrandId],
          });
        }
      }
    });

    // combine recommend and bonus list, sort by percentage DESC
    const pinnedBrands = [...recommendedBonusBrandList, ...bonusMemberBrandList].sort(
      (prevBrand, nextBrand) => nextBrand.bonusPercent - prevBrand.bonusPercent,
    );

    // combine pinned brands and non-bonus brands
    return [...pinnedBrands, ...nonBonusMemberBrandList];
  }, [bonusMap, brandSortPriorityMap, bonusBrandIds, self, allBrands, selectedPointPayload, isFetchingBonusBrands]);

  const isSkipShowSelector = useMemo(() => {
    // if there is only one brand and VIP has identifier, skip showing selector
    return availableBrands.length === 1 && !!availableBrands[0].identifier;
  }, [availableBrands]);

  useEffect(() => {
    if (isSkipShowSelector) {
      setSelectedAccount(availableBrands[0]);
      setIsShowConfirmModal(true);
    }
  }, [availableBrands, isSkipShowSelector]);

  const handleOnSignUpInstruction = useCallback(
    (brand: MemberBrand) => {
      let exchangeCash = selectedPointPayload.amount;
      const hasBonus = !!brand && !!bonusMap[brand.brandId];
      if (hasBonus) {
        const bonusPercent = bnOrZero(bonusMap[brand.brandId]);
        exchangeCash = selectedPointPayload.amount.times(bonusPercent.plus(1)).dp(2, BigNumber.ROUND_FLOOR);
      }

      const payload: SignUpBrandSelectionPayload = {
        exchangeCash: exchangeCash,
        originalExchangeCash: selectedPointPayload.amount,
        account: brand,
        bonusPercentage: bonusMap[brand.brandId],
      };

      onSignUpInstruction(payload);
    },
    [onSignUpInstruction, bonusMap, selectedPointPayload.amount],
  );

  const handleOnSelectAccount = useCallback(
    (account: MemberBrand) => {
      if (bonusBrandIds.includes(account.brandId)) {
        if (!account.identifier) {
          handleOnSignUpInstruction(account);
          return;
        }
      }
      setSelectedAccount(account);
      setIsShowConfirmModal(true);
    },
    [bonusBrandIds, handleOnSignUpInstruction],
  );

  const handleOnCloseConfirmModal = useCallback(() => {
    if (isExchanging) return;
    setIsShowConfirmModal(false);
    if (isSkipShowSelector) {
      onBack();
    }
  }, [isExchanging, isSkipShowSelector, onBack]);

  const handleOnSubmit = useCallback(async () => {
    await runExchangePoint(async () => {
      try {
        if (!selectedAccount) return;
        const submitExchangePointRequest: SubmitExchangePointRequest = {
          brandId: selectedAccount?.brandId,
          identifier: selectedAccount?.identifier,
          variantId: selectedPointPayload.exchangeVariant.id,
          quantity: selectedPointPayload.points.integerValue(BigNumber.ROUND_FLOOR).toNumber(),
        };

        const submitExchangePointResponse = await submitExchangePoint(submitExchangePointRequest);

        const { data: checkoutResponseData, error: exchangeError } =
          handleApiResponse<SubmitExchangePointResponse>(submitExchangePointResponse);

        if (!!checkoutResponseData) {
          await refetchBalance();
          onSubmit({
            account: selectedAccount,
            order: checkoutResponseData.model,
            bonusPercentage: bonusMap[selectedAccount.brandId],
          });
        }

        if (exchangeError) {
          if (exchangeError.data?.error?.message === "invalid quantity") {
            toast.error(t("Invalid points"));
            // product min quantity has been changed, re-fetch exchange point variant
            await refreshExchangeVariant();
          } else {
            toast.error(getErrorMessage(exchangeError, t));
          }
        }
      } catch (error) {
        logger._console.log({ error });
        toast.error(getErrorMessage(error, t));
      }
    });
  }, [
    refreshExchangeVariant,
    submitExchangePoint,
    onSubmit,
    refetchBalance,
    runExchangePoint,
    selectedAccount,
    selectedPointPayload,
    t,
    bonusMap,
  ]);

  return (
    <>
      <div className="px-4 lg:mt-24">
        {!isSkipShowSelector && (
          <Card
            variant="gradient-l"
            className="mx-auto pt-4 lg:max-w-lg"
          >
            <CardTitle>{t("Select Account")}</CardTitle>

            <div className="relative mt-4">
              <div className="flex flex-col gap-y-2">
                {availableBrands?.map((brand) => {
                  const isBonusBrand = !!bonusMap[brand.brandId];
                  const isRecommendedBrand = !brand.identifier && isBonusBrand;
                  return (
                    <Card
                      key={brand.brandId}
                      variant="container-dark"
                      border={false}
                      className="cursor-pointer border border-transparent !p-2 drop-shadow-xl transition-colors duration-200 hover:border-token-border hover:bg-container-3"
                      onClick={() => handleOnSelectAccount(brand)}
                    >
                      <div className="flex gap-x-3">
                        <div className="padding-1 flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-lg bg-[rgba(255,255,255,0.1)]">
                          <img
                            src={brand.brand?.icon?.url}
                            alt={brand.brand?.name}
                            height={32}
                            width={32}
                            className="object-contain object-center"
                          />
                        </div>
                        {!isRecommendedBrand && (
                          <div className="flex flex-col justify-between">
                            <h2 className="min-h-[20px] break-all text-sm text-white">{brand.identifier}</h2>
                            <p className="text-xxs text-token-secondary-2">{brand.brand?.name}</p>
                          </div>
                        )}
                        {isRecommendedBrand && (
                          <div className="h-7 w-fit self-center">
                            <Button
                              variant="blue-violet"
                              size="xs"
                            >
                              {t("Sign Up for Bonus")}
                            </Button>
                          </div>
                        )}

                        {isBonusBrand && (
                          <div className="ml-auto self-center">
                            <BonusBadge percent={bonusMap[brand.brandId]} />
                          </div>
                        )}
                      </div>
                    </Card>
                  );
                })}
              </div>
            </div>
          </Card>
        )}
      </div>
      <Modal
        show={isShowConfirmModal}
        onClose={handleOnCloseConfirmModal}
        showCloseButton={false}
        panelClassName="pt-3"
      >
        <h2 className="text-center text-xl text-white">{t("Points to Cash")}</h2>
        <div className="mt-6 flex flex-col items-center">
          <h3 className="text-sm font-normal text-white">{t("Exchanging")}</h3>
          <div className={clsx("mt-2 flex min-w-[60%] flex-col flex-wrap items-center")}>
            {/*POINTS*/}
            <div className="flex w-full items-center justify-between gap-x-2 rounded-full bg-black bg-opacity-50 px-3 py-1">
              <IcoPoint
                width={24}
                height={24}
              />
              <span className="text-xl font-bold">{fNumber(exchangePoint)}</span>
            </div>
            {/*ARROW*/}
            <div className="my-2 mx-auto rounded-full bg-[#2F1B66] p-1 drop-shadow-xl">
              <img
                src="/images/icon/arrow-flush-down.gif"
                alt="flush-all-icon"
                className="translate-y-0.5 rounded-full"
                height={20}
                width={20}
              />
            </div>
            {/*CASHES*/}
            <div className="flex w-full items-center justify-between gap-x-2 rounded-full bg-black bg-opacity-50 px-3 py-1">
              <IcoCash
                width={24}
                height={24}
              />
              <span className="text-xl font-bold">{Currency(exchangeCash).format(currency)}</span>
            </div>
            {/*BONUS*/}
            {isSelectedHasBonus && (
              <div className="mt-1">
                <BonusDetail
                  exchangeCash={exchangeCash}
                  originalExchangeCash={originalExchangeCash}
                  bonusPercentage={bonusPercentage}
                  currency={currency}
                />
              </div>
            )}
          </div>
        </div>
        <hr className="mt-4 mb-3 border-token-divider" />
        <div className="flex items-center justify-center gap-x-3">
          <span className="text-xxs font-bold uppercase">{t("To")}: </span>
          <div className="flex gap-x-3">
            <div className="padding-1 flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-lg bg-[rgba(255,255,255,0.1)]">
              <img
                src={selectedAccount?.brand?.icon?.url}
                alt={selectedAccount?.brand?.name}
                height={32}
                width={32}
                className="object-contain"
              />
            </div>
            <div className="flex flex-col justify-between">
              <h2 className="text-sm text-white">{selectedAccount?.identifier}</h2>
              <p className="text-xxs text-token-secondary-2">{selectedAccount?.brand?.name}</p>
            </div>
          </div>
        </div>
        <div className="mt-8 flex items-center gap-x-4">
          <Button
            outlined
            onClick={handleOnCloseConfirmModal}
            disabled={isExchanging}
            variant="blue-violet"
            size="lg"
          >
            {t("Cancel")}
          </Button>

          <Button
            onClick={handleOnSubmit}
            loading={isExchanging}
            variant="blue-violet"
            size="lg"
          >
            {t("Confirm")}
          </Button>
        </div>
      </Modal>
    </>
  );
}

export default StepSelectAccount;
