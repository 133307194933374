import { clsx } from "clsx";
import { throttle } from "lodash";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { FaChevronRight, FaTrashCan } from "react-icons/fa6";
import { toast } from "react-toastify";
import IcoSpinner from "src/assets/IcoSpinner";
import { siteApi } from "src/main/api";
import { Card, Checkbox, LazyImage, LoadingContainer } from "src/main/components";
import LocalizationDisplay from "src/main/components/LocalizationDisplay";
import QuantitySelector from "src/main/components/_inputs/QuantitySelector";
import { DEFAULT_PRODUCT_IMAGE_URL } from "src/main/contants";
import { useAsyncTask } from "src/main/hooks";
import useTypedSelector from "src/main/hooks/useTypedSelector";
import { CartItemModel, UpdateCartProps } from "src/main/types";
import { fNumber, getErrorMessage, getItemCartPrice, getItemCartSubTotalPrice } from "src/main/utils";

export interface ItemCartProps {
  item: CartItemModel;
  selectedVariants: CartItemModel[];
  onUpdateSelectedVariants: (ids: CartItemModel[]) => void;
  error?: string;
  cartContainer: string;
  onClickUpdateVariant?: (variant: CartItemModel) => void;
  onDelete?: (variant: CartItemModel) => void;
}

interface UpdateCartValues {
  type: "remove" | "update";
  quantity?: number;
}

function CartItemCard(props: ItemCartProps) {
  const { item, onUpdateSelectedVariants, selectedVariants, onClickUpdateVariant, error, cartContainer, onDelete } =
    props;

  const { t } = useTranslation();
  const { cart } = useTypedSelector((state) => state.products);
  const [updateCart] = siteApi.useUpdateCartMutation();
  const [runUpdateCart] = useAsyncTask("update/cart");
  const [isRemoving, setIsRemoving] = useState(false);
  const [isRemoved, setIsRemoved] = useState(false);
  const { isFetching: isFetchingCart } = siteApi.useGetCartDetailQuery({});

  const onSelectUnselect = useCallback(
    (newCartItem: CartItemModel) => {
      let newSelectedVariants = [...selectedVariants];
      if (newSelectedVariants.map((variant) => variant.variantId).includes(newCartItem.variantId)) {
        newSelectedVariants.splice(
          newSelectedVariants.findIndex((variant) => variant.variantId === newCartItem.variantId),
          1,
        );
      } else {
        newSelectedVariants.push(newCartItem);
      }
      onUpdateSelectedVariants(newSelectedVariants);
    },
    [selectedVariants, onUpdateSelectedVariants],
  );

  const onUpdateCart = useCallback(
    (updateValue: UpdateCartValues, withConfirm = true) => {
      runUpdateCart(async () => {
        try {
          const { type, quantity } = updateValue;
          const isRemoveMode = type === "remove" || (type === "update" && quantity === 0);

          if (isRemoveMode && !!onDelete && withConfirm) {
            // Use external onDelete function
            onDelete?.(item);
            return;
          }

          let cartItems = cart?.data.items
            ?.map((curItem) => {
              if (curItem.variantId === item.variantId && isRemoveMode) {
                if (selectedVariants.map((ite) => ite.variantId).includes(curItem.variantId)) onSelectUnselect(curItem);
                return null;
              }

              if (curItem.variantId === item.variantId) {
                return {
                  ...curItem,
                  quantity,
                };
              }

              return {
                ...curItem,
                variantId: curItem.variantId,
                quantity: curItem.quantity,
              };
            })
            .filter((ite) => !!ite);

          let data = {
            ...cart?.data,
            items: cartItems,
            version: cart?.data.version ?? "1",
          } as unknown as UpdateCartProps;

          if (isRemoveMode) {
            setIsRemoving(true);
          }
          await updateCart({
            data,
          }).unwrap(); // unwrap to handle errors with try-catch
          if (type === "remove" || (type === "update" && quantity === 0)) {
            toast.success(t("Removed item from cart!"));
          }
          if (isRemoveMode) {
            setIsRemoved(true);
          }
        } catch (error: any) {
          toast.error(getErrorMessage(error, t));
        } finally {
          setIsRemoving(false);
        }
      });
    },
    [runUpdateCart, onDelete, cart?.data, updateCart, item, selectedVariants, onSelectUnselect, t],
  );

  const throttleUpdateCart = useMemo(() => {
    const emitter = (v: UpdateCartValues) => {
      window.dispatchEvent(
        new CustomEvent(`${cartContainer}_HANDLE_THROTTLED_UPDATE_CART_${item.variantId}`, {
          detail: {
            data: v,
          },
        }),
      );
    };
    return throttle(emitter, 500, {
      leading: true,
      trailing: false,
    });
  }, [cartContainer, item.variantId]);

  useEffect(() => {
    const handleThrottledUpdateCart = (
      v: CustomEventInit<{
        data: UpdateCartValues;
      }>,
    ) => {
      const updateValue = v.detail?.data;
      if (updateValue) {
        onUpdateCart(updateValue);
      }
    };

    window.addEventListener(
      `${cartContainer}_HANDLE_THROTTLED_UPDATE_CART_${item.variantId}`,
      handleThrottledUpdateCart,
    );

    return () => {
      window.removeEventListener(
        `${cartContainer}_HANDLE_THROTTLED_UPDATE_CART_${item.variantId}`,
        handleThrottledUpdateCart,
        false,
      );
    };
  }, [cartContainer, item.variantId, onUpdateCart]);

  const { itemPrice } = useMemo(
    () => ({
      itemPrice: getItemCartPrice(item),
      subTotal: getItemCartSubTotalPrice(item),
    }),
    [item],
  );

  const itemImage = useMemo(() => {
    return item?.variant?.image || item?.variant?.product?.image;
  }, [item?.variant?.image, item?.variant?.product?.image]);

  const isItemLoading = useMemo(() => {
    return (isFetchingCart && isRemoved) || isRemoving;
  }, [isFetchingCart, isRemoving, isRemoved]);

  const hasOneVariation = useMemo(() => {
    return item.variant?.name === item.variant?.product?.name;
  }, [item.variant?.name, item.variant?.product?.name]);

  return (
    <LoadingContainer
      loading={isItemLoading}
      loader={
        <div className="h-full w-full animate-fade-in">
          <div className="flex h-full w-full items-center justify-center rounded-lg bg-gray-300 opacity-60">
            <IcoSpinner className="!m-0 !h-8 !w-8" />
          </div>
        </div>
      }
      overlay
    >
      <Card
        className={clsx("rounded pt-2.5", {
          "border-2 border-solid border-red-500": !!error,
        })}
        variant="solid-inverted-surface"
      >
        <div className={`flex w-full flex-row items-center space-x-3`}>
          <Checkbox
            onChange={() => onSelectUnselect(item)}
            id="exampleCheckbox"
            className="flex-shrink-0"
            variant="filled"
            checked={!!selectedVariants.find((cur) => cur.variantId === item.variantId)}
          />
          <div className={`relative h-[70px] w-[70px] flex-shrink-0 p-0`}>
            {
              <LazyImage
                src={itemImage?.url}
                className="h-full w-full rounded-lg  bg-white object-contain"
                alt="card"
                fallbackSrc={DEFAULT_PRODUCT_IMAGE_URL}
              />
            }
          </div>
          <div className="flex w-full flex-grow flex-col justify-between self-stretch py-1.5 overflow-x-hidden">
            <LocalizationDisplay
              field="name"
              localisations={item?.variant?.product?.localisations}
              defaultValue={item?.variant?.product?.name}
              className="line-clamp-1 text-xs font-bold text-gray-700"
            />

            {!hasOneVariation && (
              <button
                onClick={() => onClickUpdateVariant?.(item)}
                className="flex w-fit max-w-full items-center space-x-0.5 rounded border border-midnight-50 py-2 pl-3 pr-2 overflow-x-hidden active:bg-gray-50"
              >
                <LocalizationDisplay
                  field="name"
                  localisations={item?.variant?.localisations}
                  defaultValue={item?.variant?.name}
                  className="line-clamp-1 text-xs text-gray-700"
                />
                <FaChevronRight
                  size={14}
                  className="flex-shrink-0 text-content-secondary"
                />
              </button>
            )}
          </div>
        </div>

        <div className="mt-2 flex w-full items-center justify-end space-x-3 ">
          {!!item?.variant?.price && (
            <div className="flex-grow-1 flex min-w-[calc(100%-216px)] items-center space-x-1">
              <img
                src="/images/icon/heart_icon_yellow.svg"
                className="h-5 w-5"
                alt="heart-icon"
              />
              <span className="text-sm text-gray-700">{fNumber(itemPrice)}</span>
            </div>
          )}

          <QuantitySelector
            onIncrease={() =>
              throttleUpdateCart({
                type: "update",
                quantity: item.quantity + 1,
              })
            }
            onDecrease={() =>
              throttleUpdateCart({
                type: "update",
                quantity: item.quantity - 1,
              })
            }
            value={item.quantity}
            className="flex-shrink-0"
            minValue={0}
          />
        </div>

        {!!error && (
          <div className="flex items-center justify-between pt-2 text-xs text-red-500">
            <div className="text-right">{error}</div>
            <button onClick={() => onUpdateCart({ type: "remove" }, false)}>
              {t("Remove")}
              <FaTrashCan className="ml-1 inline-block h-3 w-3" />
            </button>
          </div>
        )}
      </Card>
    </LoadingContainer>
  );
}

export default CartItemCard;
