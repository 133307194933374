import React, { PropsWithChildren } from "react";
import { useTranslation } from "react-i18next";
import IcoGreaterThan from "src/assets/IcoGreaterThan";
export declare type Params<Key extends string = string> = {
  readonly [key in Key]: string | undefined;
};

const LoginTimer = (props: UserMenu_listProps) => {
  const { children, onClick } = props;
  const { t } = useTranslation();
  const styles = {
    divSelectedClass: `w-full inline-flex justify-between items-center self-stretch rounded-lg text-white text-left font-bold drop-shadow-lg rounded-md bg-gradient-to-r from-[rgba(0,48,172,1)] to-[rgba(152,34,244,1)] p-0.5`,
    divSelectedInnerClass: `rounded-md h-full w-full items-center bg-[rgba(0,0,0,1)] px-2 py-4`,
  };

  return (
    <>
      <div className="rounded-md bg-black bg-opacity-40 px-3 text-white">
        <div className="flex w-full justify-center py-2 font-bold capitalize text-[#F13017]">{t("login failed")}</div>
        <div className={`${styles.divSelectedClass}`}>
          <div className={`${styles.divSelectedInnerClass}`}>
            <div className="flex w-full justify-center">{children}</div>
          </div>
        </div>
        <div className="flex w-full justify-center py-2 text-center text-xxs	font-normal">{t("login_failed_1")}</div>
      </div>
      <div className="relative flex items-center py-2">
        <div className="flex-grow border-t border-white"></div>
        <span className="mx-1 flex-shrink text-xxs text-white">{t("login_failed_2")}</span>
        <div className="flex-grow border-t border-white"></div>
      </div>

      <button
        type="submit"
        onClick={onClick}
        className={`
                            w-full rounded-full
                            bg-black
                            bg-opacity-40
                            p-1
                            text-[14px]
                            font-bold
                            text-white
                            shadow-lg
                            `}
      >
        {t("login_failed_3")}
        <div className="mx-2 inline-block">
          <IcoGreaterThan color="#99B6FF" />
        </div>
      </button>
    </>
  );
};

LoginTimer.defaultProps = {
  text: "",
};

interface UserMenu_listProps extends PropsWithChildren {
  onClick: (evt: React.MouseEvent<HTMLButtonElement>) => void;
}

export default LoginTimer;
