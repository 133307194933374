import { DetailedHTMLProps, ImgHTMLAttributes } from "react";

interface IcoCashProps extends DetailedHTMLProps<ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement> {}

function IcoCash(props: IcoCashProps) {
  return (
    <img
      src="/images/icon/ico-cash.png"
      alt="IcoCash"
      {...props}
    />
  );
}

export default IcoCash;
