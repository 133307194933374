import clsx from "clsx";

interface MissionIconProps {
  isExpired: boolean;
  className?: string;
  iconSrc?: string | null;
}

export default function MissionIcon({
  isExpired,
  className,
  iconSrc = "/images/body-bg/mission-icon.png",
}: MissionIconProps) {
  return (
    <div
      className={`relative h-[68px] w-[70px] rounded-tl-lg ${className}`}
      style={{
        backgroundImage: "url(/images/body-bg/mission-icon-bg.jpeg)",
      }}
    >
      <img
        className={clsx("absolute bottom-0 left-0 z-[1] h-[88px] w-[70px] bg-cover", {
          grayscale: isExpired,
        })}
        src={iconSrc ?? ""}
        alt="mission icon"
      />
      <div className="absolute bottom-0 left-0 z-[2] h-[68px] w-[70px] rounded-tl-lg bg-[radial-gradient(114.31%_100%_at_50.3%_32.21%,rgba(32,10,81,0)_37.68%,#12072B_76.53%)]"></div>
    </div>
  );
}
