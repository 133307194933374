import { clsx } from "clsx";
import { PropsWithChildren, ReactNode } from "react";

export interface LoadingContainerProps extends PropsWithChildren {
  loading: boolean;
  loader: ReactNode;
  overlay?: boolean;
  containerClassName?: string;
}

function LoadingContainer({ loading, loader, children, overlay = false, containerClassName }: LoadingContainerProps) {
  if (overlay) {
    return (
      <>
        {loading ? (
          <div className="relative">
            {children}
            <div className="absolute top-0 left-0 h-full w-full">{loader}</div>
          </div>
        ) : (
          <div className={clsx("animate-fade-in", containerClassName)}>{children}</div>
        )}
      </>
    );
  } else {
    return <>{loading ? loader : <div className={clsx("animate-fade-in", containerClassName)}>{children}</div>}</>;
  }
}

export default LoadingContainer;
