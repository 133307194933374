import { PromotionBannerModel } from "src/main/models";
import { Meta, QueryResult } from "src/main/types";
import { parseMeta, require200Status } from "src/main/utils";
import { BuilderProp } from "..";

export interface ListBannerRequest {
  meta?: Partial<Meta>;
}

export interface ListBannerResponse {
  entries: PromotionBannerModel[];
  meta: Meta;
}

export const listBanners = (builder: BuilderProp) => {
  return builder.query<ListBannerResponse, ListBannerRequest>({
    providesTags: ["banner"],
    query: ({ meta }) => ({
      url: `/member/banner/list${parseMeta(meta)}`,
      validateStatus: require200Status,
    }),
    transformResponse: (response: QueryResult<ListBannerResponse>) => {
      return response.result;
    },
  });
};
