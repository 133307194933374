import { clsx } from "clsx";
import { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { siteApi } from "src/main/api";
import { RewardType } from "src/main/types";
import { getErrorMessage } from "src/main/utils";
import { ClaimPointsSection } from "src/main/views/RewardsPage/components";
import { CoinLabelRef } from "src/main/views/RewardsPage/components/CoinLabel/CoinLabel";
import RewardCard from "src/main/views/RewardsPage/components/RewardCard";
import { RewardBoxStatus } from "src/main/views/RewardsPage/types";
import { useGetRewardsInfo } from "../../hooks";
import AnimatedBackground from "../AnimatedBackground";
import "./RewardBoxMonthly.css";

const RewardBoxMonthly = () => {
  const { t } = useTranslation();
  const refCoinLabel = useRef<CoinLabelRef>(null);

  const { data, /* isFetching, */ refetch, isLoading, isGetMorePoints, initialRewardStatus } = useGetRewardsInfo(
    RewardType.MONTHLY,
  );
  const [rewardStatus, setRewardStatus] = useState<RewardBoxStatus | undefined>(initialRewardStatus);

  const [claim, { isLoading: isClaiming }] = siteApi.useClaimRewardMutation();

  useEffect(() => {
    if (data) {
      const { remainingSeconds } = data.monthly;
      if (remainingSeconds !== 0) {
        setRewardStatus(RewardBoxStatus.LOCKED);
      } else {
        setRewardStatus(RewardBoxStatus.CLAIMABLE);
      }
    }
  }, [data]);

  const handleOnClaim = useCallback(async () => {
    try {
      await claim({ type: RewardType.MONTHLY }).unwrap();
      setRewardStatus(RewardBoxStatus.CLAIMED);
      const timer = setTimeout(() => {
        refCoinLabel.current?.count();
        clearTimeout(timer);
      }, 2000);
    } catch (error: any) {
      console.log(error);
      toast.error(getErrorMessage(error, t));
      refetch();
    }
  }, [t, claim, refetch]);

  const handleOnCountDownEnd = useCallback(() => {
    refetch();
  }, [refetch]);

  const handleOnTimerComplete = useCallback(() => {
    setRewardStatus(RewardBoxStatus.CLAIMABLE);
  }, []);

  return (
    <RewardCard
      title={t("monthly")}
      loading={isLoading}
      borderEffect={rewardStatus === RewardBoxStatus.CLAIMABLE && !isGetMorePoints}
      className="animate-fade-in opacity-0 ![animation-delay:600ms]"
    >
      {!!data && !!rewardStatus && (
        <>
          <div className="relative z-[-1] flex h-full flex-col">
            <AnimatedBackground status={isGetMorePoints ? RewardBoxStatus.LOCKED : rewardStatus}>
              <div className="absolute top-0 left-0 h-1/2 w-full">
                {/*LIGHT*/}
                <div
                  className={clsx(
                    "absolute left-1/2 bottom-[0px] h-full w-[100%] -translate-x-1/2 bg-contain bg-no-repeat opacity-80 transition-[opacity,bottom] duration-[2s]",
                    {
                      "!bottom-2": rewardStatus === RewardBoxStatus.CLAIMED,
                      "!opacity-0": rewardStatus === RewardBoxStatus.LOCKED || isGetMorePoints,
                    },
                  )}
                  style={{
                    backgroundImage: "url('/images/body-bg/card-light.svg')",
                    backgroundPositionY: "bottom",
                  }}
                />

                {/*COINS*/}
                <div
                  className={clsx(
                    "absolute left-1/2 bottom-0 w-[35%] translate-x-[calc(-50%-70%)] translate-y-[30%] rounded-full",
                    {
                      "animate-[coinLockedRaise_1.2s_forwards] bg-[#1D104A]":
                        rewardStatus === RewardBoxStatus.LOCKED || isGetMorePoints,
                      "animate-[coinScale_6s_infinite]": rewardStatus === RewardBoxStatus.CLAIMABLE && !isGetMorePoints,
                      "animate-[coinRaiseHigher_1.2s_forwards]": rewardStatus === RewardBoxStatus.CLAIMED,
                    },
                  )}
                >
                  <img
                    src="/images/icon/reward-coin.svg"
                    className={clsx("h-full w-full transition-opacity duration-500", {
                      "opacity-20": rewardStatus === RewardBoxStatus.LOCKED || isGetMorePoints,
                      "opacity-100": rewardStatus === RewardBoxStatus.CLAIMABLE && !isGetMorePoints,
                      "opacity-0 [transition-delay:2s]": rewardStatus === RewardBoxStatus.CLAIMED && !isGetMorePoints,
                    })}
                    alt="coin"
                  />
                </div>
                <div
                  className={clsx(
                    "absolute left-1/2 bottom-0 w-[35%] translate-x-[calc(-50%+70%)] translate-y-[30%] rounded-full",
                    {
                      "animate-[coinLockedRaise_1.2s_forwards] bg-[#1D104A]":
                        rewardStatus === RewardBoxStatus.LOCKED || isGetMorePoints,
                      "animate-[coinScale_6s_infinite]": rewardStatus === RewardBoxStatus.CLAIMABLE && !isGetMorePoints,
                      "animate-[coinRaiseHigher_1.2s_forwards]": rewardStatus === RewardBoxStatus.CLAIMED,
                    },
                  )}
                >
                  <img
                    src="/images/icon/reward-coin.svg"
                    className={clsx("h-full w-full transition-opacity duration-500", {
                      "opacity-20": rewardStatus === RewardBoxStatus.LOCKED || isGetMorePoints,
                      "opacity-100": rewardStatus === RewardBoxStatus.CLAIMABLE && !isGetMorePoints,
                      "opacity-0 [transition-delay:2s]": rewardStatus === RewardBoxStatus.CLAIMED && !isGetMorePoints,
                    })}
                    alt="coin"
                  />
                </div>
                <div
                  className={clsx(
                    "absolute left-1/2 bottom-0 w-[42%] -translate-x-1/2 translate-y-[20%] rounded-full",
                    {
                      "animate-[coinLockedRaise_1.2s_forwards] bg-[#1D104A]":
                        rewardStatus === RewardBoxStatus.LOCKED || isGetMorePoints,
                      "animate-[coinScale_6s_infinite]": rewardStatus === RewardBoxStatus.CLAIMABLE && !isGetMorePoints,
                      "animate-[coinRaise_1.2s_forwards]": rewardStatus === RewardBoxStatus.CLAIMED,
                    },
                  )}
                >
                  <img
                    src="/images/icon/reward-coin.svg"
                    className={clsx("h-full w-full transition-opacity duration-500", {
                      "opacity-20": rewardStatus === RewardBoxStatus.LOCKED || isGetMorePoints,
                      "opacity-100": rewardStatus === RewardBoxStatus.CLAIMABLE && !isGetMorePoints,
                      "opacity-0 [transition-delay:2s]": rewardStatus === RewardBoxStatus.CLAIMED && !isGetMorePoints,
                    })}
                    alt="coin"
                  />
                </div>

                {/*COINS TICK*/}
                <div
                  className={clsx(
                    "absolute left-1/2 bottom-0 z-10 w-[35%] translate-x-[calc(-50%-70%)] translate-y-[30%] rounded-full",
                    {
                      "animate-[coinRaiseHigher_1.2s_forwards]": rewardStatus === RewardBoxStatus.CLAIMED,
                      "opacity-0": rewardStatus !== RewardBoxStatus.CLAIMED,
                    },
                  )}
                >
                  <div
                    className={clsx({
                      "translate-y-[100%] opacity-0 transition-all duration-[3s] ![transition-delay:4s]":
                        rewardStatus === RewardBoxStatus.CLAIMED,
                    })}
                  >
                    <img
                      src="/images/icon/reward-coin-tick.svg"
                      className={clsx("h-full w-full opacity-0 transition-opacity duration-500", {
                        "opacity-100 [transition-delay:2s]": rewardStatus === RewardBoxStatus.CLAIMED,
                      })}
                      alt="coin"
                    />
                  </div>
                </div>
                <div
                  className={clsx(
                    "absolute left-1/2 bottom-0 z-10 w-[35%] translate-x-[calc(-50%+70%)] translate-y-[30%] rounded-full",
                    {
                      "animate-[coinRaiseHigher_1.2s_forwards]": rewardStatus === RewardBoxStatus.CLAIMED,
                      "opacity-0": rewardStatus !== RewardBoxStatus.CLAIMED,
                    },
                  )}
                >
                  <div
                    className={clsx({
                      "translate-y-[100%] opacity-0 transition-all duration-[3s] ![transition-delay:4s]":
                        rewardStatus === RewardBoxStatus.CLAIMED,
                    })}
                  >
                    <img
                      src="/images/icon/reward-coin-tick.svg"
                      className={clsx("h-full w-full opacity-0 transition-opacity duration-500", {
                        "opacity-100 [transition-delay:2s]": rewardStatus === RewardBoxStatus.CLAIMED,
                      })}
                      alt="coin"
                    />
                  </div>
                </div>
                <div
                  className={clsx(
                    "absolute left-1/2 bottom-0 z-10 w-[42%] -translate-x-1/2 translate-y-[20%] rounded-full",
                    {
                      "animate-[coinRaise_1.2s_forwards]": rewardStatus === RewardBoxStatus.CLAIMED,
                      "opacity-0": rewardStatus !== RewardBoxStatus.CLAIMED,
                    },
                  )}
                >
                  <div
                    className={clsx({
                      "translate-y-[100%] opacity-0 transition-all duration-[3s] ![transition-delay:4s]":
                        rewardStatus === RewardBoxStatus.CLAIMED,
                    })}
                  >
                    <img
                      src="/images/icon/reward-coin-tick.svg"
                      className={clsx("h-full w-full opacity-0 transition-opacity duration-500", {
                        "opacity-100 [transition-delay:2s]": rewardStatus === RewardBoxStatus.CLAIMED,
                      })}
                      alt="coin"
                    />
                  </div>
                </div>
              </div>
            </AnimatedBackground>
            <ClaimPointsSection
              onPointsCountEnd={handleOnCountDownEnd}
              onTimerComplete={handleOnTimerComplete}
              onClaim={handleOnClaim}
              isClaiming={isClaiming}
              status={rewardStatus}
              refCoinLabel={refCoinLabel}
              isGetMorePoints={isGetMorePoints}
              rewardData={data.monthly}
            />
          </div>
        </>
      )}
    </RewardCard>
  );
};

export default RewardBoxMonthly;
