import BigNumber from "bignumber.js";
import { clsx } from "clsx";
import { utc } from "moment";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { CircularProgress, PropsWithClassName } from "src/main/components";
import { ONE_DAY_IN_SECONDS } from "src/main/contants";
import { bnOrZero } from "src/main/utils";

enum DisplayMode {
  EXPIRED,
  WITHIN_1DAY,
  WITHIN_3DAYS,
  OVER_3DAYS,
}

interface MissionTimerProps extends PropsWithClassName {
  remainingSeconds: number;
  totalSeconds: number;
}

const TimerBadge = ({ remainingSeconds, totalSeconds, className }: MissionTimerProps) => {
  const { t } = useTranslation();
  const displayMode = getDisplayMode(remainingSeconds);
  const percentage = !!totalSeconds ? Math.ceil((remainingSeconds / totalSeconds) * 100) / 100 : 0;

  const { textStyledClass, badgeStyledClass, formatTime, progressColor } = useMemo(() => {
    // time formatter
    let formatter: Function | undefined = undefined;
    switch (displayMode) {
      case DisplayMode.EXPIRED:
      case DisplayMode.WITHIN_1DAY:
        formatter = (timeInSeconds: number) => utc(timeInSeconds * 1000).format("HH:mm:ss");
        break;
      case DisplayMode.WITHIN_3DAYS:
      case DisplayMode.OVER_3DAYS:
        formatter = (timeInSeconds: number) => {
          const days = bnOrZero(timeInSeconds).div(ONE_DAY_IN_SECONDS).dp(0, BigNumber.ROUND_DOWN).toNumber();
          return t("day", { count: days });
        };
        break;
    }

    return {
      textStyledClass: TEXT_STYLED_CLASS_MAP[displayMode],
      badgeStyledClass: BADGE_STYLED_CLASS_MAP[displayMode],
      progressColor: PROGRESS_COLOR_MAP[displayMode],
      formatTime: formatter,
    };
  }, [displayMode, t]);

  return (
    <span className={clsx("flex items-center space-x-1", className)}>
      <span className="text-xs uppercase leading-4.5 text-content-secondary">{t("time left")}</span>
      <span className={clsx("flex items-center space-x-1 rounded-full pl-0.5 pr-1.5 text-sm", badgeStyledClass)}>
        <CircularProgress
          percentage={percentage}
          color={progressColor}
        />
        <span className={clsx(textStyledClass)}>{formatTime(remainingSeconds)}</span>
      </span>
    </span>
  );
};

const getDisplayMode = (remainingSeconds: number): DisplayMode => {
  if (remainingSeconds <= 0) {
    return DisplayMode.EXPIRED;
  }
  if (remainingSeconds < ONE_DAY_IN_SECONDS) {
    return DisplayMode.WITHIN_1DAY;
  }
  if (remainingSeconds <= ONE_DAY_IN_SECONDS * 3) {
    return DisplayMode.WITHIN_3DAYS;
  }
  return DisplayMode.OVER_3DAYS;
};

const TEXT_STYLED_CLASS_MAP: Record<DisplayMode, string> = {
  [DisplayMode.EXPIRED]: "text-midnight-100",
  [DisplayMode.WITHIN_1DAY]: "text-content-negative-primary",
  [DisplayMode.WITHIN_3DAYS]: "text-content-warning-primary",
  [DisplayMode.OVER_3DAYS]: "text-content-positive-primary",
};

const BADGE_STYLED_CLASS_MAP: Record<DisplayMode, string> = {
  [DisplayMode.EXPIRED]: "bg-[#FFFFFF1F]",
  [DisplayMode.WITHIN_1DAY]: "bg-[#F43F5E33]",
  [DisplayMode.WITHIN_3DAYS]: "bg-[#F59E0B33]",
  [DisplayMode.OVER_3DAYS]: "bg-[#22C55E33]",
};

const PROGRESS_COLOR_MAP: Record<DisplayMode, string> = {
  [DisplayMode.EXPIRED]: "#BCB8C1",
  [DisplayMode.WITHIN_1DAY]: "#F43F5E",
  [DisplayMode.WITHIN_3DAYS]: "#F59E0B",
  [DisplayMode.OVER_3DAYS]: "#22C55E",
};

export default TimerBadge;
