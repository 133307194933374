import moment from "moment";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { FaCalendarDays, FaTruckFast } from "react-icons/fa6";
import { OrderItemCard, PointDisplay } from "src/main/components";
import { OrderHistoryModel, ShippingAddressModel } from "src/main/types";
import { bnOrZero } from "src/main/utils";
import { AddressItem } from "src/main/views/AddressListPage/components";

interface RedeemedOrderDetailProps {
  order: OrderHistoryModel;
}

const RedeemedOrderDetail = ({ order }: RedeemedOrderDetailProps) => {
  const { t } = useTranslation();
  const address: ShippingAddressModel = useMemo(() => {
    return {
      id: "",
      recipientName: order.shipment?.recipientName ?? "",
      recipientMobile: order.shipment?.recipientMobile ?? "",
      city: order.shipment?.city ?? "",
      state: order.shipment?.state ?? "",
      address: order.shipment?.address ?? "",
      address2: order.shipment?.address2 ?? "",
      country: order.shipment?.country ?? "",
      postalCode: order.shipment?.postalCode ?? "",
    };
  }, [order.shipment]);

  const isAllVirtualItems = useMemo(() => {
    return order?.orderItems?.every((item) => item.info?.product?.fulfillmentType === "virtual");
  }, [order?.orderItems]);

  return (
    <>
      {/*CREATED AT*/}
      <div className="mt-4 flex items-center space-x-1.5">
        <FaCalendarDays className="h-3.5 w-3.5 text-content-secondary" />
        <span className="inline-block text-xs text-content-primary">
          {moment.utc(order.createdAt).local().format("DD MMM YYYY")}
        </span>
      </div>

      {/*SHIPMENT INFO*/}
      {order.shipment && !isAllVirtualItems && (
        <div className="mt-3.5 rounded-md bg-onOverlay py-2 px-2.5">
          <AddressItem
            address={address}
            shortMode
            editable={false}
            displayDefaultMark={false}
          />
        </div>
      )}

      {/*REMARK*/}
      {!!order.shipment?.remarks && (
        <div className="mt-3.5">
          <span className="text-xs font-bold capitalize text-content-secondary">{t("remark")}:</span>
          <span className="pl-1 text-xs">{order.shipment?.remarks}</span>
        </div>
      )}

      {/*ITEM LIST*/}
      <div className="mt-3.5 py-4">
        <span className="text-sm font-bold text-content-primary ">{t("Item(s)")}:</span>
        <div className="space-y-1">
          {order.orderItems.map((item, index) => (
            <OrderItemCard
              item={item}
              key={item.variantId}
            />
          ))}
        </div>
      </div>

      {/*TOTAL*/}
      <div className="mt-3.5 flex items-center justify-between">
        <span className="text-xs font-bold uppercase text-content-primary">{t("Total")}:</span>
        <PointDisplay
          points={bnOrZero(order.total)}
          textClassName="text-base !text-content-primary font-bold"
        />
      </div>

      {/*SHIPMENT ESTIMATED TIME*/}
      <div className="mt-3.5 flex items-center space-x-1.5">
        <FaTruckFast className="h-5 w-5 text-content-secondary" />
        <span className="text-xxs text-content-secondary">{t("Delivery estimated in two weeks")}</span>
      </div>
    </>
  );
};

export default RedeemedOrderDetail;
