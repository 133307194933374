import { Moment } from "moment";
import { SimpleMap } from "../utils";
import { PrivilegeModel } from "./auth";
import { BaseModel } from "./models";

export enum MemberStatusType {
  ACTIVATED = "active",
  SUSPENDED = "suspended",
  DISABLED = "disabled",
}

export enum WalletTxSourceWalletType {
  Primary = "primary",
  Secret = "secret",
  Vip = "vip",
  Weekly = "weekly",
  Monthly = "monthly",
}

export interface Wallet {
  balance: string;
  id: string;
  type: string;
}

export interface MemberBrandsData {
  identifier: string;
  brandId: string;
}

export interface MemberModel extends BaseModel {
  accountId: string;
  crmKey: string;
  email: string;
  firstname: string;
  lastname: string;
  status: MemberStatusType;
  remark: string | null;
  onboardAt: null | string;
  syncAt: null | string;
  phone: string;
  wallets: any[];
  phoneVerifiedAt: string | null;
  phoneVerified?: boolean | null;
  // brands?: BrandModel[];
  memberBrands?: MemberBrandsData[];
  lastDailyRedeemedAt: Moment | null;
  primaryWallet?: Wallet;
  isBlocked?: boolean;
  passwordExpiredAt?: string | null;
  voluumCid?: string;
}

export interface SelfEntityModel extends BaseModel {
  ownerType: string;
  ownerId: string;

  lastSeenAt: Date | null;

  totpEnabled: boolean | null;

  member?: MemberModel;
  privileges?: PrivilegeModel[];
  claimedDailyBonus: boolean;
}

export interface AccountModel extends BaseModel {
  profileImageId?: string;
  ownerId: string;
  name: string;
  status: string;
  accountVariables?: SimpleMap<SimpleMap<string>>[];
}

export interface ProfileModel {
  self: SelfEntityModel;
  accounts: AccountModel[];
}
