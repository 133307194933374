import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
import { appConfig } from "src/config";
import { Language } from "src/main/types";
import { checkWithOrWithoutWWW } from "src/main/utils";

export interface PreferenceState {
  language: string | null;
}

const LANG_KEY = "lng";

const getPersisLanguage = () => {
  try {
    const savedLanguage = localStorage.getItem(LANG_KEY);
    if (!!savedLanguage) return savedLanguage;

    const hostname = window.location.hostname ?? "";
    // Get language from domain config
    const domainConfig = appConfig.domains.find((domainConfig) => {
      return checkWithOrWithoutWWW(domainConfig.domain, hostname);
    });

    if (domainConfig?.country) {
      // Get language from country config first, if not found, get from appConfig, if not found, use default
      return domainConfig?.countryConfig?.language ?? appConfig.countries[domainConfig.country].language ?? Language.EN;
    }

    return Language.EN;
  } catch (error) {
    return Language.EN;
  }
};

const initialState: PreferenceState = {
  language: getPersisLanguage(),
};

const preferenceSlice = createSlice({
  name: "@preference",
  initialState,
  reducers: {
    setLanguage(state, action: PayloadAction<string>) {
      state.language = action.payload;
      localStorage.setItem(LANG_KEY, state.language);
    },
  },
});

export default preferenceSlice;
