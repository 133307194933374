import { Moment } from "moment";

export interface AuthToken {
  tokenType: "bearer";
  accessToken: string;
  expiresAt: Moment;
  refreshExpiresAt?: Moment;
  refreshToken?: string;
}

export interface OAuthAccessTokenResult {
  token_type: "bearer";
  access_token: string;
  expires_at: number;
  expires_in: number;
  refresh_expires_at: number;
  refresh_expires_in: number;
  refresh_token: string;
}

export enum PrivilegeDomain {
  Account = "account",
  Platform = "platform",
}

export interface PrivilegeModel {
  name: string;
  label: string;
  description: string;
  managemable: boolean;
  domain: PrivilegeDomain;
}
