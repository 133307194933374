import { BaseQueryFn, FetchArgs, FetchBaseQueryError, FetchBaseQueryMeta } from "@reduxjs/toolkit/dist/query";
import { EndpointBuilder } from "@reduxjs/toolkit/dist/query/endpointDefinitions";
import { createApi } from "@reduxjs/toolkit/dist/query/react";
import { QueryResult } from "src/main/types";
import { queryWithRefresh } from "../base";
import {
  changePassword,
  checkOnboard,
  checkUsername,
  claimTodayBonus,
  loginUnlockTime,
  mobileLogin,
  requestOnboard,
  requestOTP,
  setupPassword,
  submitOTP,
  updateVolumm,
  verifyOnboard,
} from "./endpoints";
import { ProfileResponse } from "./types";

export const require200Status = (response: Response) => response.status === 200;

export type AuthTagType = "oauth" | "self" | "account" | "brand";
export type BuilderProp = EndpointBuilder<
  BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError, {}, FetchBaseQueryMeta>,
  AuthTagType,
  "authApi"
>;

export const accountTags: AuthTagType[] = ["oauth", "self", "account", "brand"];

const authApi = createApi({
  reducerPath: "authApi",
  baseQuery: queryWithRefresh,
  tagTypes: accountTags,
  endpoints: (builder) => ({
    profile: builder.query<ProfileResponse, void>({
      providesTags: ["self"],
      query: () => ({
        url: "self/profile",
        validateStatus: require200Status,
      }),
      transformResponse: (response: QueryResult<ProfileResponse>) => {
        return response.result;
      },
    }),

    authenticate: builder.query<ProfileResponse, string>({
      providesTags: ["self"],
      query: (token: string) => ({
        url: "self/profile",
        validateStatus: require200Status,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }),
      transformResponse: (response: QueryResult<ProfileResponse>) => {
        return response.result;
      },
    }),

    checkOnboard: checkOnboard(builder),
    checkUsername: checkUsername(builder),
    loginUnlockTime: loginUnlockTime(builder),
    mobileLogin: mobileLogin(builder),

    requestOtp: requestOTP(builder),
    submitOtp: submitOTP(builder),
    resetPassword: changePassword(builder),
    requestOnboard: requestOnboard(builder),
    verifyOnboard: verifyOnboard(builder),
    setupPassword: setupPassword(builder),
    claimTodayBonus: claimTodayBonus(builder),

    updateVoluum: updateVolumm(builder),
  }),
});

export default authApi;
