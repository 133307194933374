import { clsx } from "clsx";
import { ReactNode, useMemo } from "react";
import { FaCircleCheck, FaCircleInfo, FaTriangleExclamation, FaXmark } from "react-icons/fa6";
import { useLocation } from "react-router";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { TypeOptions } from "react-toastify/dist/types";
import { useBreakPoint } from "src/main/hooks";
import { isPrivatePath } from "src/main/utils/paths";
import "./Toast.css";

const ICON_MAP: Record<TypeOptions, ReactNode> = {
  default: null,
  error: <FaTriangleExclamation className="text-base text-amber-500" />,
  info: <FaCircleInfo className="text-base text-content-primary" />,
  success: <FaCircleCheck className="text-base text-lime-500" />,
  warning: <FaTriangleExclamation className="text-base text-amber-500" />,
};

interface ToastProps {}

export default function Toast(props: ToastProps) {
  const { isMobile } = useBreakPoint();
  const location = useLocation();

  const isDisplayTopBar = useMemo(() => {
    return isPrivatePath(location.pathname);
  }, [location.pathname]);

  return (
    <ToastContainer
      className={clsx("top-[72px] ml-2 w-[calc(100%-1rem)] lg:w-full lg:max-w-sm", {
        "!top-[10px]": !isDisplayTopBar,
      })}
      enableMultiContainer
      hideProgressBar
      icon={({ type }) => ICON_MAP[type] || null}
      autoClose={2000}
      bodyClassName="!text-content-secondary text-sm mx-0 my-0"
      toastClassName={clsx(
        "mx-auto !mb-1.5 !min-h-[unset] w-fit !rounded !border-none !bg-overlay !py-1 lg:w-full",
        {},
      )}
      progressClassName="!h-0"
      closeButton={
        <button className="flex h-8 w-8 flex-shrink-0 items-center justify-center">
          <FaXmark className="h-4 w-4 text-base text-content-secondary" />
        </button>
      }
      position={isMobile ? "top-center" : "top-right"}
    />
  );
}

Toast.defaultProps = {};
