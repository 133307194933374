import React, { PropsWithChildren } from "react";
import { Link } from "react-router-dom";

interface TopNavigationItemProps extends PropsWithChildren {
  title: string | React.ReactNode;
  icon: React.ReactNode;
  linkTo?: string;
  linkReplace?: boolean;
  indicator?: "chat-indicator" | "claimable-indicator";
  onClick?: () => void;
}

const TopNavItem = ({ icon, title, linkTo, linkReplace = false, indicator, onClick }: TopNavigationItemProps) => {
  return (
    <Link
      to={linkTo ?? ""}
      relative="path"
      replace={linkReplace}
      className="group relative hidden cursor-pointer items-center text-center lg:block"
      onClick={onClick}
    >
      <div className="relative mx-auto mt-1 h-8 w-8 pb-1 group-hover:drop-shadow-[0px_0px_6px_#FFF0D1]">
        {icon}

        {indicator === "claimable-indicator" && (
          <div className="absolute right-[-10px] top-[-10px] z-10 flex h-8 w-8 items-center justify-center rounded-full">
            <img
              src="/images/icon/badge-coin-pulse.svg"
              height="32"
              width="32"
              className="absolute border-transparent"
              alt="points-icon"
            />
          </div>
        )}

        {indicator === "chat-indicator" && (
          <div className="absolute right-[-4px] top-[-5px] z-10 flex h-4 w-4 items-center justify-center rounded-full">
            <div className="h-2 w-2 animate-scale-pulse rounded-full border-[3px] border-transparent bg-[#39FF14] drop-shadow-[0_0_6px_#39FF14] filter" />
          </div>
        )}
      </div>
      <p className="text-xxs font-normal uppercase">{title}</p>
    </Link>
  );
};

export default TopNavItem;
