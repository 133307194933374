import { ReactNode, useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import IcoSpinner from "src/assets/IcoSpinner";
import { siteApi } from "src/main/api";
import { ListCategoryProductRequest } from "src/main/api/site/Product";
import { LoadingContainer, Skeleton } from "src/main/components";
import { useAsyncTask } from "src/main/hooks";
import { RootState } from "src/main/store";
import productSlice, { ProductState } from "src/main/store/product/slices";
import { Meta } from "src/main/types";
import { ProductSortingButton } from "src/main/views/GiftsPage/components";
import ProductCard from "src/main/views/GiftsPage/components/ProductCard";
import InfiniteScroll from "../../../component/InfiniteScroll";
import CategoryList from "../CategoryList";

interface ProductListSectionProps {
  children?: ReactNode;
}

const ProductListSection = (props: ProductListSectionProps) => {
  const { t } = useTranslation();
  const [listCategoryProducts] = siteApi.useLazyListCategoryProductsQuery();
  const [listProducts] = siteApi.useLazyListProductsQuery();
  const [runListProduct, isLoading] = useAsyncTask("list/product");

  const dispatch = useDispatch();
  const [querySort, setQuerySort] = useState<string | undefined>(undefined);

  const [selectedCategory, setSelectedCategory] = useState<string | null>(null);

  const { products, productsMeta } = useSelector<RootState, ProductState>((state) => state.products);

  const [isFirstFetching, setIsFirstFetching] = useState(true);

  useEffect(() => {
    if (!!products) {
      setIsFirstFetching(false);
    }
  }, [products]);

  const getProducts = useCallback(
    (meta?: Meta, slug?: string) => {
      runListProduct(async () => {
        const listProductProps = {
          meta: meta ?? {
            limit: 10,
            offset: 0,
            sort: querySort,
          },
          slug,
        } as ListCategoryProductRequest;

        if (!slug) {
          const { entries, meta } = await listProducts(listProductProps).unwrap();

          dispatch(productSlice.actions.addToProductsList({ products: entries, meta }));
        } else {
          const { entries, meta } = await listCategoryProducts(listProductProps).unwrap();
          dispatch(productSlice.actions.addToProductsList({ products: entries, meta }));
        }
      });
    },
    [runListProduct, querySort, listProducts, dispatch, listCategoryProducts],
  );

  const categoryRes = siteApi.useGetCategoriesQuery({ meta: { limit: 100 } });

  const categories = useMemo(() => categoryRes.data?.category ?? [], [categoryRes]);

  const onSort = useCallback(
    (newSort: string) => {
      if (!!querySort && querySort === newSort) return;
      const sort = newSort ?? undefined;
      setQuerySort(sort);
      dispatch(productSlice.actions.updateProducts({ products: [] }));
      getProducts({ limit: 10, offset: 0, sort }, selectedCategory ?? undefined);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [querySort, dispatch, selectedCategory],
  );

  const loadNewCategory = useCallback(
    (category: string | null, meta?: Meta) => {
      dispatch(productSlice.actions.updateProducts({ products: [] }));

      if (category !== selectedCategory && category !== null) {
        getProducts(meta ?? { limit: 10, offset: 0, sort: querySort }, category);
      } else if (category !== selectedCategory && category === null) {
        getProducts(meta ?? { limit: 10, offset: 0, sort: querySort });
      }

      setSelectedCategory(category);
    },
    [dispatch, selectedCategory, getProducts, querySort],
  );

  useEffect(() => {
    if (!products) getProducts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [products]);

  const loadMoreData = useCallback(() => {
    if (!productsMeta?.count) return;

    if (productsMeta.offset + productsMeta.limit >= (productsMeta.count ?? 0)) return;
    let offset = (productsMeta.offset ?? 0) + 10;

    getProducts({ limit: 10, offset, sort: querySort }, selectedCategory ?? undefined);
  }, [productsMeta, getProducts, querySort, selectedCategory]);

  const endOfPage = (productsMeta?.offset ?? 0) + (productsMeta?.limit ?? 0) >= (productsMeta?.count ?? 0);

  return (
    <LoadingContainer
      loading={isFirstFetching}
      loader={
        <div>
          <Skeleton
            component={Skeleton.Box}
            className="ml-3 !h-8 !w-1/2 lg:hidden"
          />
          <div className="grid grid-cols-4 gap-x-2 p-3">
            <Skeleton
              component={Skeleton.Box}
              count={4}
              className="!h-8"
            />
          </div>
          <div className="grid grid-cols-2 gap-2 px-3">
            <Skeleton
              component={Skeleton.Box}
              count={4}
              className="aspect-square w-full"
            />
          </div>
        </div>
      }
    >
      <div className="-mt-2">
        <div className="sticky top-[58px] z-[30] flex max-w-full items-center space-x-2 bg-container-1 py-3 px-4 shadow-[0px_1px_4px_0px_#00000026] lg:rounded-b-xl">
          <ProductSortingButton
            onUpdate={onSort}
            querySort={querySort ?? ""}
            className="ml-1"
          />
          <CategoryList
            categories={categories}
            loadNewCategory={loadNewCategory}
            selectedCategory={selectedCategory}
            className="flex-grow overflow-x-auto"
          />
        </div>

        <div className="min-h-full">
          {!!products?.length && (
            <InfiniteScroll
              className="mx-4 mt-2 grid grid-cols-2 gap-2 sm:grid-cols-2 md:grid-cols-4 lg:grid-cols-5"
              hasMoreData={!endOfPage}
              isLoading={isLoading}
              onBottomHit={loadMoreData}
              loadOnMount={false}
            >
              {products.map((item) => (
                <div key={item.id}>
                  <ProductCard product={item} />
                </div>
              ))}
            </InfiniteScroll>
          )}

          {isLoading && (
            <>
              <div className="mt-2 grid grid-cols-2 gap-2 px-3 lg:hidden">
                <Skeleton
                  component={Skeleton.Box}
                  count={2}
                  className="aspect-[2/3] w-full"
                />
              </div>
              <div className="hidden items-center justify-center lg:flex">
                <IcoSpinner className="!m-0 !h-8 !w-8" />
              </div>
            </>
          )}

          {!products?.length && !isLoading && (
            <div className="text-center capitalize text-white">
              <p>{t("no product")}</p>
            </div>
          )}
        </div>
      </div>
    </LoadingContainer>
  );
};

export default ProductListSection;
