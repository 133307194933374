import { useTranslation } from "react-i18next";
import { NavigateFunction, useNavigate } from "react-router-dom";
import { IcoSwap } from "src/assets";
import { Button } from "src/main/components";
import useTypedSelector from "src/main/hooks/useTypedSelector";
import { fNumber, Paths } from "src/main/utils";

function ProfileNavigation() {
  let navigate: NavigateFunction = useNavigate();
  const { t } = useTranslation();
  const { self } = useTypedSelector((state) => state.auth);
  return (
    // <div
    //   className="flex flex-col gap-1 rounded-md bg-gradient-to-b from-[rgba(126,125,217,0.4)] to-[rgba(20,47,117,0.4)] px-2.5
    //     py-4 drop-shadow-lg [backdrop-filter:blur(40px)]"
    // >
    //   <div className="flex w-full items-center gap-2 self-stretch overflow-clip rounded-md bg-[rgba(0,0,0,0.5)] pl-2 text-white">
    //     <div className="w-full">
    //       <div className="flex w-[179px] flex-col items-start gap-1.5 py-3.5">
    //         <div className="flex items-center justify-center gap-1.5 text-center font-bold">
    //           <div className="relative h-5 w-5">
    //             <img
    //               src="/images/icon/heart_icon_yellow.svg"
    //               height={24}
    //               width={24}
    //               alt="points-icon"
    //             />
    //           </div>
    //           <p className="m-0 text-2xl leading-6">{fNumber(self?.member?.primaryWallet?.balance, "floor")}</p>
    //         </div>
    //       </div>
    //     </div>
    //     <div className="flex w-full justify-end">
    //       <div
    //         className="cursor-pointer gap-1.5"
    //         onClick={() => {
    //           navigate(Paths.Private.CashBack);
    //         }}
    //       >
    //         <div
    //           className={`btn_small
    //           inline-flex
    //           h-16 w-full flex-1 flex-grow items-center justify-center gap-1 rounded-r-md px-3.5
    //           py-1 text-left font-bold text-white`}
    //         >
    //           <img
    //             className="mt-0.5 h-8 w-8"
    //             src="/images/body-bg/swap-icon.svg"
    //             alt="swap icon"
    //           />
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    // </div>
    <div className="w-full rounded-lg bg-[radial-gradient(84.58%_106.92%_at_83.69%_0%,#26125C_0%,#170B37_100%)] p-3 [box-shadow:1px_1px_0px_0px_rgba(137,118,255,0.24)_inset]">
      <div className="flex w-full items-center gap-2 overflow-clip rounded-lg bg-[rgba(0,0,0,0.5)] pl-2 text-white">
        <div className="max-w-[calc(100%-131px)] grow overflow-hidden">
          <div className="flex flex-col items-start gap-1 py-3">
            <div className="flex items-center justify-center gap-1.5 text-center font-bold">
              <div className="relative h-[30px] w-[30px]">
                <img
                  src="/images/icon/heart_icon_yellow.svg"
                  height={30}
                  width={30}
                  alt="points-icon"
                />
              </div>
              <p className="m-0 text-2xl leading-6">{fNumber(self?.member?.primaryWallet?.balance, "floor")}</p>
            </div>
          </div>
        </div>
        <div className="h-[54px]">
          <Button
            onClick={() => {
              navigate(Paths.Private.CashBack);
            }}
            iconPlacement="right"
            variant="blue-violet"
            size="lg"
            className="h-[54px] mr-5 rounded-l-none"
            children={<div className="">{t("cashBack")}</div>}
            icon={<IcoSwap />}
          />
        </div>
      </div>
    </div>
  );
}

export default ProfileNavigation;
