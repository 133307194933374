import React from "react";

const ActiveMarker: React.FC<ActiveMarkerProps> = () => {
  return (
    <img
      src="/images/footer/b1_glow_orb.svg"
      alt="Lighting"
      className="absolute top-[-3px] h-[105px] w-[145px]"
    />
  );
};

export default ActiveMarker;

ActiveMarker.defaultProps = {};

interface ActiveMarkerProps {}
