import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Footer } from "src/main/components";
import { useTypedSelector } from "src/main/hooks";
import { LineChatIcon } from "src/main/views/component";

interface BodyProps extends React.PropsWithChildren {
  withFooter?: boolean;
}

const BasicBody = (props: BodyProps) => {
  const { children, withFooter = true } = props;
  const location = useLocation();
  const state = useTypedSelector((state) => state);

  useEffect(() => {
    if (location.pathname === "/home") {
      document.body.style.backgroundImage = `url("/images/body-bg/bg_lg_wavy.webp")`;
    } else {
      document.body.style.backgroundImage = `url("/images/body-bg/bg_starry.jpg")`;
    }

    document.body.style.backgroundRepeat = "no-repeat";
    document.body.style.backgroundSize = "cover";
    document.body.style.backgroundAttachment = "fixed";
  }, [location]);
  return (
    <div
      className="flex flex-col"
      style={{ height: "100%" }}
    >
      {/* <Toast /> */}
      <main className="flex-1">
        {state.isLoading ? (
          <div className="fixed top-0 left-0 right-0 bottom-0 z-50 flex h-screen w-full flex-col items-center justify-center overflow-hidden bg-gray-700 opacity-75">
            <div className="loader mb-4 h-12 w-12 rounded-full border-4 border-t-4 border-gray-200 ease-linear"></div>
          </div>
        ) : (
          <></>
        )}
        {children}
        {withFooter && <Footer />}
      </main>
      {location.pathname !== "/login" && location.pathname !== "/maintenance" && <LineChatIcon />}
    </div>
  );
};

export default BasicBody;
