import { useEffect, useState } from "react";

// Default breakpoint is 1024px - tailwindcss lg breakpoint
const useBreakPoint = (breakpoint = 1024) => {
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleWindowResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleWindowResize);

    // Return a function from the effect that removes the event listener
    return () => window.removeEventListener("resize", handleWindowResize);
  }, []);

  return {
    isMobile: width < breakpoint,
    isDesktop: width >= breakpoint,
  };
};

export default useBreakPoint;
