import { FormikHelpers, useFormik } from "formik";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { IcoPhone } from "src/assets";
import { authApi } from "src/main/api";
import { Button, ContactSupportButton, InputField } from "src/main/components";
import useTypedSelector from "src/main/hooks/useTypedSelector";
import { formatPhoneNumber, getDeployEnv, logger } from "src/main/utils";
import * as Yup from "yup";

type LoginFormProps = {
  count: number;
  phone: string;
  handleRequestAgain: () => void;
};

interface OtpFormType {
  otp: string;
  secret: string;
  confirmSecret: string;
}

const initialValues: OtpFormType = {
  otp: "",
  secret: "",
  confirmSecret: "",
};

enum Step {
  INPUT_OTP = "input-otp",
  INPUT_PWD = "input-pwd",
}

const OtpForm = ({ count, phone, handleRequestAgain }: LoginFormProps) => {
  const [verifyOnboard, { isLoading: isVerifyOnboard }] = authApi.useVerifyOnboardMutation();

  // step
  const [step, setStep] = useState(Step.INPUT_OTP);
  const [shouldProceed, setShouldProceed] = useState(false);
  //
  const { t } = useTranslation();
  const navigate = useNavigate();
  const currentEnv = getDeployEnv();

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        otp: Yup.string().required(t("This field is required!") ?? "OTP is required"),
        secret: Yup.string().required(t("password is required") ?? "Password is required"),
        confirmSecret: Yup.string()
          .required(t("retype to confirm") ?? "Confirm Password is required")
          .oneOf([Yup.ref("secret"), null], t("passwords must match") ?? "Passwords must match"),
      }),
    [t],
  );

  const state = useTypedSelector((state) => state);
  const [setupPassword, { isLoading }] = authApi.useSetupPasswordMutation();

  const phoneNumber = useMemo(
    () => formatPhoneNumber(phone, currentEnv.country_code ?? ""),
    [currentEnv.country_code, phone],
  );

  const handleOtp = useCallback(
    async (formValue: OtpFormType, helper: FormikHelpers<OtpFormType>) => {
      const { otp, secret } = formValue;
      try {
        await setupPassword({
          phone: phoneNumber.replaceAll(" ", ""),
          secret,
          otp: "" + otp,
        }).unwrap();
        toast.success(t("Password updated successfully!"));
        navigate("/vipcheck");
      } catch (error: any) {
        logger._console.log(error);
        // inform incorrect otp
        if (error?.data?.error?.message === "otp incorrect") {
          helper.setFieldError("otp", t("otp incorrect") ?? "otp incorrect");
        }
        //
        setStep(Step.INPUT_OTP); // always back to step 1
        toast.error(t(error?.data?.error?.message ?? "Something went wrong"));
      }
    },
    [navigate, phoneNumber, setupPassword, t],
  );

  const { errors, touched, values, handleChange, submitForm, validateField, setTouched } = useFormik({
    initialValues,
    validationSchema,
    onSubmit: handleOtp,
  });

  const handleVerifyOnboard = useCallback(async () => {
    try {
      const result = await verifyOnboard({
        phone: phoneNumber.replaceAll(" ", ""),
        otp: "" + values.otp,
      }).unwrap();
      if (result) {
        setShouldProceed(true);
      }
    } catch (error: any) {
      logger._console.log(error);
      toast.error(t(error?.data?.error?.message ?? "Something went wrong"));
    }
  }, [phoneNumber, t, verifyOnboard, setShouldProceed, values.otp]);

  const handleClickNext = useCallback(
    async (e: React.MouseEvent) => {
      e.preventDefault();
      if (step === Step.INPUT_PWD) {
        submitForm();
      } else {
        setTouched({
          secret: false,
          otp: true,
          confirmSecret: false,
        });
        await validateField("otp");

        handleVerifyOnboard();
      }
    },
    [setTouched, step, submitForm, validateField, handleVerifyOnboard],
  );

  const handleClickRequestAgain = useCallback(
    async (event: React.MouseEvent) => {
      if (count > 0) {
        event.preventDefault();
        return;
      }
      handleRequestAgain();
    },
    [count, handleRequestAgain],
  );

  // work around
  useEffect(() => {
    if (shouldProceed === false) return;
    if (errors.otp) {
      setShouldProceed(false);
      return;
    }
    setStep(Step.INPUT_PWD);
  }, [shouldProceed, errors]);

  return (
    <div className="login-form">
      <form>
        <div className="flex flex-col gap-4 text-center text-white">
          <div>
            <div className="text-[12px] text-secondary text-token-tertiary first-letter:capitalize">
              {t("please enter the OTP sent to:")}
            </div>
            <div className="w-full justify-center py-2 text-2xl font-bold">
              <div className="flex w-full items-center justify-center text-primary-200">
                <IcoPhone
                  width="24"
                  height="24"
                />
                <div className="text-white">{phoneNumber}</div>
              </div>
            </div>
          </div>

          <div className="mb-2 flex flex-col items-center gap-3">
            {step === Step.INPUT_PWD && (
              <>
                <InputField
                  type="password"
                  value={values.secret}
                  onChange={handleChange}
                  name="secret"
                  label="enter a new password"
                  error={touched.secret && !!errors.secret}
                  helperText={touched.secret && !!errors.secret ? errors.secret : ""}
                />

                <InputField
                  type="password"
                  value={values.confirmSecret}
                  onChange={handleChange}
                  name="confirmSecret"
                  label="retype to confirm"
                  error={touched.confirmSecret && !!errors.confirmSecret}
                  helperText={touched.confirmSecret && !!errors.confirmSecret ? errors.confirmSecret : ""}
                />
              </>
            )}
            {step === Step.INPUT_OTP && (
              <>
                <InputField
                  type="number"
                  value={values.otp}
                  onChange={handleChange}
                  name="otp"
                  label="enter OTP"
                  error={touched.otp && !!errors.otp}
                  helperText={touched.otp && !!errors.otp ? errors.otp : ""}
                  style={{
                    fontSize: "1.25rem",
                  }}
                  inputMode="numeric"
                />
                <div className={`text-left text-xs text-token-text-secondary`}>{t(`OTP valid for 5 mins`)}</div>
              </>
            )}
          </div>

          {step === Step.INPUT_OTP && (
            <>
              <div
                onClick={handleClickRequestAgain}
                className={`mb-3 text-[13px] font-semibold text-white first-letter:capitalize ${
                  count > 0 ? "opacity-30" : "cursor-pointer"
                }`}
              >
                {t("no OTP? request again")} {count > 0 && `(${count})s`}
              </div>
            </>
          )}

          <Button
            onClick={handleClickNext}
            loading={state.isLoading || isLoading || isVerifyOnboard}
            variant="blue-violet"
          >
            {t("Next")}
          </Button>

          <ContactSupportButton />
          <div className="text-center text-[12px] text-secondary">
            <div
              className="px-4 py-2 text-[13px] font-semibold text-white"
              onClick={() => navigate(-1)}
            >
              {t("Back to login")}
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default OtpForm;
