import { useMemo } from "react";
import { siteApi } from "src/main/api";
import useTypedSelector from "src/main/hooks/useTypedSelector";
import { RootState } from "src/main/store";
import { MissionUIStatus } from "src/main/views/MissionsPage/types";
import { getMissionUIStatus } from "src/main/views/MissionsPage/utils";

interface UseNavIndicator {
  claimableMissionState: boolean;
}

const useNavIndicator = (): UseNavIndicator => {
  const timezoneOffset = useTypedSelector((state: RootState) => state.auth.utcOffset);
  const { data: missionsResponse } = siteApi.useListMissionQuery();

  const missions = useMemo(() => {
    return missionsResponse?.entries ?? [];
  }, [missionsResponse]);

  const claimableMissionState = useMemo(() => {
    return (
      missions.find((mission) => getMissionUIStatus(mission, timezoneOffset ?? 0) === MissionUIStatus.CLAIMABLE) !==
      undefined
    );
  }, [missions, timezoneOffset]);

  return { claimableMissionState };
};

export default useNavIndicator;
