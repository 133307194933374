import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { AuthBody } from "src/main/components";
import { clearLocalstorage } from "src/main/services/auth.service";
import { LoginFormBox } from "src/main/views/login/components";
import MobileOtpRequestForm from "src/main/views/login/MobileOtpRequestForm";

type Props = {
  page: "reset" | "setup";
};

const MobileResetPassword: React.FC<Props> = ({ page }) => {
  const { t } = useTranslation();

  useEffect(() => {
    clearLocalstorage();
  }, []);

  return (
    <AuthBody flexibleHeight={true}>
      <LoginFormBox>
        <div className="flex flex-col items-center">
          <img
            className="h-[99px]"
            src="/images/login/ilv_slots_animated.gif"
            alt="Frame 99"
          />
          <div className="text-xl font-bold capitalize text-white">{t(`${page} password`)}</div>
          <div className="text-[12px] text-secondary">{t("OTP required for new account setup")}</div>
        </div>
        <MobileOtpRequestForm page={page} />
      </LoginFormBox>
    </AuthBody>
  );
};

export default MobileResetPassword;
