import moment from "moment";
import { MemberModel } from "src/main/types";

export const isPasswordExpired = (member: Partial<MemberModel>) => {
  if (!!member?.passwordExpiredAt) {
    const expiredAt = moment(member.passwordExpiredAt);
    const now = moment();
    return expiredAt.isBefore(now);
  }
  return false;
};
