export default function Footer(props: FooterProps) {
  return (
    <div className={`w-full text-left font-normal text-[rgba(133,144,172,1)]`}>
      <div
        className={`flex w-full items-start justify-center gap-2.5 self-stretch px-2.5 max-lg:h-40 max-lg:pt-[60px] max-lg:pb-[60px] lg:pb-8`}
      >
        <p className="m-0 text-xs leading-[normal]">©2023 ILOVEVIP. All rights reserved.</p>
      </div>
    </div>
  );
}

Footer.defaultProps = {};

interface FooterProps {}
