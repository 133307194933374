import moment, { Moment } from "moment/moment";
import { useEffect, useRef } from "react";

interface SharedTimerOptions {
  eventName: string;
  stepInMs?: number;
}

export const useSharedTimerProvider = ({ eventName, stepInMs }: SharedTimerOptions) => {
  const refSharedTimer = useRef<NodeJS.Timeout | undefined>();

  useEffect(() => {
    // sync with machine clock
    const clock = setTimeout(() => {
      // fire every sec
      refSharedTimer.current = setInterval(() => {
        window.dispatchEvent(
          new CustomEvent(eventName, {
            detail: moment(),
          }),
        );
      }, stepInMs);
      //
      clearTimeout(clock);
    }, 1000 - new Date().getMilliseconds());
    //
    const clearTimer = () => {
      refSharedTimer.current && clearInterval(refSharedTimer.current);
    };
    window.addEventListener("beforeunload", clearTimer);

    return () => {
      clearTimer();
      window.removeEventListener("beforeunload", clearTimer);
    };
  }, [eventName, stepInMs]);

  return {
    timer: refSharedTimer,
  };
};

interface SharedTimerConsumerOptions {
  eventName: string;
  enabled?: boolean;
  onTick: (timerValue: CustomEventInit<Moment>) => void;
}

export const useSharedTimerConsumer = ({ eventName, enabled = true, onTick }: SharedTimerConsumerOptions) => {
  useEffect(() => {
    // listen to mission-timer event
    if (enabled) {
      window.addEventListener(eventName, onTick);
      return () => {
        window.removeEventListener(eventName, onTick);
      };
    } else {
      window.removeEventListener(eventName, onTick);
    }
  }, [enabled, eventName, onTick]);
};
