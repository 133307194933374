import { useTranslation } from "react-i18next";
import { IcoCash } from "src/assets";
import { Card, CardLogo, ContactSupportButton } from "src/main/components";
import { SOCIAL_MEDIA_TYPE } from "src/main/contants";
import Currency from "src/main/utils/currency";
import { BonusDetail } from "src/main/views/CashbackPage/components";
import { PointSelectionPayload, SignUpBrandSelectionPayload } from "../../types";

interface CashbackSuccessProps {
  selectedSignUpBrandPayload: SignUpBrandSelectionPayload;
  selectedPointPayload: PointSelectionPayload;
}

function StepSignUpInstruction({ selectedSignUpBrandPayload, selectedPointPayload }: CashbackSuccessProps) {
  const { t } = useTranslation();

  return (
    <div className="px-4 pt-8 lg:mt-24">
      <Card
        variant="gradient-l"
        className="mx-auto pt-4 lg:max-w-lg"
      >
        <CardLogo
          float
          src="/images/icon/cash-back-logo.gif"
          className="h-auto w-20"
        />
        <Card
          variant="container-light"
          border={false}
          className="mt-2 gap-y-1 !bg-black !bg-opacity-20 shadow-xl"
        >
          <div className="text-center text-white">
            <span className="text-sm leading-4">{t("Upon signing up, get cashback")}</span>
          </div>
          <div className="mx-auto mt-0.5 flex items-center gap-x-2 rounded-full bg-black bg-opacity-50 px-3 py-1 text-white">
            <IcoCash
              width={24}
              height={24}
            />
            <span className="text-xl font-bold">
              {Currency(selectedSignUpBrandPayload.exchangeCash).format(selectedPointPayload.exchangeTarget.currency)}
            </span>
          </div>
          <div className="w-full">
            <BonusDetail
              exchangeCash={selectedSignUpBrandPayload.exchangeCash}
              originalExchangeCash={selectedSignUpBrandPayload.originalExchangeCash}
              bonusPercentage={selectedSignUpBrandPayload.bonusPercentage}
              currency={selectedPointPayload.exchangeTarget.currency}
              className="mx-auto mb-3 w-fit"
            />
            <ContactSupportButton
              className="w-full"
              textRenderer={(countryISO, platform) =>
                t("Add platform to Continue", {
                  platform: platform === SOCIAL_MEDIA_TYPE.Messenger ? "Messenger" : platform,
                })
              }
            />
          </div>
        </Card>
      </Card>
    </div>
  );
}

export default StepSignUpInstruction;
