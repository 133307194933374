import React from "react";

interface CheckedInCoinProps {
  coin?: string;
  today?: boolean;
  day: number;
}

const CheckedInCoin: React.FC<CheckedInCoinProps> = (props) => {
  const { coin, today = false, day } = props;
  return (
    <div className="relative">
      <div
        className={`flex h-[114px] w-full flex-col bg-opacity-25 ${coin ? "bg-[#100826]" : "bg-[#525CAF]"} gap-0.5} items-center justify-center rounded-md text-white`}
      >
        {coin && (
          <>
            <div className="relative h-[40px] w-[33px]">
              <img
                src="/images/icon/heart_icon_yellow.svg"
                height={33}
                width={33}
                alt="points-icon"
              />
              <div className="absolute mt-[-10px] ml-[20px]">
                <img
                  src="/images/icon/ico-check-blue.svg"
                  height={14}
                  width={14}
                  alt="points-icon"
                />
              </div>
            </div>
            <div className="text-xxs font-bold text-secondary-700">+{coin} PTS.</div>
          </>
        )}

        {!coin && (
          <>
            <div className="h-[40px] w-[40px]">
              {day !== 7 && (
                <img
                  src="/images/icon/daily-gift-icon.svg"
                  height={40}
                  width={40}
                  alt="gift-icon"
                />
              )}
              {day === 7 && (
                <img
                  src="/images/icon/daily-gift-gold-rate-up-icon.svg"
                  height={40}
                  width={40}
                  alt="rate-up-icon"
                />
              )}
            </div>
          </>
        )}

        <div className="absolute">
          {today && (
            <div className="mt-20 rounded-full bg-primary-100 px-2 py-0.5 text-xxs font-bold text-black">TODAY</div>
          )}
        </div>
      </div>
      <div className="absolute top-0 flex h-[20px] w-[20px] items-center justify-center p-4 text-white">
        <div className="absolute h-[20px] w-[20px] rounded-full border-2 border-token-border text-[12px] font-bold opacity-30"></div>
        <div className="text-[12px]">{day}</div>
      </div>
    </div>
  );
};

export default CheckedInCoin;

CheckedInCoin.defaultProps = {};
