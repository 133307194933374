import React, { useEffect } from "react";
import ReactPixel from "react-facebook-pixel";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import auth from "src/main/store/auth";
import preference from "src/main/store/preference";
import { Language } from "src/main/types";
import { getDeployEnv } from "src/main/utils";
import { uriToToken } from "src/main/utils/EncodePathURL";

export declare type Params<Key extends string = string> = {
  readonly [key in Key]: string | undefined;
};

const currentEnv = getDeployEnv();

const Authorize: React.FC = () => {
  const { i18n } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();

  useEffect(() => {
    if (searchParams.get("key")) {
      if (searchParams.get("d")) localStorage.setItem("d", searchParams.get("d") ?? "");
      if (searchParams.get("lng")) {
        const lang = searchParams.get("lng") ?? Language.EN;
        i18n.changeLanguage(lang);
        dispatch(preference.slice.actions.setLanguage(lang));
      }
      const token = uriToToken(searchParams.get("key") as string);
      if (token) {
        dispatch(auth.slice.actions.updateAccessToken(token));
        try {
          ReactPixel.trackCustom("LoginSuccess", {
            brand: currentEnv.brand,
          });
        } catch (error) {
          console.error(error);
        }
        navigate("/home", { replace: true }); // idk why it doesn't redirect to /home automatically
      }
    }
  }, [dispatch, i18n, navigate, searchParams]);
  return <></>;
};

export default Authorize;
