import { Meta, QueryResult, ShippingAddressModel } from "src/main/types";
import { parseMeta } from "src/main/utils";
import { BuilderProp, require200Status } from "..";

export interface ListShippingAddressResponse {
  entries: ShippingAddressModel[];
  meta: Meta;
}

export interface ListShippingAddressRequest {
  meta?: Partial<Meta>;
}

export const listShippingAddress = (builder: BuilderProp) => {
  return builder.query<ListShippingAddressResponse, ListShippingAddressRequest>({
    providesTags: ["user", "cart"],
    query: ({ meta }) => ({
      url: `/member/address/list${parseMeta(meta)}`,
      validateStatus: require200Status,
    }),
    transformResponse: (response: QueryResult<ListShippingAddressResponse>) => {
      return response.result;
    },
  });
};

export interface CreateShippingAddressResponse {
  model: ShippingAddressModel;
}

export interface CreateShippingAddressRequest {
  recipientName: string;
  recipientMobile: string;
  address: string;
  address2?: string;
  city: string;
  state: string;
  country: string;
  postalCode: string;
  remarks?: string;
  isDefault?: boolean;
}

export const createShippingAddress = (builder: BuilderProp) => {
  return builder.mutation<CreateShippingAddressResponse, CreateShippingAddressRequest>({
    invalidatesTags: ["user", "cart"],
    query: (data) => ({
      url: `/member/address/create`,
      validateStatus: require200Status,
      method: "POST",
      body: data,
    }),
    transformResponse: (response: QueryResult<CreateShippingAddressResponse>) => {
      return response.result;
    },
  });
};

export interface ShippingAddressRequest {
  addressId: string;
}

export interface ShippingAddressResponse {
  model: ShippingAddressModel;
}

export const getShippingAddress = (builder: BuilderProp) => {
  return builder.query<ShippingAddressResponse, ShippingAddressRequest>({
    providesTags: ["user", "cart"],
    query: ({ addressId }) => ({
      url: `/member/address/${addressId}/detail`,
      validateStatus: require200Status,
    }),
    transformResponse: (response: QueryResult<ShippingAddressResponse>) => {
      return response.result;
    },
  });
};

export interface UpdateShippingAddressRequest {
  addressId: string;
  data: CreateShippingAddressRequest;
}

export interface UpdateShippingAddressResponse {
  model: ShippingAddressModel;
}

export const updateShippingAddress = (builder: BuilderProp) => {
  return builder.mutation<UpdateShippingAddressResponse, UpdateShippingAddressRequest>({
    invalidatesTags: ["user", "cart"],
    query: ({ addressId, data }) => ({
      url: `/member/address/${addressId}/update`,
      validateStatus: require200Status,
      method: "POST",
      body: data,
    }),
    transformResponse: (response: QueryResult<UpdateShippingAddressResponse>) => {
      return response.result;
    },
  });
};

export interface DeleteShippingAddressRequest {
  addressId: string;
}

export interface DeleteShippingAddressResponse {}

export const deleteShippingAddress = (builder: BuilderProp) => {
  return builder.mutation<DeleteShippingAddressResponse, DeleteShippingAddressRequest>({
    invalidatesTags: ["user", "cart"],
    query: ({ addressId }) => ({
      url: `/member/address/${addressId}/delete`,
      validateStatus: require200Status,
      method: "DELETE",
    }),
    transformResponse: (response: QueryResult<DeleteShippingAddressResponse>) => {
      return response.result;
    },
  });
};
