import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { publicApi } from "src/main/api";
import { Drawer, ScrollBox } from "src/main/components";
import useActions from "src/main/hooks/useActions";
import { BrandModel } from "src/main/models";
import { RootState } from "src/main/store";
import { AuthState } from "src/main/store/auth/slices";
import { UserState } from "src/main/store/user/slices";
import { MemberBrandsData } from "src/main/types";
import SiteButton from "../SiteButton";

interface PlayNowDrawerProps {
  open?: boolean;
}

const TRANSITION_DURATION = 310;

interface ActiveBrand extends BrandModel {
  bonusPercent?: number;
  hasAccount?: boolean;
}

const PlayNowDrawer: React.FC<PlayNowDrawerProps> = ({ open = false }) => {
  const [isShow, setIsShow] = useState(open);
  const actions = useActions();
  const { t } = useTranslation();
  const { brands: allBrands } = useSelector<RootState, UserState>((state) => state.user);
  const { self } = useSelector<RootState, AuthState>((state) => state.auth);
  const { data: creditBonusInfo, isFetching: isFetchingBonusBrands } = publicApi.useGetPublicInfoQuery();

  useEffect(() => {
    setIsShow(open);
  }, [open]);

  const handleClose = useCallback(() => {
    setIsShow(false);
    setTimeout(() => {
      actions.launchPlaynow({ launch: false });
    }, TRANSITION_DURATION);
  }, [actions]);

  const bonusBrandMap: Record<string, number> = useMemo(() => {
    return creditBonusInfo?.creditBonus ?? {};
  }, [creditBonusInfo]);

  const memberBrandMap = useMemo(() => {
    return (
      self?.member?.memberBrands?.reduce(
        (acc, brand) => {
          acc[brand.brandId] = brand;
          return acc;
        },
        {} as Record<string, MemberBrandsData>,
      ) ?? {}
    );
  }, [self?.member?.memberBrands]);

  const activeBrands: ActiveBrand[] = useMemo(() => {
    if (isFetchingBonusBrands) return [];
    let activeBrands: ActiveBrand[] = [];

    allBrands?.forEach((brand) => {
      if (brand.status !== "active") return;
      activeBrands.push({
        ...brand,
        bonusPercent: bonusBrandMap[brand.id],
        hasAccount: !!memberBrandMap[brand.id],
      });
    });

    return activeBrands;
  }, [isFetchingBonusBrands, allBrands, bonusBrandMap, memberBrandMap]);

  return (
    <Drawer
      show={isShow}
      onClose={handleClose}
      placement="bottom"
      contentClassName="h-[calc(100%-96px)] mt-24 rounded-t-2xl pb-16"
      title={t("earn more points")}
      preload
    >
      <ScrollBox
        className="mt-4 flex flex-1 flex-col overflow-y-hidden"
        contentClassName="grid grid-flow-row-dense auto-rows-min grid-cols-3 justify-items-center pb-8 gap-x-2 gap-y-6 pt-2 lg:grid-cols-5"
      >
        {activeBrands?.map((brand) => (
          <SiteButton
            key={brand.id}
            brand={brand}
            bonusPercent={brand.bonusPercent}
            hasAccount={brand.hasAccount}
          />
        ))}
      </ScrollBox>
    </Drawer>
  );
};

PlayNowDrawer.defaultProps = {};

export default PlayNowDrawer;
