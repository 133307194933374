import { clsx } from "clsx";
import React from "react";
import { Skeleton } from "src/main/components";

interface SkeletonLoaderProps extends React.HTMLAttributes<HTMLDivElement> {}

function SkeletonLoader(props: SkeletonLoaderProps) {
  return (
    <>
      {/*DESKTOP VIEW*/}
      <div
        {...props}
        className={clsx("mb-8 hidden grid-cols-8 gap-x-4 lg:container lg:grid", props.className)}
      >
        <div className="col-span-3">
          <div className="flex flex-col gap-y-3">
            <Skeleton
              component={Skeleton.Box}
              className="aspect-square w-full"
            />
            <div className="grid grid-cols-4 gap-x-3">
              <Skeleton
                component={Skeleton.Box}
                count={4}
                className="aspect-square w-full"
              />
            </div>
          </div>
        </div>
        <div className="col-span-5 space-y-4">
          <Skeleton
            component={Skeleton.Box}
            className="!h-2/3"
          />
          <Skeleton
            component={Skeleton.Box}
            className="!h-40"
          />
        </div>
      </div>

      {/*MOBILE VIEW*/}
      <div
        {...props}
        className={clsx("flex flex-col gap-y-3 px-3 pt-4 lg:hidden", props.className)}
      >
        <div className="flex flex-col gap-y-3">
          <Skeleton
            component={Skeleton.Box}
            count={1}
            className="aspect-square w-full"
            componentProps={{
              variant: "secondary",
            }}
          />
          <div className="grid grid-cols-4 gap-x-3">
            <Skeleton
              component={Skeleton.Box}
              count={4}
              className="aspect-square w-full"
              componentProps={{
                variant: "secondary",
              }}
            />
          </div>
        </div>
        <Skeleton
          component={Skeleton.Box}
          count={4}
          className="h-32 w-full"
          componentProps={{
            variant: "secondary",
          }}
        />
      </div>
    </>
  );
}

export default SkeletonLoader;
