import { Field, Form, Formik } from "formik";
import { throttle } from "lodash";
import React, { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { FaCartPlus, FaGift } from "react-icons/fa6";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { siteApi } from "src/main/api";
import { Button, LocalizationDisplay, localizationValue } from "src/main/components";
import { useAsyncTask } from "src/main/hooks";
import { RootState } from "src/main/store";
import productSlice, { ProductState } from "src/main/store/product/slices";
import { CartModel, CartType, ProductModel, ProductVariantModel, UpdateCartProps } from "src/main/types/cart";
import { bnOrZero, fNumber, getProductPrice } from "src/main/utils";
import BackButton from "src/main/views/component/BackButton/BackButton";
import ExpandableCard from "src/main/views/component/ExpandableCard";
import "../../ProductPage.css";

export interface AddToCartFormProps {
  product?: ProductModel | null;
  onChange: (variant: ProductVariantModel) => void;
  selectedVariant: ProductVariantModel;
}

export interface cart_initial_values {
  quantity: number;
  colour: string;
  id: string;
  name: string;
  selected: boolean;
}

const MIN_INPUT_QUANTITY = 1;
const MAX_INPUT_QUANTITY = 99;

const AddToCartForm = (props: AddToCartFormProps) => {
  let { product, onChange, selectedVariant } = props;
  const navigate = useNavigate();
  const [quantity, setQuantity] = useState<number | undefined>(1);
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const { cart } = useSelector<RootState, ProductState>((state) => state.products);

  const [runAddToCart] = useAsyncTask("add/to/cart");
  const [addToCart] = siteApi.useUpdateCartMutation();

  const checkQuantity = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value === "") {
      setQuantity(undefined);
      return;
    }
    const bnQuantity = bnOrZero(e.target.value);
    let quantity = bnQuantity.toNumber();
    if (bnQuantity.lt(MIN_INPUT_QUANTITY)) {
      quantity = MIN_INPUT_QUANTITY;
    }
    if (bnQuantity.gt(MAX_INPUT_QUANTITY)) {
      quantity = MAX_INPUT_QUANTITY;
    }
    setQuantity(quantity);
  };

  const addToCartHandler = (formValue: CartType, redeem: boolean) => {
    console.log({ formValue, redeem });
    // const { quantity, colour, id, } = formValue;
    // let colorValue: Array<colorVariants> = []
    // const selectVariant = variantRef ?? colour
    // if (item.variants?.Colour?.length) {
    //     colorValue = item.variants.Colour.filter((color) => {
    //         return color.id === parseInt(selectVariant)
    //     })
    // }
    // const variant = item.variants?.Colour?.length ? { 'color': { id: parseInt(selectVariant), value: colorValue ? colorValue[0].value : 'colour' } } : {}
    // // actions.addUserCartItemApi({ selected: redeem, product_id: id, quantity: quantity, sub_information: JSON.stringify({ ...variant }) });
    // actions.addCartItemApi({ selected: redeem, product_id: id, quantity: quantity, sub_information: JSON.stringify({ ...variant }) });
    // toast.success(`${t("Add", { ns: ['main', 'home'] })} ${item.name} ${t("to Cart Successfully", { ns: ['main', 'home'] })}`, { containerId: 'default' })
    // //update top bar card number
    // actions.updateCartApi({ cart_item: quantity })
    // if (redeem) {
    //     setTimeout(() => {
    //         navigate("/checkout");
    //     }, 1000);
    // }
  };

  interface UpdateOrRedeemValues {
    type: "add" | "redeem";
    quantity?: number;
    cart: CartModel | null | undefined;
  }

  const AddOrRedeem = useCallback(
    ({ type, quantity, cart }: UpdateOrRedeemValues) => {
      runAddToCart(async () => {
        if (!quantity) {
          toast.error(t("Please enter quantity"));
          return;
        }
        if (!selectedVariant) {
          toast.error(t("You have not selected any items for checkout"));
          return;
        }
        let data = {
          items: [...(cart?.data.items ?? [])],
          version: cart?.data.version ?? "1",
        } as unknown as UpdateCartProps;

        const newItem = [{ quantity, variantId: selectedVariant.id }];
        if (!!data.items.length) {
          const existItem = data.items.find((item) => item.variantId === selectedVariant.id);

          if (!!existItem) {
            try {
              data.items.forEach((item, index) => {
                if (item.variantId === selectedVariant.id) {
                  const replaceItem = {
                    ...item,
                    quantity: type === "redeem" ? quantity : item.quantity + quantity,
                  };
                  data.items[index] = replaceItem;
                }
              });
            } catch (error) {
              console.error(error);
            }
          } else {
            data.items = data.items.concat(newItem);
          }
        } else {
          data.items = newItem;
        }

        await addToCart({
          data,
        });

        if (type === "add") {
          toast.success(`${t("Add to cart successfully")}`);
        } else {
          const redeemNowVariant = product?.productVariants?.find((pVar) => pVar.id === selectedVariant.id);
          if (!!product && !!redeemNowVariant) {
            dispatch(
              productSlice.actions.updateCheckoutCart([
                {
                  ...newItem[0],
                  variant: {
                    ...redeemNowVariant,
                    product,
                  },
                },
              ]),
            );
          }
          navigate("/checkout");
        }
      });
    },
    [runAddToCart, selectedVariant, addToCart, t, product, dispatch, navigate],
  );

  const throttleAddOrRedeem = useMemo(
    () =>
      throttle(AddOrRedeem, 500, {
        leading: true,
        trailing: false,
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedVariant],
  );

  let submitAction: string | undefined = undefined;

  const productActualPrice = useMemo(() => {
    return product ? getProductPrice(product, selectedVariant?.id) : 0;
  }, [product, selectedVariant]);

  const category = useMemo(() => {
    const nonTopProd = product?.categories?.filter((c) => c.name.toLowerCase() !== "top product");
    nonTopProd?.sort((c1, c2) => (c2?.sortPriority ?? 0) - (c1?.sortPriority ?? 0));
    return nonTopProd?.[0];
  }, [product?.categories]);

  const hasOnlyOneVariant = useMemo(() => product?.productVariants?.length === 1, [product?.productVariants]);

  const handleOnSelectVariant = useCallback(
    (ev: React.ChangeEvent<HTMLInputElement>) => {
      const newVar = product?.productVariants?.find((prod) => prod.id === ev.target.value);
      if (newVar) onChange(newVar);
    },
    [onChange, product?.productVariants],
  );

  if (!product) return null;

  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={
          {
            quantity: quantity,
            colour: "",
            id: product.id,
            name: localizationValue("name", product.localisations, product.name),
            selected: true,
          } as cart_initial_values
        }
        onSubmit={(values, param) => {
          console.log({ values, param });
          if (submitAction) {
            addToCartHandler(values, submitAction === "redeem");
          }
        }}
      >
        {({ handleSubmit, values, errors, touched }) => (
          <Form className="cart-form relative h-full md:bg-[rgba(0,0,0,0)] lg:bg-[rgba(0,0,0,0.5)]">
            <div className="m-3 rounded-lg bg-gradient-to-b from-[rgba(126,125,217,0.4)] to-[rgba(20,47,117,0.4)] p-6 lg:m-0 lg:bg-gradient-to-b lg:from-[rgba(0,0,0,0)] lg:to-[rgba(0,0,0,0)]">
              <div className="flex">
                <img
                  src="/images/icon/heart_icon_yellow.svg"
                  alt="heart-icon"
                  className="w-[20px]"
                />
                <p className="px-1 text-[18px] font-extrabold text-[rgba(246,189,102,1)] md:px-2 md:text-2xl lg:text-lg lg:font-bold">
                  {fNumber(productActualPrice)}
                </p>
              </div>
              <div>
                <LocalizationDisplay
                  field="name"
                  localisations={product?.localisations}
                  defaultValue={product!.name}
                  className="mt-2 break-words text-[16px] font-bold text-white md:text-2xl"
                />

                {category && (
                  <LocalizationDisplay
                    field="name"
                    className="mt-1 text-xxs text-white md:text-xl lg:text-xs lg:font-normal"
                    localisations={category.localisations}
                    defaultValue={category.name}
                  />
                )}
              </div>

              {!!product?.productVariants?.length && (
                <div className="mt-3 flex">
                  {!hasOnlyOneVariant && (
                    <>
                      <div className="w-1/3 md:w-2/6 lg:w-1/4">
                        <p className="mt-2 text-[12px] font-bold text-white md:text-2xl lg:text-xs">
                          {t("Select Type")}:
                        </p>
                      </div>
                      <div className="w-2/3 md:w-4/6 lg:w-2/3">
                        <Field
                          id="product variant"
                          onChange={handleOnSelectVariant}
                          value={selectedVariant?.id}
                          name="colour"
                          as="select"
                          className={
                            "w-1/1 inline-flex w-full items-center rounded-lg border bg-black px-4 py-2.5 text-left text-sm font-medium text-white md:mt-2"
                          }
                        >
                          {product?.productVariants?.map((variant) => (
                            <option
                              // data-image={variant.product_image_id}
                              key={variant.id}
                              value={variant.id}
                            >
                              {localizationValue("name", variant.localisations, variant.name)}
                            </option>
                          ))}
                        </Field>
                      </div>
                    </>
                  )}
                </div>
              )}

              <div className="mt-3 flex">
                <div className="w-1/3 md:w-2/6 lg:w-1/4">
                  <p className="mt-2 text-[12px] font-bold text-white md:text-2xl lg:text-xs">{t("Quantity")}:</p>
                </div>
                {/* <input className="text-white md:mt-2 w-28 bg-black font-medium rounded-lg text-sm px-4 py-2.5 text-center inline-flex items-center border" value="0" type="number" /> */}
                <div className="w-2/3 md:w-4/6 lg:w-2/3">
                  <Field
                    type="number"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => checkQuantity(e)}
                    value={quantity}
                    className="inline-flex w-full items-center rounded-lg border bg-black px-4 py-2.5 text-center text-sm font-medium text-white md:mt-2 lg:w-1/3"
                    id="quantity"
                    name="quantity"
                    placeholder={t("Quantity")}
                  />
                </div>
              </div>

              <div className="mt-3 flex flex-col gap-y-3 lg:hidden">
                <div>
                  <Button
                    onClick={() =>
                      throttleAddOrRedeem({
                        type: "add",
                        quantity,
                        cart,
                      })
                    }
                  >
                    <div className="flex items-center justify-center gap-x-2">
                      {t("Add to Cart")}
                      <FaCartPlus className="inline-block h-[18px] w-[18px]" />
                    </div>
                  </Button>
                </div>
                <div>
                  <Button
                    onClick={() =>
                      throttleAddOrRedeem({
                        type: "redeem",
                        quantity,
                        cart,
                      })
                    }
                  >
                    <div className="flex items-center justify-center gap-x-2">
                      {t("redeem now")}
                      <FaGift className="inline-block" />
                    </div>
                  </Button>
                </div>
              </div>

              {/* <Field className="text-white md:mt-2 w-28 bg-black font-medium rounded-lg text-sm px-4 py-2.5 text-center inline-flex items-center border" id='quantity' name='quantity' placeholder={t("Quantity", { ns: ['main', 'home'] })} /> */}
              <Field
                type="hidden"
                className="inline-flex w-28 items-center rounded-lg border bg-black px-4 py-2.5 text-center text-sm font-medium text-white md:mt-2"
                id="name"
                name="name"
              />
              <Field
                type="hidden"
                className="inline-flex w-28 items-center rounded-lg border bg-black px-4 py-2.5 text-center text-sm font-medium text-white md:mt-2"
                id="productId"
                name="productId"
              />

              <ExpandableCard
                title="product details"
                localisations={product?.info?.localisations}
                localeField="info"
                content={product?.info?.content}
                showByDefault={true}
                contentStyles="lg:h-[250px]"
                displayHtml={true}
              />
            </div>

            <div className="mt-5 mb-10 lg:hidden">
              <BackButton />
            </div>

            <div className="hidden w-full bg-[rgba(0,0,0,0.1)] p-6 lg:block">
              <div className="flex flex-row">
                <div className="basis-1/2">
                  <button
                    type="button"
                    onClick={() =>
                      throttleAddOrRedeem({
                        type: "add",
                        quantity,
                        cart,
                      })
                    }
                    className="btn-purple md:text-2xl lg:text-base"
                  >
                    {t("Add to Cart")}
                    <img
                      src="/images/icon/ico-add-to-cart.svg"
                      alt="plus-icon"
                      className="mx-2 mb-1 inline-block md:h-[25px]"
                    />
                  </button>
                </div>
                <div className="basis-1/2 pl-2">
                  <button
                    type="button"
                    onClick={() =>
                      throttleAddOrRedeem({
                        type: "redeem",
                        quantity,
                        cart,
                      })
                    }
                    className="btn-orange capitalize md:text-2xl lg:text-base"
                  >
                    {t("redeem now")}
                    <img
                      src="/images/icon/ico-gift.svg"
                      alt="gift-icon"
                      className="mx-1 mb-1 inline-block h-[15px] md:h-[25px]"
                    />
                  </button>
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default AddToCartForm;
