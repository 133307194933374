import React from "react";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import { FadeUpSmall, Page, RevealOnScroll } from "src/main/components";
import { ProductListSection, PromotionBannerSection, TopProductSection } from "./components";
import "./GiftsPage.css";

const GiftsPage: React.FC = () => {
  return (
    <Page
      className="-mt-5 space-y-4 lg:mt-0 lg:pt-20"
      paddingTop
    >
      <RevealOnScroll
        animation={FadeUpSmall}
        triggerOnce
        cascade
        damping={RevealOnScroll.DAMPLING.FAST}
        fraction={0.13}
      >
        <PromotionBannerSection />
        <TopProductSection />
        <ProductListSection />
      </RevealOnScroll>
    </Page>
  );
};

export default GiftsPage;
