import React from "react";

const BowlLogo: React.FC<BowlLogoProps> = () => {
  return (
    <div
      className="relative h-12 w-24 cursor-pointer"
      style={{
        backgroundImage: `url(/images/footer/bowl-darkened9.png)`,
        backgroundPosition: "center",
        backgroundSize: "contain",
        backgroundRepeat: "no-repeat",
        position: "relative",
        zIndex: -1,
        top: "0px",
        // left: "-27px",
        filter: "brightness(0.6)",
      }}
    ></div>
  );
};

export default BowlLogo;

BowlLogo.defaultProps = {};

interface BowlLogoProps {}
