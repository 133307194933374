import React from "react";
import { useTranslation } from "react-i18next";
import { NavigateFunction, useNavigate } from "react-router-dom";

const TermsAndCondition: React.FC = () => {
  const { t } = useTranslation();

  let navigate: NavigateFunction = useNavigate();

  return (
    <div className={`relative mb-10 h-screen w-full px-2.5 py-1.5 lg:mx-auto lg:max-w-md`}>
      <div className="flex h-20 w-full items-center justify-center text-center text-white">
        <p className="absolute m-0 text-lg text-[18px] font-bold leading-[normal]">{t("Tnc")}</p>
      </div>
      <div className="text-[15px] font-normal">
        <div className="mt-1 flex w-full">
          <div
            className={`inline-flex h-6 w-6 flex-col items-center justify-center rounded-[50px] bg-gradient-to-b from-[rgba(126,125,217,1)] to-[rgba(20,47,117,1)] text-center font-bold text-white`}
          >
            <p className="m-0 text-sm leading-[10px]">1</p>
          </div>
          <p className="m-0 ml-3 flex-1 leading-[18px] text-white">{t("Orders_1")}</p>
        </div>
        <div className="mt-4 flex w-full">
          <div
            className={`inline-flex h-6 w-6 flex-col items-center justify-center rounded-[50px] bg-gradient-to-b from-[rgba(126,125,217,1)] to-[rgba(20,47,117,1)] text-center font-bold text-white`}
          >
            <p className="m-0 text-sm leading-[10px]">2</p>
          </div>
          <p className="m-0 ml-3 flex-1 leading-[18px] text-white">{t("Orders_2")}</p>
        </div>
        <div className="mt-4 flex w-full">
          <div
            className={`inline-flex h-6 w-6 flex-col items-center justify-center rounded-[50px] bg-gradient-to-b from-[rgba(126,125,217,1)] to-[rgba(20,47,117,1)] text-center font-bold text-white`}
          >
            <p className="m-0 text-sm leading-[10px]">3</p>
          </div>
          <p className="m-0 ml-3 flex-1 leading-[18px] text-white">{t("Orders_3")}</p>
        </div>
        <div className="mt-4 flex w-full">
          <div
            className={`inline-flex h-6 w-6 flex-col items-center justify-center rounded-[50px] bg-gradient-to-b from-[rgba(126,125,217,1)] to-[rgba(20,47,117,1)] text-center font-bold text-white`}
          >
            <p className="m-0 text-sm leading-[10px]">4</p>
          </div>
          <p className="m-0 ml-3 flex-1 leading-[18px] text-white">{t("Orders_4")}</p>
        </div>
        <div className="mt-4 flex w-full">
          <div
            className={`inline-flex h-6 w-6 flex-col items-center justify-center rounded-[50px] bg-gradient-to-b from-[rgba(126,125,217,1)] to-[rgba(20,47,117,1)] text-center font-bold text-white`}
          >
            <p className="m-0 text-sm leading-[10px]">5</p>
          </div>
          <p className="m-0 ml-3 flex-1 leading-[18px] text-white">{t("Orders_5")}</p>
        </div>
      </div>

      <div className="mt-4 flex w-full text-center text-[12px]">
        <p className="inline flex-1 font-normal leading-[18px] leading-[normal] text-white">
          <span className="m-0 inline font-bold  leading-[normal]">Need help? </span>
          Contact our support team or go through our FAQs.
        </p>
      </div>
      <div className="mt-5">
        <button
          className="flex w-full
                items-center justify-between rounded-md bg-gradient-to-b from-[rgba(126,125,217,0.4)] to-[rgba(20,47,117,0.4)]
                px-4 py-2
                text-sm font-semibold text-white"
          onClick={() => {
            navigate("/faqs", { replace: true });
          }}
        >
          <span className="my-3 flex text-lg ">
            <img
              src="/images/icon/question-mark.svg"
              alt="question-mark"
            />
            <span className="ml-3 uppercase">{t("faqs")}</span>
          </span>
          <span className="">
            <img
              src="/images/icon/right-arrow.svg"
              alt="arrow-right"
            />
          </span>
        </button>
      </div>
    </div>
  );
};

export default TermsAndCondition;
