import { clsx } from "clsx";
import { FaGift } from "react-icons/fa6";
import { AssetModel } from "src/main/types";

const GiftCategoryButton = (props: GiftCategoryButtonProps) => {
  const { name, id, onClick, active, logo } = props;
  return (
    <button
      name={name}
      id={id}
      className={clsx(
        "flex w-max items-center gap-x-1 rounded-lg px-2.5 py-2.5 text-sm leading-3 text-token-secondary-2 transition-colors",
        {
          "bg-white bg-opacity-[12%] px-2 font-bold !text-white": active,
        },
      )}
      onClick={onClick}
    >
      {logo && logo.url ? (
        <img
          src={logo.url}
          alt={name}
          className="h-4 w-4 rounded object-contain"
        />
      ) : (
        <FaGift
          className={clsx("inline-block leading-3", {
            "text-token-icon": active,
          })}
        />
      )}
      <span className="leading-3">{name}</span>
    </button>
  );
};

interface GiftCategoryButtonProps {
  id: string;
  name: string;
  onClick: (evt: React.MouseEvent<HTMLButtonElement>) => void;
  active: boolean;
  logo?: AssetModel;
}

export default GiftCategoryButton;
