import { clsx } from "clsx";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { FaRegCircleCheck, FaXmark } from "react-icons/fa6";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { siteApi } from "src/main/api";
import { UpdateCartRequest } from "src/main/api/site/Cart";
import {
  Button,
  Drawer,
  LazyImage,
  LoadingContainer,
  LocalizationDisplay,
  PropsWithClassName,
} from "src/main/components";
import { DEFAULT_PRODUCT_IMAGE_URL } from "src/main/contants";
import { RootState } from "src/main/store";
import { ProductState } from "src/main/store/product/slices";
import { CartItemModel, ProductVariantModel } from "src/main/types";
import { fNumber, getErrorMessage, getProductPrice } from "src/main/utils";
import { SkeletonLoader } from "./components";

interface CartVariationDrawerProps extends PropsWithClassName {
  show: boolean;
  onClose: () => void;
  cartItem: CartItemModel;
  productSlug: string;
  onChangeVariant: (oldVariant: ProductVariantModel, newVariant: ProductVariantModel) => void;
}

const CartVariantDrawer = ({ show, onChangeVariant, cartItem, onClose, productSlug }: CartVariationDrawerProps) => {
  const { t } = useTranslation();
  const [isShow, setIsShow] = useState(show);
  const { data: productResponse, isFetching: isFetchingProductDetail } = siteApi.useGetProductDetailQuery({
    slug: productSlug,
  });
  const [selectedVariant, setSelectedVariant] = useState(cartItem.variant);
  const { cart } = useSelector<RootState, ProductState>((state) => state.products);

  const [updateCart, { isLoading: isUpdatingCart, isSuccess }] = siteApi.useUpdateCartMutation();

  useEffect(() => {
    setIsShow(show);
  }, [show]);

  useEffect(() => {
    setSelectedVariant(cartItem.variant);
  }, [cartItem.variant]);

  const product = useMemo(() => {
    return productResponse?.product;
  }, [productResponse]);

  const displayingImage = useMemo(() => {
    return selectedVariant?.image?.url ?? product?.image?.url ?? "";
  }, [selectedVariant, product]);

  const variantPrice = useMemo(() => {
    return product ? getProductPrice(product, selectedVariant?.id) : 0;
  }, [product, selectedVariant]);

  const handleOnSelectVariant = useCallback((variant: ProductVariantModel) => {
    setSelectedVariant(variant);
  }, []);

  const handleOnCloseDrawer = useCallback(async () => {
    // reset selected variant
    onClose();
    setTimeout(() => {
      setSelectedVariant(cartItem.variant);
    }, 500);
  }, [cartItem.variant, onClose]);

  const handleOnChangeVariant = useCallback(async () => {
    const oldVariant = cartItem.variant;
    const newVariant = selectedVariant;
    if (!!oldVariant && !!newVariant) {
      let newCartItems = [...(cart?.data.items ?? [])];
      // remove old variant from cart
      newCartItems = newCartItems.filter((item) => item.variantId !== oldVariant?.id);

      // if new variant is already in cart, increase quantity
      const existingItemIndex = newCartItems.findIndex((item) => item.variantId === newVariant?.id);
      if (existingItemIndex >= 0) {
        newCartItems[existingItemIndex] = {
          ...[newCartItems[existingItemIndex]],
          variantId: newVariant.id,
          quantity: newCartItems[existingItemIndex].quantity + cartItem.quantity,
        };
      } else {
        // if new variant is not in cart, add new variant to cart
        newCartItems.push({
          variantId: newVariant?.id,
          quantity: cartItem.quantity,
        });
      }

      const updateCartRequest: UpdateCartRequest = {
        data: {
          items: newCartItems,
          version: String(cart?.data.version ?? 1),
        },
      };

      try {
        await updateCart(updateCartRequest).unwrap();
        onClose();
      } catch (error) {
        toast.error(getErrorMessage(error, t));
        console.error(error);
      }
    }
  }, [
    cart?.data.items,
    cart?.data.version,
    cartItem.quantity,
    cartItem.variant,
    onClose,
    selectedVariant,
    t,
    updateCart,
  ]);

  return (
    <Drawer
      show={isShow}
      onClose={handleOnCloseDrawer}
      placement="bottom"
      contentClassName="fixed left-0 bottom-[60px] w-full rounded-t-xl !bg-gray-50 px-4 pt-4"
      backdropClassName="bg-black bg-opacity-50"
      closeButton={
        <button className="absolute right-0 top-0 z-[60]">
          <FaXmark
            className="h-5 w-5 text-gray-300"
            onClick={handleOnCloseDrawer}
          />
        </button>
      }
    >
      <LoadingContainer
        loading={isFetchingProductDetail}
        loader={<SkeletonLoader />}
      >
        {/*PRODUCT INFO*/}
        <div className="mb-4 flex items-center space-x-3">
          <LazyImage
            src={displayingImage}
            alt={selectedVariant?.name ?? product?.name}
            className="h-28 w-28 rounded-md bg-white"
            fallbackSrc={DEFAULT_PRODUCT_IMAGE_URL}
          />
          <div>
            <h1 className="leading-4">
              <LocalizationDisplay
                field="name"
                localisations={selectedVariant?.localisations ?? product?.localisations}
                defaultValue={selectedVariant?.name ?? product?.name}
                className="line-clamp-2 inline text-base font-bold leading-5 text-gray-800"
              />
            </h1>
            <div className="mt-2 flex items-center space-x-1">
              <img
                src="/images/icon/heart_icon_yellow.svg"
                className="h-5 w-5"
                alt="point"
              />
              <span className="text-sm text-gray-800">{fNumber(variantPrice)}</span>
            </div>
          </div>
        </div>

        {/*VARIANT SELECTOR*/}
        <div className="mb-4 flex flex-wrap gap-3">
          {product?.productVariants?.map((variant) => (
            <button
              key={variant.id}
              className={clsx(
                "line-clamp-1 max-w-full rounded border border-[#DAD7ED] px-2.5 py-2 text-xs text-gray-700",
                {
                  "bg-midnight-400 px-2 font-bold !text-white": selectedVariant?.id === variant.id,
                },
              )}
              onClick={() => handleOnSelectVariant(variant)}
            >
              <LocalizationDisplay
                field="name"
                localisations={variant.localisations}
                defaultValue={variant.name}
                className="line-clamp-1"
              />
            </button>
          ))}
        </div>

        <div className="w-full pt-3 pb-5">
          <Button
            className="w-full uppercase"
            disabled={!selectedVariant}
            onClick={handleOnChangeVariant}
            loading={isUpdatingCart && !isSuccess}
          >
            {isSuccess && !isShow ? <FaRegCircleCheck /> : t("Confirm")}
          </Button>
        </div>
      </LoadingContainer>
    </Drawer>
  );
};

export default CartVariantDrawer;
