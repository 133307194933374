import { Page } from "src/main/components";
import { ProfileView } from "src/main/views/component";
import { getCurrentUser } from "../../services/auth.service";
import ChangePasswordContent from "./components/ChangePasswordContent";
import ChangePasswordForce from "./components/ChangePasswordForce";

type Props = {};

const ChangePassword: React.FC<Props> = () => {
  const { firstTimeLogin } = getCurrentUser();

  return (
    <Page>
      {firstTimeLogin ? (
        <ChangePasswordForce />
      ) : (
        <>
          <ProfileView title="change password">
            <ChangePasswordContent />
          </ProfileView>
        </>
      )}
    </Page>
  );
};

export default ChangePassword;
