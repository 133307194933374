import { FaClone } from "react-icons/fa6";
import { copyToClipboard } from "src/main/utils";

interface Props {
  value: string | null | undefined;
}

export default function CopyText({ value }: Props) {
  if (!value) return null;

  return (
    <span
      onClick={() => copyToClipboard(value)}
      className="inline-flex cursor-pointer items-center whitespace-nowrap"
    >
      {value}
      <FaClone className="ml-2 inline-block" />
    </span>
  );
}
