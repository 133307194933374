import { clsx } from "clsx";
import { PropsWithChildren, ReactNode } from "react";
import { Footer, LoadingContainer } from "src/main/components";

interface LayoutStyleProps {
  paddingTop?: boolean;
  paddingBottom?: boolean;
  paddingLeft?: boolean;
  paddingRight?: boolean;
  paddingX?: boolean;
  paddingY?: boolean;
  flexColumn?: boolean;
  flexRow?: boolean;
  mobileView?: boolean;
}

interface PageProps extends PropsWithChildren, LayoutStyleProps {
  children?: ReactNode;
  loader?: ReactNode;
  loading?: boolean;
  title?: string | ReactNode;
  className?: string;
  footer?: boolean;
  contentClassName?: string;
  titleClassName?: string;
  hideTitleOn?: "mobile" | "desktop";
}

const Page = ({
  className,
  loader,
  paddingTop,
  title,
  children,
  loading = false,
  footer = true,
  contentClassName,
  titleClassName,
  paddingX,
  paddingY,
  paddingBottom,
  paddingLeft,
  paddingRight,
  flexColumn,
  flexRow,
  mobileView = false,
  hideTitleOn,
}: PageProps) => {
  return (
    <>
      <div
        className={clsx(
          "lg:container lg:mx-auto lg:mt-20 lg:px-4",
          {
            "flex flex-col": flexColumn,
            "flex flex-row": flexRow,
            "px-4": paddingX,
            "py-4": paddingY,
            "pb-4": paddingBottom,
            "pl-4": paddingLeft,
            "pr-4": paddingRight,
            "pt-5": paddingTop,
            "!max-w-md": mobileView,
          },
          className,
        )}
      >
        {!!title && (
          <div
            className={clsx("p-4 text-xl capitalize", {
              "hidden lg:block": hideTitleOn === "mobile",
              "lg:hidden": hideTitleOn === "desktop",
            })}
          >
            {["string", "number"].includes(typeof title) ? (
              <h1 className={clsx("text-center text-lg font-bold text-content-primary", titleClassName)}>{title}</h1>
            ) : (
              title
            )}
          </div>
        )}
        {!!loader && (
          <LoadingContainer
            loading={loading}
            loader={loader}
            containerClassName={contentClassName}
          >
            {children}
          </LoadingContainer>
        )}
        {!loader && children}
      </div>
      {footer && (
        <div className="mt-6">
          <Footer />
        </div>
      )}
    </>
  );
};

export default Page;
