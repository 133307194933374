import { toast } from "react-toastify";

const copyToClipboard = async (text: string | null | undefined) => {
  if (!text) return;

  try {
    await navigator.clipboard.writeText(text);
    toast.success("Copied", { autoClose: 1000 });
  } catch (error: unknown) {
    toast.error(
      (
        error as {
          message?: string;
        }
      )?.message || "Can not copy",
      { autoClose: 1000 },
    );
  }
};

export { copyToClipboard };
