export default function IcoGift(props: any) {
  return (
    <svg
      width="25"
      height="25"
      preserveAspectRatio="none"
      viewBox="0 0 27 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M 12.871 22.547 H 5.711 V 13 H 12.871 V 22.547 Z M 12.871 8.227 H 4.12 V 11.409 H 12.871 V 8.227 Z M 14.463 22.547 H 21.623 V 13 H 14.463 V 22.547 Z M 14.463 8.227 V 11.409 H 23.214 V 8.227 H 14.463 Z M 17.938 3.453 C 15.594 3.453 14.222 6.219 13.667 7.431 H 17.919 C 20.698 7.431 20.728 3.453 17.938 3.453 Z M 17.305 6.334 H 15.68 C 16.268 5.442 16.825 4.77 17.543 4.77 C 18.475 4.77 18.52 6.334 17.305 6.334 Z M 9.415 7.431 H 13.667 C 13.112 6.219 11.74 3.453 9.396 3.453 C 6.606 3.453 6.636 7.431 9.415 7.431 Z M 9.791 4.77 C 10.509 4.77 11.066 5.442 11.654 6.334 H 10.029 C 8.814 6.334 8.859 4.77 9.791 4.77 Z"
        fill="currentColor"
      />
    </svg>
  );
}

IcoGift.defaultProps = {};

// interface IcoGiftProps {}
