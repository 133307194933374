import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import useActions from "src/main/hooks/useActions";
import useTypedSelector from "src/main/hooks/useTypedSelector";
import { getDeployEnv, getStorageItem } from "src/main/utils";
import { CountdownTimer } from "../component";
import { Topbar } from "./component";
type Props = {};

const VipStatusInfo: React.FC<Props> = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const actions = useActions();
  const state = useTypedSelector((state) => state);
  const currentEnv = getDeployEnv();
  const selectedLang = getStorageItem("lng", currentEnv.defaultLang);

  interface RouteParams {
    mobile: string;
  }

  interface QueryParams extends RouteParams {
    mobile: string;
    token: string;
  }
  const params = useParams<keyof RouteParams>() as RouteParams;
  const [searchParams] = useSearchParams();

  useEffect(() => {
    document.body.style.backgroundImage = `url("/images/body-bg/bg_lg_wavy.webp")`;
    document.body.style.backgroundRepeat = "no-repeat";
    document.body.style.backgroundSize = "cover";
    document.body.style.backgroundAttachment = "fixed";
  }, []);

  const styles = {
    divClass: `px-4 py-4 w-full inline-flex justify-between items-center self-stretch rounded-lg text-white text-left font-bold drop-shadow-lg bg-gradient-to-b from-[rgba(126,125,217,0.4)] to-[rgba(20,36,117,0.4)]`,
  };

  const handleStatusQuery = (props: QueryParams) => {
    const { mobile, token } = props;
    actions.getVipStatus({ mobile: mobile, token: token });
  };

  useEffect(() => {
    const props = {
      mobile: state.auth.mobile,
      token: searchParams.get("token") ?? "",
    };
    handleStatusQuery(props);
    // eslint-disable-next-line
  }, [params]);

  useEffect(() => {
    if (state.vipStatus?.data?.valid === null) return;
    if (state.vipStatus?.data?.valid === false) navigate("/vipstatus/signup");
    // eslint-disable-next-line
  }, [state.vipStatus.data]);

  const handleClick = () => {
    window.open("https://line.me/R/ti/p/@645ojnqc", "_blank");
  };

  return (
    <div className="flex h-screen justify-center text-white">
      <div className="h-100 w-full max-w-[500px]">
        <div className="w-full">
          <Topbar />

          <div className="gap-2.5 p-5">
            <div className="flex justify-between pb-2">
              <div>
                <div className="flex flex-col gap-2 text-xl font-bold">
                  <div className="leading-3 text-[#F03F96]">Hi, {state.vipStatus?.data?.username ?? "Guest"}</div>
                  <div>
                    {t("You are a VIP!", {
                      lng: selectedLang,
                    })}
                  </div>
                </div>
              </div>
              <img
                className="h-[45px] w-[45px]"
                src="/images/icon/ico-vip.svg"
                alt="VIP badge"
              />
            </div>

            <div className="mt-5 flex h-11 gap-2">
              <div className={`${styles.divClass}`}>
                <div className="flex w-full items-center justify-center">
                  <div className="px-2 font-[14px] font-bold">
                    {t("You Have:", {
                      lng: selectedLang,
                    })}
                  </div>
                  <img
                    className="h-[20px] w-[20px]"
                    src="/images/icon/heart_icon_yellow.svg"
                    alt="VIP badge"
                  />
                  <div className="px-1 text-xl font-bold text-[#F6BD66]">
                    {state.vipStatus?.data?.offer_points ?? "0"}
                    <span className="px-1 text-[14px] font-normal">
                      {t("Free Credits", {
                        lng: selectedLang,
                      })}
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <div className="mt-5">
              <div className="font-base font-bold">
                {t("Free Credits for New Logins", {
                  credit: "80",
                  lng: selectedLang,
                })}
                !
              </div>
              <div className={`${styles.divClass}`}>
                <div className="flex w-full flex-col">
                  <div className="flex w-full flex-col rounded-md border-2 border-[#99B6FF]">
                    <div className="flex items-center justify-center">
                      <img
                        className="mt-[-18px] h-[100px] w-[100px]"
                        src="/images/icon/vippacket.svg"
                        alt="VIP badge"
                      />
                      <div className="flex flex-col gap-2">
                        <h5 className="text-[20px] font-bold leading-6">{t("credit", { credit: "80" })}</h5>
                        <div className="text-[12px] font-normal	leading-3 text-[#99B6FF]">
                          {t("One-Time Offer", {
                            lng: selectedLang,
                          })}
                        </div>
                      </div>
                    </div>

                    <div className="flex h-[40px] items-center justify-center bg-[#141033]">
                      <div className="flex items-center gap-2">
                        <img
                          className="h-[10px] w-[10px]"
                          src="/images/icon/ico-clock.svg"
                          alt="VIP badge"
                        />
                        <h5 className="text-xxs font-bold uppercase">
                          {t("LIMITED Time", {
                            lng: selectedLang,
                          })}
                        </h5>
                        <div>
                          <CountdownTimer />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="mt-10">
                    <div className="w-full text-center text-xs font-normal leading-3 drop-shadow-lg">
                      {t("contact our VIP service to claim your credit now", {
                        lng: selectedLang,
                      })}
                      !
                    </div>
                    <button
                      type="button"
                      className="
  mt-1
  mr-2
  inline-flex
  h-12
            w-full
            items-center
            justify-center
                        rounded-lg
             bg-gradient-to-tr from-[#37B10C] to-[#7BDD59] px-6 py-4 text-center text-base font-bold"
                      onClick={handleClick}
                    >
                      <img
                        className="mr-2 mt-1 mb-1"
                        src="/images/icon/ico-line.svg"
                        height="24"
                        width="24"
                        alt="LINE"
                      />
                      {t("Claim Now", {
                        lng: selectedLang,
                      })}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VipStatusInfo;
