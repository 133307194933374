import React from "react";
import { useTranslation } from "react-i18next";
import { FaCheck } from "react-icons/fa6";
import { useDispatch } from "react-redux";
import useTypedSelector from "src/main/hooks/useTypedSelector";
import preference from "src/main/store/preference";

import { Page } from "src/main/components";
import { Language } from "src/main/types";

const ChangeLanguageContent: React.FC = () => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const lang = useTypedSelector((state) => state.preference.language);

  const changeLanguageHandler = (lang: string) => {
    i18n.changeLanguage(lang);
    dispatch(preference.slice.actions.setLanguage(lang));
  };

  return (
    <div>
      <Page
        className="text-white lg:mt-0"
        footer={false}
        paddingX
        title={t("Change Language")}
        hideTitleOn="desktop"
      >
        <div
          className="h-12 cursor-pointer rounded-lg bg-[radial-gradient(84.58%_106.92%_at_83.69%_0%,#26125C_0%,#170B37_100%)] text-white [box-shadow:1px_1px_0px_0px_rgba(137,118,255,0.24)_inset]"
          onClick={() => changeLanguageHandler(Language.EN)}
        >
          <div className="flex h-full items-center justify-center gap-[10px] px-4 py-[14px]">
            <img
              className="w-[25px]"
              src="/images/icon/flag-en.svg"
              alt="English-Language"
            />
            <div className="shrink grow">
              <p className="text-left text-[14px] font-bold leading-4">English</p>
            </div>
            {lang === Language.EN ? <FaCheck className="h-[10px] w-[10px]" /> : <div className="h-[10px] w-[10px]" />}
          </div>
        </div>
        <div
          className="mt-2 h-12 cursor-pointer rounded-lg bg-[radial-gradient(84.58%_106.92%_at_83.69%_0%,#26125C_0%,#170B37_100%)] [box-shadow:1px_1px_0px_0px_rgba(137,118,255,0.24)_inset]"
          onClick={() => changeLanguageHandler(Language.TH)}
        >
          <div className="flex h-full items-center justify-center gap-[10px] px-4 py-[14px]">
            <img
              className="w-[25px]"
              src="/images/icon/flag-th.svg"
              alt="English-Language"
            />

            <div className="shrink grow">
              <p className="text-left text-[14px] font-bold leading-4">{t("Thailand")}</p>
            </div>

            {lang === Language.TH ? <FaCheck className="h-[10px] w-[10px]" /> : <div className="h-[10px] w-[10px]" />}
          </div>
        </div>
      </Page>
    </div>
  );
};

export default ChangeLanguageContent;
