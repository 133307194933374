import {
  BaseQueryFn,
  FetchArgs,
  fetchBaseQuery,
  FetchBaseQueryError,
  FetchBaseQueryMeta,
} from "@reduxjs/toolkit/dist/query/react";
import moment from "moment";
import { toast } from "react-toastify";
import { appConfig } from "src/config";
import i18n from "src/i18n";
import { languageIds } from "../services/auth-header";
import { RootState } from "../store";
import auth from "../store/auth";
import { logger, transformAccessToken } from "../utils";

export const queryWithAuth = fetchBaseQuery({
  baseUrl: appConfig.server.baseURL,
  prepareHeaders: (headers, api) => {
    const state = api.getState() as RootState;
    if (
      state.auth.oauth?.accessToken &&
      state.auth.oauth?.expiresAt?.isAfter(moment()) &&
      api.endpoint !== "/oauth/access_token"
    )
      headers.set("Authorization", `Bearer ${state.auth.oauth.accessToken}`);
    else logger._console.debug("header not set", api, state);

    if (!!state.preference.language) {
      const userLanguage = languageIds[state.preference.language];
      headers.set("Lang", userLanguage + "");
    }
  },
});

export type QueryFn = BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError, {}, FetchBaseQueryMeta>;
export const queryWithRefresh: QueryFn = async (args, api, extraOptions) => {
  let result = await queryWithAuth(args, api, extraOptions);
  //#region
  // when login with password, we dont need to refresh token (fix bug id: ILV-933)
  if ((args as FetchArgs).url === "/oauth/access_token" && (args as FetchArgs).body.grant_type === "password") {
    return result;
  }
  ///#endregion
  if (result.error && result.error.status === 401) {
    const state = api.getState() as RootState;
    let tokenReloadSuccess = false;
    if (state.auth.oauth?.refreshToken && state.auth.oauth?.refreshExpiresAt?.isAfter(moment())) {
      const refreshToken = state.auth.oauth.refreshToken;
      const refreshResult = await queryWithAuth(
        {
          method: "POST",
          url: "/oauth/access_token",
          body: {
            grant_type: "refresh_token",
            refresh_token: refreshToken,
          },
        },
        api,
        extraOptions,
      );
      if (refreshResult.data) {
        const authToken = transformAccessToken(refreshResult.data);
        api.dispatch(auth.slice.actions.updateAccessToken(authToken));
        result = await queryWithAuth(args, api, extraOptions);
        tokenReloadSuccess = true;
      }
    }

    if (!tokenReloadSuccess) {
      if (state.auth.oauth?.refreshToken) {
        toast.error(i18n.t("Your session has expired, please log in again"), {
          toastId: "session-expired",
        });
      }
      api.dispatch(auth.slice.actions.logout());
    }
  }
  return result;
};
