import { useTranslation } from "react-i18next";
import { FaArrowLeft } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";

interface BackButtonProps {
  label?: string;
}

const BackButton: React.FC<BackButtonProps> = (props) => {
  const { label = null } = props;
  const navigate = useNavigate();
  const { t } = useTranslation();
  const goBack = () => {
    navigate(-1);
  };
  return (
    <button
      type="button"
      onClick={goBack}
      className="m-0 rounded-lg border-0 text-[15px] capitalize leading-[normal] text-white md:text-2xl lg:text-base lg:font-normal"
    >
      <FaArrowLeft className="mx-2 inline-block" />
      {label && t(`${label}`)}
    </button>
  );
};

export default BackButton;
