import { Field, Form, Formik, getIn } from "formik";
import { FormikHelpers } from "formik/dist/types";
import React from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { siteApi } from "src/main/api";
import { ChangePasswordResponse } from "src/main/api/site/User";
import { Button, Page } from "src/main/components";
import { resetPasswordSchema } from "src/main/components/validation/resetPasswordSchema";
import { ErrorResponse, getErrorMessage, handleApiResponse, QueryError } from "src/main/utils";
function getStyles(errors: any, fieldName: string) {
  if (getIn(errors, fieldName)) {
    return {
      border: "1px solid red",
    };
  }
}

type ResetPasswordType = {
  new_password: string;
  confirm_new_password: string;
};

const initialValues: ResetPasswordType = {
  new_password: "",
  confirm_new_password: "",
};

type Props = {};

const ChangePassword: React.FC<Props> = () => {
  const { t } = useTranslation();
  const [changePasswordMutation, { isLoading: isChangePasswordLoading }] = siteApi.useChangePasswordMutation();

  const handlePasswordChange = async (
    formValue: ResetPasswordType,
    { resetForm }: FormikHelpers<ResetPasswordType>,
  ) => {
    const { new_password } = formValue;
    try {
      const changePasswordResponse = await changePasswordMutation({ password: new_password });

      const { data, error } = handleApiResponse<ChangePasswordResponse>(changePasswordResponse);

      if (data) {
        toast.success(t("Password updated successfully!"));
        resetForm();
      }

      if (error) {
        toast.error(getErrorMessage(error as QueryError<ErrorResponse>, t));
      }
    } catch (error: unknown) {
      toast.error(getErrorMessage(error as QueryError<ErrorResponse>, t));
    }
  };

  return (
    <Page
      footer={false}
      paddingX
      title={t("Change Password")}
      hideTitleOn="desktop"
      className="lg:mt-0"
    >
      <div className="change-password-form rounded-lg bg-[radial-gradient(84.58%_106.92%_at_83.69%_0%,#26125C_0%,#170B37_100%)] p-4 [box-shadow:1px_1px_0px_0px_rgba(137,118,255,0.24)_inset]">
        <Formik
          initialValues={initialValues}
          validationSchema={resetPasswordSchema}
          onSubmit={handlePasswordChange}
        >
          {({ errors, touched, isValid, submitCount }) => (
            <Form>
              <div className="px-2">
                {touched.new_password && errors.new_password && (
                  <div className="text-red-400">{t(errors.new_password)}</div>
                )}
                {touched.confirm_new_password && errors.confirm_new_password && !errors.new_password ? (
                  <div className="text-red-400">{t(errors.confirm_new_password)}</div>
                ) : (
                  !isValid &&
                  (submitCount && (!errors.new_password as unknown as number)) > 0 && (
                    <div className="text-red-400">{t("Please fill in all fields")}</div>
                  )
                )}
                <Field
                  type="password"
                  className="mt-4 inline-flex w-full items-center rounded-lg px-4 py-2.5 text-white placeholder-white"
                  id="new_password"
                  name="new_password"
                  placeholder={t("New Password")}
                  style={getStyles(errors, "new_password")}
                />
                <Field
                  type="password"
                  className="mt-4 mb-6 inline-flex w-full items-center rounded-lg px-4 py-2.5 text-white placeholder-white"
                  id="confirm_new_password"
                  name="confirm_new_password"
                  placeholder={t("Confirm new password")}
                  style={getStyles(errors, "confirm_new_password")}
                />

                <Button
                  size="xl"
                  variant="blue-violet"
                  loading={isChangePasswordLoading}
                >
                  {t("Update Password")}
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </Page>
  );
};

export default ChangePassword;
