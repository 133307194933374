import { useMemo } from "react";
import { Fade } from "react-awesome-reveal";
import { useTranslation } from "react-i18next";
import { LocalizationDisplay } from "src/main/components";
import { OrderItem } from "src/main/types";
import { fNumber, getItemOrderPrice } from "src/main/utils";

interface RedeemSuccessfulItemCardProps {
  orderItem: OrderItem;
}

/**
 * @deprecated Will be removed in V4
 */
function RedeemSuccessfulItemCard({ orderItem }: RedeemSuccessfulItemCardProps) {
  const { info, quantity, subtotal, product, productVariant } = orderItem;

  const { t } = useTranslation();

  const unitPrice = useMemo(() => {
    return getItemOrderPrice(orderItem);
  }, [orderItem]);

  const imageUrl = info?.variant?.imageUrl || info?.product?.imageUrl;

  return (
    <div className="mt-2 flex items-center gap-4 rounded-lg border-t border-l border-[rgba(137,118,255,0.24)] bg-gradient-to-b from-[rgba(38,18,92,0.90)] to-[rgba(23,11,55,0.90)] p-2">
      <div className="relative w-1/4 lg:w-1/5">
        <div className="relative rounded-md bg-white">
          <img
            className="mx-auto rounded-md object-contain"
            src="/images/products/backdrop.png"
            alt="backdrop"
          />
          {!!imageUrl && (
            <Fade triggerOnce>
              <img
                src={imageUrl}
                className="absolute top-0 left-0 z-10 h-full w-full rounded-lg object-contain"
                alt="card"
              />
            </Fade>
          )}
        </div>
      </div>
      <div className="w-2/3 text-sm text-white md:text-2xl lg:w-4/5 lg:text-sm">
        {!!productVariant && (
          <LocalizationDisplay
            field="name"
            localisations={productVariant?.localisations}
            defaultValue={productVariant?.name}
            className="mt-2 break-words text-sm font-bold text-white md:text-2xl lg:text-sm"
          />
        )}
        {!!product && !productVariant && (
          <LocalizationDisplay
            field="name"
            localisations={product?.localisations}
            defaultValue={product?.name}
            className="mt-2 break-words text-sm font-bold text-white md:text-2xl lg:text-sm"
          />
        )}

        <div className="mt-1 flex text-xs font-normal text-white md:text-[20px]">
          <img
            src="/images/icon/heart_icon_yellow.svg"
            width="15px"
            height="15px"
            alt="heart-icon"
          />
          <p className="text-sm md:text-[20px] lg:text-sm">
            <span className="ml-2 font-bold">{fNumber(unitPrice)}</span>
            <span className="ml-1 text-[#8A93A4]">—</span>
            {/* — {t("Quantity")} : {quantity} */}
            <span> Qty: {quantity}</span>
          </p>
        </div>

        <p className="mt-3 text-xs font-normal text-[#8A93A4]">
          {t("Sub Total")} : {fNumber(subtotal ?? 0)}
        </p>
      </div>
    </div>
  );
}

export default RedeemSuccessfulItemCard;
