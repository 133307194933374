import { useTranslation } from "react-i18next";

function BrandFooter(props: BrandFooterProps) {
  const { footerSiteUrl, footerLogoUrl, brandLogoStyle, brandNameStyle } = props;

  const { t } = useTranslation();

  return (
    <div className="w-[60px]">
      <a
        href={footerSiteUrl}
        target="_blank"
        rel="noopener noreferrer"
      >
        <img
          src={footerLogoUrl}
          className={brandLogoStyle}
          alt="footer-logo"
        />
      </a>
      <p className={brandNameStyle}>{t("play now")}</p>
    </div>
  );
}

interface BrandFooterProps {
  footerSiteUrl: string;
  footerLogoUrl: string;
  brandLogoStyle?: string;
  brandNameStyle?: string;
}

BrandFooter.defaultProps = {
  footerSiteUrl: "",
  footerLogoUrl: "",
  brandLogoStyle: "",
  brandNameStyle: "",
};

export default BrandFooter;
