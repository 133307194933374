function getStorageItem<T = string>(key: string, defaultValue: T, parser?: (value: string) => T, parseKey = "[]"): T {
  try {
    if (typeof parser === "function") {
      return parser(localStorage.getItem(key) ?? parseKey);
    }
    return (localStorage.getItem(key) as unknown as T) ?? defaultValue;
  } catch (error) {
    console.error(error);
    return defaultValue;
  }
}

export { getStorageItem };
