import React, { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { getStorageItem, Paths } from "src/main/utils";

export declare type Params<Key extends string = string> = {
  readonly [key in Key]: string | undefined;
};

const SessionCheck: React.FC = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  useEffect(() => {
    const token = getStorageItem("VIP_TOKEN", null);
    if (!token) {
      window.location.replace(searchParams.get("d") + "/logout");
    }
    navigate(Paths.Private.Home, { replace: true });
    // eslint-disable-next-line
  }, []);
  return <></>;
};

export default SessionCheck;
