import clsx from "clsx";
import { useTranslation } from "react-i18next";
import { MissionUIStatus } from "../../../../types";

interface MissionCardBottomProps {
  status: MissionUIStatus;
  onClick?: () => void;
  displayReadMoreLabel?: boolean;
}

const content = {
  [MissionUIStatus.IN_PROGRESS]: "read more",
  [MissionUIStatus.CLAIMABLE]: "claim now!",
  [MissionUIStatus.CLAIMED]: "claimed",
  [MissionUIStatus.EXPIRED]: "expired",
};

export default function MissionCardBottom({ status, onClick, displayReadMoreLabel = true }: MissionCardBottomProps) {
  const { t } = useTranslation();

  return (
    <div
      className={clsx(
        "no-tap-highlight flex !h-6 w-full min-w-max cursor-pointer items-center justify-center rounded-b-lg bg-[#34256A] shadow-[0px_-4px_8px_0px_rgba(0,0,0,0.25)] transition-opacity active:opacity-90",
        {
          "pointer-events-none !bg-[#261C4F] text-midnight-200": status === MissionUIStatus.EXPIRED,
          "pointer-events-none text-midnight-200": status === MissionUIStatus.CLAIMED,
          "pointer-events-none text-content-positive-primary": status === MissionUIStatus.CLAIMABLE,
          "text-content-warning-primary": status === MissionUIStatus.IN_PROGRESS,
        },
      )}
      onClick={onClick}
    >
      <div
        className={clsx("text-xs font-bold uppercase leading-[18px]", {
          "opacity-0": !displayReadMoreLabel && status === MissionUIStatus.IN_PROGRESS,
        })}
      >
        {t(content[status])}
      </div>
    </div>
  );
}
