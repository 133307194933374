import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { siteApi } from "src/main/api";
import { useProfileBalance } from "src/main/hooks";
import { RootState } from "src/main/store";
import productSlice, { ProductState } from "src/main/store/product/slices";
import { CartItemModel } from "src/main/types";
import { getCartTotalPrice, logger, SimpleMap } from "src/main/utils";

interface CartValidationResult {
  isValid: boolean;
  error: {
    message: string | null;
    items: SimpleMap | null;
  };
}

const useCart = () => {
  const dispatch = useDispatch();
  const [fetchCartDetail, { isLoading: isFetchingCart }] = siteApi.useLazyGetCartDetailQuery();
  const {
    cart,
    checkoutCartItems = [],
    checkoutShippingInfo,
  } = useSelector<RootState, ProductState>((state) => state.products);
  const [updateCart, { isLoading: isUpdatingCart }] = siteApi.useUpdateCartMutation();
  const [isValidating, setIsValidating] = useState(false);
  const { balance } = useProfileBalance();
  const { t } = useTranslation();

  const setCheckoutCartItems = useCallback(
    (updateCartIds: CartItemModel[]) => {
      dispatch(productSlice.actions.updateCheckoutCart(updateCartIds));
    },
    [dispatch],
  );

  const grandTotal = useMemo(() => {
    return cart ? getCartTotalPrice(cart, checkoutCartItems) : 0;
  }, [cart, checkoutCartItems]);

  const validateCheckoutCartItems = useCallback(async () => {
    let validateResult: CartValidationResult = {
      isValid: true,
      error: {
        message: null,
        items: null,
      },
    };
    try {
      setIsValidating(true);
      const {
        model: {
          data: { items = [] },
        },
      } = await fetchCartDetail({}).unwrap();
      // validate items length
      if (checkoutCartItems.length === 0 || items.length === 0) {
        validateResult.isValid = false;
        validateResult.error.message = t("Please select items to proceed");
        return validateResult;
      }

      // validate balance
      if (balance.lt(grandTotal)) {
        validateResult.isValid = false;
        validateResult.error.message = t("Insufficient balance");
        return validateResult;
      }

      // validate each item
      const checkoutVariantIds = checkoutCartItems.map((item) => item.variantId);
      const itemsValidationResults =
        items?.reduce((map, { quantity, variant, variantId }) => {
          const availableQuantity = variant?.productInventory?.quantity ?? 0;
          if (checkoutVariantIds.includes(variantId)) {
            // check available quantity
            if (quantity > availableQuantity) {
              let quantityErrorMsg = `${t("There are")} ${availableQuantity} ${t("quantities remaining for this item")}`;
              if (availableQuantity === 0) quantityErrorMsg = t("Sorry this item is sold out");
              if (availableQuantity === 1)
                quantityErrorMsg = `${t("There is only")} 1 ${t("quantity remaining for this item")}`;
              map[variantId] = quantityErrorMsg;
            }

            // check minimum ordering quantity
            if (!!variant?.product?.minQuantity && quantity < variant?.product?.minQuantity) {
              map[variantId] = t("Order minimum number products", {
                count: variant?.product?.minQuantity,
              });
            }

            // check maximum ordering quantity
            if (!!variant?.product?.maxQuantity && quantity > variant?.product?.maxQuantity) {
              map[variantId] = t("Order maximum number products", {
                count: variant?.product?.maxQuantity,
              });
            }
          }

          return map;
        }, {} as SimpleMap) ?? ({} as SimpleMap);

      if (Object.keys(itemsValidationResults).length === 0) {
        return validateResult;
      } else {
        validateResult.isValid = false;
        validateResult.error.items = itemsValidationResults;
        return validateResult;
      }
    } catch (error: unknown) {
      logger._console.log(error);
      validateResult.isValid = false;
      return validateResult;
    } finally {
      setIsValidating(false);
    }
  }, [balance, checkoutCartItems, fetchCartDetail, grandTotal, t]);

  return {
    cart,
    checkoutCartItems,
    setCheckoutCartItems,
    fetchCartDetail,
    isFetchingCart,
    grandTotal,
    updateCart,
    isUpdatingCart,
    checkoutShippingInfo,
    validateCheckoutCartItems,
    isValidating,
  };
};

export default useCart;
