import { BrandModel } from "src/main/models";
import { Meta, QueryResult } from "src/main/types";
import { parseMeta } from "src/main/utils";
import { BuilderProp, require200Status } from "..";

export interface GetBrandInfoRequest {
  brandId: number;
}

export interface GetBrandInfoResponse {}

export const getBrandInfo = (builder: BuilderProp) => {
  return builder.query<GetBrandInfoResponse, GetBrandInfoRequest>({
    providesTags: ["brand"],
    query: ({ brandId }) => ({
      url: `/brand/${brandId}`,
      validateStatus: require200Status,
    }),
    transformResponse: (response: GetBrandInfoResponse) => {
      return response;
    },
  });
};

export interface ListBrandsRequest {
  meta?: Partial<Meta>;
}

export interface ListBrandsResponse {
  entries: BrandModel[];
  meta: Meta;
}

export const listBrands = (builder: BuilderProp) => {
  return builder.query<ListBrandsResponse, ListBrandsRequest>({
    providesTags: ["brand"],
    query: ({ meta }) => ({
      url: `/member/brand/list${parseMeta(meta)}`,
      validateStatus: require200Status,
    }),
    transformResponse: (response: QueryResult<ListBrandsResponse>) => {
      return response.result;
    },
  });
};
