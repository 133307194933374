import React, { useEffect, useRef, useState } from "react";

type Props = {
  onBottomHit: () => void;
  isLoading: boolean;
  hasMoreData: boolean;
  loadOnMount: boolean;
  children?: React.ReactNode;
  className?: string;
};

function isBottom(ref: React.RefObject<HTMLDivElement>) {
  if (!ref.current) {
    return false;
  }
  return ref.current.getBoundingClientRect().bottom <= window.innerHeight;
}

const InfiniteScroll: React.FC<Props> = ({ onBottomHit, isLoading, hasMoreData, loadOnMount, children, className }) => {
  const [initialLoad, setInitialLoad] = useState(true);
  const contentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (loadOnMount && initialLoad) {
      onBottomHit();
      setInitialLoad(false);
    }
  }, [onBottomHit, loadOnMount, initialLoad]);

  useEffect(() => {
    const onScroll = () => {
      if (!isLoading && hasMoreData && isBottom(contentRef)) {
        onBottomHit();
      }
    };

    let innerFrameExists = document.getElementsByClassName("inner-frame");
    if (innerFrameExists.length) {
      document.getElementsByClassName("inner-frame")[0].addEventListener("scroll", onScroll);
      return () => document.removeEventListener('inner-frame")[0]', onScroll);
    } else {
      document.addEventListener("scroll", onScroll);
      return () => document.removeEventListener("scroll", onScroll);
    }
  }, [onBottomHit, isLoading, hasMoreData]);

  return (
    <div
      className={className}
      ref={contentRef}
    >
      {children}
    </div>
  );
};

export default InfiniteScroll;
