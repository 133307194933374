import { clsx } from "clsx";
import { ImgHTMLAttributes } from "react";

interface IcoMessengerProps extends ImgHTMLAttributes<HTMLImageElement> {}

const IcoMessenger = (props: IcoMessengerProps) => {
  return (
    <img
      src="/images/icon/fab-messenger.svg"
      alt="fab-messenger"
      {...props}
      className={clsx("hue-rotate-[-6deg]", props.className)}
    />
  );
};

export default IcoMessenger;
