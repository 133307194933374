import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { AuthBody } from "src/main/components";
import { Paths } from "src/main/utils";
import { LoginFormBox } from "src/main/views/login/components";
import { LoginForm } from "./components";

type Props = {};

const Login: React.FC<Props> = () => {
  const { t } = useTranslation();
  const location = useLocation();

  const loginByPhone = useMemo((): boolean => {
    return location.pathname === Paths.Public.MobileLogin;
  }, [location]);

  return (
    <AuthBody>
      <LoginFormBox>
        <div className="flex flex-col items-center text-center">
          <img
            className="h-[99px]"
            src="/images/login/ilv_slots_animated.gif"
            alt="Frame 99"
          />
          {loginByPhone && (
            <>
              <div className="text-xl font-bold text-white">{t("Log In to ILOVEVIP")}</div>
              <div className="text-[12px] text-secondary">{t("and get numerous perks!")}</div>
            </>
          )}
        </div>
        <LoginForm loginByPhone={loginByPhone} />
      </LoginFormBox>
    </AuthBody>
  );
};

export default Login;
