import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Fade } from "react-awesome-reveal";
import { useTranslation } from "react-i18next";
import { FaChevronRight } from "react-icons/fa6";
import { useParams } from "react-router-dom";
import Slider, { Settings } from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import { siteApi } from "src/main/api";
import { BackButton, LocalizationDisplay, localizationValue, Page, Pulse } from "src/main/components";
import { useAsyncTask, useBreakPoint } from "src/main/hooks";
import { AssetModel, ProductModel, ProductVariantModel } from "src/main/types";
import { fNumber, getProductPrice, isTopProduct, mergeKeys } from "src/main/utils";
import { AddToCartDrawerRef } from "src/main/views/ProductPage/components/AddToCartDrawer/AddToCartDrawer";
import { ProductImage, SkeletonLoader } from "./components";
import AddToCartDrawer from "./components/AddToCartDrawer";
import AddToCartForm from "./components/AddToCartForm";
import "./ProductPage.css";

interface RouteParams {
  id: string;
}

const ProductPage: React.FC = () => {
  const params = useParams<keyof RouteParams>() as RouteParams;
  const refMobileSlider = useRef<Slider>(null);
  const refAddToCartDrawer = useRef<AddToCartDrawerRef>(null);
  const [productImage, setProductImage] = useState<string>("");
  const [productImages, setProductImages] = useState<AssetModel[]>([]);
  const [product, setProduct] = useState<ProductModel | null>(null);
  const [runGetProduct, isFetchingProduct] = useAsyncTask("product/detail");
  const [getProductDetail] = siteApi.useLazyGetProductDetailQuery();
  const [selectedVariant, setSelectedVariant] = useState<ProductVariantModel>();
  const [currentImageSlide, setCurrentImageSlide] = useState(0);
  const { t } = useTranslation();
  const { isDesktop } = useBreakPoint();

  useEffect(() => {
    // On desktop view, always select the first variant
    if (isDesktop) {
      setSelectedVariant(product?.productVariants?.[0]);
    } else {
      // On mobile view, only set default variant if product has 1 variant
      if (product?.productVariants?.length === 1) setSelectedVariant(product?.productVariants?.[0]);
    }
  }, [isDesktop, product]);

  const getProduct = useCallback(() => {
    runGetProduct(async () => {
      const { product } = await getProductDetail({ slug: params.id }).unwrap();
      const { image, extraImages, productVariants } = product;

      setProduct(product);

      const images: AssetModel[] = [];
      if (image) images.push(image);
      if (extraImages) images.push(...extraImages);
      if (productVariants) {
        productVariants.forEach((variant) => {
          if (variant.image) images.push(variant.image);
        });
      }

      setProductImages(images);

      if (images.length > 0) setProductImage(images[0].url || "");
    });
  }, [getProductDetail, params.id, runGetProduct]);

  /*Show slider arrows only on desktop*/
  const [showArrow, setShowArrow] = useState(false);

  useEffect(() => {
    getProduct();
    const updateShowArrow = () => {
      if (window.matchMedia("(min-width: 1024px)").matches) {
        setShowArrow(true);
      } else {
        setShowArrow(false);
      }
    };

    updateShowArrow();
    window.addEventListener("resize", updateShowArrow);
    return () => {
      window.removeEventListener("resize", updateShowArrow);
    };
    // eslint-disable-next-line
  }, []);

  const settings: Settings = {
    dots: false,
    infinite: false,
    slidesToShow: 4.5,
    slidesToScroll: 1,
    autoplay: false,
    arrows: showArrow,
  };

  const imageHandler = (imageURl: string, index: number) => {
    setProductImage(imageURl);
  };

  const handleSelectVariant = (variant: ProductVariantModel) => {
    setSelectedVariant(variant);
  };

  const handleOnSlideChange = (imageIndex: number) => {
    setCurrentImageSlide(imageIndex);
    const sliderNavigatorItem = document.getElementById("mobile-slider-navigator-items-" + imageIndex);
    if (sliderNavigatorItem) {
      sliderNavigatorItem.scrollIntoView({ behavior: "smooth", block: "nearest", inline: "center" });
    }
  };

  const productActualPrice = useMemo(() => {
    return product ? getProductPrice(product, selectedVariant?.id) : 0;
  }, [product, selectedVariant]);

  const isDisplayVariations = useMemo(() => {
    // Check if product has more than 1 variant
    return product?.productVariants && product?.productVariants?.length > 1;
  }, [product]);

  const isDisplayHotBadge = useMemo(() => {
    return !!product && isTopProduct(product);
  }, [product]);

  const handleOnClickVariation = useCallback(() => {
    refAddToCartDrawer.current?.open("add");
  }, [refAddToCartDrawer]);

  return (
    <Page
      loading={isFetchingProduct}
      loader={<SkeletonLoader />}
      className="-mb-4 min-h-screen bg-gray-50 pb-16 lg:mb-0 lg:bg-transparent"
      contentClassName="flex-col flex h-full"
      footer={false}
    >
      {/*DESKTOP*/}
      <div
        className="mt-16 hidden
             rounded-md
             border-4
             border-[rgba(153,182,255,0.2)]
             lg:block
            "
      >
        <div
          className="
            grid grid-cols-8
            bg-gradient-to-b
            from-[rgba(126,125,217,0.4)]
            to-[rgba(20,47,117,0.4)]
            "
        >
          <div className="col-start-1 col-end-4">
            <div className="rounded-t-lg bg-white">
              <div className="relative hidden lg:block">
                <img
                  alt="product_backdrop"
                  className="mx-auto rounded-t-lg object-contain"
                  src="/images/products/backdrop.png"
                ></img>
                {productImage ? (
                  <Fade triggerOnce>
                    <img
                      alt="product_detail"
                      className="absolute top-0 left-0 z-10 mx-auto h-auto w-full rounded-t-lg object-contain"
                      src={productImage}
                    ></img>
                  </Fade>
                ) : !!product?.image ? (
                  <img
                    alt="product_detail_0"
                    className="absolute top-0 left-0 z-10 mx-auto h-auto min-h-full w-full rounded-t-lg object-contain"
                    src={!!product?.image ? product.image.url! : ""}
                  ></img>
                ) : (
                  <div className="text-white">
                    <Pulse />
                  </div>
                )}
              </div>
              <div className="lg:hidden">
                {productImage ? (
                  <img
                    alt="product_detail_1"
                    className="mx-auto rounded-t-lg object-contain"
                    src={productImage}
                  ></img>
                ) : !!product?.image ? (
                  <img
                    alt="product_detail_2"
                    className="mx-auto min-h-full rounded-t-lg object-contain"
                    src={!!product?.image ? product.image.url! : ""}
                  ></img>
                ) : (
                  <div className="text-white">
                    <Pulse />
                  </div>
                )}
              </div>
            </div>

            <div className="relative bg-white px-4 lg:h-[100px]">
              <Slider {...settings}>
                {!!productImages.length ? (
                  productImages.map((image, index) => (
                    <div
                      className="h-[81px] w-[92px] lg:h-[100px] lg:w-[100px]"
                      key={index}
                      onClick={() => imageHandler(image.url!, index)}
                    >
                      <ProductImage imageUrl={image.url!} />
                    </div>
                  ))
                ) : (
                  <div></div>
                )}
              </Slider>
            </div>
          </div>
          <div className="col-span-5 col-end-9 md:px-2 lg:px-1">
            {selectedVariant && (
              <AddToCartForm
                product={product}
                onChange={handleSelectVariant}
                selectedVariant={selectedVariant}
              />
            )}
          </div>
          <div className="col-start-1 col-end-9">
            <div className="flex">
              <div className="mt-4 w-32 flex-none">
                <BackButton />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/*MOBILE*/}
      <div className="container mx-auto flex h-full max-w-md flex-grow flex-col lg:hidden lg:max-w-screen-2xl">
        <div className="lg:mt-3 lg:w-2/5 lg:pl-24">
          {/*IMAGE SLIDER*/}
          <div className="relative overflow-hidden bg-white lg:h-[200px]">
            <span className="absolute right-2.5 top-2.5 z-10 rounded-full bg-black px-2 py-0.5 text-center text-xxs font-bold tracking-widest text-white">
              {currentImageSlide + 1}/{productImages.length}
            </span>
            <Slider
              {...settings}
              className="mobile-slider"
              dots
              dotsClass="slick-dots slick-custom-thumb text-gray-800"
              slidesToShow={1}
              customPaging={(imgIndex) => {
                const currentImage = productImages[imgIndex];
                return (
                  // eslint-disable-next-line jsx-a11y/anchor-is-valid
                  <button id={"mobile-slider-navigator-items-" + imgIndex}>
                    <ProductImage
                      imageUrl={currentImage!.url!}
                      variant="full"
                    />
                  </button>
                );
              }}
              swipe
              ref={refMobileSlider}
              afterChange={handleOnSlideChange}
            >
              {productImages?.map((image, index) => (
                <div
                  className="aspect-square w-full"
                  key={mergeKeys("main-product-image", index)}
                  onClick={() => imageHandler(image!.url!, index)}
                >
                  <ProductImage
                    imageUrl={image!.url!}
                    variant="full"
                    className="rounded-t-lg"
                  />
                </div>
              ))}
            </Slider>
          </div>
        </div>

        {/*PRODUCT DETAIL*/}
        <div className="flex-grow bg-gray-50 px-4 pt-4">
          <h1 className="leading-4">
            {isDisplayHotBadge && (
              <span className="mr-2 inline-block translate-y-[-2px] rounded bg-tertiary-500 py-1 px-1 text-xxs font-normal leading-[0.9] tracking-wide text-pink-50">
                HOT
              </span>
            )}
            <LocalizationDisplay
              field="name"
              localisations={product?.localisations}
              defaultValue={product?.name}
              className="inline text-base font-bold leading-4 text-gray-800"
            />
          </h1>
          <div className="mt-2 flex items-center space-x-1">
            <img
              src="/images/icon/heart_icon_yellow.svg"
              className="h-5 w-5"
              alt="point"
            />
            <span className="text-sm text-gray-800">{fNumber(productActualPrice)}</span>
          </div>

          {isDisplayVariations && (
            <div className="flex space-x-4 py-5">
              <span className="flex-shrink-0 text-sm font-bold text-gray-800">{t("Variations")}:</span>
              <div
                className="flex flex-grow items-center text-right text-sm text-gray-500 overflow-x-hidden"
                onClick={handleOnClickVariation}
              >
                <span className="flex-grow text-ellipsis whitespace-nowrap overflow-x-hidden">
                  {product?.productVariants
                    ?.map((variant) => localizationValue("name", variant.localisations, variant.name))
                    .join(", ")}
                </span>
                <span className="flex-shink-0 inline-block h-[14px] w-[14px] basis-[14px] text-violet-600">
                  <FaChevronRight />
                </span>
              </div>
            </div>
          )}

          <div className="mt-5 mb-4 rounded-sm bg-gray-100 p-4">
            <h2 className="mb-4 text-sm font-bold text-gray-800">{t("Description")}:</h2>
            <LocalizationDisplay
              field={"info"}
              localisations={product?.info?.localisations}
              defaultValue={product?.info?.content}
              displayHtml
              className="text-gray-500"
            />
          </div>
        </div>
      </div>

      {product && (
        <AddToCartDrawer
          product={product}
          onChangeVariant={handleSelectVariant}
          selectedVariant={selectedVariant}
          ref={refAddToCartDrawer}
        />
      )}
    </Page>
  );
};

export default ProductPage;
