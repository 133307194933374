import "./StrokeText.css";
interface StrokeTextProps {
  text: string;
}

export default function StrokeText({ text }: StrokeTextProps) {
  return (
    <div className="StrokeText-container">
      <div className="StrokeText-layer1">{text}</div>
      <div className="StrokeText-layer3">{text}</div>
    </div>
  );
}
