import clsx from "clsx";
import { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { ReactComponent as CoinTick } from "src/assets/reward-coin-tick.svg";
import { ReactComponent as Coin } from "src/assets/reward-coin.svg";
import { siteApi } from "src/main/api";
import { ClaimDailyRewardResponse } from "src/main/api/site/User";
import { RewardType } from "src/main/types";
import { getErrorMessage } from "src/main/utils";
import { RewardBoxStatus } from "src/main/views/RewardsPage/types";
import { useGetRewardsInfo } from "../../hooks";
import ClaimButton from "../ClaimButton";
import CoinLabel from "../CoinLabel";
import { CoinLabelRef } from "../CoinLabel/CoinLabel";
import RewardCard from "../RewardCard";
import TimerBar from "../TimerBar";
import "./RewardBoxDaily.css";

enum Steps {
  COUNT_DOWN = "COUNT_DOWN", // show the closed chest
  RECEIVE_CHEST = "RECEIVE_CHEST", // show the opening chest
  CLAIM_CHEST = "CLAIM_CHEST", // perform coins moving animation, at the end call api
  RECEIVE_COINS = "RECEIVE_COINS", // api success -> perform coins changing animation and chest moving animation
}

/**
 * ## clip path
 * - define the wrapper with this
 * ```
 * <svg
    width="0"
    height="0"
    preserveAspectRatio="none"
  >
    <defs>
      <clipPath
        id="your-id-here"
        clipPathUnits="objectBoundingBox"
      >
      ...
    </clipPath>
    </defs>
  </svg>
 * ```
  - copy `<path>` in design and paste to `...` above
  - convert `d` of the `<path>` to new value with this tool: https://yoksel.github.io/relative-clip-path/
  - add your id to the `clipPath`
  - clip an element with this `style`:
  ```
  clipPath: "url(#your-id-here)", // id of the SVG above
  ```
 *
 */

export default function RewardBoxDaily() {
  const { t } = useTranslation();
  const [step, setStep] = useState<Steps | undefined>();
  const refCoinLabel = useRef<CoinLabelRef>(null);
  const [showLockAnimation, setShowLockAnimation] = useState(false); // should perform animation for components of step count-down?

  const [remaining, setRemaining] = useState(5);
  const [amount, setAmount] = useState(0);
  const { data, isLoading, refetch } = useGetRewardsInfo(RewardType.DAILY);
  const [claim, { isLoading: isClaiming }] = siteApi.useClaimRewardMutation();

  const [shouldSlideButton, setShouldSlideButton] = useState(false);

  useEffect(() => {
    if (data) {
      const { remainingSeconds } = data.daily;
      if (remainingSeconds !== 0) {
        setShouldSlideButton(true);
        setStep(Steps.COUNT_DOWN);
      } else {
        setStep(Steps.RECEIVE_CHEST);
      }
      setRemaining(remainingSeconds);
    }
  }, [data]);

  const onClaim = useCallback(async () => {
    try {
      const claimRes = await claim({ type: RewardType.DAILY }).unwrap();
      const amount = claimRes as ClaimDailyRewardResponse;
      setStep(Steps.CLAIM_CHEST);

      const timer = setTimeout(() => {
        setAmount(Number(amount));
        clearTimeout(timer);
      }, 1500);
    } catch (error: any) {
      toast.error(getErrorMessage(error, t));
      refetch();
    }
  }, [t, claim, refetch]);

  useEffect(() => {
    if (amount !== 0) {
      refCoinLabel.current?.count();
    }
  }, [amount]);
  const onCountUpEnd = useCallback(() => {
    setStep(Steps.RECEIVE_COINS);
    // longest animation when starting: (change opacity of coin-tick [1.5s])
    // animation when ending: (moving down [1.5s] delay [0.5s])
    // total = 3.5s

    // after 3.5s change to count down
    const timer = setTimeout(() => {
      refetch();
      setShowLockAnimation(true);
      setAmount(0);
      clearTimeout(timer);
    }, 2000);
  }, [refetch]);

  const handleOnTimerComplete = useCallback(() => {
    setStep(Steps.RECEIVE_CHEST);
  }, []);

  return (
    <RewardCard
      loading={isLoading}
      borderEffect={step === Steps.CLAIM_CHEST || step === Steps.RECEIVE_CHEST}
      className="animate-fade-in opacity-0 ![animation-delay:200ms]"
    >
      {!!data && (
        <>
          <div className="relative h-full w-full">
            {/* header layer */}
            <div className="absolute top-3 left-0 z-[3] w-full text-center text-sm font-bold uppercase leading-[22px] text-[#CCC2ED]">
              {t("daily surprise")}
            </div>
            {/* layer inset shadow */}
            <div
              className="absolute left-0 top-0 z-[4] h-[72%] w-full"
              style={{
                background: "radial-gradient(110% 80% at 50% 43%, rgba(32, 10, 81, 0) 37.68%, rgb(18, 7, 43) 76.53%)",
              }}
            ></div>
            {/* layer chest */}
            {/* closed chest */}

            {step === Steps.COUNT_DOWN && (
              <div
                className={clsx("absolute left-0 top-0 z-[1] flex h-[74%] w-full flex-col items-center justify-end", {
                  "animate-[raising-locked-chest_600ms_linear]": showLockAnimation,
                })}
              >
                <div className="relative aspect-[109/100] w-[70%]">
                  <div
                    className="absolute h-full w-full bg-[#210C52]"
                    style={{
                      maskImage: "url(/images/body-bg/closed-chest.png)",
                      maskSize: "contain",
                    }}
                  ></div>
                  <img
                    className="absolute h-full w-full object-contain opacity-50"
                    src="/images/body-bg/closed-chest.png"
                    alt="closed-chest"
                  />
                </div>
              </div>
            )}

            {/* available chest */}
            {step !== Steps.COUNT_DOWN && (
              <div
                className={clsx("absolute top-0 left-0 h-full w-full", {
                  "open-chest-container-disappear opacity-0": step === Steps.RECEIVE_COINS,
                  "animate-[show-available-chest_0.5s_linear]": step === Steps.RECEIVE_CHEST,
                })}
              >
                <div
                  className={clsx("absolute top-0 left-0 h-full w-full transition duration-500", {
                    "opacity-1 bg-[#1D104A]": step !== Steps.RECEIVE_COINS,
                    "opacity-1 !bg-[#142A2F]": step === Steps.RECEIVE_COINS,
                  })}
                >
                  <div className="absolute top-0 left-0 h-[72%] w-full bg-gradient-to-b from-[#8F790500] to-[#F5CF09] mix-blend-soft-light"></div>
                  <div className="absolute top-0 left-[50%] aspect-[380/214] h-[214px] w-[380px] translate-x-[-50%] mix-blend-luminosity">
                    <img
                      alt=" "
                      src="/images/body-bg/reward-card-bg.gif"
                      className="min-h-full min-w-full object-cover"
                    />
                  </div>
                </div>
                {/* chest */}
                {/* moving down 267px, why? to make the chest and coins have same speed. and 267px is max height of chest container */}
                <div
                  className={clsx("absolute left-0 top-0 z-[1] flex h-[74%] w-full flex-col items-center justify-end", {
                    "open-chest-components-moving-down translate-y-[267px]": step === Steps.RECEIVE_COINS,
                  })}
                >
                  <div className="relative aspect-[109/100] min-h-[100px] w-[70%] min-w-[100px]">
                    <img
                      className="absolute h-full w-full object-contain"
                      src="/images/body-bg/open-chest-lid.png"
                      alt="open-chest"
                    />
                  </div>
                </div>
                {/* coins */}
                <div
                  className={clsx("absolute left-0 bottom-[49%] z-[2] aspect-[165/117] min-h-[50%] w-full", {
                    "open-chest-components-moving-down translate-y-[267px]": step === Steps.RECEIVE_COINS,
                  })}
                >
                  <div className="absolute h-full w-full">
                    {/* coin shine */}
                    <div className="absolute bottom-[-60%] left-[50%] z-[1] aspect-[97/118] w-[58%] translate-x-[-50%] animate-[coin-shining_5s_linear_infinite] rounded-full bg-[radial-gradient(circle,rgba(255,239,110,1)_0%,rgba(255,255,255,0)_80%)] opacity-10"></div>

                    {/* mid coin */}
                    <div
                      className={clsx("absolute top-[97%] left-[50%] z-[3] aspect-square w-1/3", {
                        "translate-y-[-70%] transition duration-[1500ms] ease-linear":
                          step === Steps.CLAIM_CHEST || step === Steps.RECEIVE_COINS,
                      })}
                    >
                      <div
                        className={clsx("relative h-full w-full translate-x-[-50%]", {
                          "animate-[mid-coin-moving_5s_linear_infinite]": step === Steps.RECEIVE_CHEST,
                        })}
                      >
                        <Coin className="h-full w-full" />
                        <CoinTick
                          className={clsx("absolute top-0 left-0 z-[1] h-full w-full opacity-0", {
                            "opacity-100 transition duration-[1500ms] ease-linear": step === Steps.RECEIVE_COINS,
                          })}
                        />
                      </div>
                    </div>
                    {/* left coin */}
                    <div
                      className={clsx("absolute top-[86%] left-[16%] z-[2] aspect-square w-1/3", {
                        "translate-x-[-15%] translate-y-[-100%] scale-[1.15] transition duration-[1500ms] ease-linear":
                          step === Steps.CLAIM_CHEST || step === Steps.RECEIVE_COINS,
                      })}
                    >
                      <div
                        className={clsx("relative h-full w-full rotate-[-15deg] scale-50", {
                          "animate-[left-coin-moving_5s_linear_infinite]": step === Steps.RECEIVE_CHEST,
                        })}
                      >
                        <Coin className="h-full w-full" />
                        <CoinTick
                          className={clsx("absolute top-0 left-0 z-[1] h-full w-full opacity-0", {
                            "opacity-100 transition duration-[1500ms] ease-linear": step === Steps.RECEIVE_COINS,
                          })}
                        />
                      </div>
                    </div>
                    {/* right coin */}
                    <div
                      className={clsx("absolute top-[86%] right-[16%] z-[2] aspect-square w-1/3", {
                        "translate-x-[10%] translate-y-[-70%] scale-[1.15] transition duration-[1500ms] ease-linear":
                          step === Steps.CLAIM_CHEST || step === Steps.RECEIVE_COINS,
                      })}
                    >
                      <div
                        className={clsx("relative h-full w-full rotate-[12deg] scale-50", {
                          "animate-[right-coin-moving_5s_linear_infinite]": step === Steps.RECEIVE_CHEST,
                        })}
                      >
                        <Coin className="h-full w-full" />
                        <CoinTick
                          className={clsx("absolute top-0 left-0 z-[1] h-full w-full opacity-0", {
                            "opacity-100 transition duration-[1500ms] ease-linear": step === Steps.RECEIVE_COINS,
                          })}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className={clsx("absolute left-0 top-0 z-[3] flex h-[74%] w-full flex-col items-center justify-end", {
                    "open-chest-components-moving-down translate-y-[267px]": step === Steps.RECEIVE_COINS,
                  })}
                >
                  <div className="relative aspect-[109/100] min-h-[100px] w-[70%] min-w-[100px]">
                    <img
                      className="absolute h-full w-full object-contain"
                      src="/images/body-bg/open-chest-bottom.png"
                      alt="open-chest"
                    />
                  </div>
                </div>
              </div>
            )}

            {/* end layer chest */}

            {/* footer layer */}
            {/* height 36% (design: footer height 80px; card height 222px) */}
            <div
              className="absolute left-[-2px] bottom-[0] z-[5] h-[calc(36%-1px)] w-[calc(100%+3px)] translate-y-[1px]"
              style={{
                backgroundImage: "url('/images/body-bg/bg-reward-point-daily.svg')",
                backgroundSize: "cover",
              }}
            >
              <div className="relative top-4 h-[calc(100%-1rem)] w-full overflow-hidden">
                {/* gradient mask */}

                {/* content */}
                <div
                  className={clsx(
                    "absolute top-0 flex h-full w-full flex-col items-center justify-end p-1.5 min-[387px]:p-3 min-[456px]:pb-[18px] min-[524px]:pb-6 min-[596px]:pb-[30px]",
                    {
                      "animate-[raising-locked-chest_600ms_linear]": showLockAnimation,
                      "translate-y-[267px]": step !== Steps.COUNT_DOWN,
                    },
                  )}
                >
                  <TimerBar
                    remainingSeconds={remaining}
                    totalSeconds={data[RewardType.DAILY].periodInSeconds}
                    className={clsx("w-full")}
                    onComplete={handleOnTimerComplete}
                  />
                </div>
                {step !== Steps.COUNT_DOWN && (
                  <>
                    <div
                      className={clsx(
                        "absolute top-0 flex h-full w-full translate-y-[-100%] flex-col items-center justify-end p-1.5 min-[387px]:p-3 min-[456px]:pb-[18px] min-[524px]:pb-6 min-[596px]:pb-[30px]",
                        {
                          // slide up animation
                          "translate-y-[-7%] transition duration-[1s] ease-linear": step === Steps.CLAIM_CHEST,
                          // slide down animation
                          "translate-y-[100%] transition duration-[1s] ease-linear [transition-delay:1.5s]":
                            step === Steps.RECEIVE_COINS,
                        },
                      )}
                    >
                      <span>
                        <CoinLabel
                          startVal={0}
                          endVal={amount}
                          ref={refCoinLabel}
                          onCountEnd={onCountUpEnd}
                          type="up"
                          glowEffect
                        />
                      </span>
                    </div>
                    <div
                      className={clsx(
                        "absolute top-0 flex h-full w-full flex-col items-center justify-end p-1.5 min-[387px]:p-3 min-[456px]:pb-[18px] min-[524px]:pb-6 min-[596px]:pb-[30px]",
                        {
                          "translate-y-[100%] transition duration-[1s] ease-linear": step === Steps.CLAIM_CHEST,
                          "translate-y-[200%] transition duration-[1s] ease-linear": step === Steps.RECEIVE_COINS,
                          "animate-[claim-button-raising_0s_linear]":
                            step === Steps.RECEIVE_CHEST && !shouldSlideButton,
                          "animate-[claim-button-raising_400ms_linear]":
                            step === Steps.RECEIVE_CHEST && shouldSlideButton,
                        },
                      )}
                    >
                      <ClaimButton
                        loading={isClaiming}
                        onClick={onClaim}
                        status={RewardBoxStatus.CLAIMABLE}
                      />
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </>
      )}
    </RewardCard>
  );
}
