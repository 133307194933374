import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { FaTruckFast } from "react-icons/fa6";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { FaCirclePlusGradient } from "src/assets";
import { siteApi } from "src/main/api";
import { CheckoutCartResponse } from "src/main/api/site/Cart";
import { Button, Card, InputField, Page } from "src/main/components";
import { FULFILMENT_TYPE } from "src/main/contants";
import { useAsyncTask, useCart, useProfileBalance } from "src/main/hooks";
import productSlice from "src/main/store/product/slices";
import { CartItemModel } from "src/main/types";
import { getErrorMessage, handleApiResponse, logger, Paths } from "src/main/utils";
import { AddressInfo, CheckoutBottomBar, CheckoutItemList } from "./components";

const CheckoutPage: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { refetch: refetchBalance } = useProfileBalance();

  const { cart, grandTotal, updateCart, checkoutShippingInfo, checkoutCartItems } = useCart();

  const isAllVirtualItems = useMemo(() => {
    return checkoutCartItems.every((i) => {
      return i.variant?.product?.fulfilmentType === FULFILMENT_TYPE.Virtual;
    });
  }, [checkoutCartItems]);

  const [runCheckoutCart, isRunningCheckoutTask] = useAsyncTask("update/cart");

  const [createShippingAddress] = siteApi.useCreateShippingAddressMutation();

  const [checkoutCart] = siteApi.useCheckoutCartMutation();

  const [remarks, setRemarks] = useState<string>("");

  const addressesRes = siteApi.useListShippingAddressQuery({
    meta: { limit: 100, offset: 0 },
  });

  const { defaultAddr, addressList } = useMemo(() => {
    let addressList = addressesRes.data?.entries ?? [];
    let defaultAddr = addressList.find((addr) => addr.isDefault);
    return { addressList, defaultAddr };
  }, [addressesRes]);

  const onCheckoutCart = useCallback(() => {
    runCheckoutCart(async () => {
      if (!checkoutCartItems.length) {
        toast.error(t("Cannot checkout cart, please try again later"));
        return;
      }
      if (!checkoutShippingInfo && !isAllVirtualItems) {
        toast.error(t("Please select a shipping address"));
        return;
      }

      await updateCart({
        data: {
          items: cart?.data.items ?? [],
          version: "1",
          shippingInfo: {
            ...(checkoutShippingInfo ?? {}),
            remarks,
          },
        },
      }).unwrap();

      if (!isAllVirtualItems && !defaultAddr && !!checkoutShippingInfo) {
        await createShippingAddress({
          ...checkoutShippingInfo!,
          isDefault: true,
        });
      }

      // const cartIds = cart?.data?.items?.map((caritem) => caritem.variantId);
      // const checkoutCartIds = checkoutCartItems?.map((caritem) => caritem.variantId);

      const checkoutRes = await checkoutCart({
        checkoutItems: checkoutCartItems.map((ite) => ite.variantId),
      });

      const { data, error } = handleApiResponse<CheckoutCartResponse>(checkoutRes);

      if (data) {
        await refetchBalance();
        dispatch(productSlice.actions.updateCheckoutCart([]));
        navigate(Paths.Private.RedeemSuccessful, { state: data.model });
      }
      if (error) {
        navigate(Paths.Private.RedeemFailed, { state: error });
      }
    }).catch((er) => {
      logger._console.log({ er });
      toast.error(getErrorMessage(er, t));
    });
  }, [
    runCheckoutCart,
    checkoutCartItems,
    checkoutShippingInfo,
    isAllVirtualItems,
    updateCart,
    cart?.data.items,
    remarks,
    defaultAddr,
    checkoutCart,
    t,
    createShippingAddress,
    refetchBalance,
    dispatch,
    navigate,
  ]);

  const checkoutItems = useMemo(() => {
    return (cart?.data?.items ?? [])
      .map((item) => {
        if (checkoutCartItems.map((ite) => ite.variantId).includes(item.variantId)) return item;
        return null;
      })
      .filter((ite) => !!ite) as CartItemModel[];
  }, [cart, checkoutCartItems]);

  useEffect(() => {
    if (!checkoutShippingInfo && defaultAddr) {
      dispatch(productSlice.actions.updateShippingInfo(defaultAddr));
    }
    if (!!checkoutShippingInfo) {
      // update new data from address list to checkoutShippingInfo
      if (addressList.length) {
        const newShippingInfo = addressList.find((addr) => addr.id === checkoutShippingInfo.id);
        if (newShippingInfo) {
          dispatch(productSlice.actions.updateShippingInfo(newShippingInfo));
        }
      }
    }
  }, [defaultAddr, cart, checkoutShippingInfo, dispatch, addressList]);

  const handleAddShippingAddress = useCallback(() => {
    navigate(Paths.Private.AddressList);
  }, [navigate]);

  return (
    <Page
      className="mx-auto mb-20 text-white"
      paddingX
      footer={false}
      title={t("Review Your Order")}
      mobileView
    >
      <Card
        className="space-y-6 !rounded-lg px-4 pt-3 pb-6"
        variant="container-gradient"
      >
        {/*SHIPPING INFO FOR PHYSICAL ITEMS*/}
        {!isAllVirtualItems && (
          <>
            {!!checkoutShippingInfo && <AddressInfo address={checkoutShippingInfo} />}

            {!checkoutShippingInfo && (
              <div className="py-2">
                <Button
                  className="w-full uppercase"
                  variant="secondary"
                  size="lg"
                  icon={<FaCirclePlusGradient className="h-5 w-5" />}
                  onClick={handleAddShippingAddress}
                >
                  {t("Add shipping address")}
                </Button>
              </div>
            )}
          </>
        )}

        <div className="my-4 w-full">{!!checkoutItems.length && <CheckoutItemList variants={checkoutItems} />}</div>

        <div className="flex items-center space-x-1.5">
          <FaTruckFast className="h-5 w-5 text-content-secondary" />
          <span className="text-xxs text-content-secondary">{t("Delivery estimated in two weeks")}</span>
        </div>

        <InputField
          type="multiline"
          name="note"
          label={t("Order note")}
          value={remarks}
          onChange={(e) => setRemarks(e.currentTarget.value)}
          variant="filled"
          className="mt-6"
        />
      </Card>

      <CheckoutBottomBar
        total={grandTotal}
        onRedeem={onCheckoutCart}
        loading={isRunningCheckoutTask}
      />
    </Page>
  );
};

export default CheckoutPage;
