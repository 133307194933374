import { useTranslation } from "react-i18next";

const CopyRight: React.FC = () => {
  const { t } = useTranslation();
  return (
    <div className="w-full text-center text-xxs font-normal leading-4 text-white">
      <p>©2023 ILOVEVIP</p>
      <p>{t("All rights reserved")}</p>
    </div>
  );
};

export default CopyRight;
