import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
import { BannerModel, BrandModel, PopupBannerModel, PromotionBannerModel } from "src/main/models";
import { ProfileType } from "./types";

export type UserState = {
  profile?: ProfileType | null;
  brands?: BrandModel[] | null;
  promoBanner?: PromotionBannerModel[] | null;
  popupBanners?: PopupBannerModel[] | null;
};

export const initialState: UserState = {
  profile: {},
  brands: null,
  promoBanner: null,
  popupBanners: null,
};

export const userSlice = createSlice({
  name: "updateUser",
  initialState,
  reducers: {
    updateUserProfile(state, action: PayloadAction<ProfileType | null>) {
      state.profile = action.payload;
    },
    updateUserBrand(state, action: PayloadAction<BrandModel[] | null>) {
      state.brands = action.payload;
    },
    updatePromoBanner(state, action: PayloadAction<PromotionBannerModel[] | null>) {
      state.promoBanner = action.payload;
    },
    updatePopupBanners(state, action: PayloadAction<PopupBannerModel[] | null>) {
      state.popupBanners = action.payload;
    },
    updateBanners(state, action: PayloadAction<BannerModel[] | null>) {
      // filter banners
      let promoBanners: PromotionBannerModel[] = [];
      let popupBanners: PopupBannerModel[] = [];
      action.payload?.forEach((banner: BannerModel) => {
        switch (banner.category) {
          case "banner":
            promoBanners.push(banner);
            break;
          case "pop-up":
            popupBanners.push(banner);
            break;
        }
      });
      state.promoBanner = promoBanners;
      state.popupBanners = popupBanners;
    },
  },
});

// export const { updateUserProfile, updateUserBrand } = userSlice.actions;
export default userSlice;
// export default user.reducer;
// export const { setUserProfile, logout, setFetchBannerFlag } = userProfile.actions
