import { CategoryModel, Meta, ProductModel, QueryResult } from "src/main/types";
import { parseMeta } from "src/main/utils";
import { BuilderProp, require200Status } from "..";

export interface GetCategoryResponse {
  category: CategoryModel[];
}

export interface GetCommonListRequest {
  meta?: Partial<Meta>;
}

export const getCategories = (builder: BuilderProp) => {
  return builder.query<GetCategoryResponse, GetCommonListRequest>({
    providesTags: ["product"],
    query: () => ({
      url: `/member/category/list`,
      validateStatus: require200Status,
    }),
    transformResponse: (response: QueryResult<GetCategoryResponse>) => {
      return response.result;
    },
  });
};

export interface ListProductsResponse {
  entries: ProductModel[];
  meta: Meta;
}

export interface ListProductsRequest {
  meta?: Meta;
}

export const listProducts = (builder: BuilderProp) => {
  return builder.query<ListProductsResponse, ListProductsRequest>({
    providesTags: ["product"],
    query: ({ meta }) => ({
      url: `/member/product/list${parseMeta(meta)}`,
      validateStatus: require200Status,
    }),
    transformResponse: (response: QueryResult<ListProductsResponse>) => {
      return response.result;
    },
  });
};

export interface ListCategoryProductRequest {
  meta: Meta;
  slug: string;
}

export interface ListCategoryProductResponse {
  entries: ProductModel[];
  meta: Meta;
}

export const listCategoryProducts = (builder: BuilderProp) => {
  return builder.query<ListProductsResponse, ListCategoryProductRequest>({
    providesTags: ["product"],
    query: ({ meta, slug }) => ({
      url: `/member/category/${slug}/product/list${parseMeta(meta)}`,
      validateStatus: require200Status,
    }),
    transformResponse: (response: QueryResult<ListProductsResponse>) => {
      return response.result;
    },
  });
};

export interface GetProductRequest {
  slug: string;
}

export interface GetProductResponse {
  product: ProductModel;
}

export const getProductDetail = (builder: BuilderProp) => {
  return builder.query<GetProductResponse, GetProductRequest>({
    providesTags: ["product"],
    query: ({ slug }) => ({
      url: `/member/product/${slug}/detail`,
      validateStatus: require200Status,
    }),
    transformResponse: (response: QueryResult<GetProductResponse>) => {
      return response.result;
    },
  });
};
