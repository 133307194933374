import BigNumber from "bignumber.js";
import { clsx } from "clsx";
import { useTranslation } from "react-i18next";
import { IcoPointV4 } from "src/assets";
import { Button, FloatingBar, PropsWithClassName } from "src/main/components";
import { BOTTOM_NAVIGATION_HEIGHT } from "src/main/contants";
import { fNumber } from "src/main/utils";

interface CheckoutBottomBarProps extends PropsWithClassName {
  total: number | BigNumber;
  onRedeem?: () => void;
  loading?: boolean;
}

const CheckoutBottomBar = ({ total, className, onRedeem, loading }: CheckoutBottomBarProps) => {
  const { t } = useTranslation();

  return (
    <FloatingBar
      placement="bottom"
      autoOffset
      maxWidth="mobile"
      offset={BOTTOM_NAVIGATION_HEIGHT}
      contentClassName={clsx("flex space-x-4 border-t border-gray-300 bg-gray-50 px-4 pb-4.5 pt-2", className)}
    >
      <div className="flex flex-grow w-full flex-wrap items-center mr-12">
        <span className="mr-2 text-xxs uppercase text-gray-500">{t("total")}:</span>
        <div className="flex items-center space-x-1">
          <IcoPointV4 className="h-5 w-5" />
          <span className="text-lg font-bold text-gray-700">{fNumber(total)}</span>
        </div>
      </div>
      <Button
        variant="violet"
        className="uppercase"
        onClick={onRedeem}
        loading={loading}
        size="md"
      >
        {t("redeem now")}
      </Button>
    </FloatingBar>
  );
};

export default CheckoutBottomBar;
