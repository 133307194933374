import { t } from "i18next";
import { OrderDetailItemListType } from "src/main/store/types";

function ItemOrderDetailCard(props: OrderDetailItemListType) {
  const { quantity, sub_total, image, name, logistics, is_exchange_game_credit } = props;

  const formatShippingLink = (text: string) => {
    const hyperlinkURLs = (text: string): string => {
      const urlRegex = /(http:\/\/|https:\/\/)[^\s]+/g;
      return text.replace(
        urlRegex,
        (url) => `<a target="_blank" rel="noopener noreferrer" class="underline" href="${url}">${url}</a>`,
      );
    };

    const splitText = text
      .split(". ")
      .map((sentence, index, array) =>
        index < array.length - 1 ? hyperlinkURLs(sentence) + ".<br/>" : hyperlinkURLs(sentence),
      );

    const htmlText = splitText.join("");

    return (
      <div
        className="break-words text-sm font-normal text-white md:text-2xl"
        dangerouslySetInnerHTML={{ __html: htmlText }}
      />
    );
  };

  const renderExchangeGameCredit = () => (
    <div className="mt-3 flex rounded-lg bg-[#1E0E4A] p-2">
      <div className="relative flex w-1/3 items-center p-2 md:mt-0">
        <img
          src="/images/icon/game-credit.png"
          className="w-full object-contain"
          style={{ maxHeight: 100 }}
          alt="game_credit"
        />
      </div>
      <div className="flex w-2/3 flex-col justify-center space-y-2 p-2 text-base text-white md:text-lg">
        <p>{name}</p>
        <div className="flex flex-wrap items-center justify-between font-bold">
          <div className="flex items-center space-x-2">
            <img
              src="/images/icon/heart_icon_yellow.svg"
              width="18px"
              height="18px"
              alt="heart-icon"
            />
            <span className="text-[rgba(246,189,102,1)]">{Math.trunc(parseInt(sub_total)).toLocaleString()}</span>
          </div>
          <div>{t("Qty")} : {quantity}</div>
        </div>
      </div>
    </div>
  );

  if (is_exchange_game_credit) return renderExchangeGameCredit();

  return (
    <div className="mt-3 flex rounded-lg bg-[#1E0E4A] p-2">
      <div className="relative flex w-1/4 items-center p-2 md:mt-0">
        {/* <img src={images[0].url} className="object-contain w-full h-2/4  bg-white" style={{ borderRadius: "10px" }} alt="Card image" /> */}
        <img
          src={image}
          className="w-full bg-white object-contain"
          style={{ borderRadius: "10px" }}
          alt="product_detail"
        />
      </div>
      {/* {product.images ? <img src={product.images[0].url} className="w-1/3 p-4" style={{ borderRadius: "30px" }} alt="Card image" /> : <></>} */}
      <div className="flex w-3/4 flex-col justify-center space-y-2 break-words p-2 text-base text-white md:text-lg">
        <p>{name}</p>
        <div className="flex items-center justify-between font-bold">
          <div className="flex items-center space-x-2">
            <img
              src="/images/icon/heart_icon_yellow.svg"
              width="18px"
              height="18px"
              alt="heart-icon"
            />
            <span className="text-[rgba(246,189,102,1)]">{Math.trunc(parseInt(sub_total)).toLocaleString()}</span>
          </div>
          <div>{t("Qty")}: {quantity}</div>
        </div>

        {/* {tracking_number_id && <p className="mt-2 capitalize">{t("Tracking No", { ns: ['main', 'home'] })}: {tracking_number_id}

                    <button
                        className="
                                         bg-gradient-to-r
                                         from-[rgba(0,48,172,1)]
                                         to-[rgba(81,140,255,1)]
                                         relative
                                         px-1
                                         ml-1
                                         text-center
                                         text-sm
                                         text-white
                                         border-0
                                         rounded-lg"
                        onClick={() => copyHandler(tracking_number_id)}>
                        <i><FontAwesomeIcon icon={faCopy} /></i>
                    </button>
                </p>} */}
        {logistics && formatShippingLink(logistics.tracking_url)}
      </div>
    </div>
  );
}

export default ItemOrderDetailCard;
