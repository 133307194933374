import { clsx } from "clsx";
import { DetailedHTMLProps, HTMLAttributes, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import IcoSpinner from "src/assets/IcoSpinner";
import { PropsWithThemeConfig, ThemeSize } from "src/main/components";
import { styledClassBuilder } from "src/main/utils";

type ButtonVariantType =
  | "orange-yellow"
  | "blue-violet"
  | "violet"
  | "green"
  | "secondary"
  | "ghost"
  | "bare"
  | "ghost-inverted";

interface ButtonProps
  extends DetailedHTMLProps<HTMLAttributes<HTMLButtonElement>, HTMLButtonElement>,
    PropsWithThemeConfig<ThemeSize, ButtonVariantType> {
  icon?: React.ReactNode;
  iconPlacement?: "left" | "right";
  loading?: boolean;
  disabled?: boolean;
  className?: string;
  type?: "button" | "submit" | "reset";
  href?: string;
  navigateReplace?: boolean;
  outlined?: boolean;
  delayOnClick?: number;
}

/**
 * ILV V4 Button
 * Design: https://www.figma.com/file/Vlxq6T7OH0xnepcCZw6y9Y/(WIP)-I%E2%99%A1VIP4?type=design&node-id=2-1126&mode=design&t=aJcKQvxqTEXpmOqG-0
 */
const Button = ({
  outlined = false,
  className,
  loading,
  variant = "violet",
  size = "md",
  icon,
  iconPlacement = "left",
  children,
  disabled,
  type,
  href,
  navigateReplace = false,
  onClick,
  delayOnClick = 300,
  ...props
}: ButtonProps) => {
  const navigate = useNavigate();

  const handleOnClick = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      if (loading || disabled) {
        return;
      }

      onClick?.(e);
      if (!!href) {
        navigate(href, {
          replace: navigateReplace,
        });
      }
    },
    [loading, disabled, onClick, href, navigate, navigateReplace],
  );

  return (
    <button
      {...props}
      className={clsx(
        className,
        {
          "pointer-events-none": loading,
        },
        "no-tap-highlight group flex w-full items-center justify-center space-x-2 rounded-lg border-2 transition-all disabled:pointer-events-none",
        {
          "btn-gradient-outlined capitalize": outlined,
          [OUTLINED_SIZE_MAP[size]]: outlined,
          [buildStyledClass({ size, variant })]: !outlined,
        },
      )}
      disabled={disabled}
      type={type}
      onClick={handleOnClick}
    >
      {loading ? (
        <>
          <IcoSpinner className="!mx-0 h-5 w-5" />
        </>
      ) : (
        <>
          {!!icon && iconPlacement === "left" && icon}
          <span className={clsx(VARIANT_TEXT_CLASS_MAP[variant])}>{children}</span>
          {!!icon && iconPlacement === "right" && icon}
        </>
      )}
    </button>
  );
};

const VARIANT_CLASS_MAP: Record<ButtonVariantType, string> = {
  "orange-yellow": "btn-orange border-hidden",
  "blue-violet": "border-hidden bg-[linear-gradient(85deg,#0030AC_-1.07%,#9822F4_99.12%)]",
  violet:
    "bg-[radial-gradient(53.55%_100%_at_46.45%_0%,#7F3EED_0%,#5E25B7_100%)] border-[1px] [border-image-source:linear-gradient(180deg,rgba(255,255,255,0.24)_0%,rgba(255,255,255,0)_100%)] shadow-[0px_4px_4px_0px_#00000040,0px_0px_20px_0px_#8B5CF68F,0px_4px_12px_0px_#FFFFFF33_inset]",
  green:
    "bg-[radial-gradient(50%_100%_at_50%_0%,#A3E635_0%,#65A30D_100%)] border border-[#FFFFFF3F] shadow-[0px_4px_4px_0px_#00000040,0px_0px_20px_0px_#84CC168F,0px_4px_12px_0px_#FFFFFF33_inset]",
  secondary: "border border-midnight-500 bg-overlay",
  ghost: "bg-transparent border border-violet-500",
  bare: "bg-transparent border-hidden outline-none ring-0",
  "ghost-inverted": "bg-violet-500 text-white",
};

const VARIANT_CLASS_ACTIVE_MAP: Record<ButtonVariantType, string> = {
  "orange-yellow": "active:brightness-110",
  "blue-violet": "active:brightness-110",
  violet:
    "active:bg-[radial-gradient(53.55%_100%_at_46.45%_0%,#6D28D9_0%,#4C1D95_100%)] active:shadow-[0px_4px_12px_0px_#00000033_inset] active:translate-y-[1px]",
  green: "active:shadow-[#408b34_0_3px_7px_inset] border-b-2 active:translate-y-[1px]",
  secondary: "active:shadow-[0px_4px_12px_0px_#00000033_inset]",
  ghost: "",
  bare: "border-hidden",
  "ghost-inverted": "",
};

const VARIANT_CLASS_DISABLED_MAP: Record<ButtonVariantType, string> = {
  "orange-yellow": "",
  "blue-violet": "",
  violet: "disabled:bg-onBase  disabled:border-none disabled:shadow-none",
  green:
    "disabled:bg-[radial-gradient(50%_100%_at_50%_0%,#6B7280_0%,#4B5563_100%)] disabled:border-none disabled:shadow-none",
  secondary: "disabled:bg-onBase",
  ghost: "",
  bare: "",
  "ghost-inverted": "",
};

const VARIANT_TEXT_CLASS_MAP: Record<ButtonVariantType, string> = {
  "blue-violet": "text-white group-disabled:text-opacity-30",
  violet: "text-[#FFFFFFCC] group-disabled:text-opacity-30",
  green: "text-white group-disabled:text-opacity-30",
  secondary: "text-[#FFFFFFCC] group-disabled:text-opacity-30",
  ghost: "text-violet-500",
  bare: "text-white",
  "ghost-inverted": "text-white",
  "orange-yellow": "text-white",
};

const combineVariantClasses = (variant: ButtonVariantType) => {
  return [VARIANT_CLASS_MAP[variant], VARIANT_CLASS_ACTIVE_MAP[variant], VARIANT_CLASS_DISABLED_MAP[variant]].join(" ");
};

const OUTLINED_SIZE_MAP: Record<ThemeSize, string> = {
  xs: "text-xs font-bold px-[12px] h-7 rounded-md",
  sm: "text-xs font-bold px-[6px] h-8",
  md: "text-sm font-bold  px-[6px] h-10",
  lg: "text-base font-bold px-[6px] h-10",
  xl: "text-base font-bold px-[24px] py-[12px] h-[52px]",
};

const buildStyledClass = styledClassBuilder<ThemeSize, ButtonVariantType>({
  size: OUTLINED_SIZE_MAP,
  variant: {
    "orange-yellow": combineVariantClasses("orange-yellow"),
    "blue-violet": combineVariantClasses("blue-violet"),
    green: combineVariantClasses("green"),
    secondary: combineVariantClasses("secondary"),
    ghost: combineVariantClasses("ghost"),
    bare: combineVariantClasses("bare"),
    "ghost-inverted": combineVariantClasses("ghost-inverted"),
    violet: combineVariantClasses("violet"),
  },
});

export default Button;
