import { clsx } from "clsx";
import { useMemo } from "react";
import { useLocation } from "react-router";
import { Paths } from "src/main/utils";

interface PageTransitionOverlayProps {
  animationClassName?: string;
}

const PRIVATE_PATHS = Object.values(Paths.Private);

/**
 * Display a full screen overlay to do fade out transition between pages
 * Prevent re-rendering of the whole page, safer than using transition-group which can cause re-rendering problems.
 */
function PageTransitionOverlay({ animationClassName = "animate-fade-out" }: PageTransitionOverlayProps) {
  const location = useLocation();

  const overlayClassName = useMemo(() => {
    if (PRIVATE_PATHS.includes(location.pathname)) {
      return "z-[40]";
    }
    return "z-[1030]";
  }, [location.pathname]);

  return (
    <div
      key={location.pathname}
      className={clsx(
        "pointer-events-none fixed top-0 right-0 h-screen w-screen bg-primary-700 lg:hidden",
        animationClassName,
        overlayClassName,
      )}
    />
  );
}

export default PageTransitionOverlay;
