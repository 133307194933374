import clsx from "clsx";
import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useTypedSelector } from "src/main/hooks";
import useActions from "src/main/hooks/useActions";

interface PlayNowIconProps {}

const PlayNowIcon: React.FC<PlayNowIconProps> = () => {
  const { t } = useTranslation();
  const actions = useActions();
  const state = useTypedSelector((state) => state);
  const active = useMemo(() => state.layout.playnow.launch, [state.layout.playnow.launch]);

  const clickHandler = useCallback(() => {
    // toggle play now modal
    const launchState = !state.layout.playnow.launch;
    actions.launchPlaynow({ launch: launchState });
  }, [state, actions]);

  return (
    <div
      className="no-tap-highlight group relative flex h-[60px] w-16 cursor-pointer select-none flex-col items-center gap-0.5 bg-transparent pt-[8px]"
      onClick={clickHandler}
    >
      {/* layer 0 */}
      <div className="absolute top-0 left-0 bottom-0 right-0 z-[-1] h-[60px] w-16"></div>

      {active && (
        <div className="absolute top-0 left-0 bottom-0 right-0 z-[2] flex h-[60px] w-16 flex-col justify-between">
          <div className="h-[1.6px] w-16 rounded-lg"></div>
          <div
            className="mx-auto h-5 w-[60px] lg:hidden"
            style={{
              background: `radial-gradient(
                50.52% 83.64% at 50.52% 100%,
                #fff5c1 0%,
                rgba(246, 161, 146, 0.3) 61.48%,
                rgba(254, 1, 68, 0) 100%
              )`,
            }}
          ></div>
        </div>
      )}

      {/*  */}
      <span className="relative h-[25px] w-[25px]">
        <div
          className="absolute top-[1px] left-1/2 -translate-y-1/2 -translate-x-1/2 text-center lg:-translate-y-1"
          onClick={clickHandler}
        >
          <div
            className="h-10 w-10 rounded-full lg:h-[30px] lg:w-[30px]"
            style={{
              background: "url('/images/icon/ilv-playnow.svg') lightgray 50% / cover no-repeat",
            }}
          ></div>
          {/* </div> */}
        </div>
      </span>
      <p
        className={clsx("text-xxs capitalize lg:uppercase", {
          "font-bold": active,
        })}
      >
        {t("play now")}
      </p>
    </div>
  );
};

PlayNowIcon.defaultProps = {};

export default PlayNowIcon;
