import { Transition } from "@headlessui/react";
import { clsx } from "clsx";
import React, { PropsWithChildren, useEffect, useState } from "react";
import { CloseButton } from "src/main/components";
import {
  TRANSITION_IN_FADE,
  TRANSITION_IN_SLIDE_DOWN,
  TRANSITION_IN_SLIDE_LEFT,
  TRANSITION_IN_SLIDE_RIGHT,
  TRANSITION_IN_SLIDE_UP,
  TRANSITION_OUT_FADE,
  TRANSITION_OUT_SLIDE_DOWN,
  TRANSITION_OUT_SLIDE_LEFT,
  TRANSITION_OUT_SLIDE_RIGHT,
  TRANSITION_OUT_SLIDE_UP,
} from "src/main/contants";
import { SimpleMap } from "src/main/utils";

type DrawerPlacement = "top" | "bottom" | "left" | "right";

interface DrawerProps extends PropsWithChildren {
  show: boolean;
  onClose: () => void;
  className?: string;
  showCloseButton?: boolean;
  closeButton?: React.ReactNode;
  title?: string | React.ReactNode;
  placement?: DrawerPlacement;
  contentClassName?: string;
  backdropClassName?: string;
  titleClassName?: string;
  preload?: boolean;
  onBackdropClick?: () => void;
}

const TRANSITION_MAP: Record<DrawerPlacement, SimpleMap> = {
  top: {
    ...TRANSITION_IN_SLIDE_DOWN,
    ...TRANSITION_OUT_SLIDE_UP,
  },
  bottom: {
    ...TRANSITION_IN_SLIDE_UP,
    ...TRANSITION_OUT_SLIDE_DOWN,
  },
  left: {
    ...TRANSITION_IN_SLIDE_RIGHT,
    ...TRANSITION_OUT_SLIDE_LEFT,
  },
  right: {
    ...TRANSITION_IN_SLIDE_LEFT,
    ...TRANSITION_OUT_SLIDE_RIGHT,
  },
};

const Drawer = ({
  show,
  onClose,
  showCloseButton = true,
  title,
  placement = "bottom",
  backdropClassName,
  contentClassName,
  className,
  children,
  closeButton,
  preload,
  onBackdropClick = onClose,
  titleClassName,
}: DrawerProps) => {
  const [isShow, setIsShow] = useState(show);

  useEffect(() => {
    setIsShow(show);
  }, [show]);

  // Disable page scrolling when drawer is open
  useEffect(() => {
    if (isShow) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [isShow]);

  return (
    <Transition
      show={isShow}
      onMouseDown={(e: React.MouseEvent) => {
        e.preventDefault();
      }}
      className={clsx(
        "fixed top-0 left-0 z-[60] h-full w-full bg-primary-700 bg-opacity-50 overflow-y-hidden",
        className,
      )}
      unmount={!preload}
      appear
    >
      <Transition.Child
        className={clsx("absolute top-0 right-0 h-full w-full bg-white bg-opacity-20", backdropClassName)}
        {...TRANSITION_IN_FADE}
        {...TRANSITION_OUT_FADE}
        unmount={!preload}
        appear
        onClick={onBackdropClick}
      />
      <Transition.Child
        className={clsx(
          "z-50 flex flex-col items-center justify-start bg-surface outline-none drop-shadow-lg overflow-x-hidden overflow-y-hidden focus:outline-none",
          contentClassName,
        )}
        {...TRANSITION_MAP[placement]}
        unmount={!preload}
        appear
      >
        <div className="relative mx-auto flex max-h-full w-full flex-1 flex-col rounded-lg lg:max-w-2xl">
          {(!!title || showCloseButton) && !closeButton && (
            <div className="flex w-full flex-shrink-0 flex-col justify-center gap-4 text-center text-white">
              <div className="relative mx-4 mb-4 mt-5 flex items-center justify-center">
                {!!title && <h4 className={clsx("text-2xl leading-8", titleClassName)}>{title}</h4>}
                {showCloseButton && (
                  <CloseButton
                    onClick={onClose}
                    className="absolute -right-1 w-fit flex-shrink-0 self-start rounded-full"
                  />
                )}
              </div>
            </div>
          )}
          {closeButton}
          {children}
        </div>
      </Transition.Child>
    </Transition>
  );
};

export default Drawer;
