import { clsx } from "clsx";
import { ImgHTMLAttributes } from "react";

interface CardLogoProps extends ImgHTMLAttributes<HTMLImageElement> {
  float?: boolean;
  wrapperClassName?: string;
}

function CardLogo({ float = false, wrapperClassName, ...imgProps }: CardLogoProps) {
  return (
    <div
      className={clsx("w-full", wrapperClassName, {
        "absolute left-0 -translate-y-[115%]": float,
      })}
    >
      <img
        alt="card-logo"
        {...imgProps}
        className={clsx("mx-auto", imgProps.className)}
      />
    </div>
  );
}

export default CardLogo;
