import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { FaCircleCheck, FaClone } from "react-icons/fa6";
import { useLocation } from "react-router";
import { Button, Card, Page } from "src/main/components";
import { OrderHistoryModel } from "src/main/types";
import { copyToClipboard, Paths, truncate } from "src/main/utils";
import { AnimatedGiftBox, RedeemedOrderDetail } from "./components";

const RedeemSuccessfulPage: React.FC = () => {
  const { t } = useTranslation();
  const { state: orderHistory } = useLocation() as {
    state: OrderHistoryModel[];
  };

  /**
   * Combine all order items into first order
   */
  const orderDetail = useMemo(() => {
    let order = orderHistory?.[0];
    // combine all order items into first order
    if (order) {
      order.orderItems = orderHistory?.map((order) => order.orderItems).flat();
    }
    // combine total price
    if (order) {
      order.total = orderHistory?.reduce((acc, order) => acc + parseInt(order.total), 0).toString();
    }
    // combine order id
    if (order) {
      order.id = orderHistory?.map((order) => order.id).join(",");
    }
    return order;
  }, [orderHistory]);

  const shortOrderId = useMemo(() => truncate(orderDetail.id, 0, 8), [orderDetail.id]);

  return (
    <Page
      title={`${t("redeemed successfully")}!`}
      titleClassName="capitalize"
      className="text-white"
      paddingX
      mobileView
      footer={false}
    >
      {orderDetail ? (
        <>
          <Card
            variant="container-gradient"
            className="p-4"
            rounded="lg"
          >
            <Card
              variant="solid-overlay"
              rounded="lg"
              border={false}
              className="px-4 pt-2 pb-4"
            >
              <div className="flex items-center space-x-3">
                <AnimatedGiftBox />
                <div className="flex flex-col">
                  <h2 className="flex items-center text-base font-bold text-content-primary">
                    {t("Order Placed")}
                    <FaCircleCheck className="ml-1 inline-block h-3.5 w-3.5 text-content-positive-primary" />
                  </h2>
                  <p className="text-xxs text-content-secondary">{t("We are now preparing your package")}</p>
                </div>
              </div>
              <Button
                className="mt-2 uppercase"
                href={Paths.Private.Home}
              >
                {t("back to homepage")}
              </Button>
            </Card>

            <Card
              className="mt-8 pb-8"
              rounded="lg"
              header={
                <div className="flex items-center">
                  <span className="text-xs font-bold text-content-secondary">{t("Your Order ID")}:</span>
                  <span className="ml-1 text-xs font-normal uppercase text-content-primary">{shortOrderId}</span>
                  <button
                    className="ml-auto flex h-7 w-7 items-center justify-center active:text-content-secondary"
                    onClick={async () => {
                      await copyToClipboard(orderDetail.id);
                    }}
                  >
                    <FaClone className="h-3.5 w-3.5" />
                  </button>
                </div>
              }
              variant="solid-overlay"
              border={false}
            >
              <RedeemedOrderDetail order={orderDetail} />
            </Card>
          </Card>
        </>
      ) : (
        <div>
          <div className="flex h-20 w-full items-center justify-center text-center">
            <p className="text-2xl font-bold text-white">There is no bill</p>
          </div>
        </div>
      )}
    </Page>
  );
};

export default RedeemSuccessfulPage;
