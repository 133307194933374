import { clsx } from "clsx";
import { forwardRef, useCallback, useImperativeHandle, useRef, useState } from "react";
import { useCountUp } from "react-countup";
import { ID_TOP_BAR_COIN_ICON } from "src/main/contants";
import { useBreakPoint } from "src/main/hooks";
import { fNumber, generateFlyingCoins } from "src/main/utils";

interface CoinLabelProps {
  startVal: number;
  endVal: number;
  onCountEnd?: () => void;
  type?: "up" | "down";
  glowEffect?: boolean;
  flyingCoinsEffect?: boolean;
}

export interface CoinLabelRef {
  count: () => void;
}

const NUMBER_OF_FLYING_COINS = 5;
const COIN_SIZE_DESKTOP = 32;
const COIN_SIZE_MOBILE = 20;

const CoinLabel = forwardRef<CoinLabelRef, CoinLabelProps>(
  ({ startVal, endVal, onCountEnd, type = "down", glowEffect, flyingCoinsEffect = true }: CoinLabelProps, ref) => {
    const countUpRef = useRef(null);
    const coinIconRef = useRef<HTMLDivElement>(null);
    const { isMobile } = useBreakPoint();

    const renderFlyingCoins = useCallback(() => {
      if (coinIconRef.current) {
        const topBarCoinIcon = isMobile ? ID_TOP_BAR_COIN_ICON.MOBILE : ID_TOP_BAR_COIN_ICON.DESKTOP;
        const targetPosition = document.getElementById(topBarCoinIcon)?.getBoundingClientRect();
        if (targetPosition) {
          generateFlyingCoins(coinIconRef.current, targetPosition, NUMBER_OF_FLYING_COINS, {
            source: {
              width: COIN_SIZE_DESKTOP,
              height: COIN_SIZE_DESKTOP,
            },
            target: {
              width: isMobile ? COIN_SIZE_MOBILE : COIN_SIZE_DESKTOP,
              height: isMobile ? COIN_SIZE_MOBILE : COIN_SIZE_DESKTOP,
            },
          });
        }
      }
    }, [isMobile]);

    const handleOnCountEnd = useCallback(() => {
      glowEffect && setWithGlowEffect(true);
      onCountEnd?.();
    }, [glowEffect, onCountEnd]);

    const { start } = useCountUp({
      end: endVal,
      start: startVal,
      duration: 1.5,
      ref: countUpRef,
      startOnMount: false,
      startVal: startVal,
      onEnd: handleOnCountEnd,
      formattingFn: (value) => fNumber(value),
    });
    const [withGlowEffect, setWithGlowEffect] = useState(false);

    const handleOnCount = useCallback(() => {
      start();
      flyingCoinsEffect && renderFlyingCoins();
    }, [flyingCoinsEffect, renderFlyingCoins, start]);

    useImperativeHandle(ref, () => ({
      count: handleOnCount,
    }));

    return (
      <div className="flex flex-row items-center gap-2">
        <div
          ref={coinIconRef}
          className="h-6 w-6 rounded-full"
          style={{
            background:
              "url(/images/icon/heart_icon_yellow.svg) lightgray -3.279px -2.107px / 126.183% 118.473% no-repeat",
          }}
        ></div>
        <div
          ref={countUpRef}
          className={clsx(
            "text-[28px] font-medium uppercase leading-7 text-white transition-all duration-300 text-shadow-md",
            {
              "[text-shadow:rgba(255,162,0,1)_0px_0px_7px]": withGlowEffect,
            },
          )}
        >
          {fNumber(type === "down" ? startVal : endVal)}
        </div>
      </div>
    );
  },
);

export default CoinLabel;
