import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router";
import { Outlet, useNavigate } from "react-router-dom";
import { RootState } from "src/main/store";
import { AuthState } from "src/main/store/auth/slices";
import { Paths } from "src/main/utils/index";
import logger from "./logger";

const PublicRoutes = () => {
  const navigate = useNavigate();
  const { self, oauth } = useSelector<RootState, AuthState>((state) => state.auth);
  const location = useLocation();

  useEffect(() => {
    logger._console.log("zz", self, oauth);
    if (!!self && !!oauth && location.pathname !== Paths.Public.ForceUpdatePassword) {
      logger._console.log("has profile and token", { self }, { oauth });
      navigate("/home");
    }
  }, [navigate, self, oauth, location.pathname]);

  logger._console.log("main site", self, oauth);

  if (oauth === undefined) return null;
  logger._console.log("main site returning");

  return <Outlet />;
};

export default PublicRoutes;
