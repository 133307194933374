import { clsx } from "clsx";
import { FaMinus, FaPlus } from "react-icons/fa6";
import { PropsWithClassName } from "src/main/components";

interface QuantitySelectorProps extends PropsWithClassName {
  onIncrease: () => void;
  onDecrease: () => void;
  value: number;
  readonly?: boolean;
  disableIncrease?: boolean;
  disableDecrease?: boolean;
  minValue?: number;
  maxValue?: number;
}

const DEFAULT_MIN_INPUT_QUANTITY = 1;
const DEFAULT_MAX_INPUT_QUANTITY = 99;

const QuantitySelector = ({
  onIncrease,
  onDecrease,
  value,
  readonly = true,
  disableIncrease = false,
  disableDecrease = false,
  className,
  minValue = DEFAULT_MIN_INPUT_QUANTITY,
  maxValue = DEFAULT_MAX_INPUT_QUANTITY,
}: QuantitySelectorProps) => {
  return (
    <div className={clsx("flex items-center", className)}>
      <button
        onClick={onDecrease}
        disabled={disableDecrease || (!!minValue && value <= minValue)}
        className="flex h-7 w-7 items-center justify-center rounded border border-gray-200 bg-white text-gray-700 disabled:text-gray-300 active:bg-gray-100"
      >
        <FaMinus className="h-3 w-3" />
      </button>
      <input
        type="number"
        className="w-9 bg-transparent py-0 px-0.5 text-center text-sm text-gray-700 !outline-none ![box-shadow:none] ![border:none]"
        name="custom-input-number"
        value={value}
        readOnly={readonly}
      />
      <button
        onClick={onIncrease}
        disabled={disableIncrease || (!!maxValue && value >= maxValue)}
        className="flex h-7 w-7 items-center justify-center rounded border border-gray-200 bg-white text-gray-700 disabled:text-gray-300 active:bg-gray-100"
      >
        <FaPlus className="h-3 w-3" />
      </button>
    </div>
  );
};

export default QuantitySelector;
