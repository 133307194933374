import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Button } from "src/main/components";
import { Paths } from "src/main/utils";

interface MissionEmptyStateProps {}

const MissionEmptyState = (props: MissionEmptyStateProps) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <div className="w-full">
      <img
        src="/images/body-bg/empty-mission-bg.png"
        alt="no-mission"
        className="m-auto mb-1 h-[120px] w-[120px] lg:h-[240px] lg:w-[240px]"
      />
      <div className="w-full px-4 text-center text-base font-bold text-content-primary">
        {t("Check back soon for new missions")}
        <Button
          className="m-auto mt-4"
          size="sm"
          onClick={() => navigate(Paths.Private.Home)}
        >
          {t("EXPLORE REWARDS")}
        </Button>
      </div>
    </div>
  );
};

export default MissionEmptyState;
