import clsx from "clsx";
import { Form, Formik } from "formik";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { FaXmark } from "react-icons/fa6";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import { siteApi } from "src/main/api";
import { CreateShippingAddressRequest, CreateShippingAddressResponse, DeleteShippingAddressResponse } from "src/main/api/site/User";
import { Button, Card, Checkbox, ConfirmModal, InputField, Page } from "src/main/components";
import { DeployCountry } from "src/main/types";
import { getDeployEnv, handleApiResponse, Paths } from "src/main/utils";
import { SkeletonLoader } from "src/main/views/AddressUpdatePage/components";
import * as Yup from "yup";
interface Props {
  isManage?: boolean;
}

export default function AddressUpdateContent({ isManage = false }: Props) {
  const { id: addressId = "" } = useParams();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [createShippingAddress, { isLoading: isAdding }] = siteApi.useCreateShippingAddressMutation();
  const [updateShippingAddress, { isLoading: isUpdating }] = siteApi.useUpdateShippingAddressMutation();
  const [deleteShippingAddress, { isLoading: isDeleting }] = siteApi.useDeleteShippingAddressMutation();

  const handleDelete = async () => {
    if (!currentAddress) return;
    const result = await deleteShippingAddress({ addressId: currentAddress.id });
    const { data, error } = handleApiResponse<DeleteShippingAddressResponse>(result);
    if (data) {
      toast.success(t("The address has been deleted"));
      navigate("/manage-address");
    }
    if (error) {
      let errorMessage = error?.data?.error?.message ?? t("Cannot delete the address, please try again later");
      toast.error(errorMessage);
    }
  };

  const currentEnv = getDeployEnv();

  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const result = siteApi.useGetShippingAddressQuery({ addressId }, { skip: !addressId });
  const currentAddress = useMemo(() => result?.data?.model ?? undefined, [result?.data?.model]);

  const Schema = useMemo(() => {
    const requiredMsg = t("This field is required!", {}) ?? "This field is required!";

    return Yup.object().shape({
      recipientName: Yup.string().required(requiredMsg),
      recipientMobile: Yup.string().required(requiredMsg),
      address: Yup.string().required(requiredMsg),
      address2: currentEnv.deployCountry === DeployCountry.PH ? Yup.string().required(requiredMsg) : Yup.string(),
      city: Yup.string().required(requiredMsg),
      state: Yup.string().required(requiredMsg),
      postalCode: Yup.string().required(requiredMsg),
      country: Yup.string().required(requiredMsg),
    });
  }, [t, currentEnv.deployCountry]);

  const initialValues = useMemo(
    () => ({
      recipientName: currentAddress?.recipientName ?? "",
      recipientMobile: currentAddress?.recipientMobile ?? "",
      address: currentAddress?.address ?? "",
      address2: currentAddress?.address2 ?? "",
      city: currentAddress?.city ?? "",
      state: currentAddress?.state ?? "",
      postalCode: currentAddress?.postalCode ?? "",
      country: currentEnv.deployCountry === DeployCountry.PH ? "Philippines" : "Thailand",
      isDefault: currentAddress?.isDefault ?? false,
    }),
    [currentAddress, currentEnv.deployCountry],
  );

  const handleSaveAddress = async (values: CreateShippingAddressRequest) => {
    const result = currentAddress?.id
      ? await updateShippingAddress({
          addressId: currentAddress?.id,
          data: values,
        })
      : await createShippingAddress(values);

    const { data, error } = handleApiResponse<CreateShippingAddressResponse>(result);
    if (data) {
      setTimeout(() => {
        toast.success(t("The address has been updated."));
      }, 0);

      if (!!isManage) {
        navigate(Paths.Private.ManageAddress);
        return;
      }

      if (!!searchParams.get("backTo")) {
        navigate(searchParams.get("backTo") ?? "");
        return;
      }

      navigate(Paths.Private.AddressList);
    }
    if (error) {
      let errorMessage = error?.data?.error?.message ?? t("Cannot update the address, please try again later");
      toast.error(errorMessage);
    }
  };

  const handleDeleteAddress = () => {
    setShowDeleteModal(true);
  };

  if (addressId && currentAddress === undefined && !result.isLoading) {
    return <code>Invalid address id</code>;
  }

  return (
    <>
      <Page
        loading={result.isFetching}
        loader={<SkeletonLoader />}
        title={t(addressId ? "Edit Address" : "New Address")}
        className={clsx(
          {
            "lg:mt-20": isManage,
          },
          "text-white",
        )}
        footer={false}
        paddingX
        mobileView
      >
        <Card
          className="flex flex-col items-center gap-4 !rounded-lg p-4"
          variant="container-gradient"
        >
          <Formik
            enableReinitialize={true}
            initialValues={initialValues}
            validationSchema={Schema}
            onSubmit={(values) => {
              handleSaveAddress(values);
            }}
          >
            {({ errors, touched, values, handleChange }) => (
              <Form className="w-full">
                <div className="flex flex-col gap-4.5">
                  <p className="text-sm font-bold">{t("Contact")}:</p>
                  <div className="flex flex-col gap-2">
                    <InputField
                      value={values.recipientName}
                      onChange={handleChange}
                      name="recipientName"
                      label={t("Recipient's Full Name")}
                      error={!!errors.recipientName && !!touched.recipientName}
                      helperText={touched.recipientName ? errors.recipientName : ""}
                      variant="filled"
                      helperTextPreload={false}
                    />
                  </div>
                  <div className="flex flex-col gap-2">
                    <InputField
                      value={values.recipientMobile}
                      onChange={handleChange}
                      name="recipientMobile"
                      inputMode="tel"
                      label={t("Recipient's Mobile")}
                      error={!!errors.recipientMobile && !!touched.recipientMobile}
                      helperText={touched.recipientMobile ? errors.recipientMobile : ""}
                      variant="filled"
                      helperTextPreload={false}
                    />
                  </div>
                </div>

                <div className="mt-8 flex flex-col gap-4.5">
                  <p className="text-sm font-bold">{t("Address")}:</p>

                  {currentEnv.deployCountry === DeployCountry.PH && (
                    <>
                      <div className="flex flex-col gap-2">
                        <InputField
                          value={values.state}
                          onChange={handleChange}
                          name="state"
                          label={t("Region, Province")}
                          error={!!errors.state && !!touched.state}
                          helperText={touched.state ? errors.state : ""}
                          variant="filled"
                          helperTextPreload={false}
                        />
                      </div>
                      <div className="flex flex-col gap-2">
                        <InputField
                          value={values.city}
                          onChange={handleChange}
                          name="city"
                          label={t("City")}
                          error={!!errors.city && !!touched.city}
                          helperText={touched.city ? errors.city : ""}
                          variant="filled"
                          helperTextPreload={false}
                        />
                      </div>
                      <div className="flex flex-col gap-2">
                        <InputField
                          value={values.address2}
                          onChange={handleChange}
                          name="address2"
                          label={t("Barangay")}
                          error={!!errors.address2 && !!touched.address2}
                          helperText={touched.address2 ? errors.address2 : ""}
                          variant="filled"
                          helperTextPreload={false}
                        />
                      </div>
                      <div className="flex flex-col gap-2">
                        <InputField
                          value={values.postalCode}
                          onChange={handleChange}
                          name="postalCode"
                          label={t("Postal Code")}
                          inputMode="numeric"
                          error={!!errors.postalCode && !!touched.postalCode}
                          helperText={touched.postalCode ? errors.postalCode : ""}
                          variant="filled"
                          helperTextPreload={false}
                        />
                      </div>
                      <div className="flex flex-col gap-2">
                        <InputField
                          value={values.address}
                          onChange={handleChange}
                          name="address"
                          label={t("House No., Building, Soi, Moo, Street")}
                          error={!!errors.address && !!touched.address}
                          helperText={touched.address ? errors.address : ""}
                          variant="filled"
                          helperTextPreload={false}
                        />
                      </div>
                      <div className="flex flex-col gap-2">
                        <InputField
                          value={values.country}
                          onChange={handleChange}
                          name="country"
                          label={t("Country")}
                          error={!!errors.country && !!touched.country}
                          helperText={touched.country ? errors.country : ""}
                          disabled={true}
                          variant="filled"
                          helperTextPreload={false}
                        />
                      </div>
                    </>
                  )}

                  {currentEnv.deployCountry === DeployCountry.TH && (
                    <>
                      <div className="flex flex-col gap-2">
                        <InputField
                          value={values.address}
                          onChange={handleChange}
                          name="address"
                          label={t("Address 1")}
                          error={!!errors.address && !!touched.address}
                          helperText={touched.address ? errors.address : ""}
                          variant="filled"
                          helperTextPreload={false}
                        />
                      </div>
                      <div className="flex flex-col gap-2">
                        <InputField
                          value={values.address2}
                          onChange={handleChange}
                          name="address2"
                          label={t("Address 2 (Optional)")}
                          error={!!errors.address2 && !!touched.address2}
                          helperText={touched.address2 ? errors.address2 : ""}
                          variant="filled"
                          helperTextPreload={false}
                        />
                      </div>
                      <div className="flex flex-col gap-2">
                        <InputField
                          value={values.city}
                          onChange={handleChange}
                          name="city"
                          label={t("District")}
                          error={!!errors.city && !!touched.city}
                          helperText={touched.city ? errors.city : ""}
                          variant="filled"
                          helperTextPreload={false}
                        />
                      </div>
                      <div className="flex flex-col gap-2">
                        <InputField
                          value={values.state}
                          onChange={handleChange}
                          name="state"
                          label={t("Province")}
                          error={!!errors.state && !!touched.state}
                          helperText={touched.state ? errors.state : ""}
                          variant="filled"
                          helperTextPreload={false}
                        />
                      </div>
                      <div className="flex flex-col gap-2">
                        <InputField
                          value={values.postalCode}
                          onChange={handleChange}
                          name="postalCode"
                          inputMode="numeric"
                          label={t("Postal Code")}
                          error={!!errors.postalCode && !!touched.postalCode}
                          helperText={touched.postalCode ? errors.postalCode : ""}
                          variant="filled"
                          helperTextPreload={false}
                        />
                      </div>
                      <div className="flex flex-col gap-2">
                        <InputField
                          value={values.country}
                          onChange={handleChange}
                          name="country"
                          label={t("Country")}
                          error={!!errors.country && !!touched.country}
                          helperText={touched.country ? errors.country : ""}
                          disabled={true}
                          variant="filled"
                          helperTextPreload={false}
                          disabledVariant="lock-icon"
                        />
                      </div>
                    </>
                  )}
                </div>

                <div className="mt-6 flex items-center py-3">
                  <Checkbox
                    name="isDefault"
                    onChange={handleChange}
                    checked={values.isDefault}
                    className="h-5 w-5"
                    id="checkbox-default-address"
                    variant="filled"
                  />
                  <label
                    className="ml-2 text-sm"
                    htmlFor="checkbox-default-address"
                  >
                    {t("Set as Default Address")}
                  </label>
                </div>

                {!!isManage && currentAddress?.id && (
                  <div className="mt-4 flex cursor-pointer items-center justify-center">
                    <Button
                      size="sm"
                      type="button"
                      variant="bare"
                      onClick={handleDeleteAddress}
                      icon={<FaXmark className="inline-block text-base" />}
                    >
                      {t("Delete Address")}
                    </Button>
                  </div>
                )}
                <Button
                  type="submit"
                  loading={isAdding || isUpdating}
                  className="mt-6 w-full uppercase"
                >
                  {t("Submit")}
                </Button>
              </Form>
            )}
          </Formik>
        </Card>
        <ConfirmModal
          title={t("Confirm Delete")}
          message={t("Are you sure want to delete this address?")}
          onConfirm={handleDelete}
          onCancel={() => setShowDeleteModal(false)}
          show={showDeleteModal}
          confirmLoading={isDeleting}
          warningMessage={t("Kindly recheck your default address after deletion.")}
        />
      </Page>
    </>
  );
}
