import { clsx } from "clsx";
import { PropsWithChildren } from "react";

interface ModalContentProps extends PropsWithChildren {
  className?: string;
}

function ModalContent({ children, className }: ModalContentProps) {
  return <div className={clsx(className)}>{children}</div>;
}

export default ModalContent;
