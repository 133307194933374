import { clsx } from "clsx";
import { useId } from "react";
import { BoxSkeleton } from "./components";

interface SkeletonProps {
  component: (props: { className?: string; variant?: "primary" | "secondary" }) => JSX.Element;
  componentClassName?: string;
  componentProps?: Record<string, unknown>;
  className?: string;
  count?: number;
  variant?: "primary" | "secondary";
}

function Skeleton({
  component: Component,
  className,
  componentClassName,
  count = 1,
  componentProps,
  variant,
}: SkeletonProps) {
  const id = useId();

  return (
    <>
      {Array.from({ length: count }, (_, i) => (
        <div
          className={clsx(className, "h-full w-full")}
          key={["skeleton", id, i].join("-")}
        >
          <Component
            className={componentClassName}
            variant={variant}
            {...componentProps}
          />
        </div>
      ))}
    </>
  );
}

Skeleton.Box = BoxSkeleton;

export default Skeleton;
