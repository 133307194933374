type Console = typeof console;

class Logger {
  private static instance: Logger | null = null;
  readonly _console: Console;
  private constructor() {
    if (process.env.REACT_APP_DEBUG_LOG === "true") {
      this._console = console;
    } else {
      this._console = {
        log() {},
        debug() {},
        error() {},
        table() {},
        warn() {},
      } as Console;
    }
  }

  static getInstance(): Logger {
    if (!Logger.instance) {
      Logger.instance = new Logger();
    }
    return Logger.instance;
  }
}

const logger = Logger.getInstance();

export default logger;
