export default function IcoGiftGradient(props: any) {
  return (
    <svg
      width="21"
      height="21"
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g filter="url(#filter0_ii_2481_932)">
        <path
          d="M3.98123 10.3594H2.98123V18.3594C2.98123 18.8898 3.19194 19.3985 3.56702 19.7736C3.94209 20.1487 4.4508 20.3594 4.98123 20.3594H9.98123V10.3594H3.98123ZM16.9812 10.3594H11.9812V20.3594H16.9812C17.5116 20.3594 18.0203 20.1487 18.3954 19.7736C18.7705 19.3985 18.9812 18.8898 18.9812 18.3594V10.3594H16.9812ZM17.7722 5.35938C17.9189 4.87316 17.9894 4.36716 17.9812 3.85937C17.9812 1.92937 16.4112 0.359375 14.4812 0.359375C12.8592 0.359375 11.7762 1.84136 11.0772 3.44437C10.3882 1.92937 9.25023 0.359375 7.48123 0.359375C5.55123 0.359375 3.98123 1.92937 3.98123 3.85937C3.98123 4.45537 4.06023 4.94837 4.19023 5.35938H0.981232V9.3594H9.98123V7.35938H11.9812V9.3594H20.9812V5.35938H17.7722ZM5.98123 3.85937C5.98123 3.03236 6.65423 2.35937 7.48123 2.35937C8.36923 2.35937 9.19523 3.88438 9.67923 5.35938H6.98123C6.60723 5.35938 5.98123 5.35938 5.98123 3.85937ZM14.4812 2.35937C15.3082 2.35937 15.9812 3.03236 15.9812 3.85937C15.9812 5.35938 15.3552 5.35938 14.9812 5.35938H12.5042C13.0142 3.78338 13.7552 2.35937 14.4812 2.35937Z"
          fill="url(#paint0_linear_2481_932)"
        />
      </g>
      <defs>
        <filter
          id="filter0_ii_2481_932"
          x="0.981232"
          y="0.359375"
          width="20"
          height="20"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood
            floodOpacity="0"
            result="BackgroundImageFix"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset
            dx="0.25"
            dy="0.25"
          />
          <feComposite
            in2="hardAlpha"
            operator="arithmetic"
            k2="-1"
            k3="1"
          />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="shape"
            result="effect1_innerShadow_2481_932"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset
            dx="-0.25"
            dy="-0.25"
          />
          <feComposite
            in2="hardAlpha"
            operator="arithmetic"
            k2="-1"
            k3="1"
          />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="effect1_innerShadow_2481_932"
            result="effect2_innerShadow_2481_932"
          />
        </filter>
        <linearGradient
          id="paint0_linear_2481_932"
          x1="10.9812"
          y1="0.359375"
          x2="10.9812"
          y2="20.3594"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#B5A2F4" />
          <stop
            offset="1"
            stopColor="#6848D5"
          />
        </linearGradient>
      </defs>
    </svg>
  );
}

IcoGiftGradient.defaultProps = {};
